import VueRouter from "vue-router";
import HomeRoute from "./routes/HomeRoute.vue";
import LoginRoute from "./routes/LoginRoute.vue";
import LogoutRoute from "./routes/LogoutRoute.vue";
import AdministrationRoute from "./routes/AdministrationRoute.vue";
import AdminUsersRoute from "./routes/administration/users/UsersRoute.vue";
import AdminUserRoute from "./routes/administration/users/UserRoute.vue";
import AdministrationGroupsRoute from "./routes/administration/groups/GroupsRoute.vue";
import AdministrationGroupRoute from "./routes/administration/groups/GroupRoute.vue";
import AdminDatastoresRoute from "./routes/administration/datastores/DatastoresRoute.vue";
import AdminDatastoreRoute from "./routes/administration/datastores/DatastoreRoute.vue";
import AdminDataExportRoute from "./routes/administration/data-export/DataExportRoute.vue";
import AdminScenarioRoute from "./routes/administration/scenarios/ScenarioRoute.vue";
import AdminScenariosRoute from "./routes/administration/scenarios/ScenariosRoute.vue";
import AdminInstanceRoute from "./routes/administration/instances/InstanceRoute.vue";
import AdminInstancesRoute from "./routes/administration/instances/InstancesRoute.vue";
import AdminInstanceStatRoute from "./routes/administration/instances/InstanceStatRoute.vue";
import NotFoundRoute from "./routes/NotFoundRoute.vue";
import CacheRoute from "./routes/administration/cache/CacheRoute.vue";
import HlasRoute from "./routes/administration/hla/HlasRoute.vue";
import HlaRoute from "./routes/administration/hla/HlaRoute.vue";
import ProcessRoute from "./routes/administration/process/ProcessRoute.vue";
import ProcessDetailsRoute from "./routes/administration/process/ProcessDetailsRoute.vue";
import TerrainsRoute from "./routes/administration/terrains/TerrainsRoute.vue";
import TerrainRoute from "./routes/administration/terrains/TerrainRoute.vue";
import TerrainDetailsRoute from "./routes/administration/terrains/TerrainDetailsRoute.vue";
import TilesRoute from "./routes/administration/3dtiles/3DTilesRoute.vue";
import TileRoute from "./routes/administration/3dtiles/3DTileRoute.vue";
import TileDetailRoute from "./routes/administration/3dtiles/3DTileDetailRoute.vue";
import store from "./store";
const router = new VueRouter({
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active", // active class for *exact* links.
  routes: [
    {
      path: "/",
      component: HomeRoute,
    },
    {
      path: "/login",
      component: LoginRoute,
    },
    {
      path: "/logout",
      component: LogoutRoute,
    },
    {
      path: "/administration",
      component: AdministrationRoute,
    },
    {
      path: "/administration/users",
      component: AdminUsersRoute,
    },
    {
      path: "/administration/users/new",
      component: AdminUserRoute,
    },
    {
      path: "/administration/users/:id",
      component: AdminUserRoute,
    },
    {
      path: "/administration/groups",
      component: AdministrationGroupsRoute,
    },
    {
      path: "/administration/groups/new",
      component: AdministrationGroupRoute,
    },
    {
      path: "/administration/groups/:id",
      component: AdministrationGroupRoute,
    },
    {
      path: "/administration/datastores",
      component: AdminDatastoresRoute,
    },
    {
      path: "/administration/datastores/new",
      component: AdminDatastoreRoute,
    },
    {
      path: "/administration/datastores/:id",
      component: AdminDatastoreRoute,
    },
    {
      path: "/administration/data-export",
      component: AdminDataExportRoute,
    },
    {
      path: "/administration/scenarios",
      component: AdminScenariosRoute,
    },
    {
      path: "/administration/scenarios/new",
      component: AdminScenarioRoute,
    },
    {
      path: "/administration/scenarios/:id",
      component: AdminScenarioRoute,
    },
    {
      path: "/administration/instances",
      component: AdminInstancesRoute,
    },
    {
      path: "/administration/instances/new",
      component: AdminInstanceRoute,
    },
    {
      path: "/administration/instances/:id",
      component: AdminInstanceRoute,
    },
    {
      path: "/administration/instances/stats/:id",
      component: AdminInstanceStatRoute,
    },
    {
      path: "/administration/cache",
      component: store.state.serverInfo.features.cache
        ? CacheRoute
        : NotFoundRoute,
    },
    {
      path: "/administration/hla",
      component: store.state.serverInfo.features.hla
        ? HlasRoute
        : NotFoundRoute,
    },
    {
      path: "/administration/hla/new",
      component: store.state.serverInfo.features.hla ? HlaRoute : NotFoundRoute,
    },
    {
      path: "/administration/hla/:id",
      component: store.state.serverInfo.features.hla ? HlaRoute : NotFoundRoute,
    },
    {
      path: "/administration/process",
      component: store.state.serverInfo.features.process
        ? ProcessRoute
        : NotFoundRoute,
    },
    {
      path: "/administration/process/:name",
      component: store.state.serverInfo.features.process
        ? ProcessDetailsRoute
        : NotFoundRoute,
    },
    {
      path: "/administration/terrains",
      component: store.state.serverInfo.features.terrains3d
        ? TerrainsRoute
        : NotFoundRoute,
    },
    {
      path: "/administration/terrains/new",
      component: store.state.serverInfo.features.terrains3d
        ? TerrainRoute
        : NotFoundRoute,
    },
    {
      path: "/administration/terrains/edit/:name",
      component: store.state.serverInfo.features.terrains3d
        ? TerrainRoute
        : NotFoundRoute,
    },
    {
      path: "/administration/terrains/:name",
      component: store.state.serverInfo.features.terrains3d
        ? TerrainDetailsRoute
        : NotFoundRoute,
    },
    {
      path: "/administration/3dtiles",
      component: store.state.serverInfo.features.terrains3d
        ? TilesRoute
        : NotFoundRoute,
    },
    {
      path: "/administration/3dtiles/new",
      component: store.state.serverInfo.features.terrains3d
        ? TileRoute
        : NotFoundRoute,
    },
    {
      path: "/administration/3dtiles/edit/:name",
      component: store.state.serverInfo.features.terrains3d
        ? TileRoute
        : NotFoundRoute,
    },
    {
      path: "/administration/3dtiles/:name",
      component: store.state.serverInfo.features.terrains3d
        ? TileDetailRoute
        : NotFoundRoute,
    },
    {
      path: "*",
      component: NotFoundRoute,
    },
  ],
});
export default router;
