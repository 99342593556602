export default {
  getHelpContentMapByName: function (name) {
    switch (name) {
      case "Layer List":
        return "<p>In the layer list, it is possible to <strong>enable</strong> or <strong>disable</strong> the layers that you are interested in.</p> " +
          "<p>By ticking or unticking the checkbox in front of the layer name it will become <strong>visible</strong> or not <strong>visible</strong>.</p> " +
          "<p>By <strong>right-clicking</strong> on a layer <strong>'Manage by Controller'</strong> (orange checkbox), the user can choose from dropdown menu" +
          "to hide/show layers in layer list or in map for all players </p>" +
          "<p>It is possible to expand/collapse a <strong>group of layers</strong> by simply clicking on the layer name.</p>" +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";

      case "Legend":
        return "<p>The legend tool show, as the name implies, a legend for all the layers visible on map which helps " +
          "the user understanding the meaning of all the visual information seen on map.</p>" +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";

      case "Measurement":
        return "<p>The measurement tool let the user measure <strong>Coordinates (point)</strong>, <strong>Distances (lines)</strong>, or <strong>Surfaces (polygons)</strong> on the map.</p>" +
          "<p>The <strong>three buttons</strong> on top let you enable the measurement for respectively coordinates, distances and surfaces.</p>" +
          "<p>(When you enable one of the tools you are able to choose the appropriate measurement unit for you. For distances you could choose <strong>Miles</strong> or <strong>Kilometers</strong>).</p>" +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";

      case "Draw":
        return "<p>By using the draw tool, you can draw on map points, lines, polygons or write text on the map just like you should do on paper map and a marker but in a digital way. The draws you save will be available <strong>only</strong> to your team members or controller users.</p>" +
          "<p>The <strong>draw panel</strong> is composed by:</p>" +
          "<ul>" +
          "<li><strong>Drawing tools:</strong> that let you enable a specific drawing tool </li>" +
          "<li><strong>Proprieties:</strong> that let you change the drawing properties like stroke color or stroke width (the available properties depend on the selected drawing tool and will be visible only if there is one selected)</li>" +
          "<li><strong>Planning Layers:</strong> that consists of all draws you loaded.</li>" +
          "</ul>" +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";

      case "Statistics":
        return "<p>The statistics tool allows you to get some statistical data for an area of interest delimited by a Polygon.</p> " +
          "<p>To <strong>start drawing</strong> the polygonal area of interest enable the polygonal drawing tool by clicking its button. Click at each vertex and at the end double click to finish.It is possible to delete the polygon by clicking the x button of the corresponding field.</p> " +
          "<p>By clicking <strong>“Execute”</strong> to start the computation of all the statistics predefined by the scenario (For example, for Archaria scenario we have the hospital total beds, buildings count and population).The main values are shown in bold text while subcategories are show with regular font.</p>" +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";


      case "Orbat":
        return "<p>The orbat tool let you view and manage your own units.(Controller user do not own any unit but can see and manage the units of all sides/faction).</p> " +
          "<p>By clicking a unit, you can <strong>show/hide</strong> its subordinates and its properties table will be show. " +
          "In order to <strong>move a unit</strong>, click the move button near its coordinates, then click on map at the destination coordinates.</p>" +
          "<p><strong>Only controller can move, delete </strong> or <strong>edit unit. </strong></p>" +
          "<p>The <strong>controller users </strong> can enable the unit insert panel by clicking the “Add units” button on top. Click again to get back to the Orbat visualization and by right-clicking on a unit he can select 'Transfer unit item'. The 'Transfer of responsibility' dialog will be opened " +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";


      case "Line of Sight":
        return "<p>The line-of-sight tool let you, given 2 points on map <strong>(observer and target)</strong>, know if the observer can or not see the target considering the terrain surface that includes eventually obstacles like buildings.</p> " +
          "<p>For both the points it is possible to set an offset relatively to the terrain, for example the observer has a default offset of 1.7m to simulate the view of an upstanding person. " +
          "Alter you define all the parameters you can click “Execute” in order to start the computation.</p>" +
          "<p><strong>The result </strong> will be visible on map and in the results table as well.(It is possible to hide a specific result by removing the tick for the corresponding table row).</p> " +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";


      case "Viewshed":
        return "<p>The viewshed tool let to you, given a point on the map (observer), to know which areas are visible within a certain radius from the observer.  The areas in green are the visible areas while those in red are the non-visible ones.</p> " +
          "<p>Is possible to set two different offsets relatively to the terrain:</p> " +
          "<p><strong> •	Observer offset</strong> (for example the observer has a default offset of 1.7m to simulate the view of an upstanding person)</p>" +
          "<p><strong> •	Target offset</strong> (for example the target has a default offset of 1.7m);</p>" +
          "<p>Another option is <strong>Radius</strong>, which sets the <strong>viewshed width</strong>.</p> " +
          "<p>After you define all the parameters you can click <strong>“Execute”</strong> in order to start the computation.The result will be visible on map and in the results table as well.It is possible to hide a specific result by removing the tick for the corresponding table row and also set the opacity of the resulting image by changing the value in the 'opacity' field.</p>" +
          "<p>The result is an image where the <strong>visible</strong> parts will be green, and the <strong>non-visible</strong> parts will be red.</p>" +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";


      case "Events":
        return "<p>The events section tool allows you to view and manage events in the map.(The controller cannot have its own units, but it can see and manage the events of all factions).</p> " +
          "<p>By clicking on an event, you can <strong>show/hide</strong> the event properties in the table at the bottom.</p> " +
          "<p>At the top of the table you can select the edit button to change the event's properties or to delete it.</p> " +
          "<p>The controller can enable the insert event panel by clicking the <strong>'add events'</strong> button and after that can choose the desired side for the event.</p> " +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";

      case "Export":
        return "<p>The tool allows you to export, for different types of simulators (e. g. <strong>SWORD, JCATS</strong>...), one or more layers of the current scenario to a .zipper file with the respective characteristics of the chosen simulator. </p> " +
          "<p>In addition, it will be possible to draw a specific area of interest for which to cut the selected layers and possibly also elevation rasters.</p> " +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";
          
      case "Export Masacode dropdown":
        return "<strong>**</strong><br> this specific MASA CODE indicates to the system to compute one layer for each submerged lands (Sea) and emerged lands (UnknownLand) using the layer which it is associated (Only possible if an area of interest is indicated)."+
        "<p><a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a></p>";

      case "Documents":
        return "<p>Documents widget allows you to see a list of documents related to the current vignette of the scenario being played. These are divided into folders and you can click on the single document to bring up a popup with descriptive details.</p> " +
          "<p>In the descriptive popup we can find the details of the document such as the file name, its size and a short description. Using the <strong>'Download'</strong> button you can download the document locall.</p> " +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";

      case "Dice Roll":
        return "<p>Dices widget allows members of the <strong>white/controller</strong> team to roll some dices that may be useful add some randomness to the decision-making process during the adjudication phases of the wargames.</p> " +
          "<p>You have just to select how many dices you want do roll and how many faces these dices should have and the click the Roll button to have an immediate result.</p> " +
          "<p>By ticking the checkbox <strong>'show result to others'</strong>, the result of the dice roll will be broadcast with a popup to all users connected to the platform in view-only mode.</p> " +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";

      case "Routing":
        return "<p>The routing tool let you compute the path between two points on map following the road network of the scenario.</p> " +
          "<p>The result could be optimized to get the shortest path (<strong>by distance</strong>) or to get the fastest (<strong>by time</strong>).</p> " +
          "<p>You could also choose to honor or not the <strong>one-way</strong> flagging or not the <strong>“Respect One ways”</strong> field</p> " +
          "<p><strong>NOTE</strong>: it is possible to remove the point location from map by clicking the <strong>x</strong> button.</p> " +
          "<p>Click the <strong>“Execute”</strong> button to start the computation.All the results are available on the map and in a table format as well. It is possible to hide a result from the map by removing the tick from the corresponding table row.</p> " +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";


      case "Service Area":
        return "<p>The service area tool, given a point (e.g.: detonation of an improvised explosive device) and some breaks (meters or minutes), estimates the possible escape area following the road network available.Like the Routing tool, the service area tool can be optimized by distance or by time.</p> " +
          "<p>To set the location of the point of interest you have just to enable the point drawing tool and then click on map at the desired location.</p> " +
          "<p><strong>NOTE</strong>: it is possible to remove the point from the map by clicking the x button of the corresponding field.Set the breaks values separated by comma (,) if more than one is needed (e.g.: 3000,5000)</p> " +
          "<p>In order to hide a specific result from map just untick the checkbox of the corresponding table row</p> " +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";

      case "Map":
        return "<p>The Map tool let you change the base map used by choosing from one of the available ones for the scenario you are running.In Archaria scenario for instance there are three different base maps: <strong>Political</strong>, <strong>Physical</strong> or <strong>Satellite</strong>.</p> " +
          "<a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a>";
      case "Simulation Dialog":
        return "<p>A tool to simulate an alternative game environment to the previous round of the selected vignette.</p> " +
          "<p>A dotted line on the map will show the future movement of the unit and the future arrival point.</p>" +
          "<p>With the 'Syncronise Simulation' button, it will be possible to transfer the newly simulated changes to the source vignette.</p>" +
          "<p><a href='/client/manual/Wisdom-SimpleUserAdmin.pdf' target='_blank'>For more information, please see the manual.</a></p>";
    }
  },
  //Sezione help della parte amministratore di WISDOM.
  getHelpContentAdminByName: function (name) {
    switch (name) {
      /// START OF SCENARIO SETTING  
      case "scenario.units.editorHtml":
        return `<table class='table table-sm table-striped orbat-properties felx-grow-1'>
        <thead>
          <th>Command</th>
          <th>Description</th>
        </thead>
        <tbody>
          <tr>
            <th>{{unit.name}}</th>
            <td>Get the e
            unit name</td>
          </tr>
          <tr>
            <th>{{unit.unit_id}}</th>
            <td>Get the unit id</td>
          </tr>
          <tr>
              <th>{{unit.parent_id}}</th>
              <td>Get id of father</td>
          </tr>
          <tr>
              <th>{{unit.category}}</th>
              <td>Get the unit category</td>
          </tr>
          <tr>
              <th>{{unit.symbol}}</th>
              <td>Get the unit symbol</td>
          </tr>
          <tr>
              <th>{{unit.strength}}</th>
              <td>Get the unit strength</td>
          </tr>
          <tr>
              <th>{{unit.lon}}</th>
              <td>Get the unit longitude</td>
          </tr>
          <tr>
              <th>{{unit.lat}}</th>
              <td>Get the unit latitude</td>
          </tr>
          <tr>
              <th>{{unit.ele}}</th>
              <td>Get the unit elevation</td>
          </tr>
          
          <tr>
              <th>{{unit.capabilities}}</th>
              <td>Get the unit capabilities</td>
          </tr>
          <tr>
              <th>{{unit.symbol_preview}}</th>
              <td>Get the unit symbol</td>
          </tr>
        </tbody>
      </table>
      <table class='table table-sm table-striped orbat-properties felx-grow-1'>
        <thead>
          <th>Functions</th>
          <th>Description</th>
        </thead>
        <tbody>
          <tr>
            <th>{{#toLowerCase}}value{{/toLowerCase}}</th>
            <td>Method transforms value into lowecase </td>
          </tr>
          <tr>
            <th>{{#toUpperCase}}value{{/toUpperCase}}</th>
            <td>Method transforms value into uppercase </td>
          </tr>
          <tr>
            <th>{{#length}}value{{/length}}</th>
            <td>Method count the number of characters </td>
          </tr>
          <tr>
            <th>{{#encodeURIComponent}}value{{/encodeURIComponent}}</th>
            <td>Method removes special characters and spaces in a url <br> e.g. src=/client/images/<strong>{{#encodeURIComponent}}</strong>{{unit.name}}<strong>{{/encodeURIComponent}}</strong> </td>
          </tr>
           <tr>
            <th>{{#getStrengthWithClass}}{{/getStrengthWithClass}}</th>
            <td>Method return td element with dynamic class and value of strength
          </tr>
          <tr>
              <th>{{#trim}}value{{/trim}}</th>
              <td>Method removes spaces in the value</td>
          </tr>
          <tr>
              <th>{{#toPrecision}}value,parameter{{/toPrecision}}</th>
              <td>Method formats a number to a specified length<br> e.g. <strong>{{#toPrecision}}</strong>{{unit.lat}},4<strong>{{/toPrecision}}</strong></td>
          </tr>
          <tr>
              <th>{{#floor}}value{{/floor}}</th>
              <td>Method returns the largest integer less than or equal to a given number</td>
          </tr>
          <tr>
              <th>{{#round}}value{{/round}}</th>
              <td>Method rounds a number to the nearest integer.</td>
          </tr>
          <tr>
              <th>{{#ceil}}value{{/ceil}}</th>
              <td>Method rounds a number upwards to the nearest integer, and returns the result.</td>
          </tr>
          <tr>
              <th>{{#random}}parameter{{/random}}</th>
              <td>Method returns a random number from 1 and N that is the value of parameter</td>
          </tr>
        </tbody>
      </table>
      <a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>"`;

      case "CMS":
        return "<p>The CMS <strong>('Content Management System')</strong> allows you to choose which type of documents to show based on the vignette being played and the player's side.The files that will be uploaded will be downloadable by the players in the appropriate Documents tools.</p>" +
          "<p>By clicking the <strong>'Add Cms'</strong> button you can add this feature to your scenario. Then you will see the following screen with a simple blank file manager. You can remove the cms from the scenario by clicking the <strong>'Remove Cms'</strong> button at the top-right corner, then confirm the operation.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Map Journal":
        return "<p>This feature allows you to define multiple rich text content sections associated with different main content. As you scroll down through the sections that you had defined the main content changes accordingly.</p>" +
          "<p>By clicking the <strong>'Add Map Journal'</strong> button you can add this feature to your scenario. Then you must configure it.You can remove the map journal from the scenario by clicking the <strong>'Remove Map Journal'</strong> button at the top-right corner, then confirm the operation.</p>" +
          "<p>You can define different content for each vignette that your scenario has defined. In addition, the <strong> 'Raw JSON'</strong>  section let an advanced user modify content manually. For more information, please see the manual for Admin User.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";


      case "Base Info":
        return "<p>Base info shows the name of the scenario you are creating, defining the center point of the map, the minimum and maximum zoom, and the default zoom when the map loads.</p>" +
          "<p>You can also enable the <strong>'3D'</strong> for your scenario by ticking the appropriate checkbox. In that case you must define the terrain to use. For more information, please see the manual for Admin User.</p>" +
          "<p>As you can see, in this section you can enable the <strong>'Widget'</strong> that you want to use in this specific Scenario.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Vignettes":
        return "<p>This section defines the vignettes that will be played with the scenario. The addition of the vignettes is very simple, from the vignette section, click on <strong>'Add'</strong> and enter the name of the vignette. Once you have created, you can change the order of the list by using the <strong> ^ v</strong>  keys. </p>" +
          "<p>The order of the vignettes is important during the game phase because they will be played in progressive order compared to how they were inserted in this section.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Sides":
        return "<p>The sides section lists the factions that are expected in the scenario. In the case of wargaming, a typical side setting involves: <strong>blue-friendly</strong>, <strong>red-enemy</strong>, <strong>green-neutral/local government</strong> and <strong>white-controller/administrator</strong>.</p>" +
          "<p>To add a side click on the <strong>'Add'</strong> button and in the window that appears enter the name of the side, the group of users to associate and the type of <strong>'Affiliation'</strong> (which can be friend, hostile, neutral or unknown). You can also enable the controller function for only one side of those in the list. </p>" +
          "<p>Once the different sides have been created, the section will list them with the option to change their settings, delete them or move the order of the sides. The order of the sides, unlike the order of the vignettes, is completely irrelevant for the purposes of the game.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Dices":
        return "<p>In this section you can enable the <strong>Dices Widget</strong>. The Admin can decide which section can roll the Dices and also which section can see the result of the Dices rolled from different sections.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Layers":
        return "<p>This is the section in which you define the organization of layers, their visibility in the map, and their symbolism. Layers can be organized into main layers, groups, and subgroups. Main layers are layers or groups that are located on the root are also called root layers.</p>" +
          "<p>The features included in the layer schema are simple, but it's critical to have a clear idea of how layers are organized to avoid repeated steps.This is the longest and most complex step in creating the scenario.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Basemaps":
        return "<p>In this section, you can define the basemaps that are in the scenario. </p>" +
          "<p>By default, there is only the <strong>Open Street Map Basemap</strong>, but additional basemaps can be created using WMS/WMTS services published through QGIS Server. These services will generate caches to speed up the loading of the basemap. </p>" +
          "<p>Configuring a basemap involves filling in these fields:</p>" +
          "<p><strong>1.</strong> <strong>Name</strong> : where you will indicate the name, you want to associate with the basemap (e.g. political basemap);</p>" +
          "<p><strong>2.</strong> <strong>Thumbanail URL</strong> : pointing to the folder where the thumbnail image that will be displayed in the web mapping application within the basemap widget is contained and allows to improve the choice of basemap by the simple user.</p>" +
          "<p><strong>3.</strong> <strong>Type</strong> : the format type of service/cache, which can be of type XYZ or WMTS or WMS.</p>" +
          "<p><strong>4.</strong> <strong>URL</strong> : the path to the folder where the basemap cache is contained (es:/wmts/scenario1/).</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Statistics":
        return "<p>In this section, you can set statistics on layers on the map.  </p>" +
          "<p>To set statistics, you must enter the table (or layer) on which the statistics will apply. These tables can be searched among the layers imported into the scenario in the layer section and in this case in the <strong>'Type'</strong> we will insert Layer, or on a datastore and in the latter case the Type will be <strong>'Datastore'</strong>.</p>" +
          "<p>The <strong>'Total'</strong> field requires the name to be used to highlight the number of items calculated in the statistic (e.g., in the case of buildings, the total field name may be for example 'number of buildings present').</p>" +
          "<p>You can set additional statistics on <strong>specific columns</strong> of which we can calculate the sum of the contained values, or the number of elements or the average. For example, if we have a layer that identifies the population, a column might tell me about the population by age group (e.g. 0-18 years), in this case if I select the statistic by sum, I'll have the number of the population in this age group.</p>" +
          "<p>You can group the table information ('<strong>Group By</strong>') according to a specific field, for example for buildings you can calculate how many buildings there are in each use destination (e.g., no residential buildings, no commercial buildings, etc.).</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Routing":
        return "<p>In this section, you can set the data source that will be used by the routing tool in the web application. The section prompts you to select the road graph layer in the database. </p>" +
          "<p>In the road graph there should be <strong>'transittime'</strong> columns indicating the average travel time of a road section depending on the type of road and the oneway column that reports whether a section is one-way and through a convention name indicates its direction (<strong>FT</strong> or <strong>TF</strong>).</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Line of Sight / Viewshed":
        return "<p>This section contains the settings for the <strong> 'Line of Sight'</strong>  and <strong> 'Viewshed'</strong> tools. </p>" +
          "<p>In the <strong> Digital Surface Model (DSM)</strong>  field, enter the path of the altitude raster, in <strong> 'Srid'</strong>  (Spacial Reference Identification) enter the DSM reference system.In the <strong> 'Step' field</strong> , enter the line split interval value between the two points used for calculation in the DSM reference system measurement unit.Whereas in the <strong> 'Precision'</strong>  field enter the number of decimal places you want to have in output (for a metric system it is enough to have two decimal places).  </p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";




      case "Units":
        return "<p>In this section, you can enable the <strong>'Orbat Management'</strong> tool for managing units in the web application and to arrange the different display of the orbats according to the turns/vignettes played. To enable these two features, simply to the kind of wargame that you want to play.</p>" +
          "<p><strong>'UP Wargame'</strong> is a wargame passed on the rules of Urbanisation Project where units are moved on a turn-based system and you will see the units of the other sides but only at their start position for that turn. Your own units movements are synchronized in realtime to the other players of you side/faction. For this kind of wargame, you can also check the limit movements feature to limit the units movements to the km specified in the <strong>'Max Move field'</strong> of the unit.</p>" +
          "<p><strong>'Luna Wargame'</strong> is a wargame designed for the training of UAV pilots for the threat finding in a limited number of turns. If a red unit is inside the radius/ISR of a blue unit, it will become visible the the blue team. Another rule for this wargame is that UAV can only move in a strainght line and cannot through no-fly-zone. For this rason this kind of wargame requires to define which layer whave to be used as no-fly zones. The layer access should be set to <strong>'Instance'</strong>.</p>" +
          "<p><strong>'Custom Wargame'</strong> let you configure a wargame that may use a different combination of the features used by UP and Luna wargames.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";



      case "Events":
        return "<p>In this section the user can enable events in the scenario by clicking on the <strong>'Add Events Widget'</strong> button. You can <strong> remove the Events section </strong>  by clicking on the button on the top right corner and then confirm the operation.</p>" +
          "<p>In the add process the user can choose the type of popup to be displayed on map. The options are <strong>Classic</strong> and <strong>Templated</strong>.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";


      case "Raw JSON":
        return "<p>This section lists all scenario settings in <strong>JSON format</strong>. The json can only be edited by those who have the technical capabilities, since any error that you make to your code risks changing/erasing the scenario settings previously entered through the <strong>Gui (Graphics User Interface)</strong>.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      /// END OF SCENARIO SETTING  

      case "Users":
        return "<p>On this screen, you manage users who have access to the platform. Each user's profile is editable, and you can also remove one or more users from the list except the administrator user.</p>" +
          "<p>Clicking on the <strong>“New”</strong> button will open an input window, with the fields to be filled in by the admin  to add the new user. After the fields are completed, you can add the user as a member of an existing group.</p>" +
          "<p>With the <strong>”Import”</strong> button, the admin can import an Excel sheet containing in the rows the new users to be created.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Groups":
        return "<p>Groups are intended to link different users to a single profile.Groups can be enclosed all users who play a certain vignette, who participate in a certain scenario or who play in different sides (e.g. red, green or blue side).</p>" +
          "<p>The creation of a new group is done by clicking the <strong>“New”</strong> button, writing the name of the group, and selecting the users who will be part of it (It is possible to edit or delete groups that already created).</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Datastores":
        return "<p>Datastores are geographic databases that contain all the geographic information for a given location.The Datastores interface contains the list of available databases. Through this interface, you can modify the connection to a database or add a new one.  </p>" +
          "<p>To add a new connection to the database, click the <strong>“New”</strong> button. A new window will open in which there are several fields that need to be filled in to enable the connection to the database.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "HLA Connectors":
        return "<p>The section <strong>“HLA Connectors”</strong> allow you to define an HLA Connection with all the required information. Below there are information regarding the connections already created, and under <strong>'Actions'</strong> Admin user can delete or modify connection parameters.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "HLA Information":
        return `<table class='table table-sm table-striped orbat-properties felx-grow-1'>
            <thead>
              <th>Fieds</th>
              <th>Description</th>
            </thead>
            <tbody>
              <tr>
                <th>Name</th>
                <td>The name of the HLA Connection, used by the platform to identify a specific connection.</td>
              </tr>
              <tr>
                <th>RTI Host</th>
                <td>The Host address that must be reached.</td>
              </tr>
              <tr>
                  <th>RTI Port</th>
                  <td>The RTI Port.</td>
              </tr>
              <tr>
                  <th>Preset</th>
                  <td>Every Preset represents a specific configuration used during the RTI connection. A preset contain: 
    1.	All the Object Class and attributes used during the connection;
    2.	All the Interaction Class and parameters used during the connection;
    3.	All the FOMs (Federation Object Model) used to bind the streaming of data handled by the RTI;
    </td>
              </tr>
              <tr>
                  <th>Federation Name</th>
                  <td>The Federation Name that will be created on the RTI after the connection.</td>
              </tr>
              <tr>
                  <th>Icons</th>
                  <td>This section allow to map Entity Types(DIS dot notation) to APP6B/APP6D used by Wisdom.
    <br/>It is also possible to filter to which kind of entities to apply the icon: Aggregates, Disaggregates and/or Humans.
    </td>
              </tr>
              <tr>
                  <th>Damages</th>
                  <td>It’s used only in the RPR FOM preset with these default values:
    •	Key: 0;  value: 100;
    •	Key: 1;  value: 66;
    •	Key: 2;  value: 33;
    •	Key: 3;  value: 0;
    If these values do not satisfy, you need to overwrite the preset configuration.
    </td>
              </tr>
              <tr>
                  <th>Sides</th>
                  <td>In the RPM FOM represents the country code mapping of the entities to our sides, the corresponding set of icons to be used and the affiliation. In the GlobalSim FOM represents the sides index mapping.</td>
              </tr>
            </tbody>
            </table>`+
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Scenarios":
        return "<p>This section allows you to configure the scenario. Configuring the scenario means organizing the display and style of layers belonging to one or different data stores, associating access to layers according to vignettes and sides, and associating the controller function to a side. </p>" +
          "<p>Scenarios that are already configured can be modified or deleted, or you can create a new scenario with the <strong>“New”</strong> button.The scenario creation phase is a <strong>complex work</strong> that requires many settings and a lot of effort in creating the layer hierarchy.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";


      case "Instances":
        return "<p>Creating instances within the WISDOM platform allows you to associate the scenario with a specific group. For example, we can <strong>associate the scenario with the administrators group</strong>.</p>" +
          "<p>Each instance is prompted for a specific name, but only the scenario name will appear on the home page. Only if the user is enabled in multiple groups that have participated with the same scenario on different instances, then a list will appear, where the primary name is the scenario name and the name of the instances with the direct access keys to the scenario will appear in the sub-list.</p>" +
          "<p>After creating an instance or editing the linked scenario, you need to <strong>'Restart'</strong> the instance with the button next to the edit.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";


      case "Instance Setting":
        return "<p>During the editing of an instance you can specify if the “Units” have to be retrieved from an HLA Connection.</p>" +
          "<p>To make this possible we must active the <strong>“Active HLA Connection”</strong> option, after this the system show a dropdown field named “HLA connector Name” where you can select the desired connection.While the instance initialization process is running, if the HLA it’s activated, an HLA client will be started to reach the desired RTI and receive the incoming data.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Instance Monitoring":
        return "<p>Every created Instances will be provided with a specific monitor dashboard, reachable by pressing the icon (monitor). Inside every monitor dashboard will be presented all the information collected (near-real time) about a specific instance.</p>" +
          "<p>The information in the monitor concern:</p>" +
          "<p>1.<strong>	HTTP Timing Request</strong></p>" +
          "<p>2.<strong>	Number of active users</strong></p>" +
          "<p>3.<strong>	Number of HTTP requests</strong></p>" +
          "<p>4.<strong>	Number of HLA events</strong></p>" +
          "<p>5.<strong>	List of HTTP requests</strong></p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Cache":
        return "<p>In this section, you can prepare and generate the basemap cache.</p>" +
          "<p>The cache can be generated from a map published in WMS/WMTS format. The section is divided into two tabs:</p>" +
          "<p><strong> •	Cache Form</strong> tab, (default tab) in which you can generate a cache request or prepare a cache package (a collection of one or more requests) and you can monitor the required process of caching.</p>" +
          "<p><strong> •	Cache Package</strong> tab, where you can export (in .JSON format) or generate the cache package prepared in Cache Form tab or by a cache package prepared previously and imported.</p>" +
          "<p>To optimize cache production and reduce its weight in terms of GB, a procedure was used to use very large extents (even at the world level) for low zoom levels and gradually narrower extents for subsequent zoom levels.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";

      case "Terrains":
        return "<p>In this section there is the list of available 3D terrains. Also you can generate new 3D terrains from an elevation file in TIF format.</p>" +
          "<p>Clicking on the <strong>“New”</strong> button will open the window for configuring a new terrain. Choose a name for the new terrain, which must be unique, and select an elevation file from the drop-down menu. All other parameters are optional and already preset.Pay attention to the <strong>zoom level</strong>, because the time it takes to generate the terrain depends on this parameter.<strong> The higher the level, the more time it will take.</strong></p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";


      case "Terrains Building":
        return "<p>Clicking on <strong>'Start building terrain'</strong> will start the process of generating the terrain which will continue its execution in the background, you can monitor this process in the 'Process' section.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";


      case "3D Tiles":
        return "<p>In this section you can manage your 3D tiles. The 3D tiles are 3D models organized and optimized for web streaming.</p>" +
          "<p>You can generate a 3D Tile for buildings starting from their footprint, elevation, and height. The footprints will be extruded and appropriate textures will be applied to obtain 3D textured buildings.</p>" +
          "<p><strong> •</strong>  If an externally generated 3D tileset was copied to the server appropriate folder you can click  <strong>“Reload”</strong> to have it recognized by the platform.</p>" +
          "<p><strong> •</strong>  If you click <strong>“New”</strong> button you can start a new process that generates your buildings form footprints.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";


      case "Process":
        return "<p>In this section there is a list of running and stopped processes. You can immediately see the type of process and the status or how the process endedThen there are a series of actions that can be performed on a process depending on whether it is running or not.</p>" +
          "<p><strong> •</strong>  If a process is <strong>running</strong>, you can see the detail or stop it.</p>" +
          "<p><strong> •</strong>  If a process is <strong>stopped</strong>, you can see the detail, restart it, or delete it from the list.</p>" +
          "<p>Clicking on the process detail button you can see all the information of that process, including the input parameters, the exit code and the output and error logs.</p>" +
          "<a href='./manual/Wisdom-AdminManual.pdf' target='_blank'>For more information, please see the manual for Admin User.</a>";
    }
  }
}

