<template>
  <form @submit.prevent="" class="cms-item-property">
    <div>
      <h2 v-if="this.value.type == 'file'">File Properties</h2>
      <h2 v-if="this.value.type == 'dir'">Folder Properties</h2>
    </div>
    <div class="form-group row">
      <label
        v-if="this.value.type == 'file'"
        for="section-title"
        class="col col-12 col-md-2"
        >Filename</label
      >
      <label
        v-if="this.value.type == 'dir'"
        for="section-title"
        class="col col-12 col-md-2"
        >Name</label
      >
      <div class="input-group col col-12 col-md-10">
        <input
          id="section-title"
          placeholder="Title..."
          type="text"
          class="form-control form-control-sm"
          v-model.lazy="props.title"
        />
      </div>
    </div>

    <div v-if="this.value.type == 'file'" class="form-group row">
      <label class="col col-12 col-md-2">Upload</label>
      <div class="input-group col col-12 col-md-10">
        <input
          v-if="this.value.upload === null"
          type="file"
          id="upload"
          name="upload"
          class="form-control form-control-sm"
          @change="onFileChange"
          refs="inputFile"
        />
        <span
          v-if="this.value.upload !== null"
          class="form-control form-control-sm"
        >
          {{ this.value.upload.name }} -
          {{ this.bytesToSize(this.value.upload.size) }}
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col col-12 col-md-2">Parent Folder</label>
      <div class="input-group col col-12 col-md-10">
        <select name="parent" class="form-control mb-1" v-model="parent">
          <option :value="''" selected>/</option>
          <option
            v-for="item in dirTree.filter((it) => it.id !== this.value.id)"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="section-content" class="col col-12 col-md-2"
        >Description</label
      >
      <div class="input-group col col-12 col-md-10">
        <app-editor
          id="section-content"
          v-model="props.content"
          class="w-100"
        />
      </div>
    </div>
    <div v-if="this.value.type == 'file'" class="form-group row">
      <label class="col col-12 col-md-2">Vignettes:</label>
      <div
        class="input-group col col-12 col-md-10"
        style="flex-direction: column"
      >
        <label
          class="custom-control custom-checkbox justify-content-start"
          v-for="vignette in availableVignettes"
          :key="vignette"
        >
          <input
            type="checkbox"
            class="custom-control-input"
            name="vignette"
            v-model="vignettes[getIdByName(vignette)]"
            :checked="vignettes[getIdByName(vignette)] ? 'checked' : ''"
            @change="onCheckboxChange"
          />
          <span class="custom-control-label">{{ vignette }}</span>
        </label>
      </div>
    </div>
    <div v-if="this.value.type == 'file'" class="form-group row">
      <label class="col col-12 col-md-2">Sides:</label>
      <div
        class="input-group col col-12 col-md-10"
        style="flex-direction: column"
      >
        <label
          class="custom-control custom-checkbox justify-content-start"
          v-for="side in availableSides"
          :key="side"
        >
          <input
            type="checkbox"
            class="custom-control-input"
            name="side"
            v-model="sides[getIdByName(side)]"
            :checked="sides[getIdByName(side)] ? 'checked' : ''"
            @change="onCheckboxChange"
          />
          <span class="custom-control-label">{{ side }}</span>
        </label>
      </div>
    </div>
  </form>
</template>
<script>
import equal from "deep-equal";
import AppEditorVue from "../../../../../../xserver-common/components/AppEditor.vue";
import { getIdByName } from "../../../../../../xserver-common/dist/utils";
export default {
  name: "cms-item-property",
  props: [
    "value",
    "scenario",
    "dirTree",
    "availableVignettes",
    "availableSides",
  ],
  components: {
    "app-editor": AppEditorVue,
  },
  watch: {
    "props.title"() {
      this.updateSide();
    },
    "props.content"() {
      this.updateSide();
    },
    upload() {
      if (
        this.type === "file" &&
        this.upload === null &&
        this.$refs.inputFile
      ) {
        this.$refs.inputFile.value = null;
      }
      this.updateSide();
    },
    parent() {
      this.updateSide();
    },
    value() {
      this.onValueChange();
    },
    vignettes() {
      this.updateSide();
    },
    sides() {
      this.updateSide();
    },
  },
  created() {
    this.onValueChange();
  },
  methods: {
    getIdByName,
    bytesToSize(bytes) {
      var sizes = ["Bytes", "Kb", "Mb", "Gb", "Tb"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    removeUploadCmsEdit(idRemove) {
      const idx = this.scenario.cmsFiles.find(
        ({ item }) => item.id === idRemove
      );
      if (this.scenario.cmsFiles) delete this.scenario.cmsFiles[idx];
    },
    addUploadCmsEdit(id, file) {
      if (!this.scenario.cmsFiles) this.scenario.cmsFiles = [];
      this.scenario.cmsFiles.push({ id: id, file: file });
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.removeUploadCmsEdit(this.value.id);
        return;
      }

      var newFile = files[0];
      this.value.upload = {
        name: newFile.name,
        lastModified: newFile.lastModified,
        size: newFile.size,
        type: newFile.type,
      };
      this.addUploadCmsEdit(this.value.id, newFile);
      //todo validate filesize e type
      if (this.value.props.title == "" || this.value.props.title == "New File")
        this.value.props.title = newFile.name;
      this.onValueChange();
    },
    onCheckboxChange() {
      this.updateSide(true);
    },
    onValueChange() {
      this.type = this.value.type;
      this.id = this.value.id;
      this.depth = this.value.depth;
      this.props.title = this.value.props.title;
      this.props.content = this.value.props.content;
      this.parent = this.value.parent;
      this.status = this.value.status;
      this.upload = this.value.upload;
      this.vignettes = { ...this.value.vignettes };
      this.sides = { ...this.value.sides };
      this.updateSide(true);
    },

    updateSide(force) {
      const currentItem = {
        type: this.type,
        parent: this.parent,
        id: this.id,
        status: this.status,
        upload: this.upload,
        depth: this.depth,
        props: {
          title: this.props.title,
          content: this.props.content,
        },
        vignettes: { ...this.vignettes },
        sides: { ...this.sides },
      };
      const oldparent = this.value.parent;

      if (!equal(this.value, currentItem)) {
        if (currentItem.status === "unmodified") {
          currentItem.status = "edited";
        }
        const { title, content } = currentItem.props;
        this.value.props = { title, content };
        this.value.parent = currentItem.parent;
        this.value.status = currentItem.status;
        this.value.upload = currentItem.upload;
        this.value.vignettes = currentItem.vignettes;
        this.value.sides = currentItem.sides;
      }

      this.$emit("input", this.value);
      this.$emit("updateFilesTreeEdit", this.value, oldparent != this.parent);
      if (force) this.$forceUpdate();
    },
  },
  data() {
    return {
      type: "",
      parent: "",
      id: "",
      status: "new",
      upload: null,
      depth: 0,
      props: {
        title: "",
        content: "",
      },
      vignettes: {},
      sides: {},
    };
  },
};
</script>