<template>
  <form @submit.prevent="">
    <div class="form-group row">
      <label for="unit-name" class="col col-12 col-md-2">Name</label>
      <div class="input-group col col-12 col-md-10">
        <input
          id="unit-name"
          placeholder="Unit name..."
          type="text"
          class="form-control form-control-sm"
          v-model.lazy="value.name"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="unit-owner" class="col col-12 col-md-2">Side/Owner</label>
      <div class="input-group col col-12 col-md-10">
        <model-list-select
          id="unit-owner"
          ref="owners"
          class="form-control form-control-sm"
          :list="owners"
          v-model="owner"
          option-value="id"
          option-text="text"
          placeholder="NONE"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="unit-hq" class="col col-12 col-md-2">Unit HQ</label>
      <div class="input-group col col-12 col-md-10">
        <model-list-select
          id="unit-hq"
          ref="HQUnit"
          class="form-control form-control-sm"
          :list="parents"
          v-model="parent_id"
          option-value="id"
          option-text="text"
          placeholder="None"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="unit-symbol" class="col col-12 col-md-2">Symbol ID</label>
      <div class="input-group col col-12 col-md-10">
        <input
          id="unit-symbol"
          type="text"
          class="form-control form-control-sm"
          v-model="value.symbol_id"
        />
        <div class="input-group-append">
          <button
            class="btn btn-sm oi oi-pencil btn-outline-primary"
            @click.stop.prevent="$emit('editSymbolClick')"
          />
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="unit-radius" class="col col-12 col-md-2">Radius/ISR</label>
      <div class="input-group col col-12 col-md-10">
        <input
          id="unit-radius"
          type="number"
          class="form-control form-control-sm"
          min="0"
          max="1000"
          step="0.01"
          v-model.number="value.radius"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="unit-max-move" class="col col-12 col-md-2">Max Move</label>
      <div class="input-group col col-12 col-md-10">
        <input
          id="unit-max-move"
          type="number"
          class="form-control form-control-sm"
          min="0"
          max="1000"
          step="0.01"
          v-model.number="value.max_move"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="unit-strength" class="col col-12 col-md-2">Strength</label>
      <div class="input-group col col-12 col-md-10">
        <input
          id="unit-strength"
          type="number"
          class="form-control form-control-sm"
          min="0"
          max="100"
          step="1"
          v-model.number="value.strength"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="unit-capabilities" class="col col-12 col-md-2">
        Capabilities
      </label>
      <div class="input-group col col-12 col-md-10">
        <textarea
          id="unit-capabilities"
          rows="3"
          class="form-control form-control-sm"
          v-model="value.capabilities"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="unit-visible" class="col col-12 col-md-2"> Visible </label>
      <div class="input-group col col-12 col-md-10">
        <label class="custom-control custom-checkbox">
          <input
            id="unit-visible"
            type="checkbox"
            class="custom-control-input"
            v-model="value.visible"
          />
          <span class="custom-control-label" />
        </label>
      </div>
    </div>
<!-- IP01 inizio GARC DSOL -->

 <div class="form-group row">
  <label for="unit-referencelink" class="col col-12 col-md-2"> Reference Link </label>
  <div class="input-group col col-12 col-md-10">
    <label class="custom-control custom-checkbox">
      <input
        id="unit-referencelink"
        type="checkbox"
        class="custom-control-input"
        v-model="value.referencelink"
      />
      <span class="custom-control-label" />
    </label>
  </div>
</div>
<div class="form-group row" v-if="value.referencelink">
    <label for="unit-referenceTitle" class="col col-12 col-md-2">Title</label>
    <div class="input-group col col-12 col-md-10">
      <input
        type="text"
      id="unit-referenceTitle"
      class="form-control form-control-sm"
      v-model="value.referencedlinks.title"
      />
    </div>
  </div>
<div class="form-group row" v-if="value.referencelink">
  <div class="mb-2">
    <button class="btn btn-outline-primary" @click.prevent="newReferenceLink">
      <span class="icon icon-plus2"></span>
      <span>Add Reference Link</span>
    </button>
  </div>
</div>

<div v-if="value.referencelink">
<div class="form-group row" v-for="(referenceLink, x) in value.referencedlinks.links" :key="x" >
  <!--<div class="mb-2" v-if="value.referencelink">-->
    <!--<span class="col-2 justify-content-left">Name:</span>-->
    <label for="unit-referenceName" class="col col-12 col-md-2">Name</label>
    <div class="input-group col col-12 col-md-10">
    <input
      type="text"
      id="unit-referenceName"
      class="form-control form-control-sm"
      v-model="value.referencedlinks.links[x].name"
    />
    </div>
    <!--<span class="col-6"></span>-->
    <div class="form-group row" />
    <!--<span class="col-2 justify-content-left">Reference URL:</span>-->
    <label for="unit-referenceUrl" class="col col-12 col-md-2">URL</label>
    <div class="input-group col col-12 col-md-10">
    <input
      type="text"
      id="unit-referenceUrl"
      class="form-control form-control-sm"
      v-model="value.referencedlinks.links[x].url"
    />
    </div>
    <!--<span class="col-6"></span>-->

    <span
      class="btn btn-outline-danger"
      @click.prevent="deleteReferenceLink(referenceLink)"
      v-show="value.referencedlinks.links.length > 1"
    >
      <span class="icon icon-cross"></span>
      <span>Delete Reference Link</span>
    </span>
  <!--</div>-->
  
</div>
</div>
<confirm-dialog ref="confirmDeleteLink" :body="'Are you sure do you want to delete ReferenceLink?'"
        confirmBtnClasses="btn-danger" @confirm="confirmDeleteReferenceLink" @cancel="$refs.confirmDeleteLink.close()">
      </confirm-dialog>
     

<!-- IP01 fine GARC DSOL -->
    <div class="form-group row">
      <label for="unit-loc-x" class="col col-12 col-md-2">Location</label>
      <div class="input-group col col-12 col-md-10">
        <div class="row flex-grow-1">
          <div class="col-md-4">
            <input
              id="unit-loc-x"
              type="number"
              class="form-control form-control-sm coord-field mb-1"
              step="any"
              v-model.number="value.long"
              placeholder="Longitude/X"
            />
          </div>
          <div class="col-md-4">
            <input
              id="unit-loc-y"
              type="number"
              class="form-control form-control-sm coord-field mb-1"
              step="any"
              v-model.number="value.lat"
              placeholder="Latitude/Y"
            />
          </div>
          <div class="col-md-4">
            <input
              id="unit-loc-z"
              type="number"
              class="form-control form-control-sm coord-field mb-1"
              step="any"
              v-model.number="value.elev"
              placeholder="Elevation/Z"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="unit-name" class="col col-12 col-md-2"
        >Special Headquarters</label
      >
      <div class="input-group col col-12 col-md-10">
        <input
          id="unit-special-headquarters"
          placeholder="Special Headquarters..."
          type="text"
          class="form-control form-control-sm"
          v-model="value.special_headquarters"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="unit-extra" class="col-12">
        <b>Additional fields:</b>
      </label>
      <units-extra-editor class="col-12" v-model="value.extra"/>
    </div>
    <div class="form-row">
      <div class="col">
        <button
          class="btn btn-sm btn-outline-danger icon icon-cross"
          @click.stop.prevent="deleteRecord()"
        >
          Delete this unit
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import { ModelListSelect } from "vue-search-select";
import SymbolEditDialogVue from "../../../../../xserver-common/components/SymbolEditDialog.vue";
import UnitsExtraEditorVue from "../../../../../xserver-common/components/UnitsExtraEditor.vue";
export default {
  props: {
    value: {
      required: true,
    },
    parent: {
      required: false,
    },
    type: {
      required: false,
      default: "up",
    },
    owners: {
      required: true,
    },
    locations: {
      type: Boolean,
      default: true,
    },
  }, //["value", "owners", "parent", "edit"],
  components: {
    "model-list-select": ModelListSelect,
    "symbol-edit-dialog": SymbolEditDialogVue,
    "units-extra-editor": UnitsExtraEditorVue,
  },
  watch: {
    parent_id: function (newVal, oldVal) {
      if (this.parent_id != this.value.parent_id) {
        this.value.parent_id = newVal;
        this.$emit("parentChange", {
          newVal: newVal ? newVal : null,
          oldVal,
          record: this.value,
        });
      }
    },
    "value.parent_id"(newVal, oldVal) {
      if (newVal != this.parent_id) {
        this.parent_id = newVal || 0;
      }
    },
    owner: function (newVal, oldVal) {
      this.value.owner = newVal;
      this.$emit("ownerChange", { newVal, oldVal, record: this.value });
      this.parents = this.$parent.getParents(this.value);
    },
    value() {
      this.update();
    },
  },
  created() {
    this.update();
  },
  methods: {
    update() {
      this.parent_id = this.value.parent_id;
      this.owner = this.value.owner;
      this.parents = this.$parent.getParents(this.value);
      this.symbol_id = this.value.symbol_id;
    },
    emitCellClick(event) {
      this.$emit("cellClick", event);
    },
    deleteRecord() {
      this.$emit("delete", this.value);
    },
//IP01 inizio GARC DSOL
    newReferenceLink() {
      if (!this.value.referencedlinks) {
        this.value.referencedlinks.title = '';
        this.value.referencedlinks.links = [];
      }
      else if (!Array.isArray(this.value.referencedlinks.links)) {
        this.value.referencedlinks.links = [];
      }
      this.value.referencedlinks.links.push({
        name: "",
        url: "",
      });
      this.$forceUpdate();
    },

      deleteReferenceLink(referenceLink) {
      this.deleteingReferenceLink = referenceLink;
      this.$refs.confirmDeleteLink.open();
    },

    confirmDeleteReferenceLink() {
      if (!this.deleteingReferenceLink) return;
      const index = this.value.referencedlinks.links.indexOf(this.deleteingReferenceLink);
      if (index >= 0) {
        this.value.referencedlinks.links.splice(index, 1);
      }
      this.$refs.confirmDeleteLink.close();
      this.$forceUpdate();
    },
//IP01 fine GARC DSOL

  },
  data() {
    return { parents: [], owner: "", parent_id: 0 };
  },
};
</script>
