<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3" v-if="group">
        <h1 v-if="group.id">Edit Group</h1>
        <h1 v-else>New Group</h1>
        <div class="alert alert-danger" v-if="errors.length">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>
        <form action @submit.prevent="insertOrUpdate()">
          <div class="form-group">
            <label for="name">Group name</label>
            <input
              type="text"
              name="name"
              class="form-control"
              placeholder="Group name..."
              v-model="group.name"
              :readonly="group.system"
            />
          </div>
          <div class="form-group">
            <h3>Members</h3>
            <ul class="navbar-nav members mb-2">
              <li class="nav-item" v-for="user in group.users" :key="user.id">
                <span class="label">{{ getAutocompleteDisplay(user) }}</span>
                <span
                  class="btn btn-outline-danger icon icon-cross"
                  @click="reoveUser(user)"
                  v-if="!(user.admin && group.name == 'administrators')"
                ></span>
              </li>
            </ul>
            <autocomplete
              ref="autocomplete"
              :source="users"
              input-class="form-control"
              placeholder="Add users..."
              :results-display="getAutocompleteDisplay"
              @selected="userSelected"
            ></autocomplete>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary" v-if="group.id">
              Edit Group
            </button>
            <button type="submit" class="btn btn-primary" v-else>
              Add Group
            </button>
          </div>
          <span class="overlay align-self-center d-flex" v-if="loading">
            <div class="overlay-background bg-white opacity-50"></div>
            <div class="spinner-border text-primary m-auto" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import Autocomplete from "vuejs-auto-complete";
import eventBus from "../../../eventBus";
import api from "../../../api";
export default {
  created() {
    this.loadGroup();
    this.loadUsers();
  },
  components: {
    "admin-menu": AdminMenu,
    autocomplete: Autocomplete,
  },
  data() {
    return {
      errors: [],
      users: [],
      group: { name: "", users: [] },
      loading: false,
    };
  },
  methods: {
    loadGroup() {
      if (this.$route.params.id) {
        this.loading = true;
        this.$http
          .get(api + "/rest/groups/" + this.$route.params.id)
          .then((res) => {
            this.loading = false;
            this.group = res.body;
          })
          .catch((res) => {
            this.loading = false;
            if (res.status === 401) {
              eventBus.$emit("logout");
            } else if (res.body && res.body.error) {
              this.errors.push(res.body.error.message);
            } else {
              this.errors.push("Error " + res.status);
            }
          });
      }
    },
    loadUsers() {
      this.$http
        .get(api + "/rest/users")
        .then((res) => {
          this.users = res.body;
        })
        .catch((res) => {
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        });
    },
    getAutocompleteDisplay(user) {
      return user.username + " (" + this.getFullName(user) + ")";
    },
    userSelected(evt) {
      if (
        this.group.users.filter((u) => u.id == evt.selectedObject.id).length ==
        0
      ) {
        this.group.users.push(evt.selectedObject);
      }
      this.$refs.autocomplete.clear();
      this.$refs.autocomplete.focus();
    },
    reoveUser(user) {
      this.group.users = this.group.users.filter((u) => u.id !== user.id);
    },
    getFullName(user) {
      var name = "";
      if (user.first_name)
        name =
          name.length == 0 ? user.first_name : name + " " + user.first_name;
      if (user.middle_name)
        name =
          name.length == 0 ? user.middle_name : name + " " + user.middle_name;
      if (user.last_name)
        name = name.length == 0 ? user.last_name : name + " " + user.last_name;
      return name;
    },
    insertOrUpdate() {
      this.errors = [];
      if (this.group.id) this.update();
      else this.insert();
    },
    update() {
      this.loading = true;
      this.$http
        .post(api + "/rest/groups/" + this.group.id, this.group)
        .then((res) => {
          this.loading = false;
          this.$router.push("../groups");
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        });
    },
    insert() {
      this.loading = true;
      this.$http
        .put(api + "/rest/groups", this.group)
        .then((res) => {
          this.loading = false;
          this.$router.push("../groups");
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        });
    },
  },
};
</script>
