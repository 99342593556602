<template>
  <div class="mapJournalVignetteEdit">
    <div class="btn btn-sm px-0">
      <span class="icon icon-home"></span>
      <b class="ml-1">/</b>
    </div>
    <div class="row">
      <div class="col col-12 col-md-4 col-lg-4">
        <ul class="sections-list list-group">
          <li
            v-for="(item, j) in value"
            :key="j"
            class="list-group-item btn btn-sm text-left pt-0 pb-0"
            :class="{
              active: item === editingItem,
              'cms-item-deleted': item.status === 'deleted',
            }"
            @click.stop.prevent="onItemClick(item)"
          >
            <span
              v-for="(n, index) in item.depth"
              :key="index"
              v-bind:class="'ml-3'"
            ></span>
            <span
              v-if="item.type == 'file'"
              class="icon icon-file-spreadsheet"
            ></span>
            <span class="cms-item-title" v-if="item.type == 'file'">
              {{ item.props.title || "(new files)" }}</span
            >
            <span v-if="item.type == 'dir'" class="icon icon-documents"></span>
            <span class="cms-item-title" v-if="item.type == 'dir'">
              {{ item.props.title || "(new directory)" }}</span
            >
          </li>
          <li class="list-group-item bg-light" v-if="!value || !value.length">
            No Files here
          </li>
        </ul>
      </div>
      <cms-item-property
        v-if="editingItem"
        v-model="editingItem"
        :scenario="scenario"
        :dirTree="dirTree"
        :availableVignettes="scenario.content.vignettes"
        :availableSides="
          scenario.content.sides
            .filter((side) => !side.controller)
            .map((side) => side.name)
        "
        class="col-12 col-md-8 col-lg-8"
        v-on:updateFilesTreeEdit="updateFilesTreeEdit"
        refs="cmsItemProperty"
      />
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import CmsItemProperty from "./CmsItemProperty.vue";
export default {
  props: ["value", "vignette", "scenario"],
  name: "cms-files-tree-edit",
  components: {
    "cms-item-property": CmsItemProperty,
  },
  watch: {
    value() {
      if (this.value.length > 0 && this.editingItem === undefined)
        this.editingItem = this.value[0];
      this.createDirTree();
    },
  },
  methods: {
    updateFilesTreeEdit(item, parentEdit) {
      this.createDirTree();
      this.$emit("updateCmsEdit", item.id, parentEdit);
      this.$forceUpdate();
    },
    addFile() {
      const item = this.defaultFile();
      this.value.push(item);
      this.editingItem = item;
      this.$emit("updateCmsEdit", item.id);
      this.$forceUpdate();
    },
    addDir() {
      const item = this.defaultDir();
      this.value.push(item);
      this.editingItem = item;
      this.$emit("updateCmsEdit", item.id);
      this.$forceUpdate();
    },
    onItemClick(item) {
      this.editingItem = item;
      this.$emit("updateCmsEdit", item.id);
    },
    removeItem(item) {
      let childItems = null;
      if (item === undefined) return;
      if (item.status !== "new") {
        item.status = "deleted";
      } else {
        //remove from array
        const deleteItemIdx = this.findItemIdx(item.id);
        childItems = this.getChildItem(item.id);
        const parent = item.parent;

        childItems.forEach((i) => {
          i.parent = parent;
        });

        this.removeTreeItem(deleteItemIdx);
      }

      if (childItems === null) childItems = this.getChildItem(item.id);

      //remove subdir/files
      for (var i = 0; i < childItems.length; i++) {
        this.removeItem(childItems[i]);
      }

      //this.$emit("updateCmsEdit", item.id);
      //this.$forceUpdate();
      this.updateFilesTreeEdit(item, true);
    },
    restoreItem(item) {
      if (item.status === "deleted") {
        item.status = "edited";
      }

      const childItems = this.getChildItem(item.id);

      //restore subdir/files
      for (var i = 0; i < childItems.length; i++) {
        this.restoreItem(childItems[i]);
      }

      this.updateFilesTreeEdit(item, true);
    },
    defaultFile() {
      return {
        type: "file",
        parent: "",
        id: uuidv4(),
        status: "new",
        upload: null,
        depth: 0,
        props: {
          title: "New File",
          content: "",
        },
        vignettes: {},
        sides: {},
      };
    },
    defaultDir() {
      return {
        type: "dir",
        parent: "",
        id: uuidv4(),
        status: "new",
        upload: null,
        depth: 0,
        props: {
          title: "New Directory",
          content: "",
        },
        vignettes: {},
        sides: {},
      };
    },

    createDirTree() {
      //build "parent folder" select list
      let tmpDirTree = this.value
        .filter((item) => item.type == "dir")
        .map((item) => {
          item.depth = this.getItemDepth(item.id, 0);
          return {
            id: item.id,
            name: this.getFolderName(item.id, "", 0),
            type: item.type,
            depth: item.depth,
            parent: item.parent,
          };
        });

      //calculate files depth
      this.value
        .filter((item) => item.type == "file")
        .map((item) => {
          item.depth = this.getItemDepth(item.id, 0);
          return true;
        });

      //remove current item subfolder
      tmpDirTree = tmpDirTree.filter((item) => {
        let findItem = this.findItem(this.editingItem.id);
        if (!findItem) return false;
        if (findItem.type == "file") return true;
        while (
          item.parent != undefined &&
          item.parent != null &&
          item.parent !== ""
        ) {
          item = this.findItem(item.parent);
          if (item.id === findItem.id) return false;
        }
        return true;
      });

      this.dirTree = tmpDirTree;
    },

    getFolderName(id, origName, depth) {
      var item = this.findItem(id);
      if (
        item.parent !== undefined &&
        item.parent !== null &&
        item.parent !== ""
      ) {
        depth++;
        return this.getFolderName(
          item.parent,
          item.props.title + "/" + origName,
          depth
        );
      } else {
        return item.props.title + "/" + origName;
      }
    },
    getItemDepth(id, depth) {
      var item = this.findItem(id);
      if (
        item &&
        item.parent !== undefined &&
        item.parent !== null &&
        item.parent !== ""
      ) {
        depth++;
        return this.getItemDepth(item.parent, depth);
      } else {
        return depth;
      }
    },
    findItem(itemId) {
      return this.scenario.cms.filesTree.find(({ id }) => id === itemId);
    },
    findItemIdx(itemId) {
      return this.scenario.cms.filesTree.findIndex(({ id }) => id === itemId);
    },
    getChildItem(itemId) {
      return this.scenario.cms.filesTree.filter(
        ({ parent }) => parent === itemId
      );
    },
    removeTreeItem(idx) {
      this.scenario.cms.filesTree.splice(idx, 1);
    },
  },
  data() {
    return {
      editingItem: undefined,
      dirTree: [],
    };
  },
};
</script>