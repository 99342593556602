<template>
  <div class="modal fade" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div class="icon-preview" v-html="preview"></div>
          <app6d-symbol-form
            v-model="symbol_id"
            :hideAffiliation="true"
            :hideEchelon="true"
            :hideModifier2="true"
            :hideDomain="true"
            :hideDirection="true"
            @input="onInput"
            :editingRecord="editingRecord"
          ></app6d-symbol-form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-sm btn-primary" @click="close()">Ok</button>
        </div>
      </div>
    </div>
    <loading :loading="loading" />
  </div>
</template>
<script>
import { ModelListSelect } from "vue-search-select";
import { toLonLat } from "ol/proj";
import App6dSymbolFormVue from "../../../../../../xserver-common/components/App6dSymbolForm.vue";
import MS from "milsymbol";
export default {
  props: ["value", "events", "editingRecord", "sides"],
  components: {
    "app6d-symbol-form": App6dSymbolFormVue,
    "model-list-select": ModelListSelect,
  },
  created() {
    this.symbol_id = this.value;
    this.update();
  },
  mounted() {
    jQuery(this.$el).on("shown.bs.modal", () => {
      this.$emit("shown");
    });
    jQuery(this.$el).on("hidden.bs.modal", () => {
      this.opened = false;
      this.$emit("close");
    });
  },
  watch: {
    events() {
      this.update();
    },
    value() {
      this.symbol_id = this.value;
      this.update();
    },
  },
  computed: {},
  methods: {
    update() {
      this.updateSymbolPreview();
    },
    onInput(value) {
      this.updateSymbolPreview();
      this.$emit("input", value);
    },

    updateSymbolPreview() {
      let color;
      this.sides.forEach((side) => {
        if (side.name === this.editingRecord.owner) color = side.color;
      });
      const dynamicOpt = {};
      dynamicOpt.specialHeadquarters = this.editingRecord.special_headquarters;
      const symbol = new MS.Symbol(this.value, {
        size: 60,
        fillColor: color,
        ...dynamicOpt,
      });
      this.preview = symbol.asSVG();
    },
    open() {
      jQuery(this.$el).modal("show");
      this.opened = true;
      $(".modal-backdrop").before($(this.$el));
      this.update();
      this.$emit("open");
    },
    close() {
      jQuery(this.$el).modal("hide");
      this.opened = false;
    },
  },
  data() {
    return {
      loading: false,
      currentTab: "fields",
      id: 0,
      name: "",
      description: "",
      symbol_id: "",
      owner: "",
      long: Number.NaN,
      lat: Number.NaN,
      elev: Number.NaN,
      opened: false,
      preview: "",
      special_headquarters: "",
    };
  },
};
</script>
