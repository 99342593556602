<template>
  <form @submit.prevent="" class="map-journal-section-form">
    <div class="buttons">
      <div class="btn btn-outline-secondary mb-1" @click.prevent="moveDown">
        <span class="icon icon-arrow-down2" />
      </div>
      <div class="btn btn-outline-secondary mb-1" @click.prevent="moveUp">
        <span class="icon icon-arrow-up2" />
      </div>
      <div class="btn btn-outline-danger mb-1" @click.prevent="deleteSection">
        <span class="icon icon-bin2" />
      </div>
    </div>
    <div>
      <h2>Side Content</h2>
    </div>
    <div class="form-group row">
      <label for="section-title" class="col col-12 col-md-2">Title</label>
      <div class="input-group col col-12 col-md-10">
        <input
          id="section-title"
          placeholder="Title..."
          type="text"
          class="form-control form-control-sm"
          v-model.lazy="sideContent.title"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="section-content" class="col col-12 col-md-2">Content</label>
      <div class="input-group col col-12 col-md-10">
        <app-editor
          id="section-content"
          v-model="sideContent.content"
          class="w-100"
        />
      </div>
    </div>
    <div>
      <h2>Main Content</h2>
    </div>
    <div class="form-group row align-items-center">
      <label for="section-aspect" class="col col-12 col-md-2">Type</label>
      <div class="col col-12 col-md-10 btn-group btn-group-toggle">
        <label
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: mainContent.type === 'map' }"
        >
          <input
            type="radio"
            value="map"
            v-model="mainContent.type"
            autocomplete="off"
          />
          Map
        </label>
        <label
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: mainContent.type === 'image' }"
        >
          <input
            type="radio"
            value="image"
            v-model="mainContent.type"
            autocomplete="off"
          />
          Image
        </label>
        <label
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: mainContent.type === 'website' }"
        >
          <input
            type="radio"
            value="website"
            v-model="mainContent.type"
            autocomplete="off"
          />
          Website
        </label>
      </div>
    </div>
    <div
      class="form-group row align-items-center"
      v-if="mainContent.type === 'image' || mainContent.type === 'website'"
    >
      <label for="section-src" class="col col-12 col-md-2">Source</label>
      <div class="input-group col col-12 col-md-10">
        <input
          id="section-src"
          placeholder="Source..."
          type="text"
          class="form-control form-control-sm"
          v-model.lazy="mainContent.src"
        />
      </div>
    </div>
    <div
      class="form-group row align-items-center"
      v-if="mainContent.type === 'image'"
    >
      <label for="section-aspect" class="col col-12 col-md-2">Aspect</label>
      <div class="input-group col col-12 col-md-10">
        <div class="btn-group btn-group-toggle">
          <label
            class="btn btn-sm btn-outline-secondary"
            :class="{ active: mainContent.aspect === 'contain' }"
          >
            <input
              type="radio"
              value="contain"
              v-model="mainContent.aspect"
              autocomplete="off"
            />
            Contain
          </label>
          <label
            class="btn btn-sm btn-outline-secondary"
            :class="{ active: mainContent.aspect === 'cover' }"
          >
            <input
              type="radio"
              value="cover"
              v-model="mainContent.aspect"
              autocomplete="off"
            />
            Cover
          </label>
        </div>
      </div>
    </div>
    <div class="form-group row" v-if="mainContent.type === 'map'">
      <label for="section-src" class="col col-12 col-md-2">Enter Events</label>
      <div class="input-group col col-12 col-md-10">
        <map-journal-events-section
          v-model="mainContent.onEnter"
          :scenario="scenario"
        />
      </div>
    </div>
    <div class="form-group row" v-if="mainContent.type === 'map'">
      <label for="section-src" class="col col-12 col-md-2">Leave Events</label>
      <div class="input-group col col-12 col-md-10">
        <map-journal-events-section
          v-model="mainContent.onLeave"
          :scenario="scenario"
        />
      </div>
    </div>
  </form>
</template>
<script>
import AppEditorVue from "../../../../../../xserver-common/components/AppEditor.vue";
import MapJournalEventsSection from "./MapJournalEventsSection.vue";
export default {
  name: "map-journal-section-form",
  props: ["value", "scenario"],
  components: {
    "app-editor": AppEditorVue,
    "map-journal-events-section": MapJournalEventsSection,
  },
  watch: {
    "mainContent.type"() {
      this.value.mainContent.type = this.mainContent.type;
      this.updateMain(true);
    },
    "mainContent.src"() {
      this.updateMain();
    },
    "mainContent.aspect"() {
      this.updateMain();
    },
    "mainContent.onEnter"() {
      this.updateMain();
    },
    "mainContent.onLeave"() {
      this.updateMain();
    },
    "sideContent.title"() {
      this.updateSide();
    },
    "sideContent.content"() {
      this.updateSide();
    },
    value() {
      this.onValueChange();
    },
  },
  created() {
    this.onValueChange();
  },
  methods: {
    moveUp() {
      this.$emit("move-up");
    },
    moveDown() {
      this.$emit("move-down");
    },
    deleteSection() {
      this.$emit("delete");
    },
    onValueChange() {
      this.sideContent.title = this.value.sideContent.title;
      this.sideContent.content = this.value.sideContent.content;
      this.mainContent.type = this.value.mainContent.type;
      this.mainContent.onEnter = this.value.mainContent.onEnter || [];
      this.mainContent.onLeave = this.value.mainContent.onLeave || [];
      this.mainContent.src = this.value.mainContent.src || "";
      this.mainContent.aspect = this.value.mainContent.src || "contain";
      this.updateSide();
      this.updateMain(true);
    },
    updateMain(force) {
      const { type, onEnter, onLeave, src, aspect } = this.mainContent;
      switch (this.value.mainContent.type) {
        case "map":
          this.value.mainContent = { type, onEnter, onLeave };
          break;
        case "image":
          this.value.mainContent = { type, src, aspect };
          break;
        case "website":
          this.value.mainContent = { type, src };
          break;
        default:
          break;
      }
      this.$emit("input", this.value);
      if (force) this.$forceUpdate();
    },
    updateSide(force) {
      const { title, content } = this.sideContent;
      this.value.sideContent = { title, content };
      this.$emit("input", this.value);
      if (force) this.$forceUpdate();
    },
  },
  data() {
    return {
      mainContent: {
        type: "map",
        onEnter: [],
        onLeave: [],
        aspect: "contain",
        src: "",
      },
      sideContent: {
        title: "",
        content: "",
      },
    };
  },
};
</script>