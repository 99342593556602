<template>
  <div class="container-main container-main--3dTiles">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1 class="d-inline-block align-middle">3D Tiles</h1>
        <help-popup :name="'3D Tiles'"></help-popup>
        <div class="alert alert-danger" v-if="errors.length">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>

        <div class="mb-2 mt-2">
          <button v-on:click="reload3DTiles()" class="btn btn-primary">
            <span class="icon icon-reload"></span>
            <span class="label">Reload</span>
          </button>
          <router-link to="3dtiles/new" class="btn btn-primary">
            <span class="icon icon-plus2"></span>
            <span class="label">New</span>
          </router-link>
        </div>

        <table class="table w-100 table-3dTiles">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="tiles.length < 1">
              <td>No tiles found</td>
            </tr>
            <tr v-for="tile in tiles" v-bind:key="tile.id">
              <td>{{ tile.id }}</td>
              <td>{{ tile.name }}</td>
              <td>{{ tile.description }}</td>
              <td>
                <span title="Details" class="btn btn-outline-secondary" @click="$router.push('3dtiles/' + tile.id)">
                  <span class="icon icon-file-spreadsheet"></span> </span><span title="Edit"
                  class="btn btn-outline-primary" @click="$router.push('3dtiles/edit/' + tile.id)">
                  <span class="icon icon-pencil4"></span>
                </span>
                <span v-if="tile.collada" title="Download zipped collada (.dae)" class="btn btn-outline-primary"
                  @click="downloadCollada(tile)">
                  .DAE
                </span>
                <span title="Remove process from this list" class="btn btn-outline-danger" @click="deleteTile(tile)">
                  <span class="icon icon-bin2"></span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div id="deleteConfirm" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                Are you sure that you want to delete the tileset:<br />
                "{{ deletingTile && deletingTile.name }}"
              </div>
              <div class="modal-footer">
                <button class="btn btn-danger" @click="confirmDelete()">
                  Delete
                </button>
                <button class="btn btn-secondary" @click="cancelDelete()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <loading :loading="loading">Loading...</loading>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import eventBus from "../../../eventBus";
import api from "../../../api";
import HelpPopup from "../../../HelpPopup.vue";
import LoadingVue from "../../../components/loading/Loading.vue";
export default {
  components: {
    "admin-menu": AdminMenu,
    loading: LoadingVue,
    "help-popup": HelpPopup
  },
  created() {
    this.load3DTiles();
  },
  data() {
    return {
      tiles: [],
      deletingTile: null,
      loading: false,
      errors: [],
    };
  },
  methods: {
    load3DTiles() {
      this.loading = true;
      this.errors = [];
      this.$http
        .get(api + "/rest/3dtiles")
        .then((res) => {
          this.tiles = res.body;
        })
        .catch((res) => {
          //TODO apply errors
          if (res.status === 401) {
            eventBus.$emit("logout");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reload3DTiles() {
      this.loading = true;
      this.$http
        .get(api + "/rest/3dtiles/reload")
        .then((res) => {
          this.tiles = res.body;
        })
        .catch((res) => {
          //TODO apply errors
          if (res.status === 401) {
            eventBus.$emit("logout");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadCollada(tile) {
      this.loading = true;
      this.errors = [];
      this.$http
        .get("/rest/3dtiles/" + tile.name + "/download/collada", {
          responseType: "arraybuffer",
        })
        .then((res) => {
          let anchor = document.createElement("a");
          const downloadFilename = res.headers
            .get("content-disposition")
            .split("filename=")[1]
            .split(";")[0]
            .replace(/(^"|"$)/g, "");
          const contentType = res.headers.get("content-type");
          const blob = new Blob([res.body], { type: contentType });
          let objectUrl = window.URL.createObjectURL(blob);

          anchor.href = objectUrl;
          anchor.download = tile.name + ".zip";
          anchor.click();

          window.URL.revokeObjectURL(objectUrl);
        })
        .catch((error) => {
          this.errors.push("Error downloading collada file");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteTile(tile) {
      this.deletingTile = tile;
      $("#deleteConfirm").modal();
    },
    cancelDelete() {
      this.deletingTile = null;
      $("#deleteConfirm").modal("hide");
    },
    confirmDelete() {
      this.errors = [];
      this.$http
        .delete(api + "/rest/3dtiles/" + this.deletingTile.name)
        .then((res) => {
          $("#deleteConfirm").modal("hide");
          this.deletingTile = null;
          this.load3DTiles();
        })
        .catch((res) => {
          if (res.status === 401) {
            eventBus.$emit("logout");
            return;
          } else {
            $("#deleteConfirm").modal("hide");
            this.deletingTile = null;
            if (res && res.body && res.body.error && res.body.error.message)
              this.errors = [res.body.error.message];
            else this.errors = ["Unexpected server error"];
          }
        });
    },
  },
};
</script>