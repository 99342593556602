<template>
  <div class="w-100 p-2" v-if="scenario && scenario.content">
    <label class="custom-control custom-checkbox d-block">
      <input type="checkbox" class="custom-control-input" v-model="scenario.content.aar" />
      <span class="custom-control-label"></span>
    </label>
  </div>
</template>
<script>
export default {

  props: ["scenario"]


}
</script>