var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-home"},[_c('h1',[_vm._v("\n    Welcome "),_c('span',[_vm._v(_vm._s(_vm.user && _vm.user.username))])]),_vm._v(" "),_vm._l((_vm.scenarios),function(scenario,id){return [_c('div',{key:id,staticClass:"scenario-group"},[_c('div',{staticClass:"instance-list"},_vm._l((scenario.instances),function(instance,i){return _c('div',{key:i,staticClass:"instance mb-2"},[_c('span',[_c('span',{staticClass:"scenario-name"},[_vm._v("\n              "+_vm._s(scenario.name)+"\n            ")]),_vm._v(" "),(scenario.instances.length > 1)?_c('span',{staticClass:"instance-name"},[_vm._v("\n              ("+_vm._s(instance.name)+")\n            ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"scenario__actionWrap"},[_c('div',{staticClass:"scenario__action"},[_c('a',{staticClass:"btn btn-outline-secondary",attrs:{"href":'client/#/app/' + instance.id + '?app2D'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.open2D(instance.id)}}},[_vm._v("2D\n              ")]),_vm._v(" "),(
                  scenario.instances[0].has3D &&
                  _vm.$store.state.serverInfo.features.app3d
                )?_c('a',{staticClass:"btn btn-outline-secondary",attrs:{"href":'client/#/app/' + instance.id + '?app3D'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.open3D(instance.id)}}},[_vm._v("3D")]):_vm._e(),_vm._v(" "),(
                  scenario.instances[0].has3D &&
                  _vm.$store.state.serverInfo.features.app3d
                )?_c('a',{staticClass:"btn btn-outline-secondary",attrs:{"href":'client/#/app/' + instance.id},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.open2D3D(instance.id)}}},[_vm._v("2D | 3D")]):_vm._e(),_vm._v(" "),(
                  scenario.instances[0].hasMapJournal &&
                  _vm.$store.state.serverInfo.features.mapJournal
                )?_c('a',{staticClass:"btn btn-outline-secondary",attrs:{"href":'client/#/app/' + instance.id},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openJournal(instance.id)}}},[_vm._v("Journal")]):_vm._e()])])])}),0)])]}),_vm._v(" "),_c('loading',{attrs:{"loading":_vm.loading}}),_vm._v(" "),_c('vue-snotify')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }