<template>
  <span class="layers-list">
    <layer
      :path="''"
      :layer="layer"
      v-for="(layer, i) in layers"
      :first="i == 0"
      :last="i == layers.length - 1"
      :key="i"
      :currentLayer="currentLayer"
      @menu-open="propagateMenuOpen"
      @menu-add="propagateMenuAdd"
      @menu-edit="propagateMenuEdit"
      @menu-delete="propagateMenuDelete"
      @menu-move-up="propagateMenuMoveUp"
      @menu-move-down="propagateMenuMoveDown"
      @select="propagateSelect"
    ></layer>
    <div class="disabled" v-if="!layers.length">No Layers Defined</div>
  </span>
</template>
<script>
import LayersListItemVue from "./LayersListItem.vue";
import LayerDialogVue from "./../LayerDialog.vue";
import eventBus from "../../../../eventBus";
export default {
  components: {
    layer: LayersListItemVue,
    layerDialog: LayerDialogVue,
  },
  props: ["layers", "currentLayer"],
  methods: {
    resolveLayer(path, layer) {
      var nodes = path.split("/");
      var context = layer || {
        name: "root",
        root: true,
        type: "group",
        children: this.layers,
      };
      var result = null;
      var node = nodes[0];
      if (node == "") result = context;
      else {
        result = context.children.filter((l) => l.id == node)[0] || null;
      }
      nodes.splice(0, 1);
      if (result == null || nodes.length == 0) return result;
      else return this.resolveLayer(nodes.join("/"), result);
    },
    propagateMenuOpen(evt) {
      this.$emit("menu-open", evt);
    },
    propagateMenuAdd(evt) {
      this.$emit("menu-add", evt);
    },
    propagateMenuEdit(evt) {
      this.$emit("menu-edit", evt);
    },
    propagateMenuDelete(evt) {
      this.$emit("menu-delete", evt);
    },
    propagateMenuMoveUp(evt) {
      this.$emit("menu-move-up", evt);
    },
    propagateMenuMoveDown(evt) {
      this.$emit("menu-move-down", evt);
    },
    propagateSelect(evt) {
      this.$emit("select", evt);
    },
  },
  created() {
    //console.log("LayersList created:", this.layers);
  },
  data() {
    return {};
  },
};
</script>
<style>
.layers-list {
  display: inline-block;
  min-width: 350px;
}
</style>
