"use strict";Object.defineProperty(exports, "__esModule", { value: true });exports["default"] = void 0;var _default = exports["default"] = {
  toLowerCase: function toLowerCase() {
    return function (val, render) {
      return render(val).toLowerCase(); //{{#unit.toLowerCase}}{{/unit.toLowerCase}}
    };
  },
  toUpperCase: function toUpperCase() {
    return function (val, render) {
      return render(val).toUpperCase(); //{{#unit.toUpperCase}}{{unit.name}}{{/unit.toUpperCase}}
    };
  },
  length: function length() {
    return function (val, render) {
      return render(val).length; //{{#unit.length}}{{unit.name}}{{/unit.length}}
    };
  },
  encodeURIComponent: function (_encodeURIComponent) {function encodeURIComponent() {return _encodeURIComponent.apply(this, arguments);}encodeURIComponent.toString = function () {return _encodeURIComponent.toString();};return encodeURIComponent;}(function () {
    return function (val, render) {
      return encodeURIComponent(render(val)); //<img src=/client/images/{{#unit.encodeURIComponent}}{{unit.name}}{{/unit.encodeURIComponent}} alt="fuck"></img>
    };
  }),
  trim: function trim() {
    return function (val, render) {
      return render(val).trim(); //{{#unit.trim}}{{unit.name}}{{/unit.trim}}
    };
  },
  toPrecision: function toPrecision() {
    return function (val, render) {
      try {
        var str = render(val);
        var arrayParams = str.split(/[\s]*[,]{1}[\s]*/gi);
        var number = parseFloat(arrayParams[0]);
        arrayParams.shift();
        //The arrayParams 
        var value = number.toPrecision.call(number, arrayParams);
        return value;
      } catch (error) {
        console.error(error);
        return "";
      }
    };
  },
  floor: function floor() {
    return function (val, render) {
      return Math.floor(render(val));
    };
  },
  round: function round() {
    return function (val, render) {
      return Math.round(render(val));
    };
  },
  ceil: function ceil() {
    return function (val, render) {
      return Math.ceil(render(val));
    };
  },
  random: function random() {
    return function (render, val) {
      return Math.random() * render + 1;
    };
  },
  getStrengthWithClass: function getStrengthWithClass() {
    return function (val, render) {
      var str = render("{{unit.strength}}");
      str = str.replace('%', '');
      if (str <= 25) {
        return "<span class=\"strength d-block px-1 strength-red\">" + str + "</span>";
      } else if (str <= 75) {
        return "<span class=\"strength d-block px-1 strength-orange\">" + str + "</span>";
      } else {
        return "<span class=\"strength d-block px-1 strength-green\">" + str + "</span>";
      }
    };
  },
  toJSON: function toJSON() {
    return function (val, renderer) {
      return JSON.stringify(this, null, 2);
    };
  }
};