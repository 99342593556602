var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('help-popup',{class:{'isAbsolute': true,},attrs:{"name":'Units'}}),_vm._v(" "),(_vm.scenario)?_c('div',[(_vm.scenario.content.units)?_c('div',{staticClass:"units-edit d-flex"},[_c('span',{staticClass:"h2 flex-grow-1"},[(_vm.scenario.content.units.type === 'luna')?_c('span',[_vm._v("Luna Wargame")]):_vm._e(),_vm._v(" "),(
          _vm.scenario.content.units.type === 'up' || !_vm.scenario.content.units.type
        )?_c('span',[_vm._v("\n          UP Wargame\n        ")]):_vm._e()]),_vm._v(" "),(_vm.scenario.content.units)?_c('button',{staticClass:"btn btn-outline-danger my-1",on:{"click":function($event){$event.preventDefault();return _vm.$refs.confirmRemoveUnits.open()}}},[_vm._v("\n        Remove Units Section\n      ")]):_vm._e()]):_vm._e(),_vm._v(" "),( 
        _vm.scenario.content.units &&
        (!_vm.scenario.content.units.type || _vm.scenario.content.units.type === 'up')
      )?_c('units-section-up',{attrs:{"scenario":_vm.scenario,"datastores":_vm.datastores,"markupPopupUnit":_vm.markupPopupUnit,"markupPopupUnitWithTab":_vm.markupPopupUnitWithTab,"markupPopupUnitReference":_vm.markupPopupUnitReference}}):_vm._e(),_vm._v(" "),(
        _vm.scenario.content.units &&
        _vm.scenario.content.units.type === 'luna' &&
        _vm.$store.state.serverInfo.features.luna
      )?_c('units-section-luna',{attrs:{"scenario":_vm.scenario,"datastores":_vm.datastores,"markupPopupUnit":_vm.markupPopupUnit,"markupPopupUnitWithTab":_vm.markupPopupUnitWithTab,"markupPopupUnitReference":_vm.markupPopupUnitReference}}):_vm._e(),_vm._v(" "),(_vm.scenario.content.units && _vm.scenario.content.units.type === 'custom')?_c('units-section-custom',{attrs:{"scenario":_vm.scenario,"datastores":_vm.datastores,"markupPopupUnit":_vm.markupPopupUnit,"markupPopupUnitWithTab":_vm.markupPopupUnitWithTab,"markupPopupUnitReference":_vm.markupPopupUnitReference}}):_vm._e(),_vm._v(" "),(
      _vm.scenario.content.units &&
      _vm.scenario.content.units.type === 'luna' &&
      !_vm.$store.state.serverInfo.features.luna
    )?_c('div',[_vm._v("\n      Luna Wargame not available\n    ")]):_vm._e(),_vm._v(" "),_c('div',[(!_vm.scenario.content.units)?_c('button',{staticClass:"btn btn-outline-primary my-1",class:{ active: _vm.upWargame },on:{"click":function($event){$event.preventDefault();return _vm.setType('up')}}},[_vm._v("\n        UP Wargame\n      ")]):_vm._e(),_vm._v(" "),(!_vm.scenario.content.units && _vm.$store.state.serverInfo.features.luna)?_c('button',{staticClass:"btn btn-outline-primary my-1",class:{ active: _vm.lunaWargame },on:{"click":function($event){$event.preventDefault();return _vm.setType('luna')}}},[_vm._v("\n        Luna Wargame\n      ")]):_vm._e(),_vm._v(" "),(!_vm.scenario.content.units)?_c('button',{staticClass:"btn btn-outline-primary my-1",class:{ active: _vm.customWargame },on:{"click":function($event){$event.preventDefault();return _vm.setType('custom')}}},[_vm._v("\n        Custom Wargame\n      ")]):_vm._e()]),_vm._v(" "),_c('confirm-dialog',{ref:"confirmRemoveUnits",attrs:{"body":'Are you sure do you want to delete units section? All your units will be lost!',"confirmBtnClasses":"btn-danger"},on:{"confirm":_vm.confirmRemoveUnits,"cancel":function($event){return _vm.$refs.confirmRemoveUnits.close()}}}),_vm._v(" "),_c('loading',{attrs:{"loading":_vm.loadingTables}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }