import Vue from "vue";
import eventBus from "../eventBus";
import credentialsManager from "../CredentialsManager";
export default new Vue({
  created() {
    eventBus.$on("login", token => {
      this.credentials = token;
      this.connect();
    });
    eventBus.$on("logout", () => {
      this.credentials = null;
      this.disconnect();
    });
    if (credentialsManager.credentials) {
      this.credentials = credentialsManager.credentials;
      this.connect();
    }
  },
  methods: {
    connect() {
      const config = window.JMSS.config;
      console.log("WEBSOCKET", "connect", this.token);
      let url = config.xserverWSUrl;
      if (!url.match(/^wss?:\//gi)) {
        const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
        url = protocol + "//" + window.location.host + config.xserverWSUrl
      }
      this.ws = new WebSocket(url);
      this.ws.onopen = this.onOpen.bind(this);
      this.ws.onerror = this.onError.bind(this);
      this.ws.onmessage = this.onMessage.bind(this);
      this.ws.onclose = this.onClose.bind(this);
    },
    disconnect() {
      this.ws.close();
      this.ws = null;
    },
    send(msg) {
      if (typeof msg === "object") this.ws.send(JSON.stringify(msg));
      else if (typeof msg === "string") this.ws.send(msg);
    },
    onOpen() {
      console.log("WEBSOCKET", "open");
      eventBus.$emit("ws-open", this);
    },
    onError(error) {
      console.log("WEBSOCKET", "error", error);
      eventBus.$emit("ws-error", this);
    },
    onMessage(evt) {
      //console.log("WEBSOCKET", "message", evt.data);
      eventBus.$emit("ws-message", this, evt.data);
      try {
        var msg = JSON.parse(evt.data);
        if (msg.event === "auth-required") {
          if (this.credentials) {
            this.ws.send(
              JSON.stringify({
                event: "auth",
                data: "Bearer " + this.credentials.access_token
              })
            );
          }
        } else if (msg.event) {
          if (msg.event === "authenticated") {
            this.authenticated = true;
            eventBus.$emit("ws-authenticated");
          } else {
            eventBus.$emit(msg.event, msg.data);
          }
        } else {
          console.warn("Unexpected websocket message", evt.data);
        }
      } catch (e) {
        console.error("Error handling websocket message", e);
      }
    },
    onClose() {
      console.log("WEBSOCKET", "close");
      eventBus.$emit("ws-close", this);
    }
  }
});
