<template>
  <span class="layers-list-item new-layer" :class="{ show: menuOpened }">
    <div :class="{ header: true, active: layer === currentLayer }">
      <span
        class="expanded-icon"
        :class="{
          oi: true,
          pointer: true,
          'icon-arrow-down2': expandedIconDown,
          'icon-arrow-right2': expandedIconRight,
          'icon-minus2': expandedIconMinus,
          'oi-geom-point': geomPointIcon,
          'oi-geom-polygon': geomPolygonIcon,
          'oi-geom-line': geomLineIcon,
          'oi-geom-none': geomNoneIcon,
        }"
        @click.prevent="toggleExpanded"
      ></span>
      <span class="title ml-1" @click.prevent="select">{{
        layer.title || layer.id || "(layer)"
      }}</span>
      <span class="icon icon-menu7 pointer ml-2" @click.prevent.stop="openMenu">
        <div
          ref="contextMenu"
          :class="{
            'dropdown-menu': true,
            'dropdown-menu-sm': true,
            show: menuOpened,
          }"
          :style="{ left: menuStyleLeft }"
        >
          <a
            class="dropdown-item"
            href="#"
            v-show="layer.type == 'group'"
            @click.prevent.stop="menuAdd"
          >
            <span class="icon icon-plus2"></span>
            <span class="label">Add Layer...</span>
          </a>
          <!--
          <a class="dropdown-item" href="#" @click.prevent.stop="menuEdit">
            <span class="oi oi-pencil"></span>
            <span class="label">Edit...</span>
          </a>
          -->
          <a class="dropdown-item" href="#" @click.prevent.stop="menuDelete">
            <span class="icon icon-bin2"></span>
            <span class="label">Delete...</span>
          </a>
          <a
            href="#"
            :class="{ 'dropdown-item': true, disabled: first }"
            @click.prevent.stop="menuMoveUp"
          >
            <span class="icon icon-arrow-up2"></span>
            <span class="label">Move Up</span>
          </a>
          <a
            href="#"
            :class="{ 'dropdown-item': true, disabled: last }"
            @click.prevent.stop="menuMoveDown"
          >
            <span class="icon icon-arrow-down2"></span>
            <span class="label">Move Down</span>
          </a>
        </div>
      </span>
    </div>
    <div class="children" v-show="expanded && hasChild">
      <span class="indent mr-2"></span>
      <div class="children-list">
        <layers-list-item
          v-for="(l, i) in layer.children"
          :key="i"
          :layer="l"
          :path="path + '/' + layer.id"
          :first="i == 0"
          :last="i == layer.children.length - 1"
          :currentLayer="currentLayer"
          @menu-open="propagateOpenMenu"
          @menu-add="propagateMenuAdd"
          @menu-edit="propagateMenuEdit"
          @menu-delete="propagateMenuDelete"
          @menu-move-up="propagateMenuMoveUp"
          @menu-move-down="propagateMenuMoveDown"
          @select="propagateSelect"
        ></layers-list-item>
      </div>
    </div>
  </span>
</template>
<script>
import eventBus from "../../../../eventBus";
export default {
  name: "layers-list-item",
  props: {
    layer: {
      required: true,
      type: Object,
    },
    currentLayer: {
      required: false,
    },
    path: { required: true, default: "", type: String },
    first: {
      default: false,
      type: Boolean,
    },
    last: {
      default: false,
      tyoe: Boolean,
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    menuAdd(evt) {
      evt.layer = this.layer;
      evt.layerPath = this.path + "/" + this.layer.id;
      this.$emit("menu-add", evt);
    },
    menuEdit(evt) {
      evt.layer = this.layer;
      evt.layerPath = this.path + "/" + this.layer.id;
      this.$emit("menu-edit", evt);
    },
    menuDelete(evt) {
      evt.layer = this.layer;
      evt.layerPath = this.path + "/" + this.layer.id;
      this.$emit("menu-delete", evt);
    },
    menuMoveUp(evt) {
      evt.layer = this.layer;
      evt.layerPath = this.path + "/" + this.layer.id;
      this.$emit("menu-move-up", evt);
    },
    menuMoveDown(evt) {
      evt.layer = this.layer;
      evt.layerPath = this.path + "/" + this.layer.id;
      this.$emit("menu-move-down", evt);
    },
    select(evt) {
      evt.layer = this.layer;
      evt.layerPath = this.path + "/" + this.layer.id;
      this.$emit("select", evt);
    },
    openMenu(evt) {
      evt.layer = this.layer;
      evt.layerPath = this.path + "/" + this.layer.id;
      eventBus.$emit("close-dropdown-menus");
      this.$refs.contextMenu.classList.add("show");
      this.menuOpened = true;
      var w = this.$refs.contextMenu.clientWidth - 16;
      this.menuStyleLeft = "-" + w + "px";
      this.$emit("menu-open", evt);
    },
    closeMenu(evt) {
      this.$refs.contextMenu.classList.remove("show");
      this.menuOpened = false;
    },
    propagateMenuAdd(evt) {
      this.$emit("menu-add", evt);
    },
    propagateMenuEdit(evt) {
      this.$emit("menu-edit", evt);
    },
    propagateMenuDelete(evt) {
      this.$emit("menu-delete", evt);
    },
    propagateMenuMoveUp(evt) {
      this.$emit("menu-move-up", evt);
    },
    propagateMenuMoveDown(evt) {
      this.$emit("menu-move-down", evt);
    },
    propagateOpenMenu(evt) {
      this.$emit("menu-open", evt);
    },
    propagateSelect(evt) {
      this.$emit("select", evt);
    },
    getGeometryType() {
      return this.layer.sources[0].geomType || "";
    },
  },
  computed: {
    hasChild() {
      return this.layer.children && this.layer.children.length;
    },
    expandedIconDown() {
      return this.layer.children && this.layer.children.length && this.expanded;
    },
    expandedIconRight() {
      return (
        this.layer.children && this.layer.children.length && !this.expanded
      );
    },
    expandedIconMinus() {
      return (
        this.layer.type === "group" &&
        (!this.layer.children || !this.layer.children.length)
      );
    },
    geomPointIcon() {
      var geomType = this.getGeometryType();
      return (
        this.layer.type === "wisdom-vector-layer" &&
        geomType.indexOf("POINT") >= 0
      );
    },
    geomPolygonIcon() {
      var geomType = this.getGeometryType();
      return (
        this.layer.type === "wisdom-vector-layer" &&
        geomType.indexOf("POLYGON") >= 0
      );
    },
    geomLineIcon() {
      var geomType = this.getGeometryType();
      return (
        this.layer.type === "wisdom-vector-layer" &&
        geomType.indexOf("LINE") >= 0
      );
    },
    geomNoneIcon() {
      var geomType = this.getGeometryType();
      return this.layer.type === "wisdom-vector-layer" && !geomType;
    },
  },
  created() {
    eventBus.$on("close-dropdown-menus", () => {
      this.menuOpened = false;
    });
  },
  data() {
    return {
      expanded: true,
      menuOpened: false,
      menuStyleLeft: "auto",
    };
  },
};
</script>
<style>
.layers-list-item .header .expanded-icon {
  width: 16px;
  height: 16px;
}
.layers-list-item .header {
  display: flex;
  align-items: center;
}
.layers-list-item .header .expanded-icon,
.layers-list-item .header .title {
  vertical-align: middle;
  text-align: left;
}
.layers-list-item .header .title {
  flex-grow: 1;
}
.layers-list-item .indent {
  width: 8px;
  display: inline-block;
  border-right: 1px solid black;
}
.layers-list-item .children {
  display: flex;
}
.layers-list-item .children .children-list {
  flex-grow: 1;
}
.layers-list-item .header .dropdown-menu {
  top: 16px;
}
</style>
