<template>
  <table class="table table-sm table-striped w-100">
    <thead>
      <tr>
        <th>#</th>
        <th>Type</th>
        <th>Params</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!value || !value.length">
        <td colspan="100%">No Event Specified</td>
      </tr>
      <template v-for="(event, i) in value">
        <tr :key="i" @click="selectEvent(event, i)">
          <td>{{ i + 1 }}</td>
          <td>{{ event.type }}</td>
          <td>
            <pre>{{ JSON.stringify(event.params, null, 2) }}</pre>
          </td>
          <td>
            <button
              class="btn btn-sm btn-outline-danger icon icon-bin2"
              @click.stop.prevent="removeEventAt(i)"
            />
          </td>
        </tr>
        <tr :key="i + '_edit'">
          <td v-if="event === selectedEvent" colspan="100%">
            <map-journal-event-form
              v-model="selectedEvent"
              :scenario="scenario"
            />
          </td>
        </tr>
      </template>
      <tr>
        <td
          class="btn btn-sm btn-secondary d-table-cell"
          colspan="100%"
          @click.prevent.stop="addEvent"
        >
          <span class="icon icon-plus2"></span>
          <span>Add Event</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import MapJournalEventForm from "./MapJournalEventForm.vue";
export default {
  name: "map-journal-events-section",
  props: ["value", "scenario"],
  components: {
    "map-journal-event-form": MapJournalEventForm,
  },
  watch: {
    selectedEvent() {
      this.value[this.selectedIndex] = this.selectedEvent;
      this.$emit("input", [...this.value]);
    },
  },
  methods: {
    defaultEvent() {
      return {
        type: "enable-layer",
        params: {
          layer: "",
        },
      };
    },
    selectEvent(event, index) {
      this.selectedEvent = event;
      this.selectedIndex = index;
    },
    addEvent() {
      const event = this.defaultEvent();
      // this.value.push(event);
      this.$emit("input", [...this.value, event]);
      this.selectEvent(event, this.value.length);
    },
    removeEventAt(index) {
      const newValue = [...this.value];
      newValue.splice(index, 1);
      this.$emit("input", newValue);
      if (newValue.length === 0) {
        this.selectEvent(null);
      } else {
        const selectedIndex = Math.min(index, newValue.length - 1);
        this.selectEvent(newValue[selectedIndex], selectedIndex);
      }
    },
  },
  data() {
    return {
      selectedEvent: null,
    };
  },
};
</script>