<template>
  <nav class="navbar navbar-light bg-light flex-column justify-content-start">
    <ul class="navbar-nav">
      <li class="nav-item">
        <router-link class="nav-link text-nowrap" to="/administration/users">
          <span class="icon icon-user"></span>
          <span class="label">Users</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link text-nowrap" to="/administration/groups">
          <span class="icon icon-users"></span>
          <span class="label">Groups</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link text-nowrap"
          to="/administration/datastores"
        >
          <span class="icon icon-database"></span>
          <span class="label">Datastores</span>
        </router-link>
      </li>
      <!-- <li class="nav-item">
        <router-link
          class="nav-link text-nowrap"
          to="/administration/data-export"
        >
          <span class="icon icon-download"></span>
          <span class="label">Data Export</span>
        </router-link>
      </li> -->
      <li class="nav-item">
        <router-link
          class="nav-link text-nowrap"
          v-if="$store.state.serverInfo.features.hla"
          to="/administration/hla"
        >
          <span class="icon icon-cord"></span>
          <span class="label">HLA connectors</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link text-nowrap"
          to="/administration/scenarios"
        >
          <span class="icon icon-map"></span>
          <span class="label">Scenarios</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          class="nav-link text-nowrap"
          to="/administration/instances"
        >
          <span class="icon icon-stack3"></span>
          <span class="label">Instances</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="$store.state.serverInfo.features.cache">
        <router-link class="nav-link text-nowrap" to="/administration/cache">
          <span class="icon icon-grid3"></span>
          <span class="label">Cache</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="$store.state.serverInfo.features.terrains3d">
        <router-link class="nav-link text-nowrap" to="/administration/terrains">
          <span class="icon icon-earth"></span>
          <span class="label">Terrains</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="$store.state.serverInfo.features.buildings3d">
        <router-link class="nav-link text-nowrap" to="/administration/3dtiles">
          <span class="icon icon-cube4"></span>
          <span class="label">3D Tiles</span>
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          $store.state.serverInfo.features.hla ||
            $store.state.serverInfo.features.terrains3d ||
            $store.state.serverInfo.features.buildings3d ||
            $store.state.serverInfo.features.process
        "
      >
        <router-link class="nav-link" to="/administration/process">
          <span class="icon icon-stairs"></span>
          <span class="label">Process</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: "admin-menu",
  data() {
    return {};
  },
};
</script>
