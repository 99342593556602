<template>
  <div class="mapJournalVignetteEdit">
    <div @click.stop.prevent="show = !show" class="btn btn-sm px-0">
      <span
        class="icon"
        :class="{ 'icon-arrow-down2': show, 'icon-arrow-right2': !show }"
      />
      <b class="ml-1">{{ vignette }}</b>
    </div>
    <div class="row" v-if="show">
      <div class="col col-12 col-md-3 col-lg-2">
        <ul class="sections-list list-group">
          <li
            v-for="(section, j) in value.sections"
            :key="j"
            class="list-group-item btn btn-sm"
            :class="{
              active: section === editingSection,
            }"
            @click.stop.prevent="onSectionClick(section)"
          >
            {{ section.sideContent.title || "(no title)" }}
          </li>
          <li
            class="list-group-item bg-light"
            v-if="!value.sections || !value.sections.length"
          >
            No section defined
          </li>
        </ul>
        <ul class="sections-list-add list-group">
          <li
            class="list-group-item bg-secondary btn btn-sm btn-secondary"
            @click.prevent="addSection"
          >
            <span class="icon icon-plus2"></span>
            <span>Add Section</span>
          </li>
        </ul>
      </div>
      <map-journal-section-form
        v-if="editingSection"
        v-model="editingSection"
        :scenario="scenario"
        @move-up="moveUp(editingSection)"
        @move-down="moveDown(editingSection)"
        @delete="deleteSection(editingSection)"
        class="col-12 col-md-9 col-lg-10"
      />
    </div>
  </div>
</template>
<script>
import MapJournalSectionForm from "./MapJournalSectionForm.vue";
export default {
  props: ["value", "vignette", "scenario"],
  name: "map-journa-vignette-edit",
  components: {
    "map-journal-section-form": MapJournalSectionForm,
  },
  watch: {
    value() {
      if (this.value.sections.length > 0 && this.editingSection === undefined)
        this.editingSection = this.value.sections[0];
    },
  },
  methods: {
    addSection() {
      const section = this.defaultSection();
      this.value.sections.push(section);
      this.editingSection = section;
      this.$forceUpdate();
    },
    deleteSection(section) {
      const index = this.value.sections.indexOf(section);
      if (index < 0) return;
      this.value.sections.splice(index, 1);
      this.editingSection = this.value.sections[index];
      if (!this.editingSection)
        this.editingSection =
          this.value.sections[this.value.sections.length - 1];
      this.$forceUpdate();
    },
    moveUp(section) {
      const index = this.value.sections.indexOf(section);
      if (index <= 0) return;
      this.value.sections[index] = this.value.sections[index - 1];
      this.value.sections[index - 1] = section;
      this.$forceUpdate();
    },
    moveDown(section) {
      const index = this.value.sections.indexOf(section);
      if (index < 0 || index >= this.value.sections.length - 1) return;
      this.value.sections[index] = this.value.sections[index + 1];
      this.value.sections[index + 1] = section;
      this.$forceUpdate();
    },
    onSectionClick(section) {
      this.editingSection = section;
    },
    defaultSection() {
      return {
        sideContent: {
          title: "",
          content: "",
        },
        mainContent: {
          type: "map",
          onEnter: [],
          onLeave: [],
        },
      };
    },
  },
  data() {
    return {
      show: false,
      editingSection: undefined,
    };
  },
};
</script>