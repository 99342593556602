const config = window.JMSS.config;

export const apiUrl = () => {
  console.log("#API");
  console.log(config);
  const api = config.xserverApiUrl;
  console.log("##aaa", api);
  return api; //api.replace(/\/$/, "");
};

export default apiUrl();
