<template>
  <div>
    <help-popup :class="{'isAbsolute': true,}" :name="'Base Info'"></help-popup>
    <div class="w-100 p-2">
      <div class="form-group form-group-base-info row">
        <label class="pr-1 col-2 justify-content-start">Name:</label>
        <div class="col-10 mt-2">
          <input type="text" name="name" class="form-control" placeholder="Scenario name..." v-model="scenario.name" />
        </div>
        <label class="col-2 justify-content-start">Coordinates:</label>
        <div class="col-10 mt-2">
          <input type="number" name="long" min="-90" max="90" step="0.000001" class="form-control"
            placeholder="Long/X..." v-model.number="scenario.content.coordinates.x" />
          <input type="number" name="lat" min="-90" max="90" step="0.000001" class="form-control" placeholder="Lat/Y..."
            v-model.number="scenario.content.coordinates.y" />
        </div>
        <label class="col-2 justify-content-start">Min Zoom:</label>
        <div class="col-10 mt-2">
          <select class="form-control" v-model.number="scenario.content.minZoom">
            <option v-for="z in [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
            ]" :value="z" :key="z">
              {{ z }}
            </option>
          </select>
        </div>
        <label class="col-2 justify-content-start">Max Zoom:</label>
        <div class="col-10 mt-2">
          <select class="form-control" v-model.number="scenario.content.maxZoom">
            <option v-for="z in [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
            ]" :value="z" :key="z">
              {{ z }}
            </option>
          </select>
        </div>
        <label class="col-2 justify-content-start">Initial Zoom:</label>
        <div class="col-10 mt-2">
          <select class="form-control" v-model.number="scenario.content.zoom">
            <option v-for="z in [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
            ]" :value="z" :key="z">
              {{ z }}
            </option>
          </select>
        </div>
        <label class="col-2 justify-content-start mt-2" v-if="$store.state.serverInfo.features.app3d">
          3D:
        </label>
        <div class="col-10 mt-2" v-if="$store.state.serverInfo.features.app3d">
          <label class="custom-control custom-checkbox d-block">
            <input type="checkbox" class="custom-control-input" v-model="scenario.content.app3D" @change="changed3D" />
            <span class="custom-control-label"></span>
          </label>
        </div>
        <label class="col-2 justify-content-start mt-2">
          Export widget:
        </label>
        <div class="col-10 mt-2">
          <label class="custom-control custom-checkbox d-block">
            <input type="checkbox" class="custom-control-input" v-model="scenario.content.export.enabled" />
            <span class="custom-control-label"></span>
          </label>
        </div>
        <label class="col-2 justify-content-start mt-2">
          Measurement widget:
        </label>
        <div class="col-10 mt-2">
          <label class="custom-control custom-checkbox d-block">
            <input type="checkbox" class="custom-control-input" v-model="scenario.content.measurement.enabled" />
            <span class="custom-control-label"></span>
          </label>
        </div>
        <label class="col-2 justify-content-start mt-2">
          Draw widget:
        </label>
        <div class="col-10 mt-2">
          <label class="custom-control custom-checkbox d-block">
            <input type="checkbox" class="custom-control-input" v-model="scenario.content.draw.enabled" />
            <span class="custom-control-label"></span>
          </label>
        </div>
        <label class="col-2 justify-content-start mt-2">
          Legend widget:
        </label>
        <div class="col-10 mt-2">
          <label class="custom-control custom-checkbox d-block">
            <input type="checkbox" class="custom-control-input" v-model="scenario.content.legend.enabled" />
            <span class="custom-control-label"></span>
          </label>
        </div>
        <label class="col-2 justify-content-start mt-2">
          Layer list widget:
        </label>
        <div class="col-10 mt-2">
          <label class="custom-control custom-checkbox d-block">
            <input type="checkbox" class="custom-control-input" v-model="scenario.content.layerList.enabled" />
            <span class="custom-control-label"></span>
          </label>
        </div>
        <label class="col-2 justify-content-start mt-2">
          Basemaps widget:
        </label>
        <div class="col-10 mt-2">
          <label class="custom-control custom-checkbox d-block">
            <input type="checkbox" class="custom-control-input" v-model="scenario.content.basemapsWidget.enabled" />
            <span class="custom-control-label"></span>
          </label>
        </div>
        <label class="col-2 justify-content-start mt-2">
          After Action Review:
        </label>
        <div class="col-10 mt-2">
          <label class="custom-control custom-checkbox d-block">
            <input type="checkbox" class="custom-control-input" v-model="scenario.content.aar" />
            <span class="custom-control-label"></span>
          </label>
        </div>
        <template v-if="scenario.content.app3D && $store.state.serverInfo.features.app3d">
          <label class="col-2 justify-content-start mt-2">Terrain:</label>
          <div class="col-10 mt-2">
            <select class="form-control" v-model.number="scenario.content.terrain" required>
              <option value="">Select a terrain....</option>
              <option v-for="terrain in terrains" :value="terrain.id" :key="terrain.id">
                {{ terrain.id }}
              </option>
            </select>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../../api";
import HelpPopup from "../../../HelpPopup.vue";
export default {
  components: {
    "help-popup": HelpPopup
  },
  created() {
    console.log("scenario", this.scenario);
    if (!this.scenario.content.export) {
      this.scenario.content.export = {
        enabled: false,
      };
    }
    if (!this.scenario.content.measurement) {
      this.scenario.content.measurement = {
        enabled: true,
      };
    }
    if (!this.scenario.content.legend) {
      this.scenario.content.legend = {
        enabled: true,
      };
    }
    if (!this.scenario.content.draw) {
      this.scenario.content.draw = {
        enabled: true,
      };
    }
    if (!this.scenario.content.layerList) {
      this.scenario.content.layerList = {
        enabled: true,
      };
    }
    if (!this.scenario.content.basemapsWidget) {
      this.scenario.content.basemapsWidget = {
        enabled: true,
      };
    }
    if(!this.scenario.content.aar){
      this.scenario.content.aar = false;
    }
  },
  watch: {
    scenario(newVal, oldVal) {
      if (!this.scenario || !this.scenario.content) return;
      if (!this.scenario.content.export) {
        this.scenario.content.export = {
          enabled: false,
        };
      }
      if (this.scenario.content.measurement === undefined) {
        this.scenario.content.measurement = {
          enabled: true,
        };
      }
      if (this.scenario.content.legend === undefined) {
        this.scenario.content.legend = {
          enabled: true,
        };
      }
      if (this.scenario.content.draw === undefined) {
        this.scenario.content.draw = {
          enabled: true,
        };
      }
      if (this.scenario.content.layerList === undefined) {
        this.scenario.content.layerList = {
          enabled: true,
        };
      }
      if (this.scenario.content.basemapsWidget === undefined) {
        this.scenario.content.basemapsWidget = {
          enabled: true,
        };
      }
      this.loadTerrains();
    },
  },
  props: ["scenario"],
  methods: {
    async changed3D(event) {
      const app3D = event.currentTarget.checked;
      if (app3D) {
        if (!this.scenario.content.hasOwnProperty("terrain"))
          this.scenario.content.terrain = null;
        await this.loadTerrains();
      } else {
        if (this.scenario.content.hasOwnProperty("terrain"))
          delete this.scenario.terrain;
      }
      this.$forceUpdate();
    },

    async loadTerrains() {
      this.loadingTerrains = true;
      try {
        const res = await this.$http.get(api + "/rest/terrains");
        this.terrains = res.body;
        this.loadingTerrains = false;
      } catch (err) {
        if (res.status === 401) {
          eventBus.$emit("logout");
        } else {
          this.$snotify.error(
            "Server Error:" + (err && error.message) ||
            "Unexpected Server Error",
            "Error",
            {
              timeout: 2000,
            }
          );
        }
      }
      this.loadingTerrains = false;
    },
  },
  data() {
    return {
      loadingTerrains: false,
      terrains: [],
    };
  },
};
</script>
