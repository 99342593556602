<template>
<div>
  <help-popup :class="{'isAbsolute': true,}"  :name="'Vignettes'"></help-popup>
  <div class="w-100 p-2">
    <div class="mb-2">
      <button class="btn btn-outline-primary" @click.prevent="newVignette">
        <span class="icon icon-plus2"></span>
        <span>Add</span>
      </button>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!scenario.content.vignettes.length">
          <td colspan="3">No Vignette Defined</td>
        </tr>
        <tr v-for="(vignette, i) in scenario.content.vignettes" :key="vignette" 
        :class="{'table-warning': vSimulation && vSimulation.name === vignette}">
          <td>{{ i + 1 }}</td>
          <td>{{ vignette }}</td>
          <td>
            <div
              class="btn btn-outline-primary icon icon-pencil4"
              @click.prevent="editVignette(i)"
            ></div>
            <div
              class="btn btn-outline-danger icon icon-bin2"
              @click.prevent="deleteVignette(i)"
            ></div>
            <div
              class="btn btn-outline-secondary icon icon-arrow-down2"
              :class="{
                disabled: cannotGoDown(i),
              }" @click.prevent="moveVignetteDown(i)"></div>
              <div class="btn btn-outline-secondary icon icon-arrow-up2" :class="{
                disabled: cannotGoUp(i),
              }"
              @click.prevent="moveVignetteUp(i)"
            ></div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="scenario.content.vignettes.length > 0"
      class="form-group row mx-0"
      title="Choose a vignette for simulation"
    >
      <label
        for="vSimulation"
        class="control-label col-3 col-form-label p-0 py-2"
        ><strong>Vignette for Simulation:</strong></label
      >
      <model-list-select
        id="vSimulation"
        ref="listVignettes"
        class="form-control form-control-sm col-3"
        :list="listVignettes"
        v-model="vSimulation"
        option-value="id"
        option-text="name"
        placeholder="select a vignette for Simulation..."
        @input="vignetteSimulationSet"
      />
    </div>
    <div id="vignetteDialog" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <h3>Vignette</h3>
            <span>Vignette:</span>
            <input
              type="text"
              :class="{
                'form-control': true,
                'is-invalid': vignetteDialogInvalidName,
              }" v-model="vignetteDialog.name" @keydown.enter.prevent="confirmVignetteDialog()" />
            </div>
            <div class="modal-footer">
              <button class="btn btn-success" @click.prevent="confirmVignetteDialog">
                Ok
              </button>
              <button class="btn btn-secondary" @click.prevent="cancelVignetteDialog()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="vignetteDeleteDialog" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              Are you sure do you want to delete vignette "{{
              vignetteDialog.name
              }}"
            </div>
            <div class="modal-footer">
              <button class="btn btn-danger" @click.prevent="confirmDeleteVignette">
                Confirm
              </button>
              <button class="btn btn-secondary" @click.prevent="cancelDeleteVignette">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import eventBus from "../../../eventBus";
import HelpPopup from "../../../HelpPopup.vue";
import { ModelListSelect } from "vue-search-select";

export default {
  components: {
    "help-popup": HelpPopup,
    "model-list-select": ModelListSelect,
  },
  mounted() {
    $("#vignetteDialog").on("shown.bs.modal", () => {
      $("#vignetteDialog")
        .find("input")
        .first()
        .trigger("focus");
    });
    this.inizialize();
  },
  watch: {
     scenario() { 
      if(this.scenario.content.vignettes.length > 0)
        this.listVignettes = this.scenario.content.vignettes.map((v) => ({id: v, name: v}));
      if(this.scenario.content.vignetteSimulation && Object.entries(this.scenario.content.vignetteSimulation).length > 0)
        this.vSimulation = {id: this.scenario.content.vignetteSimulation.id, name: this.scenario.content.vignetteSimulation.name};
      },
      
  },
  props: ["scenario"],
  methods: {
    inizialize() {
      if (this.scenario.content.vignetteSimulation && Object.entries(this.scenario.content.vignetteSimulation).length > 0) {
        if (this.scenario.content.vignettes.length > 0)
          this.vSimulation = {id: this.scenario.content.vignetteSimulation.id, name: this.scenario.content.vignetteSimulation.name}
      }
      if(this.scenario.content.vignettes.length > 0)
        this.listVignettes = this.scenario.content.vignettes.map((v) => ({id: v, name: v}))
    },
    editVignette(index) {
      this.vignetteDialog = {
        id: index,
        name: this.scenario.content.vignettes[index],
      };
      $("#vignetteDialog").modal("show");
    },
    newVignette() {
      this.vignetteDialog = { id: -1, name: "" };
      $("#vignetteDialog").modal("show");
    },
    deleteVignette(index) {
      this.vignetteDialog = {
        id: index,
        name: this.scenario.content.vignettes[index],
      };
      $("#vignetteDeleteDialog").modal("show");
      
    },
    confirmVignetteDialog() {
      var valid = true;
      var name = this.vignetteDialog.name.trim();
      if (!name) valid = false;
      this.scenario.content.vignettes.forEach((v, i) => {
        if (
          i != this.vignetteDialog.id &&
          v.toLowerCase() == name.toLowerCase()
        )
          valid = false;
      });
      if (!valid) {
        this.vignetteDialog.validName = false;
        this.vignetteDialog = { ...this.vignetteDialog };
      } else {
        this.vignetteDialog.validName = true;
        if (this.vignetteDialog.id == -1) {
          this.scenario.content.vignettes.push(name);
        } else {
          this.scenario.content.vignettes[this.vignetteDialog.id] = name;
        }
        eventBus.$emit("scenario-vignettes-changed");
        $("#vignetteDialog").modal("hide");
      }
      this.inizialize();
      this.$forceUpdate();

    },
    moveVignetteUp(index) {
      const vignette = this.scenario.content.vignettes[index];
      if (typeof vignette === "undefined") return;
      this.scenario.content.vignettes.splice(index, 1);
      this.scenario.content.vignettes.splice(index - 1, 0, vignette);
      eventBus.$emit("scenario-vignettes-changed");
    },
    moveVignetteDown(index) {
      const vignette = this.scenario.content.vignettes[index];
      if (typeof vignette === "undefined") return;
      this.scenario.content.vignettes.splice(index, 1);
      this.scenario.content.vignettes.splice(index + 1, 0, vignette);
      eventBus.$emit("scenario-vignettes-changed");
    },
    confirmDeleteVignette() {
      if (this.vignetteDialog.id >= 0)
        this.scenario.content.vignettes.splice(this.vignetteDialog.id, 1);
      eventBus.$emit("scenario-vignettes-changed");
      $("#vignetteDeleteDialog").modal("hide");
      this.inizialize();
    },
    cancelVignetteDialog() {
      $("#vignetteDialog").modal("hide");
    },
    cancelDeleteVignette() {
      $("#vignetteDeleteDialog").modal("hide");
    },
    cannotGoUp(i) {
      return i == 0;
    },
    cannotGoDown(i) {
      return i == this.scenario.content.vignettes.length - 1;
    },
    vignetteSimulationSet() {
      if(Object.entries(this.vSimulation).length > 0 && this.vSimulation.name !== ""){
      this.scenario.content.vignetteSimulation = {
        id: this.vSimulation.name
          .trim()
          .toLowerCase()
          .replace(" ", "_"),
        name: this.vSimulation.name.trim(),
      };
      }else{
        this.scenario.content.vignetteSimulation = {
        };
      }
    },
  },
  computed: {
    vignetteDialogInvalidName() {
      return this.vignetteDialog.validName == false;
    },
  },
  data() {
    return {
      vignetteDialog: { id: -1, name: "" },
      vSimulation: {},
      listVignettes: [],
    };
  },
};
</script>