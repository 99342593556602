class Base64UploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload. It sounds scary but do not
    // worry — the loader will be passed into the adapter later on in this guide.
    this.loader = loader;
  }

  // ...
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          // this._initRequest();
          // this._initListeners( resolve, reject, file );
          // this._sendRequest( file );
          console.log("file", file);
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            resolve({
              default: reader.result,
              // "https://tattoodo-mobile-app.imgix.net/images/posts/20201122_Avden53Jrydualu.jpeg",
            });
          };
          reader.onerror = (error) => reject(error);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }
}
export default function Base64UploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    return new Base64UploadAdapter(loader);
  };
}
