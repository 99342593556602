<template>
  <tr @click="emitCellClick">
    <td>
      <span style="display: none">{{ value.unit_id }}:</span>
      <input
        type="text"
        class="form-control form-control-sm"
        v-model.lazy="value.name"
        v-if="edit"
      />
      <span v-else>{{ value.name }}</span>
    </td>
    <td>
      <model-list-select
        ref="owners"
        class="form-control form-control-sm"
        :list="owners"
        v-model="owner"
        option-value="id"
        option-text="text"
        placeholder="NONE"
        v-if="edit"
      />
      <span v-else>{{
        owners.filter((o) => o.id === value.owner)[0].text
      }}</span>
    </td>
    <td>
      <model-list-select
        ref="HQUnit"
        class="form-control form-control-sm"
        :list="parents"
        v-model="parent_id"
        option-value="id"
        option-text="text"
        placeholder="None"
        v-if="edit"
      />
      <span v-else>{{
        parent && parent.unit ? parent.unit.name : "None"
      }}</span>
    </td>
    <td>
      <input
        type="text"
        class="form-control form-control-sm symbol-field"
        v-model="value.symbol_id"
        v-if="edit"
      />
      <span v-else>{{ value.symbol_id }}</span>
      <button
        class="btn btn-sm oi oi-pencil btn-outline-primary"
        @click.stop.prevent="$emit('editSymbolClick')"
        v-if="edit"
      />
    </td>
    <td>
      <input
        type="number"
        class="form-control form-control-sm"
        min="0"
        max="1000"
        step="0.01"
        v-model="value.radius"
        v-if="edit"
      />
      <span v-else>{{ value.radius }} Km</span>
    </td>
    <td>
      <input
        type="number"
        class="form-control form-control-sm"
        min="0"
        max="1000"
        step="0.01"
        v-model="value.max_move"
        v-if="edit"
      />
      <span v-else>{{ value.max_move || 0 }} Km</span>
    </td>
    <td>
      <input
        type="number"
        class="form-control form-control-sm"
        min="0"
        max="100"
        step="1"
        v-model="value.strength"
        v-if="edit"
      />
      <span v-else>{{ value.strength }}</span>
    </td>
    <td style="max-width: 265px">
      <textarea
        cols="30"
        rows="2"
        class="form-control form-control-sm capabilities-field"
        v-model="value.capabilities"
        v-if="edit"
      ></textarea>
      <span v-else>{{ value.capabilities }}</span>
    </td>
    <td v-if="locations">
      <input
        type="number"
        class="form-control form-control-sm coord-field"
        step="any"
        v-model="value.long"
        v-if="edit"
      />
      <span v-else>{{ value.long }}</span>
    </td>
    <td v-if="locations">
      <input
        type="number"
        class="form-control form-control-sm coord-field"
        step="any"
        v-model="value.lat"
        v-if="edit"
      />
      <span v-else>{{ value.lat }}</span>
    </td>
    <td v-if="locations">
      <input
        type="number"
        class="form-control form-control-sm coord-field"
        step="any"
        v-model="value.elev"
        v-if="edit"
      />
      <span v-else>{{ value.elev }}</span>
    </td>
    <td>
      <button
        class="btn btn-sm btn-outline-danger icon icon-cross"
        @click.stop.prevent="deleteRecord()"
      />
    </td>
  </tr>
</template>
<script>
import { ModelListSelect } from "vue-search-select";
import SymbolEditDialogVue from "../../../../../xserver-common/components/SymbolEditDialog.vue";
export default {
  props: {
    value: {
      required: true,
    },
    edit: {
      default: false,
    },
    parent: {
      required: false,
    },
    owners: {
      required: false,
    },
    locations: {
      type: Boolean,
      default: true,
    },
  }, //["value", "owners", "parent", "edit"],
  components: {
    "model-list-select": ModelListSelect,
    "symbol-edit-dialog": SymbolEditDialogVue,
  },
  watch: {
    parent_id: function (newVal, oldVal) {
      if (this.parent_id != this.value.parent_id) {
        this.value.parent_id = newVal;
        this.$emit("parentChange", {
          newVal: newVal ? newVal : null,
          oldVal,
          record: this.value,
        });
      }
    },
    "value.parent_id"(newVal, oldVal) {
      if (newVal != this.parent_id) {
        this.parent_id = newVal || 0;
      }
    },
    owner: function (newVal, oldVal) {
      if (this.owner != this.value.owner) {
        this.value.owner = newVal;
        this.$emit("ownerChange", { newVal, oldVal, record: this.value });
        if (this.edit) this.parents = this.$parent.getParents(this.value);
      }
    },
    value() {
      this.update();
    },
    edit() {
      if (this.edit) this.parents = this.$parent.getParents(this.value);
    },
  },
  created() {
    this.update();
  },
  methods: {
    update() {
      this.parent_id = this.value.parent_id;
      this.owner = this.value.owner;
      if (this.edit) this.parents = this.$parent.getParents(this.value);
    },
    emitCellClick(event) {
      this.$emit("cellClick", event);
    },
    deleteRecord() {
      this.$emit("delete", this.value);
    },
  },
  data() {
    return { parents: [], owner: "", parent_id: 0 };
  },
};
</script>
