<template>
  <div class="row">
    <label for="eventType" class="col col-12 col-md-2"> Type </label>
    <div class="col col-12 col-md-10">
      <model-list-select
        ref="eventType"
        class="form-control form-control-sm mb-1"
        :list="types"
        v-model="type"
        required
        option-value="value"
        option-text="label"
        placeholder="None"
        @input="update()"
      />
    </div>
    <template v-if="type === 'enable-layer' || type === 'disable-layer'">
      <label for="eventType" class="col col-12 col-md-2"> Layer </label>
      <div class="col col-12 col-md-10">
        <model-list-select
          ref="eventLayer"
          class="form-control form-control-sm mb-1"
          :list="layers"
          v-model="layer"
          required
          option-value="id"
          option-text="path"
          placeholder="None"
          @input="update()"
        />
      </div>
    </template>
    <template v-if="type === 'enable-widget' || type === 'disable-widget'">
      <label for="eventType" class="col col-12 col-md-2"> Widget </label>
      <div class="col col-12 col-md-10">
        <model-list-select
          class="form-control form-control-sm mb-1"
          :list="widgets"
          v-model="widget"
          required
          option-value="value"
          option-text="label"
          placeholder="Widget..."
          @input="update()"
        />
      </div>
    </template>
    <template v-if="type === 'zoom-to' || type === 'pan-to'">
      <label for="eventX" class="col col-12 col-md-2"> Long/X: </label>
      <div class="col col-12 col-md-10 mb-1">
        <input
          id="eventX"
          type="number"
          step="any"
          min="-180"
          max="180"
          class="form-control form-control-sm"
          v-model.number="x"
          @input="update()"
        />
      </div>
      <label for="eventY" class="col col-12 col-md-2"> Lat/Y: </label>
      <div class="col col-12 col-md-10 mb-1">
        <input
          id="eventY"
          type="number"
          step="any"
          min="-90"
          max="90"
          class="form-control form-control-sm"
          v-model.number="y"
          @input="update()"
        />
      </div>
    </template>
    <template v-if="type === 'zoom-to'">
      <label for="eventZoom" class="col col-12 col-md-2"> Zoom: </label>
      <div class="col col-12 col-md-10 mb-1">
        <input
          id="eventZoom"
          type="number"
          step="1"
          min="1"
          max="21"
          class="form-control form-control-sm"
          v-model.number="zoom"
          @input="update()"
        />
      </div>
    </template>
  </div>
</template>
<script>
import { ModelListSelect } from "vue-search-select";
export default {
  props: ["value", "scenario"],
  name: "map-journal-event-form",
  components: {
    "model-list-select": ModelListSelect,
  },
  created() {
    this.onValueChange();
    this.updateLayers();
  },
  vatch: {
    value() {
      this.onValueChange();
    },
  },
  methods: {
    onValueChange() {
      this.type = this.value.type;
      this.layer = this.value.params.layer || "";
      this.widget = this.value.params.widget || "";
      this.x = this.value.params.x || 0;
      this.y = this.value.params.y || 0;
      this.zoom = this.value.params.zoom || 5;
    },
    update() {
      const { type, layer, x, y, zoom, widget } = this;
      switch (type) {
        case "enable-layer":
          console.log("gramap")
        case "disable-layer":
          this.$emit("input", { type, params: { layer } });
          break;
        case "disable-widgets":
          this.$emit("input", { type, params: {} });
          break;
        case "enable-widget":
          this.$emit("input", { type, params: { widget } });
          break;
        case "zoom-to":
          this.$emit("input", { type, params: { x, y, zoom } });
          break;
        case "pan-to":
          this.$emit("input", { type, params: { x, y } });
          break;
      }
    },
    updateLayers() {
      this.layers = this.getLayers();
    },
    getLayers(type, geomType, results, parentPath, parent) {
      if (typeof results === "undefined")
        return this.getLayers(type, geomType, [], "/");
      var layers = null;
      if (typeof parent === "undefined") {
        layers = this.scenario.content.layers;
      } else {
        layers = parent.children;
      }
      layers.forEach((l) => {
        const pPath = parentPath + (parentPath.endsWith("/") ? "" : "/") + l.id;
        if (!type || l.type.indexOf(type) >= 0) {
          if (
            !geomType ||
            (l.sources && l.sources[0].geomType.indexOf(geomType) >= 0)
          ) {
            results.push({ id: l.id, path: pPath, geomType, access: l.access });
          }
        }
        if (l.type === "group") {
          this.getLayers(type, geomType, results, pPath, l);
        }
      });
      return results;
    },
  },
  data() {
    return {
      types: [
        {
          value: "enable-layer",
          label: "Enable Layer",
        },
        {
          value: "disable-layer",
          label: "Disable Layer",
        },
        {
          value: "zoom-to",
          label: "Zoom To",
        },
        {
          value: "pan-to",
          label: "Pan To",
        },
        {
          value: "enable-widget",
          label: "Enable Widget",
        },
        {
          value: "disable-widget",
          label: "Disable Widget",
        },
        {
          value: "disable-widgets",
          label: "Disable All Widgets",
        },
      ],
      widgets: [
        { value: "layer-list-widget", label: "Layer List" },
        { value: "legend-widget", label: "Legend" },
      ],
      type: "enable-layer",
      layer: "",
      widget: "",
      x: 0,
      y: 0,
      zoom: 5,
      layers: [],
    };
  },
};
</script>