<template>
  <div>
    <help-popup :class="{'isAbsolute': true,}" :name="'CMS'"></help-popup>
    <div class="cms-edit d-flex p-2 flex-column">
      <template v-if="!scenario.cms">
        <div class="noCms">
          <button class="btn btn-outline-primary my-1" @click.prevent="addCms">
            Add Cms
          </button>
        </div>
      </template>
      <template v-else>
        <div class="d-flex flex-grow-1">
          <button class="btn btn-secondary my-1" @click.prevent="addFile">
            <span class="icon icon-plus2"></span>
            Add File
          </button>
          <button class="btn btn-secondary my-1 ml-2" @click.prevent="addDir">
            <span class="icon icon-plus2"></span>
            Add Dir
          </button>
          <button class="btn btn-outline-secondary my-1 ml-2" @click.prevent="moveDown" :disabled="disabledDownBtn">
            <span class="icon icon-arrow-down2" />
          </button>
          <button class="btn btn-outline-secondary my-1 ml-2" @click.prevent="moveUp" :disabled="disabledUpBtn">
            <span class="icon icon-arrow-up2" />
          </button>
          <button v-if="editingItem && editingItem.status !== 'deleted'" class="btn btn-outline-danger my-1 ml-2"
            @click.prevent="removeItem" :disabled="disabledRemoveBtn">
            <span class="icon icon-bin2" />
          </button>
          <button v-if="editingItem && editingItem.status === 'deleted'" class="btn btn-outline-success my-1 ml-2"
            @click.prevent="restoreItem">
            <span class="icon icon-reload" />
          </button>

          <span class="title flex-grow-1"></span>
          <button class="btn btn-outline-danger my-1" @click.prevent="removeCms">
            Remove Cms
          </button>
        </div>
        <template v-if="scenario.cms.filesTree">
          <cms-files-tree-edit ref="filesTree" v-model="scenario.cms.filesTree" :scenario="scenario"
            v-on:updateCmsEdit="updateCmsEdit" />
        </template>
      </template>
      <confirm-dialog ref="confirmRemoveCms"
        :body="'Are you sure do you want to remove CMS configuration? All files will be lost!'"
        confirmBtnClasses="btn-danger" @confirm="confirmRemoveCms" @cancel="$refs.confirmRemoveCms.close()">
      </confirm-dialog>
      <confirm-dialog ref="confirmRemoveItem"
        :body="'Are you sure do you want to remove this item?\n All files/dirs below will be deleted after save scenario!'"
        confirmBtnClasses="btn-danger" @confirm="confirmRemoveItem" @cancel="$refs.confirmRemoveItem.close()">
      </confirm-dialog>
      <loading :loading="loading" />
    </div>
  </div>

</template>
<script>
import CmsFilesTreeEdit from "./CmsFilesTreeEdit.vue";
import HelpPopup from "../../../../HelpPopup.vue";
export default {
  props: ["scenario"],
  name: "cms-edit",
  components: {
    "cms-files-tree-edit": CmsFilesTreeEdit,
    "help-popup": HelpPopup
  },
  data() {
    return {
      editingItem: undefined,
      loading: false,
    };
  },
  computed: {
    disabledRemoveBtn() {
      if (this.editingItem === undefined) return true;
      if (this.editingItem.status === "deleted") return true;
      return false;
    },
    disabledDownBtn() {
      if (this.editingItem === undefined) return true;
      if (
        this.scenario.cms.filesTree.indexOf(this.editingItem) ===
        this.scenario.cms.filesTree.length - 1
      ) {
        return true;
      }

      const curridx = this.scenario.cms.filesTree.indexOf(this.editingItem);
      if (this.findNextItemDepth(curridx, this.editingItem.depth) === -1) {
        return true;
      }

      return false;
    },
    disabledUpBtn() {
      if (this.editingItem === undefined) return true;
      if (this.scenario.cms.filesTree.indexOf(this.editingItem) === 0) {
        return true;
      }
      const curridx = this.scenario.cms.filesTree.indexOf(this.editingItem);

      if (this.findPrevItemDepth(curridx, this.editingItem.depth) === -1) {
        return true;
      }
      return false;
    },
  },
  watch: {
    value() {
      if (this.value.length > 0 && this.editingItem === undefined)
        this.editingItem = this.value[0];
    },
  },
  created() { },
  methods: {
    updateCmsEdit(itemId, parentEdit = false) {
      var item = this.findItem(itemId);
      this.editingItem = item;
      if (parentEdit) {
        this.reorderFilesTree();
      }
    },

    addCms() {
      this.scenario.cms = this.defaultCms();
      this.$emit("addCms");
      this.$forceUpdate();
    },
    removeCms() {
      this.$refs.confirmRemoveCms.open();
    },
    confirmRemoveCms() {
      this.scenario.cms = undefined;
      this.$emit("removeCms");
      this.$refs.confirmRemoveCms.close();
      this.$forceUpdate();
    },
    addFile() {
      this.$refs.filesTree.addFile();
    },
    addDir() {
      this.$refs.filesTree.addDir();
    },
    removeItem() {
      this.$refs.confirmRemoveItem.open();
    },
    confirmRemoveItem() {
      this.$refs.filesTree.removeItem(this.editingItem);
      this.$refs.confirmRemoveItem.close();
    },
    restoreItem() {
      this.$refs.filesTree.restoreItem(this.editingItem);
    },
    defaultCms() {
      const cms = { filesTree: [] };
      return cms;
    },
    moveUp() {
      var idx = this.scenario.cms.filesTree.indexOf(this.editingItem);
      const prevIdx = this.findPrevItemDepth(idx, this.editingItem.depth);
      this.moveItem(idx, prevIdx);
    },
    moveDown() {
      var idx = this.scenario.cms.filesTree.indexOf(this.editingItem);
      const nextIdx = this.findNextItemDepth(idx, this.editingItem.depth);
      this.moveItem(idx, nextIdx);
    },
    findItem(itemId) {
      return this.scenario.cms.filesTree.find(({ id }) => id === itemId);
    },
    findNextItemDepth(idx, depth) {
      for (var i = idx + 1; i < this.scenario.cms.filesTree.length; i++) {
        if (this.scenario.cms.filesTree[i].depth === depth) return i;
      }
      return -1;
    },
    findPrevItemDepth(idx, depth) {
      for (var i = idx - 1; i >= 0; i--) {
        if (this.scenario.cms.filesTree[i].depth === depth) return i;
      }
      return -1;
    },
    moveItem(from, to) {
      var f = this.scenario.cms.filesTree.splice(from, 1)[0];
      this.scenario.cms.filesTree.splice(to, 0, f);
      this.reorderFilesTree();
    },
    listToTree(data) {
      var ID_KEY = "id";
      var PARENT_KEY = "parent";
      var CHILDREN_KEY = "children";

      var tree = [];
      var childrenOf = {};
      var item, id, parentId;

      for (var i = 0, length = data.length; i < length; i++) {
        item = data[i];
        if (item === undefined) continue;
        id = item[ID_KEY];
        parentId = item[PARENT_KEY] || 0;
        const parentChk = this.findItem(parentId);
        //if parent not exist reset depth
        if (parentId != 0 && parentChk === undefined) {
          parentId = 0;
          item[PARENT_KEY] = "";
          item[depth] = 0;
        }
        childrenOf[id] = childrenOf[id] || [];
        item[CHILDREN_KEY] = childrenOf[id];
        if (parentId != 0) {
          childrenOf[parentId] = childrenOf[parentId] || [];
          childrenOf[parentId].push(item);
        } else {
          tree.push(item);
        }
      }

      return tree;
    },
    treeToList(data) {
      var CHILDREN_KEY = "children";
      let outlist = [];
      let bfs = function (tree, list) {
        for (var j = 0; j < tree.length; j++) {
          var item = tree[j];
          let childList = [];
          if (item[CHILDREN_KEY] && item[CHILDREN_KEY].length > 0)
            childList = [...item[CHILDREN_KEY]];
          if (item[CHILDREN_KEY]) delete item[CHILDREN_KEY];
          list.push(item);
          if (childList.length > 0) {
            bfs(childList, list);
          }
        }
        return;
      };
      bfs(data, outlist);
      return outlist;
    },
    reorderFilesTree() {
      const tree = this.listToTree(this.scenario.cms.filesTree);
      this.scenario.cms.filesTree = this.treeToList(tree);
    },
  },
};
</script>