<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light p-1 navbar__custom">
    <router-link class="navbar-brand p-0" to="/">
      <span class="logo"></span>
      <span></span>
    </router-link>
    <button class="navbar-toggler ml-auto" data-toggle="collapse" data-target="#top-menu">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="top-menu">
      <ul class="navbar-nav">
        <li class="nav-item" v-if="loggedIn">
          <router-link class="nav-link" to="/" exact>
            <span class="icon icon-home"></span>
            <span class="label">Home</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="isAdministrator">
          <router-link class="nav-link" to="/administration">
            <span class="icon icon-cog"></span>
            <span class="label">Settings</span>
          </router-link>
        </li>
      </ul>
      <div class="navbar__customUserWrap">
        <form action class="form-inline">
          <span v-if="!loggedIn">
            <a href="#/login" class="btn btn-primary">Login</a>
          </span>
          <span v-if="loggedIn">
            <div class="navbar__customUserAvatar">
              <span class="icon icon-user"></span>
              <span>{{ user && user.username }}</span>
            </div>
            <div class="navbar__customUserAction">
              <a href="#/logout" class="icon icon-cross">Logout</a>
            </div>
          </span>
          <div v-if="isAdministrator">
            <a class="btn btn-sm btn-secondary custom-manual m-1" href="./manual/Wisdom-AdminManual.pdf" target="_blank">
              <span class="m-2" title="Open Admin Setting Manual">?</span>
            </a>
          </div>
        </form>
      </div>
    </div>
  </nav>
</template>
<script>
import eventBus from "./eventBus";
import api from "./api";
import credentialsManager from "./CredentialsManager";
export default {
  created() {
    this.$http
      .get(api + "/rest/users/self")
      .then((res) => {
        this.user = res.body;
      })
      .catch((res) => {
        if (res.status === 401 || res.status === 403) {
          this.$router.push(
            "/login?redirect_uri=" + encodeURIComponent(this.$route.path)
          );
        }
      });
    eventBus.$on("login", (token) => {
      this.user = token.user;
    });
    eventBus.$on("logout", () => {
      this.user = null;
      var redirect =
        (this.$route.query.redirect_uri &&
          decodeURIComponent(this.$route.query.redirect_uri)) ||
        this.$route.path;
      if (redirect.indexOf("/login") === 0 || redirect.indexOf("/logout") === 0)
        redirect = "/";
      this.$router.push("/login?redirect_uri=" + encodeURIComponent(redirect));
    });
  },
  data() {
    return { user: null };
  },
  computed: {
    loggedIn() {
      return this.user ? true : false;
    },
    isAdministrator() {
      return (
        this.user &&
        this.user.groups.filter((g) => g.name == "administrators").length == 1
      );
    },
  },
};
</script>