var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('help-popup',{class:{'isAbsolute': true,},attrs:{"name":'Dices'}}),_vm._v(" "),(_vm.scenario && _vm.scenario.content)?_c('div',{staticClass:"dices-edit w-100 p-2"},[(_vm.scenario.content.dicesWidget)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"h2 flex-grow-1"}),_vm._v(" "),(_vm.scenario.content.dicesWidget)?_c('button',{staticClass:"btn btn-outline-danger my-1",on:{"click":function($event){$event.preventDefault();return _vm.$refs.confirmRemoveDices.open()}}},[_vm._v("\n        Remove Dices Section\n      ")]):_vm._e()]):_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn btn-outline-primary my-1",on:{"click":function($event){$event.preventDefault();return _vm.createDices()}}},[_vm._v("\n        Add Dices Widget\n      ")])]),_vm._v(" "),(_vm.scenario.content.dicesWidget)?_c('div',[(_vm.scenario.content.sides.length > 0)?_c('table',{staticClass:"table table-striped new-scenario-sides"},[_vm._m(0),_vm._v(" "),_c('tbody',_vm._l((_vm.scenario.content.sides),function(side,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(side.name))]),_vm._v(" "),_c('td',[(side.color && side.color !== '')?_c('div',[_c('span',{staticClass:"preview-color-picker",style:({ background: side.color })}),_vm._v(" "),_c('span',[_vm._v(_vm._s(side.color))])]):_c('div',[_c('span',{staticClass:"preview-color-picker",style:({
                  background: _vm.getColorByAffiliation(side.affiliation),
                })})])]),_vm._v(" "),_c('td',[_c('label',{staticClass:"custom-control custom-checkbox d-block"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox"},domProps:{"checked":!!_vm.scenario.content.dicesWidget.permissions[
                    _vm.getIdByName(side.name)
                  ]['view']},on:{"change":function($event){_vm.changedDicesView(_vm.getIdByName(side.name))}}}),_vm._v(" "),_c('span',{staticClass:"custom-control-label"})])]),_vm._v(" "),_c('td',[_c('label',{staticClass:"custom-control custom-checkbox d-block"},[_c('input',{staticClass:"custom-control-input",attrs:{"disabled":side.controller,"type":"checkbox"},domProps:{"checked":!!_vm.scenario.content.dicesWidget.permissions[
                    _vm.getIdByName(side.name)
                  ]['roll']},on:{"change":function($event){_vm.changedDicesRoll(_vm.getIdByName(side.name))}}}),_vm._v(" "),_c('span',{staticClass:"custom-control-label"})])])])}),0)]):_c('table',[_vm._v("\n        No Side Defined\n      ")])]):_vm._e(),_vm._v(" "),_c('confirm-dialog',{ref:"confirmRemoveDices",attrs:{"body":'Are you sure do you want to delete dices section? All your dices settings will be lost!',"confirmBtnClasses":"btn-danger"},on:{"confirm":_vm.confirmRemoveDices,"cancel":function($event){return _vm.$refs.confirmRemoveDices.close()}}})],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_vm._v(" "),_c('th',[_vm._v("Color")]),_vm._v(" "),_c('th',[_vm._v("View Dices")]),_vm._v(" "),_c('th',[_vm._v("Roll Dices")])])])
}]

export { render, staticRenderFns }