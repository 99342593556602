<template>
  <div class="col col-12 mt-1">
    <div v-if="popup">
      <label class="col-4 col-md-3 justify-content-end p-0">Popup Template</label>
      <select class="
          col col-sm-12 col-md-6 col-lg-3
          form-control form-control-sm
          mb-1
          d-inline
          popup-type-select
        " @input="onTypeChange" v-model="popup.type">
        <option value="default">Classic</option>
        <option value="templated">Templated</option>
      </select>
    </div>
    <div v-if="popup.type == 'templated'">
      <label class="col-4 col-md-3 justify-content-end p-0">Template type</label>
      <button :class="{ active: isClassicTemplate }" class="btn btn-outline-primary" @click="getClassicTemplate">
        Classic template
      </button>
      <button class="btn btn-outline-primary" :class="{ active: isTabbedTemplate }" @click="getTabbedTemplate">
        Tabbed template
      </button>
      <!-- IP00 inizio GARC DSOL -->
      <button class="btn btn-outline-primary" :class="{ active: isRefTemplate }" @click="getRefTemplate">
        Reference template
      </button>
      <!-- IP00 FINE GARC DSOL -->
    </div>
    <div class="editor mt-2" v-if="popup.type == 'templated'">
      <div class="
          col-xs-12 col-sm-6 col-md-7 col-lg-7
          d-inline-block
          align-top
          pl-0
        ">
        <span>Editor HTML</span>
        <div class="input-group-append d-inline-block m-1">
          <button @click.stop type="button" class="input-group-text info info-popup" data-placement="right"
            data-toggle="popover" data-custom-class="popover-xl" data-html="true" :title="popupHelpTitle"
            :data-content="popupHelpContent">
            ?
          </button>
        </div>
        <app-code v-model="popup.template" class="w-100" @input="onTemplateChange"></app-code>
      </div>
      <div class="
          col-xs-12 col-sm-6 col-md-5 col-lg-5
          d-inline-block
          align-top
          pr-0
          popup-preview
        ">
        <span>Preview popup</span>
        <div class="pt-2" v-html="renderedTemplate()"></div>
      </div>
    </div>
  </div>
</template>
<script>
import eventBus from "../../../eventBus";
import api from "../../../api";
import AppEditorVue from "../../../../../xserver-client3d/node_modules/xserver-common/components/AppEditor.vue";
import CodeEditorVue from "../../../../../xserver-client3d/node_modules/xserver-common/components/CodeEditor.vue";
import Mustache from "mustache";
import MS from "milsymbol";
import MustacheFunctions from "xserver-common/dist/components/MustacheFunctions";
import { ModelListSelect } from "vue-search-select";
import HelpContent from "../../../../../xserver-common/components/HelpContent";
export default {
  name: "units-popup-type",
  props: ["popup", "markupPopupUnit", "units", "markupPopupUnitWithTab","markupPopupUnitReference"], // IP00 modifica GARC DSOL
  components: {
    "app-editor": AppEditorVue,
    "app-code": CodeEditorVue,
    "model-list-select": ModelListSelect,
  },
  created() {
    eventBus.$on("close-dropdown-menus", () => {
      this.closePopoovers();
    });
    if (this.popup.templateType) {
      if (this.popup.templateType == "classic") {
        this.isTabbedTemplate = false;
        this.isClassicTemplate = true;
        this.isRefTemplate=false; // IP00 aggiunto GARC DSOL
      } else if (this.popup.templateType == "tabbed") {        // IP00 MODIFICATO GARC DSOL
        this.isTabbedTemplate = true;
        this.isClassicTemplate = false;
        this.isRefTemplate=false; // IP00 aggiunto GARC DSOL
  //  IP00 inizio GARC DSOL
      } else{
        this.isTabbedTemplate = false;
        this.isClassicTemplate = false;
        this.isRefTemplate=true; 
      }
      //  IP00 FINE GARC DSOL
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.popover();
  },
  methods: {
    getClassicTemplate() {
      this.popup.template = this.markupPopupUnit;
      this.isTabbedTemplate = false;
      this.isClassicTemplate = true;
      this.isRefTemplate=false; // IP00 aggiunto GARC DSOL
      this.popup.templateType = "classic";
    },
    getTabbedTemplate() {
      this.popup.template = this.markupPopupUnitWithTab;
      this.isClassicTemplate = false;
      this.isTabbedTemplate = true;
      this.isRefTemplate=false; // IP00 aggiunto GARC DSOL
      this.popup.templateType = "tabbed";
    },
    // IP00 inizio GARC DSOL
    getRefTemplate (){
      this.popup.template = this.markupPopupUnitReference;
      this.isClassicTemplate = false;
      this.isTabbedTemplate = false;
      this.isRefTemplate=true;
      this.popup.templateType = "reference";
    },
    //  IP00 fine GARC DSOL
    closePopoovers() {
      $(".info.info-popup").popover("hide");
    },
    popover() {
      $(".info.info-popup").popover({
        template: `<div class="popover popover-info popover-xl" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`,
      });
    },
    onTemplateChange(value) {
      this.$forceUpdate();
    },
    onTypeChange(value) {
      if ((this.popup.type = "default")) {
        this.popup.template = this.markupPopupUnit;
      } else {
        this.popup.template = this.markupPopupUnit;
      }
      this.$forceUpdate();
    },
    renderedTemplate() {
      return Mustache.render(this.popup.template, {
        unit: this.mockupUnit,
        ...MustacheFunctions,
      });
    },

    updateSymbolPreview(symbol_id) {
      const symbol = new MS.Symbol(symbol_id, {
        size: 60,
      });

      return symbol.asSVG();
    },
  },
  data() {
    return {
      mockupUnit: {
        name: "UAV",
        unit_id: 1,
        parent_id: undefined,
        category: "ground",
        symbol: "SFG---------",
        strength: 100,
        lon: 13.5,
        lat: 10.5,
        elev: 0,
        capabilities: "Advanced movement",
        symbol_preview: this.updateSymbolPreview("SFG---------"),
      },
      popupHelpTitle: ` <h3>Editor html</h3>
                        <p>In the editor you can interpolate data dynamically using double braces e.g. <strong>{{unit.name}}</strong><br>
                          In addition you can call functions such as toUppercase e.g <strong>{{#toUpperCase}}</strong>text or {{unit.name}}<strong>{{/toUpperCase}}</strong><br>
                          Following is the list of available commands and functions:                     
                          </p>`,
      popupHelpContent: HelpContent.getHelpContentAdminByName(
        "scenario.units.editorHtml"
      ),
      showCustomEditor: false,
      isClassicTemplate: false,
      isTabbedTemplate: false,
      isRefTemplate: false, // IP00 aggiunto GARC DSOL
    };
  },
};
</script>
<style>
.code_area {
  min-height: 133px;
}

.mark>div {
  color: orange !important;
  font-weight: bold;
}

.mark {
  background-color: #282c34 !important;
}

.editor>div>span {
  font-size: 1rem;
  font-weight: bold;
}

.editor .code-editor {
  padding-top: 10px;
}

.popup-preview {
  font-size: 1rem;
}

.editor {
  font-size: 0;
}

.popover-info {
  max-width: 900px;
  widows: 100%;
  max-height: 450px;
  overflow: auto;
}

.info-popup {
  padding: 0px 8px;
}

.popover-info .table thead tr th {
  width: 50%;
}
</style>