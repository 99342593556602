<template>
  <div
    class="row align-items-center"
    v-if="scenario && scenario.content && scenario.content.units"
  >
    <!-- <div class="col col-12">
      <h2>Luna Wargaming</h2>
    </div> -->
    <div class="col col-12">
      <label class="custom-control custom-checkbox justify-content-start">
        <input
          type="checkbox"
          class="custom-control-input"
          v-model="scenario.content.units.spaceLayer"
        />
        <span class="custom-control-label">Space Layer</span>
      </label>
    </div>
    <div class="col col-sm-12 col-md-6 col-lg-3">
      <label class="justify-content-start" for="layerNoFlyZones"
        >No Fly Zones:</label
      >
    </div>
    <div class="col col-sm-12 col-md-6 col-lg-3" style="padding-left: 12px">
      <model-list-select
        ref="layerNoFlyZones"
        class="form-control form-control-sm"
        :list="
          getLayers('vector', 'POLYGON').filter((l) => l.access != 'direct')
        "
        v-model="scenario.content.units.layerNoFlyZones"
        required
        option-value="id"
        option-text="path"
        placeholder="None"
        @input="$forceUpdate()"
      />
    </div>
    <units-icons-type
      v-if="
        scenario &&
        scenario.content &&
        scenario.content.units &&
        scenario.content.units.icons
      "
      ref="type"
      :units="scenario.content.units"
    ></units-icons-type>
    <units-popup-type
      v-if="
        scenario &&
        scenario.content &&
        scenario.content.units &&
        scenario.content.units.popup
      "
      :popup="scenario.content.units.popup"
      :markupPopupUnit="markupPopupUnit"
      :markupPopupUnitWithTab="markupPopupUnitWithTab"
      :markupPopupUnitReference="markupPopupUnitReference"
      :units="scenario.content.units"
    ></units-popup-type><!-- IP00 aggiunto GARC DSOL -->
    <div class="col col-12 col-lg-6"></div>
    <units-source
      class="col col-12 mb-1"
      v-for="(source, i) in scenario.content.units.sources"
      :key="i"
      :title="scenario.content.vignettes[i]"
      v-model="source.records"
      :scenario="scenario"
      :datastores="datastores"
      :locations="false"
      @input="$refs.type.updateReadOnly()"
    />
    <loading :loading="loadingTables" />
  </div>
</template>
<script>
import { ModelListSelect } from "vue-search-select";
import eventBus from "../../../eventBus";
import UnitsSourceVue from "./UnitsSource.vue";
import api from "../../../api";
import UnitsPopupTypeVue from "./UnitsPopupType.vue";
import UnitsIconsTypeVue from "./UnitsIconsType.vue";
export default {
  name: "units-section-luna",
  props: [
    "scenario",
    "datastores",
    "markupPopupUnit",
    "markupPopupUnitWithTab",
    "markupPopupUnitReference", // IP00 aggiunto GARC DSOL
  ],
  components: {
    "units-source": UnitsSourceVue,
    "model-list-select": ModelListSelect,
    "units-popup-type": UnitsPopupTypeVue,
    "units-icons-type": UnitsIconsTypeVue,
  },
  created() {
    eventBus.$on("scenario-vignettes-changed", this.initialize.bind(this));
    this.initialize();
  },
  watch: {
    scenario() {
      // when scenario data load ensure units default value and load datastores tables
      this.initialize();
    },
    value() {
      this.onValueChange();
    },
  },
  computed: {
    upWargame() {
      return (
        this.scenario &&
        this.scenario.content.units &&
        (!this.scenario.content.units.type ||
          this.scenario.content.units.type === "up")
      );
    },
    lunaWargame() {
      return (
        this.scenario &&
        this.scenario.content.units &&
        this.scenario.content.units.type === "luna"
      );
    },
  },
  methods: {
    initialize() {
      if (!this.scenario.content.units) return; // nothing to do
      // adjust sources number according to vignettes
      if (Array.isArray(this.scenario.content.vignettes)) {
        // add sources if missing
        while (
          this.scenario.content.units.sources.length <
          this.scenario.content.vignettes.length
        ) {
          this.scenario.content.units.sources.push({
            type: "raw",
            records: [],
          });
        }
        // remove sources if there are to many
        while (
          this.scenario.content.units.sources >
          this.scenario.content.vignettes.length
        ) {
          this.scenario.content.units.sources.pop();
        }
        // source cleanup, only type raw with records are allowed
        for (let source of this.scenario.content.units.sources) {
          if (typeof source.records === "undefined") source.records = [];
          source.type = "raw";
          for (let key in source) {
            if (key !== "type" && key !== "records") delete source[key];
          }
        }
      }
      this.$forceUpdate();
    },
    addUnit(source) {
      if (!source.records) return;
      source.records.push({
        unit_id: ++this.idSeq,
        name: "",
        owner: this.scenario.content.sides[0].name,
        parent_id: null,
        parent_name: "",
        symbol_id: "",
        radius: 0,
        strength: 100,
        capabilities: "",
        long: null,
        lat: null,
        direction: null,
      });
    },
    getLayers(type, geomType, results, parentPath, parent) {
      if (typeof results === "undefined")
        return this.getLayers(type, geomType, [], "/");
      var layers = null;
      if (typeof parent === "undefined") {
        layers = this.scenario.content.layers;
      } else {
        layers = parent.children;
      }
      layers.forEach((l) => {
        const pPath = parentPath + (parentPath.endsWith("/") ? "" : "/") + l.id;
        if (!type || l.type.indexOf(type) >= 0) {
          if (
            !geomType ||
            (l.sources && l.sources[0].geomType.indexOf(geomType) >= 0)
          ) {
            results.push({ id: l.id, path: pPath, geomType, access: l.access });
          }
        }
        if (l.type === "group") {
          this.getLayers(type, geomType, results, pPath, l);
        }
      });
      return results;
    },
  },
  data() {
    return {
      tables: {},
      loadingTables: false,
      idSeq: 0,
      source: {},
    };
  },
};
</script>
