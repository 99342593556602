import proj from "proj4";
proj.defs(
  "EPSG:32633",
  "+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs "
);
export const proj4 = proj;
export function getAffiliationByName(name) {
  switch (name.toLowerCase(name)) {
    case "blue":
      return "F";
    case "red":
      return "H";
    case "green":
      return "N";
    default:
      return "U";
  }
}
export function getIdByName(name) {
  if (!name) return "";
  return name.toLowerCase().replace(/[\s\W]+/g, "_");
}
export function getLayersMap(layers, result) {
  if (typeof result === "undefined") {
    return getLayersMap(layers, {});
  }
  for (const l of layers) {
    let id = getIdByName(l.title);
    const count = 1;
    while (result[id]) {
      id = getIdByName(l.title) + "_" + count;
      count++;
    }
    result[id] = l;
    if (l.type === "group" && l.children) getLayersMap(l.children, result);
  }
  return result;
}

export const LunaWargamePhase = {
  POSITIONING: "positioning",
  PLAYING: "playing",
  BLUE_WIN: "blue_win",
  RED_WIN: "red_win",
  END: "end",
};
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}
export function checkTLE(tle){
  const lines = tle.split(/[\n\r]+/gi);
  if(lines.length < 2) return false;
  const line1 = lines[lines.length-2].trim();
  const line2 = lines[lines.length-1].trim();
  if(line1.length < 69 || line2.length < 69) return false;
  if(!line1.startsWith("1 ")) return false;
  if(!line2.startsWith("2 ")) return false;
  return [line1, line2];
}