<template>
  <span class="overlay align-self-center d-flex" v-if="loading">
    <div class="overlay-background bg-white opacity-50"></div>
    <div class="spinner-border text-primary m-auto" role="status">
      <span class="sr-only">
        <slot>Loading...</slot>
      </span>
    </div>
    <span class="text-default m-auto message">
      <slot>Loading...</slot>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    loading: {
      default: false
    }
  }
};
</script>
