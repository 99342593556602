<template>
  <div class="modal fade" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row" v-if="step === 1">
            <ul class="nav nav-tabs mb-1 col-12">
              <li class="nav-item">
                <a
                  href
                  class="nav-link"
                  :class="{ active: currentTab === 'datastore' }"
                  @click.prevent.stop="currentTab = 'datastore'"
                  >Datastore</a
                >
              </li>
              <li class="nav-item">
                <a
                  href
                  class="nav-link"
                  :class="{ active: currentTab === 'instance' }"
                  @click.prevent.stop="currentTab = 'instance'"
                  >Wargame</a
                >
              </li>
              <li class="nav-item">
                <a
                  href
                  class="nav-link"
                  :class="{ active: currentTab === 'xlsx' }"
                  @click.prevent.stop="currentTab = 'xlsx'"
                  >Excel(.xlsx)</a
                >
              </li>
            </ul>
            <!-- datastore -->
            <template v-if="currentTab === 'datastore'">
              <label for="ds" class="col col-12 col-md-2">Datastore:</label>
              <model-list-select
                ref="datastores"
                class="form-control form-control-sm col-12 col-md-10"
                :list="datastores"
                v-model="datastore"
                option-value="id"
                option-text="name"
                placeholder="datastore..."
              />
              <label for="ds" class="col col-12 col-md-2">Table:</label>
              <model-list-select
                ref="tables"
                class="form-control form-control-sm col-12 col-md-10"
                :list="tables"
                v-model="table"
                option-value="fullName"
                option-text="fullName"
                placeholder="table..."
              />
            </template>
            <!-- instance -->
            <template v-if="currentTab === 'instance'">
              <label for="instanceField" class="col col-12 col-md-2">
                Instance:
              </label>
              <model-list-select
                ref="instances"
                class="form-control form-control-sm col-12 col-md-10"
                :list="instances"
                v-model="instance"
                option-value="id"
                option-text="name"
                placeholder="wargame instance..."
              />
              <label for="vignetteField" class="col col-12 col-md-2">
                Vignette:
              </label>
              <model-list-select
                ref="vignettes"
                class="form-control form-control-sm col-12 col-md-10"
                :list="vignettes"
                v-model="vignette"
                option-value="id"
                option-text="name"
                placeholder="vignette..."
              />
              <label for="turnField" class="col col-12 col-md-2"> Turn: </label>
              <model-list-select
                ref="turns"
                class="form-control form-control-sm col-12 col-md-10"
                :list="turns"
                v-model="turn"
                option-value="id"
                option-text="name"
                placeholder="turn..."
              />
            </template>
            <!-- xlsx -->
            <template v-if="currentTab === 'xlsx'">
              <label for="xlsxField" class="col col-12 col-md-2"> File: </label>
              <div
                class="
                  my
                  custom-file
                  form-control form-control-sm
                  col-12 col-md-10
                  mb-1
                "
              >
                <input
                  type="file"
                  name="xlsx"
                  id="xlsxField"
                  ref="xlsxField"
                  @change="xlsxFileChange"
                  class="custom-file-input"
                  placeholder="Select a File..."
                />
                <label for="xlsxField" class="custom-file-label">
                  {{ xlsxFile ? xlsxFile.name : "Choose a file..." }}
                </label>
              </div>
              <label for="sheetField" class="col col-12 col-md-2">
                Sheet:
              </label>
              <model-list-select
                class="form-control form-control-sm col-12 col-md-10"
                :list="xlsxSheets"
                v-model="xlsxSheet"
                option-value="id"
                option-text="name"
                placeholder="sheet..."
              />
            </template>
          </div>
          <div v-if="step === 2">
            <div>
              <span>{{ records.length }}</span>
              <span>Records loaded</span>
            </div>
            <h3>Sides Mapping</h3>
            <div v-for="side in recordsSides" :key="side.id" class="row">
              <span class="col-3">{{ side.name }}:</span>
              <model-list-select
                class="form-control form-control-sm col-9"
                :list="scenarioSides"
                v-model="sidesMapping[side.id]"
                option-value="id"
                option-text="name"
                placeholder="side..."
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-sm btn-secondary"
            v-if="step === 1"
            @click="close"
          >
            Cancel
          </button>
          <button
            class="btn btn-sm btn-secondary"
            v-if="step > 1"
            @click="back"
          >
            Back
          </button>
          <button
            class="btn btn-sm btn-primary"
            :disabled="!validStep1"
            @click="loadData()"
            v-if="step === 1"
          >
            Next
          </button>

          <button
            class="btn btn-sm btn-primary"
            :disabled="!isSidesMappingValid"
            @click="importRecords()"
            v-if="step === 2"
          >
            Import
          </button>
        </div>
      </div>
    </div>
    <loading :loading="loading" />
  </div>
</template>
<script>
import { ModelListSelect } from "vue-search-select";
import { toLonLat } from "ol/proj";
import { getIdByName, proj4 } from "../../../../../xserver-common/utils";
import * as XLSX from "xlsx";
import MS from "milsymbol";
import api from "../../../api";
export default {
  props: ["datastores", "scenario"],
  components: {
    "model-list-select": ModelListSelect,
  },
  mounted() {
    jQuery(this.$el).on("shown.bs.modal", () => {
      this.$emit("shown");
    });
    jQuery(this.$el).on("hidden.bs.modal", () => {
      this.opened = false;
      this.$emit("close");
    });
  },
  computed: {
    isSidesMappingValid() {
      if (Object.keys(this.sidesMapping).length === 0) return false;
      let valid = true;
      //for (let id in this.sidesMapping) {
      //  if (!this.sidesMapping[id]) valid = false;
      //}
      return valid;
    },
    validStep1() {
      if (this.currentTab === "datastore") {
        return this.datastore && this.table ? true : false;
      } else if (this.currentTab === "instance") {
        return this.instance && this.vignette && this.turn ? true : false;
      } else if (this.currentTab === "xlsx") {
        return this.xlsxSheet ? true : false;
      }
    },
    loading() {
      return (
        this.loadingTables ||
        this.loadingRecords ||
        this.loadingInstances ||
        this.loadingVignettes ||
        this.loadingVariables
      );
    },
  },
  watch: {
    datastore() {
      this.tables = [];
      this.table = "";
      if (this.datastore) this.loadDSTables(this.datastore);
    },
    records() {
      const sides = {};
      for (let r of this.records) {
        if (!sides.hasOwnProperty(r.owner)) {
          sides[r.owner] = { id: r.owner, name: r.owner };
        }
      }
      const mapping = {};
      const scenarioSides = [];
      for (let s of this.scenario.content.sides) {
        const id = s.id || getIdByName(s.name);
        if (s.controller) continue; // controller do not have its own units.
        if (!mapping.hasOwnProperty(id)) {
          mapping[id] = sides.hasOwnProperty(id) ? id : "";
        }
        scenarioSides.push({ id, ...s });
      }
      this.recordsSides = Object.keys(sides).map((key) => sides[key]);
      this.sidesMapping = mapping;
      this.scenarioSides = scenarioSides;
    },
    currentTab() {
      if (this.currentTab === "instance") {
        this.instance = "";
        this.vignettes = [];
        this.vignette = "";
        this.turns = [];
        this.turn = "";
        this.loadInstances();
      }
    },
    instance() {
      if (this.instance) {
        this.loadVignettes();
        this.loadVariables();
      }
    },
    vignette() {
      const turns = parseInt(this.variables[this.vignette + "_turn"] || "0");
      const turnsOptions = [];
      for (let i = 0; i <= turns; i++) {
        turnsOptions.push({
          id: "" + i,
          name: i === 0 ? "0 (startex)" : "" + i,
        });
      }
      this.turn = "";
      this.turns = turnsOptions;
    },
  },
  methods: {
    xlsxFileChange(event) {
      console.log("xlsx file changed");
      var reader = new FileReader();
      reader.onload = (e) => {
        this.xlsxFileUpload(e.target.result);
      };
      reader.readAsBinaryString(event.target.files[0]);
      this.xlsxFile = event.target.files[0];
    },
    xlsxFileUpload(data) {
      console.log("xlsx file uploaded");
      this.workbook = XLSX.read(data, {
        type: "binary",
      });
      console.log("Workbook:", this.workbook);
      this.xlsxSheet = "";
      const sheets = [];
      for (let s of this.workbook.SheetNames) {
        sheets.push({ id: s, name: s });
      }
      this.xlsxSheets = sheets;
    },
    importRecords() {
      const result = [];
      for (let record of this.records) {
        if (!this.sidesMapping[record.owner]) continue; // no mapping no fun!
        const unit = {
          unit_id: record.unit_id,
          parent_id: record.parent_id,
          symbol_id: record.symbol_id,
          name: record.name,
          owner: this.sidesMapping[record.owner],
          radius: record.radius || 0,
          max_move: record.max_move || 0,
          visible: !!record.visible,
          referencelink: !!record.referencelink, //IP01 aggiunto GARC DSOL
          referencedlinks: record.referencedlinks || "{}", //IP01 aggiunto GARC DSOL - da verificare
          strength: record.hasOwnProperty("strength") ? record.strength : 100,
          capabilities: record.capabilities || "",
          long: record.long || null,
          lat: record.lat || null,
          elev: record.elev || null,
          special_headquarters: record.special_headquarters || "",  //SH20 AGGIUNTO GARC
        };
        if (typeof unit.long === "string") unit.long = parseFloat(unit.long);
        if (typeof unit.lat === "string") unit.lat = parseFloat(unit.lat);
        if (typeof unit.elev === "string") unit.elev = parseFloat(unit.elev);
        if (typeof unit.radius === "string")
          unit.radius = parseFloat(unit.radius);
        if (typeof unit.strength === "string")
          unit.strength = parseFloat(unit.strength);
        result.push(unit);
      }
      this.$emit("import", result);
      this.close();
    },
    loadDSTables() {
      this.loadingTables = true;
      this.error = false;
      this.$http
        .get(api + "/rest/datastores/" + this.datastore + "/tables")
        .then((res) => {
          this.loadingTables = false;
          this.tables = res.body.map((t) => {
            return { ...t, fullName: t.schema + "." + t.name };
          });
        })
        .catch((res) => {
          this.loadingTables = false;
          this.error = true;
          console.error(
            "Error getting tables for datastore with id " + this.datastore,
            res
          );
        });
    },
    loadInstances() {
      this.loadingInstances = true;
      this.error = false;
      this.instances = [];
      this.$http
        .get(api + "/rest/instances?active")
        .then((res) => {
          this.instances = res.body;
        })
        .catch((err) => {
          this.error = true;
          console.error("Error getting instances", err);
        })
        .finally(() => {
          this.loadingInstances = false;
        });
    },
    loadVignettes() {
      this.loadingVignettes = true;
      this.error = false;
      this.vignette = "";
      this.vignettes = [];
      this.$http
        .get(api + "/rest/instances/" + this.instance + "/vignettes")
        .then((res) => {
          this.vignettes = res.body;
        })
        .catch((err) => {
          this.error = true;
          console.error(
            "Error getting vignettes for instance " + this.instance,
            err
          );
        })
        .finally(() => {
          this.loadingVignettes = false;
        });
    },
    loadVariables() {
      this.loadingVariables = true;
      this.error = false;
      this.variables = {};
      this.turn = "";
      this.turns = [];
      this.$http
        .get(api + "/rest/instances/" + this.instance + "/variables")
        .then((res) => {
          this.variables = res.body;
        })
        .catch((err) => {
          this.error = true;
          console.error(
            "Error getting variables for instance " + this.instance,
            err
          );
        })
        .finally(() => {
          this.loadingVariables = false;
        });
    },
    back() {
      if (this.step > 1) this.step -= 1;
    },
    loadData() {
      if (this.currentTab === "datastore") this.loadDSData();
      else if (this.currentTab === "instance") this.loadInstanceData();
      else if (this.currentTab === "xlsx") this.loadXlsxData();
    },
    loadDSData() {
      this.loadingRecords = true;
      this.error = false;
      this.records = [];
      this.$http
        .get(
          api +
            "/rest/datastores/" +
            this.datastore +
            "/tables/" +
            encodeURIComponent(this.table) +
            "/data"
        )
        .then((res) => {
          this.loadingRecords = false;
          const table = this.tables.filter((t) => t.fullName === this.table)[0];
          this.records = res.body.filter(
            (r) =>
              r.hasOwnProperty("unit_id") &&
              r.hasOwnProperty("parent_id") &&
              r.hasOwnProperty("name") &&
              r.hasOwnProperty("symbol_id") &&
              r.hasOwnProperty("owner")
          );
          let transform = null;
          let geomColumn = (table ? table.geometry_column : null) || "geometry";
          if (table && table.srid && table.srid !== 4326) {
            if (proj4.defs("EPSG:" + table.srid)) {
              transform = proj4("EPSG:" + table.srid, "EPSG:4326");
              console.log(
                "Created transformer from EPSG:" + table.srid + " to EPSG:4326"
              );
            } else {
              console.error(
                "Unsupported Coordinates system EPSG:" + table.srid
              );
            }
          } else {
            console.log("No Coordinates transform Required");
          }
          if (transform) {
            for (let r of this.records) {
              if (
                r[geomColumn] &&
                r[geomColumn].coordinates &&
                Number.isFinite(r[geomColumn].coordinates[0]) &&
                Number.isFinite(r[geomColumn].coordinates[1])
              ) {
                const coordinates = transform.forward(
                  r[geomColumn].coordinates
                );
                if (
                  Number.isFinite(coordinates[0]) &&
                  Number.isFinite(coordinates[1])
                ) {
                  r.long = coordinates[0];
                  r.lat = coordinates[1];
                  r.elev =
                    coordinates[2] && Number.isFinite(coordinates[2])
                      ? coordinates[2]
                      : null;

                  // for new units round the coordinates to 5 decimal places
                  r.long =
                    Math.round((r.long + Number.EPSILON) * 100000) / 100000;
                  r.lat =
                    Math.round((r.lat + Number.EPSILON) * 100000) / 100000;
                }
              }
            }
          }
          this.step = 2;
        })
        .catch((res) => {
          this.loadingRecords = false;
          this.error = true;
          console.error(
            "Error getting records for datastore with id " +
              this.datastore +
              " of table " +
              this.table,
            res
          );
        });
    },
    loadInstanceData() {
      this.loadingRecords = true;
      this.error = false;
      this.records = [];
      this.$http
        .get(
          api +
            "/rest/instances/" +
            this.instance +
            "/units?vignette=" +
            this.vignette +
            "&turn=" +
            this.turn +
            "&previous=false"
        )
        .then((res) => {
          this.loadingRecords = false;
        //  this.records = res.body.features.map((f) => f.properties);
        // inizio GARC
           this.records = res.body.filter(
            (r) =>
              r.hasOwnProperty("unit_id") &&
              r.hasOwnProperty("parent_id") &&
              r.hasOwnProperty("name") &&
              r.hasOwnProperty("symbol_id") &&
              r.hasOwnProperty("owner")
          );
            // fine GARC

          this.step = 2;
        })
        .catch((res) => {
          this.loadingRecords = false;
          this.error = true;
          console.error(
            "Error getting records for instance with id " +
              this.instance +
              " of vignette " +
              this.vignette +
              " turn " +
              this.turn,
            res
          );
        });
    },
    loadXlsxData() {
      const records = [];
      const sheet = this.workbook.Sheets[this.xlsxSheet];
      const excelRows = XLSX.utils.sheet_to_row_object_array(sheet);
      const fieldsMapping = {
        unit_id: "",
        name: "",
        parent_id: "",
        symbol_id: "",
        radius: "",
        strength: "",
        capabilities: "",
        max_move: "",
        visible: "",
        referencelink: "",      //IP01 AGGIUNTO GRAC DSOL
        referencedlinks: "",      //IP01 AGGIUNTO GRAC DSOL
        long: "",
        lat: "",
        elev: "",
        owner: "",
        special_headquarters: "", //SH20 AGGIUNTO GARC
      };
      for (let row of excelRows) {
        for (let key in row) {
          if (key.match(/hq/gi)) {
            fieldsMapping.parent_id = key;
          } else if (key.match(/unit/gi)) {
            if (!fieldsMapping.unit_id) fieldsMapping.unit_id = key;
            fieldsMapping.name = key;
          }
          if (key.match(/name/gi)) {
            fieldsMapping.unit_id = key;
            fieldsMapping.name = key;
          }
          if (key === "unit_id") {
            fieldsMapping.unit_id = key;
          }
          if (key === "parent_id") {
            fieldsMapping.parent_id = key;
          }
          if (key.match(/parent/gi)) {
            if (!fieldsMapping.parent_id) fieldsMapping.parent_id = key;
          }
          if (key.match(/hq/gi)) {
            if (!fieldsMapping.parent_id) fieldsMapping.parent_id = key;
          }
          if (key.match(/capab/gi)) {
            fieldsMapping.capabilities = key;
          }
          if (key.match(/radius/gi)) {
            fieldsMapping.radius = key;
          }
          if (key.match(/isr/gi)) {
            fieldsMapping.radius = key;
          }
          if (key.match(/max/gi)) {
            fieldsMapping.max_move = key;
          }
          if (key.match(/move/gi)) {
            fieldsMapping.max_move = key;
          }
          if (key.match(/visible/gi)) {
            fieldsMapping.visible = key;
          }
          // IP01 INIZIO GARC DSOL
           if (key.match(/referencelink/gi)) {
            fieldsMapping.referencelink = key;
          }
           if (key.match(/referencedlinks/gi)) {
            fieldsMapping.referencedlinks = key;
          }
          //IP01 FINE GRAC DSOL
          if (key.match(/sym/gi)) {
            fieldsMapping.symbol_id = key;
          }
          if (key.match(/long/gi)) {
            fieldsMapping.long = key;
          }
          if (key.match(/lat/gi)) {
            fieldsMapping.lat = key;
          }
          if (key.match(/elev/gi)) {
            fieldsMapping.elev = key;
          }
          if (key.match(/^x$/gi)) {
            fieldsMapping.long = key;
          }
          if (key.match(/^y$/gi)) {
            fieldsMapping.lat = key;
          }
          if (key.match(/^z$/gi)) {
            fieldsMapping.elev = key;
          }
          if (key.match(/owner/gi)) {
            fieldsMapping.owner = key;
          }
          if (key.match(/side/gi)) {
            fieldsMapping.owner = key;
          }
          if (key.match(/fac/gi)) {
            fieldsMapping.owner = key;
          }
          if (key.match(/special_headquarters/gi)) {             //SH20 AGGIUNTO GARC
            fieldsMapping.special_headquarters = key;
          }
        }
        break;
      }
      for (let row of excelRows) {
        const rec = {
          unit_id: row[fieldsMapping.unit_id],
          name: row[fieldsMapping.name],
          parent_id: row[fieldsMapping.parent_id],
          symbol_id: row[fieldsMapping.symbol_id] || "SUG",
          radius: parseFloat(row[fieldsMapping.radius]),
          max_move: parseFloat(row[fieldsMapping.max_move]),
          visible: parseFloat(row[fieldsMapping.visible]) > 0,
          referencelink: parseFloat(row[fieldsMapping.referencelink]) > 0, //IP01 Aggiunto GARC DSOL
        referencedlinks: row[fieldsMapping.referencedlinks] || "{}", //IP01 Aggiunto GARC DSOL - da verificare
          strength: parseFloat(row[fieldsMapping.strength]),
          capabilities: row[fieldsMapping.capabilities] || "",
          long: parseFloat(row[fieldsMapping.long]),
          lat: parseFloat(row[fieldsMapping.lat]),
          elev: parseFloat(row[fieldsMapping.elev]),
          owner: row[fieldsMapping.owner] || "default",
          special_headquarters: row[fieldsMapping.special_headquarters] || "",  //SH20 AGGIUNTO GARC
        };
        if (Number.isNaN(rec.strength)) rec.strength = 100;
        if (Number.isNaN(rec.radius)) rec.radius = 0;
        if (Number.isNaN(rec.max_move)) rec.max_move = 0;
        records.push(rec);
      }
      this.records = records;
      this.step = 2;
    },
    open() {
      jQuery(this.$el).modal("show");
      this.opened = true;
      $(".modal-backdrop").before($(this.$el));
      this.$emit("open");
      this.step = 1;
      this.datastore = "";
      this.table = "";
      this.currentTab = "datastore";
      this.xlsxFile = null;
    },
    close() {
      jQuery(this.$el).modal("hide");
      this.opened = false;
    },
  },
  data() {
    return {
      loadingTables: false,
      loadingRecords: false,
      loadingInstances: false,
      loadingVignettes: false,
      loadingVariables: false,
      error: false,
      step: 1,
      datastore: "",
      table: "",
      instance: "",
      instances: [],
      vignette: "",
      vignettes: [],
      turn: "",
      turns: [],
      variables: {},
      tables: [],
      records: [],
      recordsSides: [],
      scenarioSides: [],
      sidesMapping: {},
      currentTab: "datastore",
      xlsxFile: null,
      xlsxSheet: "",
      xlsxSheets: [],
    };
  },
};
</script>
