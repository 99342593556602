<template>
  <div>
    <help-popup :class="{'isAbsolute': true,}"  :name="'Line of Sight / Viewshed'"></help-popup>
    <div class="row align-items-center">
      <div class="col col-12">
        <label class="custom-control custom-checkbox justify-content-start">
          <input type="checkbox" class="custom-control-input" v-model="scenario.content.lineOfSight.enabled" />
          <span class="custom-control-label">Enabled Line Of Sight</span>
        </label>
        <label class="custom-control custom-checkbox justify-content-start">
          <input type="checkbox" class="custom-control-input" v-model="scenario.content.viewshed.enabled" />
          <span class="custom-control-label">Enabled Viewshed </span>
        </label>
      </div>
      <label class="col-2 col-md-2 justify-content-start">DSM:</label>
      <input type="text" class="form-control form-control-sm col-10 col-md-4 mb-1"
        v-model="scenario.content.lineOfSight.DSM" />
      <label class="col-2 col-md-2 justify-content-start">Srid:</label>
      <input type="number" class="form-control form-control-sm col-10 col-md-4 mb-1"
        v-model.number="scenario.content.lineOfSight.srid" />
      <label class="col-2 col-md-2 justify-content-start">Step:</label>
      <input type="number" min="0" step="any" class="form-control form-control-sm col-10 col-md-4 mb-1"
        v-model.number="scenario.content.lineOfSight.step" />
      <label class="col-2 col-md-2 justify-content-start">Precision:</label>
      <input type="number" min="0" step="1" class="form-control form-control-sm col-10 col-md-4 mb-1"
        v-model.number="scenario.content.lineOfSight.precision" />
    </div>
  </div>
</template>
<script>
import HelpPopup from "../../../HelpPopup.vue";
export default {
  name: "lineofsight-section",
  props: ["scenario"],
  components: {
    "help-popup": HelpPopup
  },
  created() {
    if (!this.scenario.content.lineOfSight) {
      this.scenario.content.lineOfSight = {
        enabled: false,
        DSM: "",
        srid: 4326,
        step: 0.000001,
        precision: 2,
      };
    }
    if (!this.scenario.content.viewshed) {
      this.scenario.content.viewshed = {
        enabled: false,
      };
    }
  },
  watch: {
    scenario(newVal, oldVal) {
      if (!this.scenario || !this.scenario.content) return;
      if (!this.scenario.content.lineOfSight) {
        this.scenario.content.lineOfSight = {
          enabled: false,
          DSM: "",
          srid: 4326,
          step: 0.000001,
          precision: 2,
        };
      }
      if (!this.scenario.content.viewshed) {
        this.scenario.content.viewshed = {
          enabled: false,
        };
      }
    },
  },
  methods: {},
  data() {
    return {};
  },
};
</script>