import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Text from "ol/style/Text";
import { toContext } from "ol/render";
import { Point, Polygon, LineString } from "ol/geom";
function fromObject(obj) {
  if (!obj) return null;
  const type = obj.type || "style";
  if (type === "style") {
    return fromObjectStyle(obj);
  } else if (type === "icon") {
    return fromObjectIcon(obj);
  } else if (type === "circle") {
    return fromObjectCircle(obj);
  } else if (type === "fill") {
    return fromObjectFill(obj);
  } else if (type === "stroke") {
    return fromObjectStroke(obj);
  } else if (type === "text") {
    return fromObjectText(obj);
  } else if (type === "by-field") {
    return byField(obj);
  } else if (type === "simple") {
    return fromObjectStyle(obj.style);
  } else if (type === "none") {
    return null;
  } else {
    console.warn("cannot convert plain obj to style", obj.type, obj);
    return null;
  }
}
function toObject(style) {
  if (!style) return null;
  if (style instanceof Style) {
    return toObjectStyle(style);
  }
}
function toObjectStyle(style) {
  const res = {};
  if (style.getImage()) res.image = toObjectImage(style.getImage());
  if (style.getFill()) res.fill = toObjectFill(style.getFill());
  if (style.getStroke()) res.stroke = toObjectStroke(style.getStroke());
  if (style.getText()) res.text = toObjectText(style.getText());
  return res;
}
function toObjectImage(style) {
  if (!style) return null;
  if (style instanceof Icon) return toObjectIcon(style);
  else if (style instanceof Circle) return toObjectCircle(style);
  else console.warn("Unexpected image style", style);
}
function toObjectIcon(style) {
  if (!style) return null;
  const res = { type: "icon" };
  res.src = style.getSrc();
  res.scale = style.getScale();
  res.rotation = (style.getRotation() * 180) / Math.PI;
  return res;
}
function toObjectCircle(style) {
  if (!style) return null;
  const res = { type: "circle" };
  res.radius = style.getRadius();
  res.fill = toObjectFill(style.getFill());
  res.stroke = toObjectStroke(style.getStroke());
  return res;
}
function toObjectFill(style) {
  if (!style) return null;
  return { type: "fill", color: style.getColor() };
}
function toObjectStroke(style) {
  if (!style) return null;
  return {
    type: "stroke",
    width: style.getWidth(),
    color: style.getColor(),
    lineDash: style.getLineDash(),
  };
}
function toObjectText(style) {
  if (!style) return null;
  const res = { type: "text" };
  res.text = style.getText() || "";
  res.stroke = toObjectStroke(style.getStroke());
  res.bold = false;
  res.italic = false;
  res.size = 10;
  res.font = "sans-serif";
  const font = style.getFont();
  if (font) {
    const fontData = font.split(" ");
    fontData.forEach(fd => {
      if (fd === "bold") res.bold = true;
      else if (fd === "italic") res.italic = true;
      else if (fd === "oblique") res.italic = true;
      else if (fd.endsWith("px")) res.size = parseFloat(fd.replace("px", ""));
      else if (fd || fd.length > 0) res.font = fd;
    });
  }
  return res;
}
function byField(obj) {
  const { field, values } = obj;
  const styles = obj.styles.map(s => fromObject(s));
  const defaultStyle = fromObject(obj.defaultStyle);
  return function(feature, resolution) {
    const v = feature.get(field) + "";
    const i = values.indexOf(v);
    if (i === -1) {
      return defaultStyle ? [defaultStyle] : [];
    } else {
      return [styles[i]];
    }
  };
}
function fromObjectFill(obj) {
  const options = {};
  if (obj.color) options.color = obj.color;
  return new Fill(options);
}
function fromObjectText(obj) {
  const options = {};
  Object.assign(options, obj);
  if (options.fill) options.fill = fromObjectFill(options.fill);
  if (options.stroke) options.stroke = fromObjectStroke(options.stroke);
  options.font = getFontString(obj);
  return new Text(options);
}
function getFontString(text) {
  var result = [];
  if (text.italic) result.push("italic");
  if (text.bold) result.push("bold");
  result.push(text.size + "px");
  result.push("sans-serif");
  return result.join(" ");
}
function getColorByAffiliation(aff) {
  switch (aff) {
    case "H":
      return "#ff8080";
    case "N":
      return "#aaffaa";
    case "F":
      return "#80E0FF";
    default:
      return "#ffff80";
  }
}
function fromObjectStroke(obj) {
  const options = {};
  if (obj.color) options.color = obj.color;
  if (obj.width) options.width = obj.width;
  if (obj.lineCap) options.lineCap = obj.lineCap;
  if (obj.lineJoin) options.lineJoin = obj.lineJoin;
  if (obj.lineDash) options.lineDash = obj.lineDash;
  if (obj.lineDashOffset) options.lineDashOffset = obj.lineDashOffset;
  if (obj.miterLimit) options.miterLimit = obj.miterLimit;
  if (!options.width) return null;
  return new Stroke(options);
}
function fromObjectStyle(obj) {
  var options = {};
  if (obj.image && obj.image.type === "icon")
    options.image = fromObjectIcon(obj.image);
  if (obj.image && obj.image.type === "circle")
    options.image = fromObjectCircle(obj.image);
  if (obj.fill) options.fill = fromObjectFill(obj.fill);
  if (obj.stroke) options.stroke = fromObjectStroke(obj.stroke);
  if (obj.text) options.text = fromObjectText(obj.text);
  return new Style(options);
}
function fromObjectIcon(obj) {
  const options = {};
  if (obj.src) options.src = obj.src;
  if (obj.scale) options.scale = obj.scale;
  if (obj.rotation) options.rotation = (obj.rotation * Math.PI) / 180;
  options.anchor = [0.5, 0.5];
  return new Icon(options);
}
function fromObjectCircle(obj) {
  const options = {};
  options.radius = !Number.isNaN(obj.radius) ? obj.radius : 0;
  if (obj.fill) options.fill = fromObjectFill(obj.fill);
  if (obj.stroke && obj.stroke.width)
    options.stroke = fromObjectStroke(obj.stroke);
  return new Circle(options);
}
var styles = {
  hospital: fromObject({
    image: {
      type: "icon",
      src: "/public/icons/hospital2.png",
      scale: 35 / 35,
    },
  }),
};
function getLegendPreview(layer) {
  const style = layer.getStyle();
  var canvas = document.createElement("canvas");
  var sx = 40;
  var sy = 40;
  var cx = sx / 2;
  var cy = sy / 2;
  var p = 5;
  canvas.width = sx;
  canvas.height = sy;
  var ctx = canvas.getContext("2d");
  ctx.save();
  try {
    var vectorContext = toContext(ctx);
    vectorContext.setStyle(style);
    if (style.getImage()) {
      vectorContext.drawGeometry(new Point([cx, cy]));
    } else if (style.getFill()) {
      vectorContext.drawGeometry(
        new Polygon([
          [
            [sx * 0.3 + p, 0 + p],
            [sx * 0.7, 0 + p],
            [cx, cy],
            [sx - p, cy],
            [sx - p, sy - p],
            [0 + p, sy - p],
            [0 + p, cy],
            [sx * 0.3 + p, 0 + p],
          ],
        ])
      );
    } else if (style.getStroke()) {
      vectorContext.drawGeometry(
        new LineString([
          [0, cy],
          [sx, cy],
        ])
      );
    }
  } catch (e) {
    console.log("error creating legend preview");
    return null;
  }
  ctx.restore();
  if (
    layer.get("style-config") &&
    layer.get("style-config").type === "simple"
  ) {
    return $("<div>")
      .addClass("legend-simple-item")
      .append(canvas)
      .append(
        $("<div>")
          .addClass("legend-title")
          .append(layer.get("title"))
      )[0];
  }
  return canvas;
}

function testArray(arr) {
  return Array.isArray(arr) && arr.length > 0 ? true : false;
}

export default {
  fromObject: fromObject,
  toObject: toObject,
  styles: styles,
  getLegendPreview: getLegendPreview,
  testArray: testArray,
  getColorByAffiliation,
  namedColors: {
    aliceblue: "#f0f8ff",
    antiquewhite: "#faebd7",
    aqua: "#00ffff",
    aquamarine: "#7fffd4",
    azure: "#f0ffff",
    beige: "#f5f5dc",
    bisque: "#ffe4c4",
    black: "#000000",
    blanchedalmond: "#ffebcd",
    blue: "#0000ff",
    blueviolet: "#8a2be2",
    brown: "#a52a2a",
    burlywood: "#deb887",
    cadetblue: "#5f9ea0",
    chartreuse: "#7fff00",
    chocolate: "#d2691e",
    coral: "#ff7f50",
    cornflowerblue: "#6495ed",
    cornsilk: "#fff8dc",
    crimson: "#dc143c",
    cyan: "#00ffff",
    darkblue: "#00008b",
    darkcyan: "#008b8b",
    darkgoldenrod: "#b8860b",
    darkgray: "#a9a9a9",
    darkgreen: "#006400",
    darkkhaki: "#bdb76b",
    darkmagenta: "#8b008b",
    darkolivegreen: "#556b2f",
    darkorange: "#ff8c00",
    darkorchid: "#9932cc",
    darkred: "#8b0000",
    darksalmon: "#e9967a",
    darkseagreen: "#8fbc8f",
    darkslateblue: "#483d8b",
    darkslategray: "#2f4f4f",
    darkturquoise: "#00ced1",
    darkviolet: "#9400d3",
    deeppink: "#ff1493",
    deepskyblue: "#00bfff",
    dimgray: "#696969",
    dodgerblue: "#1e90ff",
    firebrick: "#b22222",
    floralwhite: "#fffaf0",
    forestgreen: "#228b22",
    fuchsia: "#ff00ff",
    gainsboro: "#dcdcdc",
    ghostwhite: "#f8f8ff",
    gold: "#ffd700",
    goldenrod: "#daa520",
    gray: "#808080",
    green: "#008000",
    greenyellow: "#adff2f",
    honeydew: "#f0fff0",
    hotpink: "#ff69b4",
    indianred: "#cd5c5c",
    indigo: "#4b0082",
    ivory: "#fffff0",
    khaki: "#f0e68c",
    lavender: "#e6e6fa",
    lavenderblush: "#fff0f5",
    lawngreen: "#7cfc00",
    lemonchiffon: "#fffacd",
    lightblue: "#add8e6",
    lightcoral: "#f08080",
    lightcyan: "#e0ffff",
    lightgoldenrodyellow: "#fafad2",
    lightgrey: "#d3d3d3",
    lightgreen: "#90ee90",
    lightpink: "#ffb6c1",
    lightsalmon: "#ffa07a",
    lightseagreen: "#20b2aa",
    lightskyblue: "#87cefa",
    lightslategray: "#778899",
    lightsteelblue: "#b0c4de",
    lightyellow: "#ffffe0",
    lime: "#00ff00",
    limegreen: "#32cd32",
    linen: "#faf0e6",
    magenta: "#ff00ff",
    maroon: "#800000",
    mediumaquamarine: "#66cdaa",
    mediumblue: "#0000cd",
    mediumorchid: "#ba55d3",
    mediumpurple: "#9370d8",
    mediumseagreen: "#3cb371",
    mediumslateblue: "#7b68ee",
    mediumspringgreen: "#00fa9a",
    mediumturquoise: "#48d1cc",
    mediumvioletred: "#c71585",
    midnightblue: "#191970",
    mintcream: "#f5fffa",
    mistyrose: "#ffe4e1",
    moccasin: "#ffe4b5",
    navajowhite: "#ffdead",
    navy: "#000080",
    oldlace: "#fdf5e6",
    olive: "#808000",
    olivedrab: "#6b8e23",
    orange: "#ffa500",
    orangered: "#ff4500",
    orchid: "#da70d6",
    palegoldenrod: "#eee8aa",
    palegreen: "#98fb98",
    paleturquoise: "#afeeee",
    palevioletred: "#d87093",
    papayawhip: "#ffefd5",
    peachpuff: "#ffdab9",
    peru: "#cd853f",
    pink: "#ffc0cb",
    plum: "#dda0dd",
    powderblue: "#b0e0e6",
    purple: "#800080",
    rebeccapurple: "#663399",
    red: "#ff0000",
    rosybrown: "#bc8f8f",
    royalblue: "#4169e1",
    saddlebrown: "#8b4513",
    salmon: "#fa8072",
    sandybrown: "#f4a460",
    seagreen: "#2e8b57",
    seashell: "#fff5ee",
    sienna: "#a0522d",
    silver: "#c0c0c0",
    skyblue: "#87ceeb",
    slateblue: "#6a5acd",
    slategray: "#708090",
    snow: "#fffafa",
    springgreen: "#00ff7f",
    steelblue: "#4682b4",
    tan: "#d2b48c",
    teal: "#008080",
    thistle: "#d8bfd8",
    tomato: "#ff6347",
    turquoise: "#40e0d0",
    violet: "#ee82ee",
    wheat: "#f5deb3",
    white: "#ffffff",
    whitesmoke: "#f5f5f5",
    yellow: "#ffff00",
    yellowgreen: "#9acd32",
  },
};
