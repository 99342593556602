<template>
  <div class="container-main container-main--terrains">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1 class="d-inline-block align-middle">Terrains</h1>
        <help-popup :name="'Terrains'"></help-popup>
        <div class="alert alert-danger" v-if="errors.length">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>
        <div class="mb-2 mt-2">
          <button v-on:click="reloadTerrains()" class="btn btn-primary">
            <span class="icon icon-reload"></span>
            <span class="label">Reload</span>
          </button>
          <router-link to="terrains/new" class="btn btn-primary">
            <span class="icon icon-plus2"></span>
            <span class="label">New</span>
          </router-link>
        </div>
        <table class="table w-100 table-terrains">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Description</th>
              <th>Z</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="terrains.length < 1">
              <td>No terrains found</td>
            </tr>
            <tr v-for="terrain in terrains" v-bind:key="terrain.id">
              <td>{{ terrain.id }}</td>
              <td>{{ terrain.name }}</td>
              <td>{{ terrain.description }}</td>
              <td>{{ terrain.z }}</td>
              <td>
                <span title="Details" class="btn btn-outline-secondary" @click="$router.push('terrains/' + terrain.id)">
                  <span class="icon icon-file-spreadsheet"></span> </span><span title="Edit"
                  class="btn btn-outline-primary" @click="$router.push('terrains/edit/' + terrain.id)">
                  <span class="icon icon-pencil4"></span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import eventBus from "../../../eventBus";
import api from "../../../api";
import HelpPopup from "../../../HelpPopup.vue";
export default {
  components: {
    "admin-menu": AdminMenu,
    "help-popup": HelpPopup
  },
  created() {
    this.loadTerrains();
  },
  data() {
    return {
      terrains: [],
      loading: false,
      errors: [],
    };
  },
  methods: {
    loadTerrains() {
      this.$http
        .get(api + "/rest/terrains")
        .then((res) => {
          this.terrains = res.body;
        })
        .catch((res) => {
          if (res.status === 401) {
            eventBus.$emit("logout");
          }
        });
    },
    reloadTerrains() {
      this.$http
        .get(api + "/rest/terrains/reload")
        .then((res) => {
          this.terrains = res.body;
        })
        .catch((res) => {
          if (res.status === 401) {
            eventBus.$emit("logout");
          }
        });
    },
  },
};
</script>
