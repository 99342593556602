<template>
  <div>
    <help-popup :class="{'isAbsolute': true,}"  :name="'Dices'"></help-popup>
    <div class=" dices-edit w-100 p-2" v-if="scenario && scenario.content">
      <div class="d-flex" v-if="scenario.content.dicesWidget">
        <span class="h2 flex-grow-1"> </span>
        <button class="btn btn-outline-danger my-1" v-if="scenario.content.dicesWidget"
          @click.prevent="$refs.confirmRemoveDices.open()">
          Remove Dices Section
        </button>
      </div>
      <div v-else class="d-flex">
        <button class="btn btn-outline-primary my-1" @click.prevent="createDices()">
          Add Dices Widget
        </button>
      </div>
      <div v-if="scenario.content.dicesWidget">
        <table v-if="scenario.content.sides.length > 0" class="table table-striped new-scenario-sides">
          <thead>
            <tr>
              <th>Name</th>
              <th>Color</th>
              <th>View Dices</th>
              <th>Roll Dices</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(side, i) in scenario.content.sides" :key="i">
              <td>{{ side.name }}</td>
              <!--<td>{{ side.affiliation | getAffiliationName }}</td>-->
              <td>
                <div v-if="side.color && side.color !== ''">
                  <span class="preview-color-picker" v-bind:style="{ background: side.color }"></span>
                  <span>{{ side.color }}</span>
                </div>
                <div v-else>
                  <span class="preview-color-picker" v-bind:style="{
                    background: getColorByAffiliation(side.affiliation),
                  }"></span>
                </div>
              </td>
              <td>
                <label class="custom-control custom-checkbox d-block">
                  <input type="checkbox" class="custom-control-input" :checked="
                    !!scenario.content.dicesWidget.permissions[
                      getIdByName(side.name)
                    ]['view']
                  " @change="changedDicesView(getIdByName(side.name))" />
                  <span class="custom-control-label"></span>
                </label>
              </td>
              <td>
                <label class="custom-control custom-checkbox d-block">
                  <input :disabled="side.controller" type="checkbox" class="custom-control-input" :checked="
                    !!scenario.content.dicesWidget.permissions[
                      getIdByName(side.name)
                    ]['roll']
                  " @change="changedDicesRoll(getIdByName(side.name))" />
                  <span class="custom-control-label"></span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <table v-else>
          No Side Defined
        </table>
      </div>
      <confirm-dialog ref="confirmRemoveDices"
        :body="'Are you sure do you want to delete dices section? All your dices settings will be lost!'"
        confirmBtnClasses="btn-danger" @confirm="confirmRemoveDices" @cancel="$refs.confirmRemoveDices.close()">
      </confirm-dialog>
    </div>
  </div>
</template>
<script>
import Autocomplete from "vuejs-auto-complete";
import HelpPopup from "../../../HelpPopup.vue";
import eventBus from "../../../eventBus";
import api from "../../../api";
import {
  getAffiliationByName,
  getIdByName,
} from "../../../../../xserver-common/utils";
import { ModelListSelect } from "vue-search-select";
import stylesManager from "../../../../../xserver-client3d/src/util/stylesManager";
var groupsMap = {};
export default {
  components: {
    autocomplete: Autocomplete,
    "model-list-select": ModelListSelect,
    "help-popup": HelpPopup
  },
  filters: {},
  props: ["scenario"],
  created() { },
  beforeUpdate() {
    if (this.scenario.content.dicesWidget === true) {
      this.createDices();
    } else if (this.scenario.content.dicesWidget === false) {
      delete this.scenario.content.dicesWidget;
    }
  },
  watch: {},
  computed: {},
  methods: {
    confirmRemoveDices() {
      if (this.scenario.content.hasOwnProperty("dicesWidget")) {
        delete this.scenario.content.dicesWidget;
      }
      this.$refs.confirmRemoveDices.close();
      this.$forceUpdate();
    },
    createDices() {
      const dicesWidget = {
        enabled: true,
        permissions: {},
      };
      for (let side of this.scenario.content.sides) {
        const sideID = getIdByName(side.name);
        dicesWidget.permissions[sideID] = {
          view: true,
          roll: side.controller === true,
        };
      }
      this.scenario.content.dicesWidget = dicesWidget;
      this.$forceUpdate();
    },
    async changedDicesView(sideID) {
      this.scenario.content.dicesWidget.permissions[sideID]["view"] =
        !this.scenario.content.dicesWidget.permissions[sideID]["view"];
    },
    async changedDicesRoll(sideID) {
      this.scenario.content.dicesWidget.permissions[sideID]["roll"] =
        !this.scenario.content.dicesWidget.permissions[sideID]["roll"];
    },
    getColorByAffiliation: stylesManager.getColorByAffiliation,
    getIdByName,
  },
  computed: {},
  data() {
    return {
      groups: [],
    };
  },
};
</script>
