<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1 class="d-inline-block align-middle">HLA Connectors</h1>
        <help-popup :name="'HLA Connectors'"></help-popup>
        <div class="alert alert-danger" v-if="errors.length">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>
        <div class="mb-2 mt-2">
          <router-link to="hla/new" class="btn btn-primary">
            <span class="icon icon-plus2"></span>
            <span class="label">New</span>
          </router-link>
        </div>
        <table class="table w-100 table-hla">
          <thead>
            <tr>
              <th>Hla Connector Name</th>
              <th>RTI Host</th>
              <th>Federation Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hla in hlaConnectors" v-bind:key="hla.id">
              <th>{{ hla.name }}</th>
              <th>{{ hla.content.rti }}</th>
              <th>{{ hla.content.federationName }}</th>
              <td>
                <span class="btn btn-outline-primary" @click="$router.push('hla/' + hla.id)">
                  <span class="icon icon-pencil4"></span>
                </span>
                <span class="btn btn-outline-danger" @click="deleteHla(hla)">
                  <span class="icon icon-bin2"></span>
                </span>
              </td>
            </tr>
            <span class="overlay align-self-center d-flex" v-if="loading">
              <div class="overlay-background bg-white opacity-50"></div>
              <div class="spinner-border text-primary m-auto" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </span>
          </tbody>
        </table>
      </div>
      <div id="deleteConfirm" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              Are you sure that you want to delete the hla connector "{{
              deletingHla && deletingHla.name
              }}"
            </div>
            <div class="modal-footer">
              <button class="btn btn-danger" @click="confirmDelete()">
                Delete
              </button>
              <button class="btn btn-secondary" @click="cancelDelete()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "../Menu.vue";
import eventBus from "../../../eventBus";
import HelpPopup from "../../../HelpPopup.vue";
export default {
  created() {
    this.loadHlaConnectors();
  },
  components: {
    "admin-menu": AdminMenu,
    "help-popup": HelpPopup
  },
  data() {
    return {
      errors: [],
      hlaConnectors: [],
      loading: false,
      deletingHla: null,
    };
  },
  methods: {
    loadHlaConnectors() {
      this.loading = true;
      this.$http
        .get("/rest/hla")
        .then((res) => {
          this.loading = false;
          this.hlaConnectors = res.body;
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else {
            this.errors.push("Error " + res.status);
          }
        });
    },
    deleteHla(hla) {
      this.deletingHla = hla;
      $("#deleteConfirm").modal("show");
    },
    validateHla(hla) {
      this.loading = true;
      this.$http
        .get("/rest/hla/" + hla.id + "/validate")
        .then((res) => {
          hla.content = res.body.content;
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        });
    },
    confirmDelete() {
      this.loading = true;
      this.$http
        .delete("/rest/hla/" + this.deletingHla.id)
        .then((res) => {
          this.loading = false;
          this.loadHlaConnectors();
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        });
      $("#deleteConfirm").modal("hide");
    },
    cancelDelete() {
      this.deletingHla = null;
      $("#deleteConfirm").modal("hide");
    },
  },
};
</script>
