<template>
  <div class="map-journal-edit d-flex p-2 flex-column">
    <help-popup :isAbsolute="true" :name="'Map Journal'"></help-popup>
    <template v-if="!scenario.mapJournal">
      <div class="noMapJournal">
        <button class="btn btn-outline-primary my-1" @click.prevent="addMapJournal()">
          Add Map Journal
        </button>
      </div>
    </template>
    <template v-else>
      <div class="d-flex flex-grow-1">
        <span class="title flex-grow-1"></span>
        <button class="btn btn-outline-danger my-1" @click.prevent="removeMapJournal()">
          Remove Map Journal
        </button>
      </div>
      <template v-if="scenario.content.vignettes">
        <map-journal-vignette-edit v-for="(vignetteConfig, i) in scenario.mapJournal.vignettes" :key="i"
          :vignette="scenario.content.vignettes[i]" v-model="scenario.mapJournal.vignettes[i]" :scenario="scenario" />
      </template>
      <map-journal-raw-json :scenario="scenario" />
    </template>
    <confirm-dialog ref="confirmRemoveMapJournal"
      :body="'Are you sure do you want to remove Map Journal Configuration? All the sections data will be lost!'"
      confirmBtnClasses="btn-danger" @confirm="confirmRemoveMapJournal" @cancel="$refs.confirmRemoveMapJournal.close()">
    </confirm-dialog>
    <loading :loading="loading" />
  </div>
</template>
<script>
import MapJournalRawJson from "./MapJournalRawJson.vue";
import MapJournalSectionForm from "./MapJournalSectionForm.vue";
import MapJournalVignetteEdit from "./MapJournalVignetteEdit.vue";
import HelpPopup from "../../../../HelpPopup.vue";
export default {
  props: ["scenario"],
  name: "map-journal-edit",
  components: {
    "map-journal-section-form": MapJournalSectionForm,
    "map-journal-vignette-edit": MapJournalVignetteEdit,
    "map-journal-raw-json": MapJournalRawJson,
    "help-popup": HelpPopup
  },
  created() { },
  methods: {
    addMapJournal() {
      this.scenario.mapJournal = this.defaultMapJournal();
      this.$emit("addMapJpurnal");
      this.$forceUpdate();
    },
    removeMapJournal() {
      this.$refs.confirmRemoveMapJournal.open();
    },
    confirmRemoveMapJournal() {
      this.scenario.mapJournal = undefined;
      this.$emit("removeMapJpurnal");
      this.$refs.confirmRemoveMapJournal.close();
      this.$forceUpdate();
    },
    onSectionClick(section) {
      this.editingSection = section;
    },
    defaultMapJournal() {
      const mapJournal = { vignettes: [] };
      if (
        this.scenario &&
        this.scenario.content &&
        this.scenario.content.vignettes
      ) {
        for (const vignette of this.scenario.content.vignettes) {
          mapJournal.vignettes.push({ sections: [] });
        }
      }
      return mapJournal;
    },
  },
  data() {
    return { editingSection: undefined, loading: false };
  },
};
</script>