<template>
  <div>
    <help-popup :class="{'isAbsolute': true,}"  :name="'Layers'"></help-popup>
    <div class="w-100 p-2">
      <div class="mb-2">
        <button class="btn btn-outline-primary" @click.prevent="newLayer()">
          <span class="icon icon-plus2"></span>
          <span class="label">Add</span>
        </button>
      </div>
      <div class="d-flex">
        <layers-list ref="layersList" :layers="scenario.content.layers" :currentLayer="currentLayer"
          @menu-add="addLayer" @menu-edit="editLayer" @menu-delete="deleteLayer" @menu-move-up="moveUpLayer"
          @menu-move-down="moveDownLayer" @select="selectLayer"></layers-list>
        <layer-editor ref="layerEditor" :layer="currentLayer" :paths="getPaths()" :layerPath="currentPath"
          :datastores="datastores" :availableVignettes="scenario.content.vignettes"
          :availableSides="scenario.content.sides" :has3d="scenario.content.app3D" v-if="currentLayer"
          @title-change="regenerateId()" @menu-move-up="moveUpLayer" @menu-move-down="moveDownLayer"
          @menu-move-to="moveLayerTo" @menu-delete="deleteLayer" @menu-duplicate="duplicateLayer" />
      </div>
      <confirm-dialog ref="confirmDelete" :body="'Are you sure do you want to delete layer ' + currentPath + '?'"
        confirmBtnClasses="btn-danger" @confirm="confirmDeleteLayer" @cancel="$refs.confirmDelete.close()">
      </confirm-dialog>
    </div>
  </div>
</template>
<script>
import LayersListVue from "./layers-list/LayersList.vue";
import eventBus from "../../../eventBus";
import LayerDialogVue from "./LayerDialog.vue";
import ConfirmDialogVue from "../../../components/confirm-dialog/ConfirmDialog.vue";
import LayerEditorVue from "./LayerEditor.vue";
import HelpPopup from "../../../HelpPopup.vue";
export default {
  created() {
    console.log("LayersSection created:", this.scenario.content.layers);
    this.currentLayer = null;
  },
  mounted() {
    // $(document).on("click touchend", function (e) {
    //   eventBus.$emit("close-dropdown-menus");
    // });
  },
  updated() {
    console.log("datastore received:", this.datastores);
  },
  components: {
    "layers-list": LayersListVue,
    "layer-dialog": LayerDialogVue,
    "layer-editor": LayerEditorVue,
    "confirm-dialog": ConfirmDialogVue,
    "help-popup": HelpPopup
  },
  props: ["scenario", "datastores"],
  methods: {
    getPaths(results, parentPath, parent) {
      if (typeof results === "undefined") return this.getPaths(["/"], "/");
      var layers = null;
      if (typeof parent === "undefined") {
        layers = this.scenario.content.layers;
      } else {
        layers = parent.children;
      }
      layers.forEach((l) => {
        if (l.type === "group") {
          const pPath =
            parentPath + (parentPath.endsWith("/") ? "" : "/") + l.id;
          results.push(pPath);
          this.getPaths(results, pPath, l);
        }
      });
      return results;
    },
    regenerateId() {
      var path = this.currentPath.split("/");
      path.pop();
      var parentLayer = this.$refs.layersList.resolveLayer(path.join("/"));

      this.currentLayer.id = this.generateLayerId(
        this.currentLayer.title,
        parentLayer
      );
      path.push(this.currentLayer.id);
      this.currentPath = path.join("/");
    },
    normalizeLayers(layers) {
      for (var i = 0; i < layers.length; i++) {
        var layer = layers[i];
        if (typeof layer.sources === "undefined") {
          layer.sources = [
            {
              datastore: layer.datastore,
              table: layer.table,
            },
          ];
          if (layer.datastore) delete layer.datastore;
          if (layer.table) delete layer.table;

        } else if (layer.type === "group") this.normalizeLayers(layer.children);
        if (
          layer.geomType ||
          layer.geomColumn ||
          layer.geomSrid ||
          layer.geomDimension 
        ) {
          layer.sources.forEach((s) => {
            s.geomType = layer.geomType;
            s.geomColumn = layer.geomColumn;
            s.geomSrid = layer.geomSrid;
            s.geomDimension = layer.geomDimension;
            //s.
          });
        }
        if (layer.geomType) delete layer.geomType;
        if (layer.geomColumn) delete layer.geomColumn;
        if (layer.geomSrid) delete layer.geomSrid;
        if (layer.geomDimension) delete layer.geomDimension;
      }
    },
    newLayer(parent, parentPath) {
      this.currentLayer = {
        id: this.generateLayerId(),
        title: "",
        type: "group",
        sources: [{ datastore: 0, table: "" , time_jamming: ""}], //BPAG 1112
        children: [],
        vignettes: {},
        access: "direct",
        visible: false,
        virtual: false,
        hidden: false,
        jammingLayers: false,
      };
      

      this.currentPath = parentPath
        ? parentPath + "/" + this.currentLayer.id
        : "/" + this.currentLayer.id;

      this.scenario.content.vignettes.forEach(
        (v) => (this.currentLayer.vignettes[v] = true)
      );
      
      if (parent) {
        parent.children.push(this.currentLayer);
      } else {
        this.scenario.content.layers.push(this.currentLayer);
      }
      eventBus.$emit("close-dropdown-menus");
      this.$forceUpdate();
      //this.$refs.layerDialog.open();
    },
    addLayer(evt) {
      console.log(
        "event:",
        "add-layer",
        "layer:",
        evt.layer,
        "path:",
        evt.layerPath
      );
      this.newLayer(evt.layer, evt.layerPath);
      //this.$refs.layerDialog.open();
    },
    editLayer(evt) {
      console.log(
        "event:",
        "edit-layer",
        "layer:",
        evt.layer,
        "path:",
        evt.layerPath
      );
      if (!evt.layer.vignettes) evt.layer.vignettes = {};
      if (
        typeof evt.layer.sources === "undefined" &&
        evt.layer.type !== "group"
      ) {
        evt.layer.sources = [
          {
            datastore: evt.layer.datastore || 0,
            table: evt.layer.table || "",
            time_jamming: evt.layer.time_jamming || "",
          },
        ];
        if (evt.layer.datastore) delete evt.layer.datastore;
        if (evt.layer.table) delete evt.layer.table;
        if (evt.layer.time_jamming) delete evt.layer.time_jamming;
      }
      
      this.scenario.content.vignettes.forEach((v) => {
        evt.layer.vignettes[v] =
          evt.layer.vignettes[v] === true || evt.layer.vignettes[v] === false
            ? evt.layer.vignettes[v]
            : new Boolean(false);
      });
      if (!evt.layer.access) evt.layer.access = "direct";
      this.currentPath = evt.layerPath;
      this.currentLayer = evt.layer;
      //this.currentLayer = { ...evt.layer };
      //this.currentLayer.vignettes = { ...evt.layer.vignettes };

      eventBus.$emit("close-dropdown-menus");
      this.$refs.layerDialog.open();
    },
    selectLayer(evt) {
      console.log("selected layer", evt.layer.id);
      this.currentLayer = evt.layer;
      this.currentPath = evt.layerPath;
      //this.$refs.layerEditor.$forceUpdate();
    },
    moveLayerTo(evt) {
      var path = evt.layerPath.split("/");
      path.pop();
      var parentLayer = this.$refs.layersList.resolveLayer(path.join("/"));
      var newParent = this.$refs.layersList.resolveLayer(evt.to);
      if (parentLayer && parentLayer.children) {
        const index = parentLayer.children.indexOf(evt.layer);
        if (index >= 0) parentLayer.children.splice(index, 1);
      }
      evt.layer.id = this.generateLayerId(evt.layer.title, newParent);
      newParent.children.push(evt.layer);
      this.currentLayer = evt.layer;
      this.currentPath = evt.to + "/" + evt.layer.id;
    },
    deleteLayer(evt) {
      console.log("delete request for", evt.layerPath);
      this.currentLayer = evt.layer;
      this.currentPath = evt.layerPath;
      eventBus.$emit("close-dropdown-menus");
      this.$refs.confirmDelete.open();
    },
    confirmDeleteLayer() {
      var path = this.currentPath.split("/");
      path.pop();
      var parentLayer = this.$refs.layersList.resolveLayer(path.join("/"));
      if (parentLayer && Array.isArray(parentLayer.children)) {
        parentLayer.children.splice(
          parentLayer.children.indexOf(this.currentLayer),
          1
        );
        eventBus.$emit("close-dropdown-menus");
        this.$refs.confirmDelete.close();
      } else {
        console.warn(
          "unable to delete layer",
          "path:",
          this.currentPath,
          "parentLayer:",
          parentLayer
        );
      }
      this.currentLayer = null;
    },
    moveUpLayer(evt) {
      var path = evt.layerPath.split("/");
      path.pop();
      var parent = this.$refs.layersList.resolveLayer(path.join("/"));
      if (!parent) return;
      var i = parent.children ? parent.children.indexOf(evt.layer) : -1;
      if (i <= 0) return;
      parent.children.splice(i, 1);
      parent.children.splice(i - 1, 0, evt.layer);
      eventBus.$emit("close-dropdown-menus");
    },
    duplicateLayer(evt) {
      var path = evt.layerPath.split("/");
      path.pop();
      var parent = this.$refs.layersList.resolveLayer(path.join("/"));
      var newLayer = JSON.parse(JSON.stringify(evt.layer));
      newLayer.id = this.generateLayerId(newLayer.title, parent);
      if (parent) {
        parent.children.push(newLayer);
      } else {
        this.content.layers.push(newLayer);
      }
      this.currentLayer = newLayer;
      this.currentPath = path.join("/") + "/" + newLayer.id;
    },
    moveDownLayer(evt) {
      var path = evt.layerPath.split("/");
      path.pop();
      var parent = this.$refs.layersList.resolveLayer(path.join("/"));
      if (!parent) return;
      var i = parent.children ? parent.children.indexOf(evt.layer) : -1;
      if (i < 0 || i >= parent.children.length - 1) return;
      parent.children.splice(i, 1);
      parent.children.splice(i + 1, 0, evt.layer);
      eventBus.$emit("close-dropdown-menus");
    },
    /*getJammingValue(parentLayer){
      const jammingCheck = parentLayer
        ? parentLayer.jammingLayers
        : false;

      var result = jammingCheck;
      return result;
    },*/
    generateLayerId(baseName, parentLayer) {
      var c = 0;
      const base = baseName
        ? baseName.trim().toLowerCase().replaceAll(" ", "_")
        : "new_layer";
      const children = parentLayer
        ? parentLayer.children
        : this.scenario.content.layers;
      const ids = {};
      children.forEach((child) => {
        ids[child.id] = true;
      });
      var result = base;
      while (ids[result]) {
        c = c + 1;
        result = base + "_" + c;
      }
      return result;
    },
    confirm() {
      this.normalizeLayers(this.scenario.content.layers);
      var parentLayer = this.$refs.layersList.resolveLayer(this.currentPath);
      if (!this.currentLayer.id)
        this.currentLayer.id = this.generateLayerId(
          this.currentLayer.title,
          parentLayer
        );
      //var layer = parentLayer.children.filter(l => l === this.currentLayer)[0];
      if (parentLayer === this.currentLayer) {
        // edit layer
        this.$refs.layerDialog.close();
      } else {
        //add new layer
        parentLayer.children.push(this.currentLayer);
        this.$refs.layerDialog.close();
      }
    },
  },
  data() {
    return {
      currentPath: "",
      currentLayer: {
        name: "",
        title: "",
        children: [],
        type: "group",
        vignettes: {},
        jammingLayers: false, //BPAG
      },
    };
  },
};
</script>
