<template>
  <div class="container-main container-main--processes">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1 class="d-inline-block align-middle">Process</h1>
        <help-popup :name="'Process'"></help-popup>
        <div class="alert alert-danger" v-if="errors.length">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>
        <div class="mb-2 mt-2">
          <button v-on:click="loadProcess()" class="btn btn-primary">
            <span class="icon icon-reload"></span>
            <span class="label">Refresh</span>
          </button>
        </div>
        <h4>Running</h4>
        <table class="table w-100 table-processes">
          <thead>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Started at</th>
              <th>Elapsed</th>
              <th>ETA</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="runningProcess.length < 1">
              <td>No running process</td>
            </tr>
            <tr v-for="proc in runningProcess" v-bind:key="proc.id">
              <td>{{ proc.processType }}</td>
              <td>{{ proc.name }}</td>
              <td>{{ printDateTime(proc.startedAt) }}</td>
              <td>{{ printDateDiff(proc.startedAt, new Date()) }}</td>
              <td>...</td>
              <td>
                <span title="Details" class="btn btn-outline-secondary" @click="$router.push('process/' + proc.name)">
                  <span class="icon icon-file-spreadsheet"></span>
                </span>
                <span title="Kill process" class="btn btn-outline-warning" v-if="proc.status == 'online'"
                  @click="killProcess(proc)">
                  <span class="icon icon-stop2"></span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>Stopped</h4>
        <button v-if="stoppedProcess.length >= 1" class="btn btn-danger mb-2" type="button" @click="deleteAllProcess()">
          <span class="icon icon-cross"></span>
          <span class="label">Delete All</span>
        </button>
        <table class="table w-100 table-processes">
          <thead>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Started at</th>
              <th>Exec Time</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="stoppedProcess.length < 1">
              <td>No process here</td>
            </tr>
            <tr v-for="proc in stoppedProcess" v-bind:key="proc.id">
              <td>{{ proc.processType }}</td>
              <td>{{ proc.name }}</td>
              <td>{{ printDateTime(proc.startedAt) }}</td>
              <td>{{ printDateDiff(proc.startedAt, proc.endedAt) }}</td>
              <td v-html="getStoppedState(proc.status, proc.exitCode)"></td>
              <td>
                <span v-if="!isExternal(proc.processType)" title="Details" class="btn btn-outline-secondary"
                  @click="$router.push('process/' + proc.name)">
                  <span class="icon icon-file-spreadsheet"></span>
                </span>
                <span v-if="!isExternal(proc.processType) &&  proc.status === 'stopped'" title="Restart this process"
                  class="btn btn-outline-success" @click="startProcess(proc)">
                  <span class="icon icon-play4"></span>
                </span>
                <span title="Remove process from this list" class="btn btn-outline-danger"
                  v-if="proc.status !== 'online'" @click="deleteProcess(proc)">
                  <span class="icon icon-bin2"></span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div id="killConfirm" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                Are you sure that you want to kill this process:<br />
                "{{ killingProcess && killingProcess.name }}"
              </div>
              <div class="modal-footer">
                <button class="btn btn-danger" @click="confirmKill()">
                  Kill
                </button>
                <button class="btn btn-secondary" @click="cancelKill()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="deleteConfirm" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                Are you sure that you want to delete this process:<br />
                "{{ deletingProcess && deletingProcess.name }}"
              </div>
              <div class="modal-footer">
                <button class="btn btn-danger" @click="confirmDelete()">
                  Delete
                </button>
                <button class="btn btn-secondary" @click="cancelDelete()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="deleteAllConfirm" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                Are you sure that you want to delete all process?
              </div>
              <div class="modal-footer">
                <button class="btn btn-danger" @click="confirmDeleteAll">
                  Delete
                </button>
                <button class="btn btn-secondary" @click="cancelDeleteAll()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="startConfirm" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                Are you sure that you want to restart this process:<br />
                "{{ startingProcess && startingProcess.name }}"
              </div>
              <div class="modal-footer">
                <button class="btn btn-danger" @click="confirmStart()">
                  Restart
                </button>
                <button class="btn btn-secondary" @click="cancelStart()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import eventBus from "../../../eventBus";
import HelpPopup from "../../../HelpPopup.vue";
import api from "../../../api";
import { printDateTime, printDateDiff } from "../../../utils";
export default {
  components: {
    "admin-menu": AdminMenu,
    "help-popup": HelpPopup
  },
  created() {
    this.loadProcess();
  },
  data() {
    return {
      allProcess: [],
      runningProcess: [],
      stoppedProcess: [],
      loading: false,
      killingProcess: null,
      deletingProcess: null,
      startingProcess: null,
      errors: [],
    };
  },
  methods: {
    printDateTime,
    printDateDiff,
    deleteAllProcess() {
      $("#deleteAllConfirm").modal();
    },
    confirmDeleteAll() {
      this.$http
        .delete(api + "/rest/proc/")
        .then((res) => {
          $("#deleteAllConfirm").modal("hide");
          this.deletingProcess = [null];
          this.loadProcess();
        })
        .catch((res) => {
          if (res.status === 401) {
            eventBus.$emit("logout");
            return;
          } else {
            if (res && res.body && res.body.error && res.body.error.message)
              this.errors = [res.body.error.message];
            else this.errors = ["Unexpected server error"];
          }
        });
    },
    cancelDeleteAll() {
      $("#deleteAllConfirm").modal("hide");
    },
    loadProcess() {
      this.$http
        .get(api + "/rest/proc")
        .then((res) => {
          this.allProcess = res.body;
          this.runningProcess = this.allProcess.filter((proc) => {
            return proc.status === "online";
          });
          this.stoppedProcess = this.allProcess.filter((proc) => {
            return proc.status !== "online";
          });
        })
        .catch((res) => {
          if (res.status === 401) {
            eventBus.$emit("logout");
          }
        });
    },
    getStoppedState(status, exitCode) {
      if (status === "stopped" && exitCode === 0)
        return '<span class="text-success">Success<span>';
      if (status === "stopped" && exitCode !== 0)
        return '<span class="text-danger">Error<span>';
      if (status === "killed") return "Killed";
      return "Unknown";
    },
    killProcess(proc) {
      this.killingProcess = proc;
      $("#killConfirm").modal();
    },
    cancelKill() {
      this.killingProcess = null;
      $("#killConfirm").modal("hide");
    },
    confirmKill() {
      this.$http
        .put(api + "/rest/proc/kill/" + this.killingProcess.name)
        .then((res) => {
          $("#killConfirm").modal("hide");
          this.killingProcess = null;
          this.loadProcess();
          setTimeout(() => {
            this.loadProcess();
          }, 2000);
        })
        .catch((res) => {
          if (res.status === 401) {
            eventBus.$emit("logout");
            return;
          } else {
            $("#killConfirm").modal("hide");
            this.killingProcess = null;
            if (res && res.body && res.body.error && res.body.error.message)
              this.errors = [res.body.error.message];
            else this.errors = ["Unexpected server error"];
          }
        });
    },
    deleteProcess(proc) {
      this.deletingProcess = proc;
      $("#deleteConfirm").modal();
    },
    cancelDelete() {
      this.deletingProcess = null;
      $("#deleteConfirm").modal("hide");
    },
    confirmDelete() {
      this.$http
        .delete(api + "/rest/proc/" + this.deletingProcess.name)
        .then((res) => {
          $("#deleteConfirm").modal("hide");
          this.deletingProcess = null;
          this.loadProcess();
        })
        .catch((res) => {
          if (res.status === 401) {
            eventBus.$emit("logout");
            return;
          } else {
            $("#deleteConfirm").modal("hide");
            this.deletingProcess = null;
            if (res && res.body && res.body.error && res.body.error.message)
              this.errors = [res.body.error.message];
            else this.errors = ["Unexpected server error"];
          }
        });
    },
    startProcess(proc) {
      this.startingProcess = proc;
      $("#startConfirm").modal();
    },
    cancelStart() {
      this.startingProcess = null;
      $("#startConfirm").modal("hide");
    },
    confirmStart() {
      this.$http
        .put(api + "/rest/proc/restart/" + this.startingProcess.name)
        .then((res) => {
          $("#startConfirm").modal("hide");
          this.startingProcess = null;
          this.loadProcess();
        })
        .catch((res) => {
          if (res.status === 401) {
            eventBus.$emit("logout");
            return;
          } else {
            $("#startConfirm").modal("hide");
            this.startingProcess = null;
            if (res && res.body && res.body.error && res.body.error.message)
              this.errors = [res.body.error.message];
            else this.errors = ["Unexpected server error"];
          }
        });
    },
    isExternal(type) {
      switch (type) {
        case "Viewshed":
          return true;

        default:
          return false;
      }
    }
  },
};
</script>
