<template>
  <div class="modal fade layer-dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <h3 v-if="!layer.id">Add Layer</h3>
          <h3 v-else>Edit Layer</h3>
          <div
            :class="{
              btn: true,
              'btn-outline-secondary': true,
              active: asJson,
            }"
            @click.prevent.stop="toggleAsJson"
          >
            View Layer JSON
          </div>
          <div class="form-group row pl-2 pr-2" v-if="!asJson">
            <!-- part one TO:-->
            <template>
              <label class="col-2 col-md-1">To:</label>
              <label class="col-10 col-md-11 justify-content-start pl-1 pr-1">{{ to || '/'}}</label>
            </template>
            <!-- part Name:, Type:-->
            <template>
              <label class="col-2 col-md-1">Title:</label>
              <input
                type="text"
                :class="{
                  'col-10': true,
                  'col-md-5': true,
                  'form-control': true,
                  'form-control-sm': true,
                  'is-invalid': invalidTitle,
                }"
                v-model="layer.title"
                @keydown.enter.prevent="confirmDialog()"
              />

              <label class="col-2 col-md-1">Type:</label>
              <select
                type="text"
                :class="{
                  'col-10': true,
                  'col-md-5': true,
                  'form-control': true,
                  'form-control-sm': true,
                  'is-invalid': invalidGroup,
                }"
                v-model="layer.type"
                @keydown.enter.prevent="confirmDialog()"
              >
                <option :selected="!layer.group" :value="''" disabled>
                  Layer Type...
                </option>
                <option :selected="!layer.group == 'group'" value="group">
                  Group
                </option>
                <option
                  :selected="!layer.group == 'wisdom-vector-layer'"
                  value="wisdom-vector-layer"
                >
                  Vector Layer
                </option>
              </select>
            </template>
            <!-- part Access:-->
            <template v-if="layer.type === 'wisdom-vector-layer'">
              <label class="col-2 col-md-1">Access:</label>
              <select
                type="text"
                :class="{
                  'col-10': true,
                  'col-md-11': true,
                  'form-control': true,
                  'form-control-sm': true,
                  'is-invalid': invalidAccess,
                }"
                v-model="layer.access"
                @keydown.enter.prevent="confirmDialog()"
                @change="accessChange()"
              >
                <option :value="''" disabled>Access Type...</option>
                <option value="direct">Direct</option>
                <option value="instance">Instance</option>
                <option value="vignette">Vignette</option>
                <option value="side">Side</option>
                <option value="vignette_side">Vignette + Side</option>
              </select>
            </template>
            <!-- part Source: Table:-->
            <div
              v-for="(source, i) in layer.sources"
              :key="i"
              class="col col-12"
            >
              <div class="row">
                <template v-if="layer.type !== 'group'">
                  <label class="col col-12 justify-content-start">
                    <b>Source {{ sourceLabel(i) }}</b>
                  </label>
                  <label class="col-2 col-md-1">DS:</label>
                  <select
                    name="datastore"
                    class="col-10 col-md-5 form-control form-control-sm"
                    v-model="source.datastore"
                    @change="datastoreChange(i)"
                  >
                    <option :value="''" disabled selected>
                      Select Datastore...
                    </option>
                    <option
                      v-for="datastore in datastores"
                      :key="datastore.id"
                      :value="datastore.id"
                    >
                      {{ datastore.name }}
                    </option>
                  </select>
                  <label class="col-2 col-md-1">Table:</label>
                  <select
                    name="table"
                    class="col-10 col-md-5 form-control form-control-sm"
                    v-model="source.table"
                    :title="source.table"
                    @change="tableChanged(i)"
                  >
                    <option :value="''" disabled selected>
                      Select Table...
                    </option>
                    <option
                      v-for="table in tables[source.datastore]"
                      :key="table.schema + '.' + table.name"
                      :value="table.schema + '.' + table.name"
                    >
                      {{ table.schema + "." + table.name }}
                    </option>
                  </select>
                </template>
                <!-- part Geom:, SRID: -->
                <template v-if="showGeometrySection(i)">
                  <label class="col-2 col-md-1">Geom:</label>
                  <label
                    class="col-10 col-md-5 justify-content-start pl-1 pr-1 bg-white"
                    >{{ source.geomType | geomTypeName
                    }}{{ source.geomDimension | geomSize }}</label
                  >
                  <label class="col-2 col-md-1">SRID:</label>
                  <label
                    class="col-10 col-md-5 justify-content-start pl-1 pr-1 bg-white"
                    >{{ source.geomSrid }}</label
                  >
                </template>
              </div>
            </div>
            <!-- part Style:-->
            <template></template>
            <!-- part  Vignettes:-->
            <template v-if="layer.vignettes">
              <label class="col-2 col-md-1">Vignettes:</label>
              <div class="col-10 col-md-11 justify-content-start">
                <label
                  class="custom-control custom-checkbox justify-content-start"
                  v-for="vignette in availableVignettes"
                  :key="vignette"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    name="vignette"
                    v-model="layer.vignettes[vignette]"
                    :checked="layer.vignettes[vignette] ? 'checked' : ''"
                  />
                  <span class="custom-control-label">{{ vignette }}</span>
                </label>
              </div>
            </template>
            <!-- part Flags: -->
            <template>
              <div class="col-12 row-separator">
                <span />
                <span />
              </div>
              <label class="col-2 col-md-1">Flags:</label>
              <div class="col-10 col-md-11 justify-content-start mt-1">
                <label
                  class="custom-control custom-checkbox justify-content-start"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    v-model="layer.virtual"
                    :checked="layer.virtual ? 'checked' : ''"
                  />
                  <span class="custom-control-label">Virtual Layer</span>
                </label>
                <label
                  class="custom-control custom-checkbox justify-content-start"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    v-model="layer.hidden"
                    :checked="layer.hidden ? 'checked' : ''"
                  />
                  <span class="custom-control-label">Hide in layers list</span>
                </label>
                <label
                  class="custom-control custom-checkbox justify-content-start"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    v-model="layer.visible"
                    :checked="layer.visible ? 'checked' : ''"
                  />
                  <span class="custom-control-label">Visible by default</span>
                </label>
              </div>
            </template>
          </div>
          <div class="form-group row px-3" v-else>
            <v-jsoneditor v-model="json" @change="jsonChange" />
          </div>
          <div class="form-group row"></div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-success" @click.prevent.stop="confirmDialog">Ok</button>
          <button class="btn btn-secondary" @click.prevent.stop="cancelDialog()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../../api";
export default {
  filters: {
    geomTypeName(type) {
      switch (true) {
        case type.indexOf("POINT") > 0:
          return "Point";
        case type.indexOf("POLYGON") > 0:
          return "Polygon";
        case type.indexOf("LINESTRING") > 0:
          return "Line";
        default:
          return type;
      }
    },
    geomSize(dim) {
      switch (dim) {
        case 3:
          return "(Z)";
        case 4:
          return "(ZM)";
        default:
          return "";
      }
    },
  },
  props: {
    to: {
      default: "",
      required: true,
    },
    layer: {
      required: true,
      type: Object,
    },
    datastores: {
      type: Array,
      required: true,
    },
    availableVignettes: {
      type: Array,
      required: true,
    },
    availableSides: {
      type: Array,
      required: true,
    },
  },
  computed: {
    invalidName() {
      return false;
    },
    invalidAccess() {
      return false;
    },
    invalidTitle() {
      return false;
    },
    invalidGroup() {
      return false;
    },
  },
  updated() {
    //if (!this.tablesLoaded && !this.tablesLoading && this.layer.datastore)
    //  this.loadTables();
  },
  mounted() {
    var that = this;
    $(this.$el).on("shown.bs.modal", () => {
      that.opened();
    });
  },
  methods: {
    toggleAsJson() {
      if (!this.asJson) {
        this.json = Object.assign({}, this.layer);
        this.asJson = true;
      } else {
        this.jsonChange();
        this.asJson = false;
      }
    },
    jsonChange() {
      for (var p in this.json) {
        this.layer[p] = this.json[p];
      }
    },
    showGeometrySection(i) {
      return (
        this.layer.type === "wisdom-vector-layer" &&
        this.layer.sources[i] &&
        this.layer.sources[i].geomType
      );
    },
    open() {
      $(this.$el).modal("show");
    },
    opened() {
      this.json = Object.assign({}, this.layer);
      this.asJson = false;
      if (typeof this.layer.vignettes === "undefined") {
        this.layer.vignettes = {};
      }
      if (typeof this.layer.sources === "undefined") {
        this.layer.sources = [
          {
            datastore: this.layer.datastore,
            table: this.layer.table,
          },
        ];
        delete this.layer.datastore;
        delete this.layer.table;
      }
      for (var i = 0; i < this.layer.sources.length; i++) {
        if (typeof this.tables[this.layer.sources[i].datastore] === "undefined")
          this.loadTables(i);
      }
    },
    close() {
      $(this.$el).modal("hide");
    },
    confirmDialog() {
      if (this.asJson) {
        this.jsonChange();
      }
      this.$emit("confirm");
    },
    cancelDialog() {
      this.$emit("cancel");
    },
    datastoreChange(i) {
      this.tablesLoading = true;
      if (!this.tables[this.layer.sources[i].datastore]) {
        this.tables[this.layer.sources[i].datastore] = [];
        console.log("loading tables...");
        this.tablesLoaded = false;
        this.loadTables(i);
      }
    },
    loadTables(i) {
      if (!this.layer.sources[i].datastore) return;
      this.tables[this.layer.sources[i].datastore] = [];
      this.$http
        .get(
          api +
            "/rest/datastores/" +
            this.layer.sources[i].datastore +
            "/tables"
        )
        .then((res) => {
          this.tables[this.layer.sources[i].datastore] = res.body;
          this.tablesLoaded = true;
          this.$forceUpdate();
        })
        .catch((res) => {
          this.tablesLoaded = true;
          console.error(
            "Error getting tables for datastore with id " + this.layer.datastore
          );
        });
    },
    tableChanged(i) {
      var table = this.tables[this.layer.sources[i].datastore].filter(
        (t) => t.schema + "." + t.name == this.layer.sources[i].table
      )[0];
      if (table) {
        this.layer.sources[i].geomType = table.type;
        this.layer.sources[i].geomColumn = table.geometry_column;
        this.layer.sources[i].geomSrid = table.srid;
        this.layer.sources[i].geomDimension = table.coord_dimension;
      } else {
        this.layer.sources[i].geomType = "";
        this.layer.sources[i].geomColumn = "";
        this.layer.sources[i].geomSrid = 0;
        this.layer.sources[i].geomDimension = 0;
      }
      this.$forceUpdate();
    },
    accessChange() {
      if (this.layer.access === "direct" || this.layer.access === "instance") {
        if (this.layer.sources.length > 1) this.layer.sources.splice(1);
      } else if (this.layer.access === "vignette") {
        if (this.layer.sources.length > this.availableVignettes.length) {
          this.layer.sources.splice(this.availableVignettes.length);
        }
        while (this.layer.sources.length < this.availableVignettes.length) {
          this.layer.sources.push({
            datastore: this.layer.sources[0].datastore,
            table: this.layer.sources[0].table,
            geomType: this.layer.sources[0].geomType,
            geomColumn: this.layer.sources[0].geomColumn,
            geomSrid: this.layer.sources[0].geomSrid,
            geomDimension: this.layer.sources[0].geomSrid,
          });
        }
      } else if (this.layer.access === "side") {
        if (this.layer.sources.length > this.availableSides.length) {
          this.layer.sources.splice(this.availableSides.length);
        }
        while (this.layer.sources.length < this.availableSides.length) {
          this.layer.sources.push({
            datastore: this.layer.sources[0].datastore,
            table: this.layer.sources[0].table,
            geomType: this.layer.sources[0].geomType,
            geomColumn: this.layer.sources[0].geomColumn,
            geomSrid: this.layer.sources[0].geomSrid,
            geomDimension: this.layer.sources[0].geomSrid,
          });
        }
      } else if (this.layer.access === "vignette_side") {
        if (
          this.layer.sources.length >
          this.availableVignettes.length * this.availableSides.length
        ) {
          this.layer.sources.splice(
            this.availableVignettes.length * this.availableSides.length
          );
        }
        while (
          this.layer.sources.length <
          this.availableVignettes.length * this.availableSides.length
        ) {
          this.layer.sources.push({
            datastore: this.layer.sources[0].datastore,
            table: this.layer.sources[0].table,
            geomType: this.layer.sources[0].geomType,
            geomColumn: this.layer.sources[0].geomColumn,
            geomSrid: this.layer.sources[0].geomSrid,
            geomDimension: this.layer.sources[0].geomSrid,
          });
        }
      }
    },
    sourceLabel(i) {
      if (this.layer.access === "direct" || this.layer.access === "instance") {
        return "";
      } else if (this.layer.access === "vignette") {
        return this.availableVignettes[i] || "";
      } else if (this.layer.access === "side") {
        return this.availableSides[i].name || "";
      } else if (this.layer.access === "vignette_side") {
        var i2 = i % this.availableSides.length;
        var i1 = (i - i2) % this.availableVignettes.length;
        return `${this.availableVignettes[i1]}, ${this.availableSides[i2].name}`;
      }
    },
  },
  data() {
    return {
      asJson: false,
      tables: {},
      tablesLoading: false,
      tablesLoaded: false,
      sources: [],
      json: null,
    };
  },
};
</script>
