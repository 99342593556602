<template>
  <div class="container login">
    <h1>
      <span class="logo"></span>
    </h1>
    <form action @submit.prevent="login" class="position-relative p-2">
      <div class="alert alert-danger" v-if="message">{{ message }}</div>
      <div class="form-group">
        <label for="username">Username</label>
        <input
          id="username"
          type="text"
          class="form-control"
          name="username"
          v-model="username"
        />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          id="password"
          type="password"
          class="form-control"
          name="password"
          v-model="password"
        />
      </div>
      <div class="form-group">
        <button class="btn btn-primary" type="submit">Login</button>
      </div>
      <span class="overlay align-self-center d-flex" v-if="loading">
        <div class="overlay-background bg-white opacity-50"></div>
        <div class="spinner-border text-primary m-auto" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </span>
    </form>
  </div>
</template>
<script>
import eventBus from "./../eventBus";
import api from "./../api";
export default {
  created() {
    if (this.$route.query.expired) {
      this.message = "Your session expired, please login again.";
    }
  },
  mounted() {
    jQuery("#username").focus();
    jQuery(".modal-backdrop").remove();
  },
  data() {
    return { username: "", password: "", loading: false, message: null };
  },
  methods: {
    login() {
      if (this.loading) return;
      console.log("submitting...");
      this.loading = true;
      this.$http
        .post(api + "/auth/tokens", {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          console.log("Logged in result", res.body);
          eventBus.$emit("login", res.body);
          this.loading = false;
          this.message = null;
          var redirect = this.$route.query.redirect_uri
            ? decodeURIComponent(this.$route.query.redirect_uri)
            : "/";
          if (redirect.indexOf("/login") !== 0) {
            this.$router.push(redirect);
          } else {
            this.$router.replace("/");
          }
        })
        .catch((err) => {
          console.error("Error Logging in...", err);
          this.message = "Invalid username and/or password";
          this.loading = false;
        });
    },
  },
};
</script>
