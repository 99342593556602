<template>
  <div class="style-chooser">
    <div class="row align-items-center">
      <label class="col-2 col-md-2 justify-content-end">Type:</label>
      <select
        type="text"
        :class="{
          'col-10': true,
          'col-md-10': true,
          'form-control': true,
          'form-control-sm': true,
          'is-invalid': false,
          'mb-1': true,
        }"
        v-model="style.type"
        @change="typeChanged"
      >
        <option value="none">None</option>
        <option value="simple">Simple</option>
        <option value="by-field">By Field</option>
      </select>
    </div>
    <div class="col col-12 p-0" v-if="style.type === 'simple'">
      <style-editor
        :olstyle="style.style"
        mode="point"
        v-if="geom.indexOf('POINT') >= 0"
        @change="emitEvents"
      />
      <style-editor
        :olstyle="style.style"
        v-if="geom.indexOf('LINE') >= 0"
        @change="emitEvents"
      />
      <style-editor
        :olstyle="style.style"
        mode="polygon"
        v-if="geom.indexOf('POLYGON') >= 0"
        @change="emitEvents"
      />
    </div>
    <div class="col col-12 p-0" v-else-if="style.type === 'by-field'">
      <div class="row p-0">
        <label class="col col-2 justify-content-end">Field:</label>
        <vue-typeahead-bootstrap
          ref="scenario"
          :data="fields"
          v-model="style.field"
          input-class="form-control form-control-sm w-100"
          class="col col-10 p-0"
          @input="
            emitEvents();
            getValues();
          "
          :minMatchingChars="0"
          :showOnFocus="true"
        ></vue-typeahead-bootstrap>
        <div class="col col-3">
          <div
            v-for="(v, i) in style.values"
            :key="i"
            @click="setCurrentIndex(i)"
            :class="{
              'p-1': true,
              'style-value': true,
              active: i === currentIndex,
            }"
          >
            <vue-typeahead-bootstrap
              :data="values"
              v-model="style.values[i]"
              class="d-inline-block"
              @click="setCurrentIndex(i)"
              @input="emitEvents"
              input-class="form-control form-control-sm style-value-field"
              :minMatchingChars="0"
              :showOnFocus="true"
              style="min-width: 100px; max-width: 100px"
            ></vue-typeahead-bootstrap>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Alias"
              v-model="style.aliases[i]"
              @input="emitEvents"
              style="min-width: 100px; max-width: 100px"
            />
            <button
              class="
                btn btn-sm btn-outline-danger
                icon icon-cross
                style-value-remove
              "
              title="Remove Value"
              @click.prevent="removeValue(i)"
            ></button>
          </div>
          <div
            class="style-value p-1"
            :class="{ active: currentIndex === -1 }"
            v-if="style.defaultStyle"
            @click="setCurrentIndex(-1)"
          >
            <input
              class="form-control-sm form-control-plaintext"
              readonly
              value="Default"
              style="min-width: 100px; max-width: 100px"
            />
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Alias"
              v-model="style.defaultAlias"
              @input="emitEvents"
              style="min-width: 100px; max-width: 100px"
            />
            <button
              class="
                btn btn-sm btn-outline-danger
                icon icon-cross
                style-value-remove
              "
              title="Remove Default Style"
              @click.prevent="removeDefaultStyle()"
            ></button>
          </div>
          <button
            class="btn btn-sm btn-outline-primary icon icon-plus2"
            v-if="!style.defaultStyle"
            @click.prevent="createDefaultStyle()"
          >
            Add Default Style
          </button>
          <button
            class="
              btn btn-sm btn-outline-primary
              icon icon-plus2
              style-value-remove
            "
            title="Add Value"
            @click.prevent="addValue"
          >
            Add Field
          </button>
        </div>
        <div class="col col-9">
          <style-editor
            :olstyle="
              currentIndex === -1
                ? style.defaultStyle
                : style.styles[currentIndex]
            "
            mode="point"
            v-if="geom.indexOf('POINT') >= 0"
            @change="emitEvents"
          />
          <style-editor
            :olstyle="
              currentIndex === -1
                ? style.defaultStyle
                : style.styles[currentIndex]
            "
            v-if="geom.indexOf('LINE') >= 0"
            @change="emitEvents"
          />
          <style-editor
            :olstyle="
              currentIndex === -1
                ? style.defaultStyle
                : style.styles[currentIndex]
            "
            mode="polygon"
            v-if="geom.indexOf('POLYGON') >= 0"
            @change="emitEvents"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import stylesManager from "../../../../../xserver-client3d/src/util/stylesManager";
import { debounce } from "throttle-debounce";
import api from "../../../api";
export default {
  name: "style-chooser",
  props: {
    value: {
      default: null,
    },
    type: {
      default: "none",
    },
    geom: {
      default: "point",
    },
    datastore: {
      default: null,
    },
    table: {
      default: null,
    },
  },
  created() {
    console.log("StyleChooser created:", this.value);
    this.updateStyle();
    if (this.style.type === "by-field") {
      this.getFields();
    }
  },
  beforeUpdate() {
    console.log("StyleChooser beforeUpdate:", this.value);
    /*
    this.style.type = this.value.type;
    this.style.field = this.value.field || null;
    this.style.style = stylesManager.fromObject(this.value.style);
    this.style.values = [];
    this.style.styles = [];
    this.value.values.forEach(v => this.style.values.push(v));
    this.value.styles.forEach(v =>
      this.style.styles.push(stylesManager.fromObject(v))
    );
    */
  },
  methods: {
    getFields: debounce(500, function() {
      console.log("getting fields...");
      this.$http
        .get(
          api +
            "/rest/datastores/" +
            encodeURIComponent(this.datastore) +
            "/tables/" +
            encodeURIComponent(this.table) +
            "/fields?q=" +
            encodeURIComponent(this.style.field)
        )
        .then(res => {
          this.fields = res.body;
          console.log("fields:", res.body);
        })
        .catch(err => {
          console.error("Error getting fields ", err);
        });
    }),
    getValues: debounce(500, function() {
      console.log("getting values...");
      this.$http
        .get(
          api +
            "/rest/datastores/" +
            encodeURIComponent(this.datastore) +
            "/tables/" +
            encodeURIComponent(this.table) +
            "/fields/" +
            encodeURIComponent(this.style.field) +
            "/values"
        )
        .then(res => {
          this.values = res.body.map(v => v + "");
          console.log("values:", res.body);
        })
        .catch(err => {
          console.error("Error getting values ", err);
          this.values = [];
        });
    }),
    createStyleAt(i) {
      this.style.styles[i] = this.randomStyle();
      this.$forceUpdate();
    },
    createDefaultStyle() {
      this.style.defaultStyle = this.randomStyle();
      this.style.defaultAlias = "";
      this.currentIndex = -1;
      this.emitEvents();
      this.$forceUpdate();
    },
    removeDefaultStyle() {
      if (typeof this.style.defaultStyle != "undefined") {
        delete this.style.defaultStyle;
      }
      if (typeof this.style.defaultAlias != "undefined") {
        delete this.style.defaultAlias;
      }
      if (this.currentIndex === -1) this.currentIndex = -2;
      this.emitEvents();
      this.$forceUpdate();
    },
    setCurrentIndex(i) {
      this.currentIndex = i;
    },
    addValue() {
      this.style.values.push("");
      this.style.aliases.push("");
      this.style.styles.push(this.randomStyle());
      this.currentIndex = this.style.values.length - 1;
      this.emitEvents();
    },
    removeValue(i) {
      this.style.values.splice(i, 1);
      this.style.aliases.splice(i, 1);
      this.style.styles.splice(i, 1);
      if (this.currentIndex >= this.style.values.length)
        this.currentIndex = this.style.values.length - 1;
      this.emitEvents();
    },
    updateStyle() {
      console.log("StyleChooser updateStyle:", this.style);
      if (!this.value) {
        this.style.type = "none";
        this.style.style = null;
        this.style.values = [];
        this.style.styles = [];
        this.style.aliases = [];
      } else {
        this.style.type = this.value.type || "simple";
        this.style.field = this.value.field || "";
        this.style.style = stylesManager.fromObject(
          this.value.style || this.value
        );
        if (this.style.type === "simple" && !this.style.style) {
          this.style.style = this.randomStyle();
        }
        this.style.values = [];
        this.style.styles = [];
        this.style.aliases = [];
        if (this.value.values) {
          this.value.values.forEach(v => this.style.values.push(v));
        }
        if (this.value.styles) {
          this.value.styles.forEach(v =>
            this.style.styles.push(stylesManager.fromObject(v))
          );
        }
        if (this.value.aliases) {
          this.value.aliases.forEach(a => this.style.aliases.push(a));
        }
        if (this.value.defaultStyle) {
          this.style.defaultStyle = stylesManager.fromObject(
            this.value.defaultStyle
          );
          this.style.defaultAlias = this.value.defaultAlias;
        }
      }
    },
    typeChanged(evt) {
      if (this.style.type === "simple" && !this.style.style) {
        this.style.style = this.randomStyle();
      }
      if (this.style.type === "by-field") {
        this.getFields();
      }
      this.emitEvents();
    },
    emitEvents() {
      const newValue = {
        type: this.style.type,
      };
      if (this.style.type === "simple") {
        newValue.style = stylesManager.toObject(this.style.style);
      } else if (this.style.type === "by-field") {
        newValue.values = [];
        newValue.styles = [];
        newValue.aliases = [];
        if (this.style.defaultStyle) {
          newValue.defaultStyle = stylesManager.toObject(
            this.style.defaultStyle
          );
          newValue.defaultAlias = this.style.defaultAlias;
        }
        newValue.field = this.style.field || "";
        this.style.values.forEach(v => {
          newValue.values.push(v);
        });
        this.style.styles.forEach(s => {
          newValue.styles.push(s ? stylesManager.toObject(s) : null);
        });
        if (this.style.aliases) {
          this.style.aliases.forEach(a => newValue.aliases.push(a));
        }
      }
      this.$emit("input", newValue);
      this.$emit("change", newValue, this.value);
    },
    fieldChanged() {
      this.emitEvents();
      console.log("StyleChooser fieldChanged:", this.style.field);
    },
    randomStyle() {
      if (this.geom.indexOf("POINT") >= 0) {
        return stylesManager.fromObject({
          image: {
            type: "circle",
            radius: 5,
            fill: { color: "rgba(255,255,255,0.4)" },
            stroke: { width: 1.25, color: "rgba(70,140,230,1)" },
          },
        });
      } else if (this.geom.indexOf("LINE") >= 0) {
        return stylesManager.fromObject({
          stroke: { width: 1.25, color: "rgba(70,140,230,1)" },
        });
      } else if (this.geom.indexOf("POLYGON") >= 0) {
        return stylesManager.fromObject({
          fill: { color: "rgba(255,255,255,0.4)" },
          stroke: { width: 1.25, color: "rgba(70,140,230,1)" },
        });
      }
    },
  },
  watch: {
    value(newValue, oldValue) {
      console.log("StyleChooser value changed:", this.value);
      this.updateStyle();
    },
  },
  data() {
    return {
      style: {
        type: "none",
        style: null,
        field: "",
        values: [],
        styles: [],
      },
      currentIndex: -2,
      fields: [],
      values: [],
    };
  },
};
</script>
