<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1 class="d-inline-block align-middle">Data Export</h1>
        <help-popup :name="'Data Export'"></help-popup>
        <div class="alert alert-danger" v-if="errors.length">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>
        <div class="map-container">
          <div id="export-map" class="map export-map"></div>
        </div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="{ active: tab === 'map' }" href="#" @click.stop.prevent="setTab('map')">Map</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: tab === 'vector' }" href="#"
              @click.stop.prevent="setTab('vector')">Vector Export</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: tab === 'raster' }" href="#"
              @click.stop.prevent="setTab('raster')">Raster Export</a>
          </li>
        </ul>
        <div class="form-row m-0" v-if="tab === 'map'">
          <div class="col-12">
            <br />
            <button class="btn btn-primary" @click="useMapExtent">
              Map Extent
            </button>
            <button class="btn btn-primary" @click="useWorldExtent">
              World Extent
            </button>
            <br />
          </div>
          <div class="form-group col-md-6 col-lg-3">
            <label for="minX">Min X</label>
            <input type="number" step="any" v-model.number="mapBoundary.minX" name="minX" class="form-control"
              @input="updateMapGeometry" />
          </div>
          <div class="form-group col-md-6 col-lg-3">
            <label for="minY">Min Y</label>
            <input type="number" step="any" v-model.number="mapBoundary.minY" name="minY" class="form-control"
              @input="updateMapGeometry" />
          </div>
          <div class="form-group col-md-6 col-lg-3">
            <label for="maxX">Max X</label>
            <input type="number" step="any" v-model.number="mapBoundary.maxX" name="maxX" class="form-control"
              @input="updateMapGeometry" />
          </div>
          <div class="form-group col-md-6 col-lg-3">
            <label for="maxY">Max Y</label>
            <input type="number" step="any" v-model.number="mapBoundary.maxY" name="maxY" class="form-control"
              @input="updateMapGeometry" />
          </div>
        </div>
        <div v-if="tab === 'vector'">
          <div class="row col-12 m-0 p-0 mt-3">
            <select name="sourceType" class="col-md-3 form-control form-control-sm" v-model="sourceType"
              @change="typeChange()">
              <option selected value>Select Type of DataSource...</option>
              <option style="font-weight: bold" value="datastore">
                Datastore
              </option>
              <option style="font-weight: bold" value="instance">
                Instance
              </option>
            </select>
            <!--<label class="col-md-2 justify-content-end">DataSource:</label>-->
            <select name="source" class="col-md-3 form-control form-control-sm" v-model="source"
              @change="dataSourceChange(source)" v-if="sourceType !== ''">
              <option :value="null">Select DataSource...</option>
              <option style="font-weight: bold" v-for="source in sources.filter((source) => {
                return source.type === sourceType;
              })" :key="source.id" :value="source">
                {{ source.name }}
              </option>
            </select>
            <!--<label class="col-md-2 justify-content-end">Table/Layer:</label>-->
            <select name="table" class="col-md-3 form-control form-control-sm" v-model="table" :title="table"
              v-if="source">
              <option v-if="sourceType === 'datastore'" :value="null">
                Select Table...
              </option>
              <option v-else-if="sourceType === 'instance'" :value="null">
                Select Layer...
              </option>
              <option style="font-weight: bold" v-for="table in tables.filter((table) => {
                return table.type !== 'NONE';
              })" :key="table.id" :value="table">
                {{ table.name }}
              </option>
            </select>
            <select name="masaCode" class="col-md-3 form-control form-control-sm" v-model="masaCode"
              v-if="table && masaCodes.length">
              <option :value="null">Select Type Map...</option>
              <option style="font-weight: bold" v-for="masaCode in masaCodes.filter((code) => {
                return (
                  table.type
                    .toUpperCase()
                    .indexOf(code.type.toUpperCase()) !== -1
                );
              })" :key="masaCode.id" :value="masaCode">
                {{ masaCode.name }}
              </option>
            </select>
          </div>
          <hr />
          <div class="row col-12 mb-3">
            <button :disabled="!masaCode" type="button" class="btn btn-sm btn-primary"
              @click.stop.prevent="addExportRequest()">
              {{
              editIndex === -1
              ? "Add to Data Export request"
              : "Update Export request "
              }}
            </button>
            <button v-if="editIndex !== -1" type="button" class="btn btn-sm btn-warning ml-2"
              @click.stop.prevent="backRequest()">
              Cancel Update
            </button>
          </div>
          <table class="table table-sm table-striped mt-1" v-if="exportPackage.vectors && exportPackage.vectors.length">
            <thead>
              <tr>
                <th colspan="4" class="align-middle">Vector list export</th>
                <th>
                  <span class="btn btn-outline-danger" @click="cleanExportVectors()">
                    <span>Clean all requests</span>
                  </span>
                </th>
              </tr>
              <tr>
                <th scope="col"># export</th>
                <th scope="col">Data Source</th>
                <th scope="col">Table/Layer</th>
                <th scope="col">MASA code</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(request, r) in exportPackage.vectors" :key="r" class=""
                :class="{ 'table-primary': r === editIndex }">
                <th scope="row">{{ r + 1 }}</th>
                <td>
                  {{ request.source.name }}
                </td>
                <td>
                  {{ request.table.name }}
                </td>
                <td>{{ request.masaCode.name }}</td>
                <td>
                  <span class="btn btn-outline-primary" @click="updateRequest(r)">
                    <span class="icon icon-pencil4"></span>
                  </span>
                  <span class="btn btn-outline-secondary" :class="{
                    disabled: r >= exportPackage.vectors.length - 1,
                  }" @click="moveDown(r)">
                    <span class="icon icon-arrow-down2"></span>
                  </span>
                  <span class="btn btn-outline-secondary" :class="{ disabled: r === 0 }" @click="moveUp(r)">
                    <span class="icon icon-arrow-up2"></span>
                  </span>
                  <span class="btn btn-outline-danger" @click="removeRequest(r)">
                    <span class="icon icon-bin2"></span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row col-12 m-0 px-1">
          <hr class="w-100" />
          <button v-if="exportPackage.vectors.length" type="button" class="btn btn-primary" @click="exportData()">
            Export Data
          </button>
          <div class="col"></div>
          <button v-if="this.exportPackage.vectors.length" type="button" class="btn btn-sm btn-primary mr-2"
            @click="downloadExportPackage()">
            Download export package
          </button>
          <button class="btn btn-sm btn-primary" @click.stop.prevent="$refs.file.click()">
            Load export package
          </button>
          <input v-show="false" ref="file" type="file" @change="loadExportPackage($event)" accept=".json" />
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>
<script>
import AdminMenu from "../Menu.vue";
import eventBus from "../../../eventBus";
import utils from "../../../utils";
import api from "../../../api";
// for map
import olcss from "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { XYZ, Vector as VectorSource } from "ol/source";
import Draw, { createBox } from "ol/interaction/Draw";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import { ScaleLine, defaults as defaultControls } from "ol/control";
import { throttle } from "throttle-debounce";
import HelpPopup from "../../../HelpPopup.vue";

export default {
  created() {
    this.loadDatastores();
    this.loadInstances();
    this.updateMapGeometry = throttle(300, this.updateMapGeometry);
  },
  mounted() {
    this.initMap();
    this.initInteraction();
  },
  destroyed() {
    this.map.dispose();
  },
  components: {
    "admin-menu": AdminMenu,
    "help-popup": HelpPopup
  },
  data() {
    return {
      datastore: {},
      errors: [],
      datastores: [],
      loading: false,
      tables: [],
      loadingTables: false,
      loadingInstances: false,
      exporting: false,
      downloading: false,
      table: null,
      instances: [],
      instance: {},
      source: null,
      sources: [],
      request: {},
      masaCode: null,
      masaCodes: utils.MASACodes,
      sourceType: "",
      editIndex: -1,
      tab: "map",
      mapBoundary: {
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0,
        proj: "EPSG:4326",
      },
      exportPackage: {
        mapBoundary: {},
        vectors: [],
        type: "SwordExportPackage",
        version: "1.1",
      },
      exportResult: {
        outDir: "",
        shapeDirs: [],
        outZip: "",
        id: "",
        startedAt: "",
        geojsons: [],
      },
    };
  },
  methods: {
    setTab(tab) {
      this.tab = tab || "map";
    },
    loadDatastores() {
      this.loading = true;
      this.$http
        .get(api + "/rest/datastores")
        .then((res) => {
          this.loading = false;
          this.datastores = res.body;
          if (this.datastores) {
            this.datastores.map((ds) => {
              this.sources.push({
                type: "datastore",
                id: ds.id,
                name: ds.name,
              });
            });
          }
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else {
            this.errors.push("Error getting Datastores " + res.status);
          }
        });
    },
    dataSourceChange(source) {
      if (!source) return;
      this.table = null;
      this.masaCode = null;
      this.tablesLoading = true;
      console.log("loading tables...");
      this.tablesLoaded = false;
      if (source.type === "datastore") {
        this.loadTables();
      } else {
        this.loadLayers();
      }
    },
    typeChange() {
      this.source = null;
      this.table = null;
      this.masaCode = null;
    },
    loadTables() {
      this.tables = [];
      this.loadingTables = true;
      this.$http
        .get(api + "/rest/datastores/" + this.source.id + "/tables")
        .then((res) => {
          this.loadingTables = false;
          const tab = res.body;
          if (tab) {
            this.tables = tab.map((t) => ({
              id: t.schema + "." + t.name,
              name: t.schema + "." + t.name,
              type: t.type ? t.type : "NONE",
            }));
          }

          this.$forceUpdate();
        })
        .catch((res) => {
          this.loadingTables = false;
          this.errors.push(
            "Error getting tables for datastore with id " + this.source.id
          );
          console.error(
            "Error getting tables for datastore with id " + this.source.id
          );
        });
    },
    loadInstances() {
      this.loadingInstances = true;
      this.instances = [];
      this.$http
        .get(api + "/rest/instances")
        .then((res) => {
          this.instances = res.body;
          this.loadingInstances = false;
          if (this.instances) {
            this.instances.map((is) => {
              this.sources.push({
                type: "instance",
                id: is.id,
                name: is.name,
              });
            });
          }
        })
        .catch((res) => {
          this.errors.push("Error getting Instances " + res.status);
          this.loadingInstances = false;
        });
    },
    loadLayers() {
      this.loadingTables = true;
      this.tables = [];
      this.$http
        .get(api + "/rest/instances/" + this.source.id + "/layers")
        .then((res) => {
          this.tables = res.body.layers;
          this.loadingTables = false;
        })
        .catch((res) => {
          this.errors.push(
            "Error getting layers for instance with id " + this.source.id
          );
          this.loadingTables = false;
        });
    },
    addExportRequest() {
      let pack = {
        source: this.source,
        table: this.table,
        masaCode: this.masaCode,
      };
      if (this.editIndex === -1) {
        this.exportPackage.vectors.push(pack);
      } else {
        this.exportPackage.vectors.splice(this.editIndex, 1, pack);
        this.editIndex = -1;
      }
    },
    cleanExportVectors() {
      this.exportPackage.vectors = [];
    },
    removeRequest(i) {
      this.exportPackage.vectors.splice(i, 1);
    },
    moveDown(i) {
      if (i >= this.exportPackage.vectors.length) return;
      let reqMoved = this.exportPackage.vectors.splice(i, 1)[0];
      this.exportPackage.vectors.splice(i + 1, 0, reqMoved);
    },
    moveUp(i) {
      if (i === 0) return;
      let reqMoved = this.exportPackage.vectors.splice(i, 1)[0];
      this.exportPackage.vectors.splice(i - 1, 0, reqMoved);
    },
    updateRequest(i) {
      this.editIndex = i;
    },
    backRequest() {
      this.editIndex = -1;
    },
    downloadExportPackage() {
      if (!this.exportPackage.vectors || !this.exportPackage.vectors.length) {
        this.errors.push("Export Package is empty.");
        return;
      }
      let error = this.checkMapBoundary();
      if (error) {
        this.errors.push(error);
        return;
      }
      this.exportPackage.mapBoundary = this.mapBoundary;
      const file = new Blob([JSON.stringify(this.exportPackage, null, 2)], {
        type: "application/json",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = "export_package";
      link.click();
      URL.revokeObjectURL(link.href);
    },
    loadExportPackage(evt) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.parseExportPackage(e.target.result);
      };
      reader.readAsText(evt.target.files[0]);
    },
    parseExportPackage(data) {
      let content = JSON.parse(data);
      if (
        content &&
        content.type === "SwordExportPackage" &&
        content.version === "1.1" &&
        Array.isArray(content.vectors) &&
        content.mapBoundary
      ) {
        this.exportPackage = content;
        this.mapBoundary = this.exportPackage.mapBoundary;
        this.updateMapGeometry();
      } else {
        console.error("File loaded is not a valid export package...");
        this.$snotify.error(
          "File loaded is not a valid export package...",
          "Error"
        );
      }
    },
    exportData() {
      let error = this.checkMapBoundary();
      if (error) {
        this.errors.push(error);
        return;
      }
      this.exportPackage.mapBoundary = this.mapBoundary;
      // call microservice
      this.exporting = true;
      this.$http
        .post(api + "/rest/export/sword", this.exportPackage)
        .then((res) => {
          this.exportResult = res.body;
          this.downloadExport(this.exportResult);
        })
        .catch((res) => {
          //TODO apply errors
          this.errors.push("Error calling export microservice :" + res.status);
          /* if (res.status === 401) {
            eventBus.$emit("logout");
          } */
        })
        .finally(() => {
          this.exporting = false;
        });
    },
    downloadExport(zip) {
      this.downloading = true;
      this.errors = [];
      this.$http
        .get(api + "/rest/export/sword/" + zip.id + "/download/" + zip.outZip, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          let anchor = document.createElement("a");
          const downloadFilename = res.headers
            .get("content-disposition")
            .split("filename=")[1]
            .split(";")[0]
            .replace(/(^"|"$)/g, "");
          const contentType = res.headers.get("content-type");
          const blob = new Blob([res.body], { type: contentType });
          let objectUrl = window.URL.createObjectURL(blob);

          anchor.href = objectUrl;
          anchor.download = zip.outZip;
          anchor.click();

          window.URL.revokeObjectURL(objectUrl);
        })
        .catch((error) => {
          this.errors.push("Error downloading export file");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    initMap() {
      const vec_source = new VectorSource({ wrapX: false });
      this.vector = new VectorLayer({
        source: vec_source,
      });
      this.map = new Map({
        controls: defaultControls().extend([
          new ScaleLine({
            units: "degrees",
          }),
        ]),
        view: new View({
          projection: "EPSG:4326",
          center: [0, 0],
          zoom: 1,
        }),
        layers: [
          new TileLayer({
            source: new XYZ({
              url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
              maxZoom: 17,
            }),
          }),
          this.vector,
        ],
        target: "export-map",
      });
    },
    initInteraction() {
      const vec_source = this.vector.getSource();
      this.draw = new Draw({
        source: vec_source,
        type: "Circle",
        geometryFunction: createBox(),
      });

      this.map.addInteraction(this.draw);
      this.draw.on("drawend", this.onDrawend.bind(this));
    },
    onDrawend(evt) {
      const vec_source = this.vector.getSource();
      const ex = evt.feature.getGeometry().getExtent();
      this.mapBoundary.minX = ex[0];
      this.mapBoundary.minY = ex[1];
      this.mapBoundary.maxX = ex[2];
      this.mapBoundary.maxY = ex[3];
      if (vec_source.getFeatures().length > 0) vec_source.clear();
    },
    updateMapGeometry() {
      const vec_source = this.vector.getSource();
      const features = vec_source.getFeatures();
      const { minX: x, minY: y, maxX: X, maxY: Y } = this.mapBoundary;
      const geometry = new Polygon([
        [
          [x, y],
          [x, Y],
          [X, Y],
          [X, y],
          [x, y],
        ],
      ]);
      if (features.length === 0) {
        const feature = new Feature({ geometry });
        vec_source.addFeature(feature);
      } else {
        const feature = features[0];
        feature.setGeometry(geometry);
      }
    },
    useMapExtent() {
      const exMap = this.map.previousExtent_;
      this.mapBoundary.minX = exMap[0];
      this.mapBoundary.minY = exMap[1];
      this.mapBoundary.maxX = exMap[2];
      this.mapBoundary.maxY = exMap[3];
      this.updateMapGeometry();
    },
    useWorldExtent() {
      //const ex1 = [-20026376.39, -20048966.1, 20026376.39, 20048966.1]; //EPSG:3857
      const ex1 = [-180.0, -90.0, 180.0, 90.0];
      this.mapBoundary.minX = ex1[0];
      this.mapBoundary.minY = ex1[1];
      this.mapBoundary.maxX = ex1[2];
      this.mapBoundary.maxY = ex1[3];
      this.updateMapGeometry();
      const geom = this.vector.getSource().getFeatures()[0].getGeometry();
      this.map.getView().fit(geom, { size: this.map.getSize() });
    },
    checkMapBoundary() {
      let error = "";
      if (this.mapBoundary.minX > this.mapBoundary.maxX) {
        error = "Min X is greater than Max X";
      } else if (this.mapBoundary.minY > this.mapBoundary.maxY) {
        error = "Min Y is greater than Max Y";
      }
      return error;
    },
  },
};
</script>
