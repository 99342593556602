<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1 class="d-inline-block align-middle">Scenarios</h1>
        <help-popup :name="'Scenarios'"></help-popup>
        <div class="alert alert-danger" v-if="errors.length">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>
        <div class="mb-2 mt-2">
          <router-link to="scenarios/new" class="btn btn-primary">
            <span class="icon icon-plus2"></span>
            <span class="label">New</span>
          </router-link>
        </div>
        <table class="table table-striped w-100 table-scenarious">
          <thead>
            <tr>
              <th>Name</th>
              <th>Main Layers</th>
              <th>Data Layers</th>
              <th>Group Layers</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!scenarios.length">
              <td colspan="5">No Scenario Defined</td>
            </tr>
            <tr v-for="scenario in scenarios" v-bind:key="scenario.id">
              <th>{{ scenario.name }}</th>
              <td>{{ scenario.main_layers }}</td>
              <td>{{ scenario.data_layers }}</td>
              <td>{{ scenario.group_layers }}</td>
              <td>
                <span class="btn btn-outline-primary" @click="$router.push('scenarios/' + scenario.id)">
                  <span class="icon icon-pencil4"></span>
                </span>
                <span class="btn btn-outline-danger" @click="deleteScenario(scenario)">
                  <span class="icon icon-bin2"></span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <loading :loading="loading">Loading Scenarios...</loading>
      </div>

      <confirm-dialog ref="confirmDelete" :body="
        'Are you sure do you want to delete scenario ' +
        (currentScenario && currentScenario.name) +
        '?'
      " confirmBtnClasses="btn-danger" @confirm="confirmDelete" @cancel="$refs.confirmDelete.close()">
      </confirm-dialog>
    </div>
  </div>
</template>
<script>
import MenuVue from "../Menu.vue";
import LoadingVue from "../../../components/loading/Loading.vue";
import ConfirmDialogVue from "../../../components/confirm-dialog/ConfirmDialog.vue";
import HelpPopup from "../../../HelpPopup.vue";
import api from "../../../api";
export default {
  components: {
    "admin-menu": MenuVue,
    loading: LoadingVue,
    "confirm-dialog": ConfirmDialogVue,
    "help-popup": HelpPopup
  },
  created() {
    this.loadScenarios();
  },
  methods: {
    loadScenarios() {
      this.scenarios = [];
      this.errors = [];
      this.loading = true;
      this.$http
        .get(api + "/rest/scenarios")
        .then((res) => {
          this.scenarios = res.body;
          this.loading = false;
        })
        .catch((res) => {
          console.error("Error getting scenarios", res.body);
          this.errors = ["Unexpected error loading scenarios"];
          this.loading = false;
        });
    },
    deleteScenario(scenario) {
      this.currentScenario = scenario;
      this.$refs.confirmDelete.open();
    },
    confirmDelete() {
      this.$refs.confirmDelete.close();
      if (this.currentScenario) {
        this.loading = true;
        this.errors = [];
        this.$http
          .delete(api + "/rest/scenarios/" + this.currentScenario.id)
          .then((res) => {
            this.loading = false;
            this.loadScenarios();
          })
          .catch((res) => {
            this.loading = false;
            if (res.body && res.body.error)
              this.errors = [res.body.error.message];
            else if (res.body.errors)
              this.errors = res.body.errors.map((e) => e.message);
            else this.errors = ["Unexpected error deleting scenario"];
          });
      }
    },
  },
  data() {
    return { errors: [], scenarios: [], loading: false, currentScenario: null };
  },
};
</script>
