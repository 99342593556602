<template>
  <div class="layer-editor px-2">
    <div
      :class="{
        btn: true,
        'btn-outline-secondary': true,
        active: asJson,
        'mb-1': true,
      }"
      @click.prevent.stop="toggleAsJson"
    >
      JSON
    </div>
    <div class="btn btn-outline-secondary mb-1" @click.prevent="moveLayerDown">
      <span class="icon icon-arrow-down2" />
    </div>
    <div class="btn btn-outline-secondary mb-1" @click.prevent="moveLayerUp">
      <span class="icon icon-arrow-up2" />
    </div>
    <div class="btn btn-outline-secondary mb-1" @click.prevent="duplicateLayer">
      <span class="icon icon-plus2" />
    </div>
    <div
      class="btn btn-outline-secondary mb-1"
      @click.prevent.stop="openMenu()"
    >
      <span class="icon icon-menu7" />
      <div
        ref="contextMenu"
        :class="{
          'dropdown-menu': true,
          'dropdown-menu-sm': true,
          show: menuOpened,
        }"
      >
        <a
          class="dropdown-item"
          href="#"
          v-for="path in paths"
          :key="path"
          @click.prevent.stop="moveLayerTo(path)"
          >{{ path }}</a
        >
      </div>
    </div>
    <div class="btn btn-outline-danger mb-1" @click.prevent="deleteLayer">
      <span class="icon icon-bin2" />
    </div>
    <div class="form-group row pl-2 pr-2" v-if="!asJson">
      <!-- part one TO:-->
      <template>
        <label class="col-2 col-md-2 justify-content-end">Path:</label>
        <label class="col-10 col-md-10 justify-content-start pl-1 pr-1">
          {{ layerPath || "/" }}
        </label>
      </template>
      <!-- part Name:, Type:-->
      <template>
        <label class="col-2 col-md-2 justify-content-end">Title:</label>
        <input
          type="text"
          :class="{
            'col-10': true,
            'col-md-4': true,
            'form-control': true,
            'form-control-sm': true,
            'is-invalid': invalidTitle,
            'mb-1': true,
          }"
          v-model="layer.title"
          @input="titleChange()"
        />

        <label class="col-2 col-md-2 justify-content-end">Type:</label>
        <select
          type="text"
          :class="{
            'col-10': true,
            'col-md-4': true,
            'form-control': true,
            'form-control-sm': true,
            'is-invalid': invalidType,
            'mb-1': true,
          }"
          v-model="layer.type"
          @change="updateFields()"
        >
          <option :selected="!layer.type" :value="''" disabled>
            Layer Type...
          </option>
          <option :selected="!layer.type == 'group'" value="group">
            Group
          </option>
          <option
            :selected="!layer.type == 'wisdom-vector-layer'"
            value="wisdom-vector-layer"
          >
            Vector Layer
          </option>
          <option :selected="!layer.type == 'wms'" value="wms">
            Raster - WMS
          </option>
          <option :selected="!layer.type == 'wmts'" value="wmts">
            Raster - WMTS
          </option>
        </select>
      </template>
      <!-- part Access:-->
      <template v-if="layer.type === 'wisdom-vector-layer'">
        <label class="col-2 col-md-2 justify-content-end">Access:</label>
        <select
          type="text"
          :class="{
            'col-10': true,
            'col-md-10': true,
            'form-control': true,
            'form-control-sm': true,
            'mb-1': true,
            'is-invalid': invalidAccess,
          }"
          v-model="layer.access"
          @change="accessChange()"
        >
          <option :value="''" disabled>Access Type...</option>
          <option value="direct">Direct</option>
          <option value="instance">Instance</option>
          <option value="vignette">Vignette</option>
          <option value="side">Side</option>
          <option value="vignette_side">Vignette + Side</option>
        </select>
        <label class="col-2 col-md-2 justify-content-end">Load Strategy:</label>
        <select
          type="text"
          :class="{
            'col-10': true,
            'col-md-10': true,
            'form-control': true,
            'form-control-sm': true,
          }"
          v-model="strategy"
          @change="strategyChange()"
        >
          <option value="all">All</option>
          <option value="bbox">BBOX</option>
        </select>
      </template>
      <!-- part restrict visibility-->
      <template v-if="layer.type !== 'group'">
        <label class="col-2 col-md-2 justify-content-end"
          >Restrict Visibility:</label
        >
        <div class="col-10 col-md-10 justify-content-start px-0">
          <label
            class="custom-control custom-checkbox justify-content-start d-block"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              name="vignette"
              v-model="restrictVisibility"
              :checked="restrictVisibility ? 'checked' : ''"
              @change="restrictVisibilityChange()"
            />
            <span class="custom-control-label"></span>
          </label>
        </div>
        <label
          v-if="restrictVisibility"
          class="col col-2 col-md-2 justify-content-end"
          >Visibility Res:</label
        >
        <input
          v-if="restrictVisibility"
          class="form-control form-control-sm col-12 col-md-5"
          type="number"
          step="0.01"
          min="0.01"
          v-model="layer.minResolution"
        />
        <input
          v-if="restrictVisibility"
          class="form-control form-control-sm col-12 col-md-5"
          type="number"
          step="0.01"
          min="0.01"
          v-model="layer.maxResolution"
        />
      </template>
      <!-- part WMS/WMTS/XYZ -->
      <template v-if="showWMSSection(layer)">
        <label class="col-2 col-md-2 justify-content-end">URL:</label>
        <input
          type="text"
          name="url"
          :placeholder="'/' + layer.type + '/world'"
          class="form-control form-control-sm col-10 col-md-10 mb-1"
          :class="{ 'is-invalid': !layer.url }"
          v-model="layer.url"
        />
        <template v-if="layer.type === 'wms' || layer.type === 'wmts'">
          <label class="col-2 col-md-2 justify-content-end">Layer:</label>
          <input
            type="text"
            name="url"
            placeholder="world"
            class="form-control form-control-sm col-10 col-md-10 mb-1"
            v-model="layer.layer"
          />
        </template>
      </template>
      <template v-if="has3d && $store.state.serverInfo.features.buildings3d">
        <label for class="form-control-label col-2 justify-content-start">
          3D Tile
        </label>
        <vue-typeahead-bootstrap
          :data="tiles"
          v-model="layer.tileset"
          class="col-10 px-0"
          input-class="form-control form-control-sm"
          :minMatchingChars="0"
          :showOnFocus="true"
        ></vue-typeahead-bootstrap>
      </template>
      <!-- part Source: Table:-->
      <div class="col-12 row-separator">
        <span />
        <span />
      </div>
      <div class="col col-12">
        <div class="row" v-for="(source, i) in layer.sources" :key="i">
          <template
            v-if="
              layer.type === 'wisdom-vector-layer' ||
              layer.type === 'vector-layer'
            "
          >
            <label class="col col-12 justify-content-start">
              <b>Source {{ sourceLabel(i) }}</b>
            </label>
            <label class="col col-2 col-md-2 justify-content-end">DS:</label>
            <select
              name="datastore"
              class="col col-10 col-md-4 form-control form-control-sm"
              v-model="source.datastore"
              @change="datastoreChange(i)"
            >
              <option :value="''" disabled selected>Select Datastore...</option>
              <option
                v-for="datastore in datastores"
                :key="datastore.id"
                :value="datastore.id"
              >
                {{ datastore.name }}
              </option>
            </select>
            <label class="col col-2 col-md-2 justify-content-end">Table:</label>
            <select
              name="table"
              class="col col-10 col-md-4 form-control form-control-sm"
              v-model="source.table"
              :title="source.table"
              @change="tableChanged(i)"
            >
              <option :value="''" disabled selected>Select Table...</option>
              <option
                v-for="table in tables[source.datastore]"
                :key="table.schema + '.' + table.name"
                :value="table.schema + '.' + table.name"
              >
                {{ table.schema + "." + table.name }}
              </option>
            </select>
          </template>
          <!-- part Geom:, SRID: -->
          <template v-if="showGeometrySection(i)">
            <label class="col-2 col-md-2 justify-content-end">Geom:</label>
            <label
              class="col-10 col-md-4 justify-content-start pl-1 pr-1 bg-white"
            >
              {{ source.geomType | geomTypeName
              }}{{ source.geomDimension | geomSize }}
            </label>
            <label class="col-2 col-md-2 justify-content-end">SRID:</label>
            <label
              class="col-10 col-md-4 justify-content-start pl-1 pr-1 bg-white"
              >{{ source.geomSrid }}</label
            >
          </template>
          <!--BPAG-->
          	<template v-if="layer.jammingLayers && layer.type=='wisdom-vector-layer'&& layer.access=='direct'">
          <!---<label class="col-2 col-md-2 justify-content-end">ID:</label>
            <input
              type="text"
              name="id_jamming"
              placeholder="id"
              class="form-control form-control-sm col-10 col-md-10 mb-1"
              v-model="source.id_jamming"
            />-->
            <label class="col-2 col-md-2 justify-content-end">LifeTime:</label>
            <input
              type="number"
              name="time_jamming"
              placeholder="LifeTime"
              class="form-control form-control-sm col-10 col-md-10 mb-1"
              :class="{ 'is-invalid': !source.time_jamming }"         

              v-model="source.time_jamming"
            />
    
          </template>
        </div>
      </div>
      <!-- part Style:-->
      <template v-if="layer.type === 'wisdom-vector-layer'">
        <div class="col-12 row-separator">
          <span />
          <span />
        </div>
        <span class="col col-12">Style:</span>
        <span class="col col-12">
          <style-chooser
            v-model="layer.style"
            :geom="layer.sources[0].geomType"
            :datastore="layer.sources[0].datastore"
            :table="layer.sources[0].table"
          />
        </span>
        <div class="col-12 row-separator">
          <span />
          <span />
        </div>
      </template>
      <!-- part Popup: -->
      <template
        v-if="
          layer.type === 'wisdom-vector-layer' ||
          layer.type === 'wms' ||
          layer.type === 'wmts'
        "
      >
        <div class="col col-12 justify-content-start">Popup</div>
        <div class="col col-12">
          <label class="custom-control custom-checkbox justify-content-start">
            <input
              type="checkbox"
              class="custom-control-input"
              name="vignette"
              v-model="layer.popup.enabled"
            />
            <span class="custom-control-label">Enabled</span>
          </label>
        </div>
        <label
          class="col-2 col-md-2 justify-content-start"
          justify-content-start
          >Type:</label
        >
        <select
          type="text"
          class="col-10 col-md-10 form-control form-control-sm mb-1"
          v-model="layer.popup.type"
        >
          <option :selected="!layer.popup.type" :value="''">Classic</option>
          <option
            :selected="layer.popup.type == 'resilienceFactor'"
            value="resilienceFactor"
          >
            Resilience Factor
          </option>
          <option :selected="layer.popup.type == 'link'" value="link">
            Link
          </option>
          <option :selected="layer.popup.type == 'html'" value="html">
            Html
          </option>
        </select>
        <label for class="form-control-label col-2 justify-content-start"
          >Highlight Field</label
        >
        <vue-typeahead-bootstrap
          :data="fields"
          v-model="layer.popup.highlight"
          class="col-10 px-0"
          input-class="form-control form-control-sm"
          :minMatchingChars="0"
          :showOnFocus="true"
        ></vue-typeahead-bootstrap>
        <div class="col col-12">
          <label class="custom-control custom-checkbox justify-content-start">
            <input
              type="checkbox"
              class="custom-control-input"
              name="vignette"
              :checked="layer.popup.fields ? false : true"
              @change="popupAllFieldChange"
            />
            <span class="custom-control-label">All Fields</span>
          </label>
        </div>
        <div class="col col-12" v-if="layer.popup.fields">
          <div
            class="row align-items-center px-3"
            v-for="(field, i) in layer.popup.fields"
            :key="i"
          >
            <vue-typeahead-bootstrap
              :data="fields"
              v-model="field.name"
              class="col col-2 px-0"
              input-class="form-control form-control-sm"
              :minMatchingChars="0"
              :showOnFocus="true"
            ></vue-typeahead-bootstrap>
            <input
              type="text"
              v-model="field.alias"
              class="form-control form-control-sm col col-2"
            />
            <select
              type="text"
              v-model="field.type"
              class="form-control form-control-sm col col-2"
              @change="fieldTypeChange(i, field)"
            >
              <option value>Auto</option>
              <option value="boolean">Boolean</option>
            </select>
            <div class="col col-5">
              <div
                class="row align-items-center p"
                v-if="field.type === 'boolean'"
              >
                <div class="col col-4">True:</div>
                <input
                  type="text"
                  class="form-control form-control-sm col col-8"
                  v-model="field.options.true"
                />
                <div class="col col-4">False:</div>
                <input
                  type="text"
                  class="form-control form-control-sm col col-8"
                  v-model="field.options.false"
                />
              </div>
            </div>
            <div class="col col-1">
              <button
                class="btn btn-sm btn-outline-danger icon-bin2"
                @click.prevent="removeField(i, field)"
              ></button>
            </div>
          </div>
          <div class="row align-items-center px-3">
            <div class="col col-12">
              <button
                class="btn btn-sm btn-outline-primary icon icon-plus2"
                @click.prevent="addField"
              >
                Add Field
              </button>
            </div>
          </div>
        </div>
       
      </template>
      
      <template v-if="layer.jammingLayers && layer.type=='group'">
     
       <label
          
          class="col col-2 col-md-2 justify-content-end"
          >StartTime:</label
        >
            <input
               type="date" id="start" name="trip-start" 
                value="2018-06-12"
                min="1900-06-07"
                max="3000-06-14"
              class="form-control form-control-sm col-12 col-md-5"
              v-model="startDate"
            />
              <input id="appt-time" type="time" name="appt-time" step="2"
               class="form-control form-control-sm col-12 col-md-5"
              v-model="startTime" />

           <div class="col-12 row-separator">
          <span />
          <span />
        </div>
      </template>
       

      <!-- part  Vignettes:-->
      <template v-if="layer.vignettes">
        <label class="col-2 col-md-2 justify-content-end">Vignettes:</label>
        <div class="col-10 col-md-10 justify-content-start">
          <label
            class="custom-control custom-checkbox justify-content-start"
            v-for="vignette in availableVignettes"
            :key="vignette"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              name="vignette"
              v-model="layer.vignettes[vignette]"
              :checked="layer.vignettes[vignette] ? 'checked' : ''"
            />
            <span class="custom-control-label">{{ vignette }}</span>
          </label>
        </div>
      </template>
      <!-- part Flags: -->
      <template>
        <div class="col-12 row-separator">
          <span />
          <span />
        </div>
        <label class="col-2 col-md-2 justify-content-end">Flags:</label>
        <div class="col-10 col-md-10 justify-content-start mt-1">
          <label class="custom-control custom-checkbox justify-content-start">
            <input
              type="checkbox"
              class="custom-control-input"
              v-model="layer.virtual"
              :checked="layer.virtual ? 'checked' : ''"
            />
            <span class="custom-control-label">Virtual Layer</span>
          </label>
          <label class="custom-control custom-checkbox justify-content-start">
            <input
              type="checkbox"
              class="custom-control-input"
              v-model="layer.hidden"
              :checked="layer.hidden  ? 'checked' : ''"
            />
            <span class="custom-control-label">Hide in layers list</span>
          </label>
          <label class="custom-control custom-checkbox justify-content-start">
            <input
              type="checkbox"
              class="custom-control-input"
              v-model="layer.visible"
              :checked="layer.visible ? 'checked' : ''"
            />
            <span class="custom-control-label">Visible by default</span>
          </label>
          <label class="custom-control custom-checkbox justify-content-start">
            <input
              type="checkbox"
              class="custom-control-input"
              v-model="layer.controlled"
              :checked="layer.controlled ? 'checked' : ''"
            />
            <span class="custom-control-label">Controller Managed </span>
          </label>
          <label
            class="custom-control custom-checkbox justify-content-start"
            v-if="layer.type == 'wms'"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              v-model="layer.tiled"
              :checked="layer.tiled !== false ? 'checked' : ''"
            />
            <span class="custom-control-label">Tiled</span>
          </label>
                       <!-- part  layers-->
          <!--BPAG-->

      <label 
        class="custom-control custom-checkbox justify-content-start"
        v-if=" layer.type==='group' ||(layer.type==='wisdom-vector-layer' && layer.access==='direct') ">
          <input
            type="checkbox"
            class="custom-control-input"
            v-model="layer.jammingLayers"
          :checked="layer.jammingLayers !== false? 'checked' : ''"
          />
          <span class="custom-control-label">Jamming</span>
        <!--BPAG-->

<!--   :checked="layer.jammingLayers1 ? 'checked' : ''"-->
        </label>
        </div>
      </template>
    </div>

    <div class="form-group row px-3" v-else>
      <v-jsoneditor :value="layer" @input="jsonChange" />
    </div>
    <loading :loading="loadingTables || loadingTiles" />
  </div>
</template>
<script>
import eventBus from "../../../eventBus";
import api from "../../../api";
import { debounce } from "throttle-debounce";
export default {
  props: [
    "layer",
    "layerPath",
    "paths",
    "datastores",
    "availableVignettes",
    "availableSides",
    "has3d",
  ],
  filters: {
    geomTypeName(type) {
      switch (true) {
        case type.indexOf("POINT") > 0:
          return "Point";
        case type.indexOf("POLYGON") > 0:
          return "Polygon";
        case type.indexOf("LINESTRING") > 0:
          return "Line";
        default:
          return type;
      }
    },
    geomSize(dim) {
      switch (dim) {
        case 3:
          return "(Z)";
        case 4:
          return "(ZM)";
        default:
          return "";
      }
    },
  },
  updated() {
    this.loadMissingTables();
  },
  beforeUpdate() {
    //this.json = Object.assign({}, this.layer);
  },
  created() {
    eventBus.$on(
      "close-dropdown-menus",
      (() => {
        this.menuOpened = false;
      }).bind(this)
    );
    console.log("tabella highlight:", this.layer); // tabella che regolamenta i pop che si aprono su info layer
    if (!this.layer.popup) this.layer.popup = { enabled: false, highlight: "" };
    if (typeof this.layer.popup.highlight === "undefined")
      this.layer.popup.highlight = "";
    this.loadMissingTables();
    this.loadTiles();
    //this.json = Object.assign({}, this.layer);
  },
  computed: {
    invalidType() {
      return false;
    },
    invalidTitle() {
      return false;
    },
    invalidAccess() {
      return false;
    },
  },
  watch: {
    layer(newValue, oldValue) {
      this.updateFields();
      if (this.has3d && typeof newValue.tileset === "undefined")
        newValue.tileset = "";
    },
  },
  methods: {
    loadTiles() {
      if (!this.$store.state.serverInfo.features.buildings3d) return;
      this.loadingTiles = true;
      this.$http
        .get(api + "/rest/3dtiles")
        .then((res) => {
          this.tiles = res.body.map((t) => {
            return {
              id: t.id,
              name: t.name,
            };
          });
        })
        .catch((res) => {
          //TODO apply errors
          if (res.status === 401) {
            eventBus.$emit("logout");
          }
        })
        .finally(() => {
          this.loadingTiles = false;
        });
    },
    updateFields() {
      if (this.layer.type === "wms" && this.layer.tiled === undefined)
        this.layer.tiled = true;
      if (!this.layer.popup)
        this.layer.popup = { enabled: false, highlight: "" };
      if (typeof this.layer.popup.highlight === "undefined")
        this.layer.popup.highlight = "";
      if (this.layer.strategy) this.strategy = this.layer.strategy;
      else this.strategy = "all";
      if (
        this.layer.minResolution !== undefined ||
        this.layer.maxResolution !== undefined
      ) {
        this.restrictVisibility = true;
      } else {
        this.restrictVisibility = false;
      }
      //gra
      if (this.layer.parent_id !== null){
        if(this.layer.parent_id.jammingLayers){
          if(this.layer.type === "wisdom-vector-layer" && this.layer.access==="direct" && this.layer.children){
              this.layer.jammingLayers = true;              
          }
        }
      }
    },
    showWMSSection(layer) {
      return (
        layer.type === "wms" || layer.type === "wmts" || layer.type === "xyz"
      );
    },
    getFields: debounce(500, function () {
      console.log("getting fields...");
      this.$http
        .get(
          api +
            "/rest/datastores/" +
            encodeURIComponent(this.layer.sources[0].datastore) +
            "/tables/" +
            encodeURIComponent(this.layer.sources[0].table) +
            "/fields"
        )
        .then((res) => {
          this.fields = res.body;
          console.log("fields:", res.body);
        })
        .catch((err) => {
          console.error("Error getting fields", err);
          this.fields = [];
        });
    }),
    addField() {
      this.layer.popup.fields.push({ name: "", alias: "", type: "" });
      this.$forceUpdate();
    },
    removeField(i, field) {
      this.layer.popup.fields.splice(i, 1);
      this.$forceUpdate();
    },
    popupAllFieldChange(evt) {
      if (evt.currentTarget.checked) {
        if (this.layer.popup.fields) delete this.layer.popup.fields;
      } else {
        this.layer.popup.fields = [];
      }
      this.$forceUpdate();
    },
    fieldTypeChange(i, field) {
      if (field.type === "boolean") {
        field.options = { trueValue: "true", falseValue: "false" };
      } else if (field.type === "") {
        if (field.options) delete field.options;
      }
      this.$forceUpdate();
    },
    moveLayerTo(path) {
      this.$emit("menu-move-to", {
        layer: this.layer,
        layerPath: this.layerPath,
        to: path,
      });
      this.menuOpened = false;
    },
    openMenu() {
      this.menuOpened = true;
    },
    deleteLayer() {
      this.$emit("menu-delete", {
        layer: this.layer,
        layerPath: this.layerPath,
      });
    },
    duplicateLayer() {
      this.$emit("menu-duplicate", {
        layer: this.layer,
        layerPath: this.layerPath,
      });
    },
    moveLayerDown() {
      this.$emit("menu-move-down", {
        layer: this.layer,
        layerPath: this.layerPath,
      });
    },
    moveLayerUp() {
      this.$emit("menu-move-up", {
        layer: this.layer,
        layerPath: this.layerPath,
      });
    },
    loadMissingTables() {
      this.layer.sources.forEach((s, i) => {
        if (typeof this.tables[s.datastore] === "undefined")
          this.datastoreChange(i);
      });
    },
    accessChange() {
      if (this.layer.access === "direct" || this.layer.access === "instance") {
        if (this.layer.sources.length > 1) this.layer.sources.splice(1);
      } else if (this.layer.access === "vignette") {
        if (this.layer.sources.length > this.availableVignettes.length) {
          this.layer.sources.splice(this.availableVignettes.length);
        }
        while (this.layer.sources.length < this.availableVignettes.length) {
          this.layer.sources.push({
            datastore: this.layer.sources[0].datastore,
            table: this.layer.sources[0].table,
            geomType: this.layer.sources[0].geomType,
            geomColumn: this.layer.sources[0].geomColumn,
            geomSrid: this.layer.sources[0].geomSrid,
            geomDimension: this.layer.sources[0].geomDimension,
          });
        }
      } else if (this.layer.access === "side") {
        if (this.layer.sources.length > this.availableSides.length) {
          this.layer.sources.splice(this.availableSides.length);
        }
        while (this.layer.sources.length < this.availableSides.length) {
          this.layer.sources.push({
            datastore: this.layer.sources[0].datastore,
            table: this.layer.sources[0].table,
            geomType: this.layer.sources[0].geomType,
            geomColumn: this.layer.sources[0].geomColumn,
            geomSrid: this.layer.sources[0].geomSrid,
            geomDimension: this.layer.sources[0].geomDimension,
          });
        }
      } else if (this.layer.access === "vignette_side") {
        if (
          this.layer.sources.length >
          this.availableVignettes.length * this.availableSides.length
        ) {
          this.layer.sources.splice(
            this.availableVignettes.length * this.availableSides.length
          );
        }
        while (
          this.layer.sources.length <
          this.availableVignettes.length * this.availableSides.length
        ) {
          this.layer.sources.push({
            datastore: this.layer.sources[0].datastore,
            table: this.layer.sources[0].table,
            geomType: this.layer.sources[0].geomType,
            geomColumn: this.layer.sources[0].geomColumn,
            geomSrid: this.layer.sources[0].geomSrid,
            geomDimension: this.layer.sources[0].geomDimension,
          });
        }
      }
    },
    showGeometrySection(i) {
      return (
        this.layer.type === "wisdom-vector-layer" &&
        this.layer.sources[i] &&
        this.layer.sources[i].geomType
      );
    },
    datastoreChange(i) {
      this.tablesLoading = true;
      if (!this.tables[this.layer.sources[i].datastore]) {
        this.tables[this.layer.sources[i].datastore] = [];
        console.log("loading tables...");
        this.tablesLoaded = false;
        this.loadTables(i);
      }
      if (i === 0) this.getFields();
    },
    loadTables(i) {
      if (!this.layer.sources[i].datastore) return;
      this.tables[this.layer.sources[i].datastore] = [];
      this.loadingTables = true;
      this.$http
        .get(
          api +
            "/rest/datastores/" +
            this.layer.sources[i].datastore +
            "/tables"
        )
        .then((res) => {
          this.loadingTables = false;
          this.tables[this.layer.sources[i].datastore] = res.body;
          this.tablesLoaded = true;
          this.$forceUpdate();
        })
        .catch((res) => {
          this.loadingTables = false;
          this.tablesLoaded = true;
          console.error(
            "Error getting tables for datastore with id " + this.layer.datastore
          );
        });
      if (i === 0) this.getFields();
    },
    tableChanged(i) {
      var table = this.tables[this.layer.sources[i].datastore].filter(
        (t) => t.schema + "." + t.name == this.layer.sources[i].table
      )[0];
      if (table) {
        this.layer.sources[i].geomType = table.type;
        this.layer.sources[i].geomColumn = table.geometry_column;
        this.layer.sources[i].geomSrid = table.srid;
        this.layer.sources[i].geomDimension = table.coord_dimension;
      } else {
        this.layer.sources[i].geomType = "";
        this.layer.sources[i].geomColumn = "";
        this.layer.sources[i].geomSrid = 0;
        this.layer.sources[i].geomDimension = 0;
      }
      if (i === 0) this.getFields();
      this.$forceUpdate();
    },
    /*oblig(evt){
      if (source.time_jamming==="") {
                    alert('Titolo obbligatorio');
                }
    },*/
    toggleAsJson() {
      if (!this.asJson) {
        //this.json = Object.assign({}, this.layer);
        this.asJson = true;
      } else {
        //this.jsonChange();
        this.asJson = false;
      }
    },
    jsonChange(json) {
      for (var p in json) {
        this.layer[p] = json[p];
      }
    },
    titleChange() {
      this.$emit("title-change");
    },
    sourceLabel(i) {
      if (this.layer.access === "direct" || this.layer.access === "instance") {
        return "";
      } else if (this.layer.access === "vignette") {
        return this.availableVignettes[i] || "";
      } else if (this.layer.access === "side") {
        return this.availableSides[i].name || "";
      } else if (this.layer.access === "vignette_side") {
        var i2 = i % this.availableSides.length;
        var i1 = (i - i2) % this.availableVignettes.length;
        return `${this.availableVignettes[i1]}, ${this.availableSides[i2].name}`;
      }
    },
    strategyChange() {
      if (this.strategy === "all") {
        if (typeof this.layer.strategy !== "undefined")
          delete this.layer.strategy;
      } else {
        this.layer.strategy = this.strategy;
      }
    },
    restrictVisibilityChange() {
      if (this.restrictVisibility) {
        if (this.layer.minResolution === undefined)
          this.layer.minResolution = 1;
        if (this.layer.maxResolution === undefined)
          this.layer.maxResolution = 1000;
      } else {
        if (this.layer.minResolution !== undefined)
          delete this.layer.minResolution;
        if (this.layer.maxResolution !== undefined)
          delete this.layer.maxResolution;
      }
    },
  },
  data() {
    return {
      fields: [],
      asJson: false,
      tables: {},
      loadingTables: false,
      loadingTiles: false,
      tiles: [],
      json: null,
      menuOpened: false,
      strategy: "all",
      restrictVisibility: false,
      controlled: false,
    };
  },
};
</script>
