<template>
  <div>
    <help-popup :class="{'isAbsolute': true,}" :name="'Events'"></help-popup>
    <div class="events-edit  w-100 p-2" v-if="scenario && scenario.content">
      <div class="d-flex" v-if="scenario.content.events">
        <span class="h2 flex-grow-1"> </span>
        <button class="btn btn-outline-danger my-1" v-if="scenario.content.events"
          @click.prevent="$refs.removeEvents.open()">
          Remove Events Section
        </button>
      </div>
      <div v-else class="d-flex">
        <button class="btn btn-outline-primary my-1" @click.prevent="createEvents()">
          Add Events Widget
        </button>
      </div>
      <events-popup-type v-if="scenario.content.events" :popup="scenario.content.events.popup"
        :markupPopupEvent="markupPopupEvent" :markupPopupEventWithTab="markupPopupEventWithTab"></events-popup-type>
      <div v-if="scenario.content.events">
        <events-source class="col col-12 mb-1" v-for="(source, i) in scenario.content.events.sources" :key="i"
          :title="scenario.content.vignettes[i]" v-model="source.records" :scenario="scenario" :datastores="datastores"
          :locations="true" @input="updateReadOnly()" />
      </div>
      <confirm-dialog ref="removeEvents"
        :body="'Are you sure do you want to delete Events section? All your events settings will be lost!'"
        confirmBtnClasses="btn-danger" @confirm="confirmRemoveEvents" @cancel="$refs.removeEvents.close()">
      </confirm-dialog>
    </div>
  </div>
</template>
<script>
import Autocomplete from "vuejs-auto-complete";
import eventBus from "../../../../eventBus";
// import api from "../../../../api";
import EventsPopupTypeVue from "./EventsPopupType.vue";
import EventsSourceVue from "./EventsSource.vue";
import { ModelListSelect } from "vue-search-select";
import HelpPopup from "../../../../HelpPopup.vue";

export default {
  components: {
    autocomplete: Autocomplete,
    "model-list-select": ModelListSelect,
    "events-popup-type": EventsPopupTypeVue,
    "events-source": EventsSourceVue,
    "help-popup": HelpPopup
  },
  filters: {},
  props: ["scenario", "datastores"],
  created() {
    eventBus.$on("scenario-vignettes-changed", this.initSources.bind(this));
    this.init();
  },
  beforeUpdate() {
    if (this.scenario.content.events === true) {
      this.createEvents();
    } else if (this.scenario.content.events === false) {
      delete this.scenario.content.events;
    }
  },
  watch: {
    scenario() {
      this.init();
    },
  },
  computed: {},
  methods: {
    init() {
      if (!this.scenario.content.events) return; // nothing to do
      this.initSources();
      this.$forceUpdate();
    },
    initSources() {
      if (Array.isArray(this.scenario.content.vignettes)) {
        if (this.scenario.content.events) {
          // add sources if missing
          while (
            this.scenario.content.events.sources.length <
            this.scenario.content.vignettes.length
          ) {
            this.scenario.content.events.sources.push({
              type: "raw",
              records: [],
            });
          }
          // remove sources if there are to many
          while (
            this.scenario.content.events.sources >
            this.scenario.content.vignettes.length
          ) {
            this.scenario.content.events.sources.pop();
          }
          // source cleanup, only type raw with records are allowed
          for (let source of this.scenario.content.events.sources) {
            if (typeof source.records === "undefined") source.records = [];
            source.type = "raw";
            for (let key in source) {
              if (key !== "type" && key !== "records") delete source[key];
            }
          }
        }
      }
    },
    confirmRemoveEvents() {
      if (this.scenario.content.hasOwnProperty("events")) {
        delete this.scenario.content.events;
      }
      this.init();
      this.$refs.removeEvents.close();
      this.$forceUpdate();
    },
    createEvents() {
      const events = {
        enabled: true,
        sources: [],
        popup: {
          type: "templated",
          template: this.markupPopupEvent,
          templateType: "classic",
        },
      };
      this.scenario.content.events = events;
      this.initSources();
      this.$forceUpdate();
    },
    updateReadOnly() {
      // const length = this.events.sources.length;
      // let readOnly = false;
      // //cycle the vignettes to see if there are any units
      // for (let i = 0; i < length; i++) {
      //   if (this.events.sources[i].records.length) {
      //     readOnly = true;
      //     break;
      //   }
      // }
      // this.readOnly = readOnly;
    },
  },
  computed: {},
  data() {
    return {
      groups: [],
      markupPopupEvent: `<table class='table table-sm table-striped orbat-properties felx-grow-1'>
    <div class="symbol_preview">{{{event.symbol_preview}}}</div>

    <tbody>
        <tr>
            <th>Event:</th>
            <td>{{ event.name }}</td>
        </tr>
        <tr>
            <th>Symbol:</th>
            <td>{{ event.symbol }}</td>
        </tr>
        <tr>
            <th>Long:</th>
            <td>{{ #toPrecision}}{{ event.lon }},6{{/ toPrecision}}</td>
        </tr>
        <tr>
            <th>Lat:</th>
            <td>{{ #toPrecision}}{{ event.lat }},6{{/ toPrecision}}</td>
        </tr>
         <tr>
            <th>Elev:</th>
            <td>{{ #toPrecision}}{{ event.elev }},6{{/ toPrecision}}</td>
        </tr>
        <tr>
            <th>Created At</th>
            <td>{{event.created_at}}</td>
        </tr>
         <tr>
            <th>Munition Type</th>
            <td>{{event.extra.munitionsType}}</td>
        </tr>
    </tbody>
</table>`,
      markupPopupEventWithTab: `<ul class="nav nav-tabs nav-justified">
   <li class="nav-item"><a class="nav-link active h-100" href="#event-general" data-toggle="tab">General</a></li>
   <li class="nav-item"><a class="nav-link h-100" href="#event-description" data-toggle="tab">Description</a></li>
   <li class="nav-item"><a class="nav-link h-100" href="#event-extra" data-toggle="tab">Extra</a></li>
</ul>
<div class="tab-content">
   <!--Content tab 1-->
   <div class="tab-pane active" id="event-general">
      <table class='table table-sm table-striped orbat-properties felx-grow-1'>
         <div class="symbol_preview m-2">{{{event.symbol_preview}}}</div>
         <tbody>
            <tr>
               <th>Event:</th>
               <td>{{event.name}}</td>
            </tr>
            <tr>
               <th>Symbol:</th>
               <td>{{event.symbol}}</td>
            </tr>   
            <tr>
                <th>Long:</th>
                <td>{{#toPrecision}}{{event.lon}},6{{/toPrecision}}</td>
             </tr>
             <tr>
                <th>Lat:</th>
                <td>{{#toPrecision}}{{event.lat}},6{{/toPrecision}}</td>
             </tr>
              <tr>
                <th>Elev:</th>
                <td>{{#toPrecision}}{{event.elev}},6{{/toPrecision}}</td>
             </tr>
         </tbody>
      </table>
<div>
<tr>
</div>
   </div>
    <!--Content tab 2-->
   <div class="tab-pane" id="event-description">
        <p>{{{event.description}}}</p>
   </div>
   <!--Content tab 3-->
   <div class="tab-pane" id="event-extra"><table class='table table-sm table-striped orbat-properties felx-grow-1'>
    <table class='table table-sm table-striped orbat-properties felx-grow-1'>
        <table class='table table-sm table-striped orbat-properties felx-grow-1'>
        <div class="symbol_preview m-2">{{{event.symbol_preview}}}</div>
        <tbody>
           <tr>
              <th>Munition Type:</th>
              <td>{{event.extra.munitionsType}}</td>
           </tr>
        </tbody>
     </table>
   </div>
</div>`,
    };
  },
};
</script>
