<template>
  <div class="row m-0">
    <admin-menu></admin-menu>
    <div class="col">
      <h3>Instance Monitoring Dashboard</h3>
      <iframe
        v-if="monitoringUrl"
        style="border: none"
        :src="
          monitoringUrl +
          '&var-Instances=' +
          $route.params.id +
          '&kiosk&theme=light'
        "
        width="100%"
        height="800"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      monitoringUrl: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.loadInstanceMonitoring();
    }
  },
  methods: {
    loadInstanceMonitoring() {
      this.$http
        .get("/rest/instances/" + this.$route.params.id + "/monitoringUrl")
        .then((res) => {
          this.monitoringUrl = res.body.url;
        })
        .catch((res) => {
          console.error("Error getting instance");
        });
    },
  },

  computed: {},
};
</script>

