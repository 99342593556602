<template>
  <div class="container-main">
    <div class="row m-0">
      <admin-menu></admin-menu>
      <div class="col">
        <h3 v-if="$route.params.id">Edit Instance</h3>
        <h3 v-else>New Instance</h3>
        <form class="form" @submit.prevent.stop="submit">
          <label class="form-group d-block">
            <span class="label">Name:</span>
            <input type="text" class="form-control" v-model="instance.name" :disabled="instance.id"
              :readonly="instance.id" />
          </label>
          <label class="form-group d-block">
            <span class="label">Scenario:</span>
            <input type="text" class="form-control" disabled readonly v-if="$route.params.id"
              :value="scenarioName(instance.scenarioId)" />
            <autocomplete ref="scenario" :source="scenarios" v-model="instance.scenarioId" input-class="form-control"
              v-else></autocomplete>
          </label>
          <label class="form-group d-block">
            <span class="label">Group:</span>
            <autocomplete ref="group" :source="groups" v-model="instance.groupId" input-class="form-control">
            </autocomplete>
          </label>
          <div class="row align-items-center" v-if="$store.state.serverInfo.features.monitoring">
            <div class="col col-12">
              <label class="custom-control custom-checkbox justify-content-start">
                <input type="checkbox" class="custom-control-input" @change="resetValueMonitoringUrl()"
                  v-model="instance.hasMonitoringUrl" />
                <span class="custom-control-label d-inline-block align-middle">Active custom monitoring url</span>
                <help-popup :name="'Instance Monitoring'"></help-popup>
              </label>
            </div>
            <div class="col col-12" v-if="instance.hasMonitoringUrl">
              <div class="form-group row">
                <label class="pr-1 col-2 justify-content-start">Monitoring url:</label>
                <div class="col-10 mt-2">
                  <input type="text" class="form-control" v-model="instance.monitoringUrl" />
                </div>
              </div>
            </div>
          </div>
          <!--TEst -->
          <div v-if="$store.state.serverInfo.features.hla" class="row align-items-center">
            <div class="col col-12">
              <label class="custom-control custom-checkbox justify-content-start">
                <input type="checkbox" class="custom-control-input" @change="resetValue()"
                  v-model="instance.hlaEnabled" />
                <span class="custom-control-label d-inline-block align-middle ">Active HLA Connector </span>
                <help-popup :name="'Instance Setting'"></help-popup>
              </label>
            </div>
            <div class="col col-12" v-if="instance.hlaEnabled">
              <div class="form-group row">
                <label class="pr-1 col-2 justify-content-start">HLA connector Name:</label>
                <div class="col-10 mt-2">
                  <select class="form-control" v-model="instance.hlaConnector">
                    <option v-for="z in hlaConnectors" :value="z.id" :key="z.id">
                      {{ z.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <!-- TEST END -->

          <div class="form-group p-2 align-right">
            <button type="submit" class="btn btn-primary">
              <span v-if="$route.params.id">Apply Edits</span>
              <span v-else>Create Instance</span>
            </button>
            <button class="btn btn-secondary ml-2" @click.prevent.stop="cancel">
              Cancel
            </button>
          </div>
        </form>
      </div>
      <loading :loading="
        loadingInstance || loadingScenarios || loadingGroups || loadingInsert
      "></loading>
      <vue-snotify></vue-snotify>
    </div>
  </div>
</template>
<script>
import api from "../../../api";
import HelpPopup from "../../../HelpPopup.vue";
export default {
  components: {
    "help-popup": HelpPopup
  },
  data() {
    return {
      instance: {
        hlaEnabled: false,
      },
      invalidNameError: false,
      loadingInstance: false,
      loadingScenarios: false,
      loadingGroups: false,
      loadingInsert: false,
      errors: [],
      scenarios: [],
      groups: [],
      hlaConnectors: [],
    };
  },
  created() {
    this.getHlaConnectorList();
    this.loadScenarios();
    this.loadGroups();
    if (this.$route.params.id) {
      this.loadInstance();
    }
  },
  methods: {
    resetValue() {
      this.instance.hlaConnector = null;
      //this.instance.hlaEnabled =
    },
    resetValueMonitoringUrl() {
      this.instance.monitoringUrl = null;
    },
    getHlaConnectorList() {
      this.$http
        .get("/rest/hla")
        .then((res) => {
          this.hlaConnectors = res.body;
          console.log("HLA connectors loaded");
        })
        .catch((res) => {
          //this.loadingDatastores = false;
          console.error("Error getting hla connectors");
        });
    },
    refreshGroupAutocomplete() {
      this.$refs.group.value = this.instance.groupId;
      this.$refs.group.selectedId = this.instance.groupId;
      this.$refs.group.display = this.getGroupName(this.instance.groupId);
      this.$refs.group.selectedDisplay = this.$refs.group.display;
    },
    getGroupName(id) {
      if (!id) return "";
      var group = this.groups.filter((s) => s.id === id)[0];
      if (group) return group.name;
      else `group(id:${id})`;
    },
    scenarioName(id) {
      if (!id) return "";
      var scenario = this.scenarios.filter((s) => s.id === id)[0];
      if (scenario) return scenario.name;
      else `scenario(id:${id})`;
    },
    loadInstance() {
      this.loadingInstance = true;
      this.$http
        .get(api + "/rest/instances/" + this.$route.params.id)
        .then((res) => {
          this.instance = res.body;

          if (this.instance.hlaConnector) {
            this.instance.hlaEnabled = true;
          }

          if (this.instance.monitoringUrl) {
            this.instance.hasMonitoringUrl = true;
          }

          this.loadingInstance = false;
          this.refreshGroupAutocomplete();
        })
        .catch((res) => {
          this.instance = {};
          console.error("Error getting instance");
          this.loadingInstance = false;
        });
    },
    loadScenarios() {
      this.loadingScenarios = true;
      this.$http
        .get(api + "/rest/scenarios")
        .then((res) => {
          this.scenarios = res.body;
          this.loadingScenarios = false;
        })
        .catch((res) => {
          this.scenarios = [];
          console.error("Error getting sceanrios");
          this.loadingScenarios = false;
        });
    },
    loadGroups() {
      this.loadingGroups = true;
      this.$http
        .get(api + "/rest/groups")
        .then((res) => {
          this.groups = res.body;
          this.loadingGroups = false;
          this.refreshGroupAutocomplete();
        })
        .catch((res) => {
          this.groups = [];
          console.error("Error getting groups");
          this.loadingGroups = false;
        });
    },
    submit() {
      this.loadingInsert = true;
      this.instance.hla = this.clearErrors();
      if (this.$route.params.id) {
        this.$http
          .post(api + "/rest/instances/" + this.$route.params.id, this.instance)
          .then((res) => {
            this.loadingInsert = false;
            this.$router.go(-1);
          })
          .catch((res) => {
            this.loadingInsert = false;
            var toast = this.$snotify.error(
              "Error while appy edits instance" +
              (res.body && res.body.error
                ? ": " + res.body.error.message
                : "")
            );
            console.error(toast.message);
            this.errors.push(toast);
          });
      } else {
        this.$http
          .put(api + "/rest/instances", this.instance)
          .then((res) => {
            this.loadingInsert = false;
            this.$router.go(-1);
          })
          .catch((res) => {
            this.loadingInsert = false;
            var toast = this.$snotify.error(
              "Error creating new wargame instance" +
              (res.body && res.body.error
                ? ": " + res.body.error.message
                : "")
            );
            console.error(toast.message);
            this.errors.push(toast);
          });
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    clearErrors() {
      this.errors.forEach((e) => this.$snotify.remove(e.id));
    },
  },
  computed: {
    validName() {
      return this.instance.name && !invalidNameError;
    },
  },
  destroyed() {
    this.clearErrors();
  },
};
</script>
