<template>
  <div>
    <help-popup :class="{'isAbsolute': true,}" :name="'Statistics'"></help-popup>
    <div class="statistics-edit row align-items-center new-statistics">
      <div class="col col-12">
        <label class="custom-control custom-checkbox justify-content-start">
          <input type="checkbox" class="custom-control-input" v-model="scenario.content.statistics.enabled" />
          <span class="custom-control-label">Enabled</span>
        </label>
      </div>
      <div class="col col-12">
        <!-- queries section -->
        <div class="row align-items-center" v-for="(query, i) in scenario.content.statistics.queries" :key="i">
          <div class="col-12">
            <b>Query #{{ i + 1 }}</b>
            <button class="btn btn-sm btn-outline-danger mb-1" @click.prevent="deleteQuery(i)">
              <span class="icon icon-cross icon"></span>
              <span class>Delete Query</span>
            </button>
          </div>
          <div class="col col-2 justify-content-left">Type:</div>
          <select class="form-control form-control-sm col-4 mb-1 px-0" v-model="query.type">
            <option value="datastore">Datastore</option>
            <option value="layer">Layer</option>
          </select>
          <div class="col-6"></div>
          <template v-if="query.type === 'layer'">
            <div class="col-2 justify-content-start">Layer:</div>
            <input type="text" v-model="query.layer" class="form-control form-control-sm col-4 mb-1" />
            <div class="col-6"></div>
          </template>
          <template v-else-if="query.type === 'datastore'">
            <label class="col-2 col-md-2 justify-content-start">Datastore:</label>
            <div class="col-10 col-md-4 px-0">
              <select name="datastore" class="form-control form-control-sm mb-1 w-100" v-model="query.datastore"
                @change="datastoreChange(i)">
                <option :value="''" disabled selected>Select Datastore...</option>
                <option v-for="datastore in datastores" :key="datastore.id" :value="datastore.id">
                  {{ datastore.name }}
                </option>
              </select>
            </div>
            <label class="col-2 col-md-2 justify-content-start">Table:</label>
            <div class="col-10 col-md-4 px-0">
              <select name="table" class="form-control form-control-sm mb-1 w-100" v-model="query.table"
                :title="query.table" @change="tableChanged(i)">
                <option :value="''" disabled selected>Select Table...</option>
                <option v-for="table in tables[query.datastore]" :key="table.schema + '.' + table.name"
                  :value="table.schema + '.' + table.name">
                  {{ table.schema + "." + table.name }}
                </option>
              </select>
            </div>
          </template>
          <div class="col-2 justify-content-left">Group By:</div>
          <input class="form-control form-control-sm col-4 mb-1" v-model="query.groupBy" />
          <div class="col-6"></div>
          <div class="col-2 justify-content-left">Total:</div>
          <input class="form-control form-control-sm col-4 mb-1" v-model="query.total" />
          <div class="col-6"></div>
          <!-- fields section -->
          <div class="col-12">
            <div class="row px-3 align-items-center">
              <div class="col-12 p-0">Fields:</div>
              <div class="col-12">
                <div class="field-row row align-items-center" v-for="(field, j) in query.fields" :key="j">
                  <div class="col-4 p-1">
                    <input type="text" placeholder="field name..." class="form-control form-control-sm w-100"
                      v-model="field.field" />
                  </div>
                  <div class="col-4 p-1">
                    <input type="text" placeholder="display as..." class="form-control form-control-sm w-100"
                      v-model="field.alias" />
                  </div>
                  <div class="col-3 p-1">
                    <select class="form-control form-control-sm w-100" v-model="field.function">
                      <option value="count">Count</option>
                      <option value="sum">Sum</option>
                      <option value="avg">Average</option>
                    </select>
                  </div>
                  <div class="col-1 text-right p-1">
                    <button class="btn btn-sm text-danger" @click="deleteField(query, j)">
                      <span class="icon icon-cross"></span>
                      <span class="label"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 py-1">
                <button class="btn btn-sm btn-outline-primary" @click.prevent="addField(query)">
                  <span class="icon icon-plus2"></span>
                  <span class="label">Add field</span>
                </button>
              </div>
              <div class="col-12 alert alert-secondary" v-if="query.fields.length === 0">
                No fields defined
              </div>
            </div>
          </div>
          <div class="col-12 row-separator">
            <span />
            <span />
          </div>
        </div>
        <!--  no queries -->
        <div class="alert alert-secondary" v-if="scenario.content.statistics.queries.length === 0">
          No Queries Defined
        </div>

        <div class="col-12 py-2">
          <button class="btn btn-sm btn-outline-primary" @click.prevent="addQuery()">
            <span class="icon icon-plus2 icon"></span>
            <span class>Add Query</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../../api";
import HelpPopup from "../../../HelpPopup.vue";
export default {
  name: "statistics-section",
  props: ["scenario", "datastores"],
  components: {
    "help-popup": HelpPopup
  },
  created() {
    if (!this.scenario.content.statistics) {
      this.scenario.content.statistics = {
        enabled: false,
        queries: [],
      };
    }
    this.loadMissingTables();
  },
  watch: {
    scenario(newVal, oldVal) {
      if (!this.scenario || !this.scenario.content) return;
      if (!this.scenario.content.statistics) {
        this.scenario.content.statistics = {
          enabled: false,
          queries: [],
        };
      }
      this.loadMissingTables();
    },
  },
  methods: {
    deleteField(query, j) {
      query.fields.splice(j, 1);
      this.$forceUpdate();
    },
    addField(query) {
      query.fields.push({ field: "", alias: "Count", function: "count" });
      this.$forceUpdate();
    },
    deleteQuery(i) {
      this.scenario.content.statistics.queries.splice(i, 1);
      this.$forceUpdate();
    },
    addQuery() {
      this.scenario.content.statistics.queries.push({
        type: "datastore",
        datastore: null,
        table: "",
        layer: "",
        total: "",
        groupBy: "",
        fields: [{ field: "", alias: "Count", function: "count" }],
      });
      this.$forceUpdate();
    },
    loadMissingTables() {
      this.scenario.content.statistics.queries.forEach((q, i) => {
        if (typeof this.tables[q.datastore] === "undefined")
          this.datastoreChange(i);
      });
    },
    datastoreChange(i) {
      if (!this.tables[this.scenario.content.statistics.queries[i].datastore]) {
        this.tables[this.scenario.content.statistics.queries[i].datastore] = [];
        this.loadTables(i);
      }
    },
    loadTables(i) {
      if (!this.scenario.content.statistics.queries[i].datastore) return;
      this.tables[this.scenario.content.statistics.queries[i].datastore] = [];
      this.loadingTables = true;
      this.$http
        .get(
          api +
          "/rest/datastores/" +
          this.scenario.content.statistics.queries[i].datastore +
          "/tables"
        )
        .then((res) => {
          this.loadingTables = false;
          this.tables[this.scenario.content.statistics.queries[i].datastore] =
            res.body;
          this.$forceUpdate();
        })
        .catch((res) => {
          this.loadingTables = false;
          console.error(
            "Error getting tables for datastore with id " + this.layer.datastore
          );
        });
    },
    tableChanged(i) { },
  },
  data() {
    return { tables: {}, tablesLoading: false };
  },
};
</script>