<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1>3D Tile Details</h1>
        <form>
          <div class="alert alert-danger" v-if="errors.length">
            <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Id:</div>
            <div class="flex-grow-1 p-2">{{ tile.id }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Name:</div>
            <div class="flex-grow-1 p-2">{{ tile.name }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Datastore:</div>
            <div class="flex-grow-1 p-2">
              {{ getDatastoreName(tile.datastore) }}
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Table:</div>
            <div class="flex-grow-1 p-2">{{ tile.table }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">X Field:</div>
            <div class="flex-grow-1 p-2">{{ tile.xField }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Y Field:</div>
            <div class="flex-grow-1 p-2">{{ tile.yField }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Z Field:</div>
            <div class="flex-grow-1 p-2">{{ tile.zField }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Height Field:</div>
            <div class="flex-grow-1 p-2">{{ tile.heightField }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Tile Size:</div>
            <div class="flex-grow-1 p-2">{{ tile.tileSize }}</div>
          </div>
        </form>
        <loading :loading="loading || loadingDatastores">Loading...</loading>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import eventBus from "../../../eventBus";
import api from "../../../api";
import LoadingVue from "../../../components/loading/Loading.vue";
export default {
  components: {
    "admin-menu": AdminMenu,
    Loading: LoadingVue,
  },
  created() {
    this.initialLoad();
  },
  methods: {
    getDatastoreName(id) {
      const datastore = this.datastores.filter((ds) => ds.id === id)[0];
      if (datastore) return datastore.name;
      else return `datastore(${id})`;
    },
    async initialLoad() {
      await this.loadDatastores();
      await this.loadTileDetail();
    },
    async loadTileDetail() {
      if (this.$route.params.name) {
        this.loading = true;
        try {
          const res = await this.$http.get(
            api + "/rest/3dtiles/" + this.$route.params.name
          );
          this.loading = false;
          this.tile = res.body;
        } catch (res) {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        }
      }
    },
    async loadDatastores() {
      try {
        const res = await this.$http.get(api + "/rest/datastores");
        this.loadingDatastores = false;
        this.datastores = res.body;
      } catch (res) {
        this.loadingDatastores = false;
        console.error("Error getting datastores");
      }
    },
  },
  data() {
    return {
      errors: [],
      loading: false,
      loadingDatastores: false,
      datastores: [],
      tile: {
        id: "",
        name: "",
        table: "",
        datastore: 0,
        xField: "",
        yField: "",
        zField: "",
        heightField: "",
        tileSize: 1000,
      },
    };
  },
};
</script>