<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3 container-newUser">
        <h1 v-if="user.id">Edit User</h1>
        <h1 v-else>New User</h1>
        <form action class="form" @submit.prevent="insertOrUpdate">
          <div class="alert alert-danger" v-if="errors.length">
            <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
          </div>
          <h2 class="mt-2">Profile Info</h2>
          <div class="form-group">
            <label for="username">Username</label>
            <input
              name="username"
              class="form-control"
              type="text"
              v-model="user.username"
            />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              name="password"
              class="form-control"
              type="password"
              v-model="user.password"
            />
            <input
              name="confirm-password"
              class="form-control mt-1"
              type="password"
              placeholder="Confirm Password..."
              v-model="confirm_password"
            />
          </div>
          <div class="form-group">
            <label for="first_name">First Name</label>
            <input
              name="first_name"
              class="form-control"
              type="text"
              v-model="user.first_name"
            />
          </div>
          <div class="form-group">
            <label for="middle_name">Middle Name</label>
            <input
              name="middle_name"
              class="form-control"
              type="text"
              v-model="user.middle_name"
            />
          </div>
          <div class="form-group">
            <label for="last_name">Last Name</label>
            <input
              name="last_name"
              class="form-control"
              type="text"
              v-model="user.last_name"
            />
          </div>
          <div class="form-group">
            <label for="email">E-mail</label>
            <input
              name="email"
              class="form-control"
              type="text"
              v-model="user.email"
            />
          </div>
          <div class="form-group">
            <h3>Members of</h3>
            <ul class="navbar-nav members-of mb-2">
              <li class="nav-item" v-for="group in user.groups" :key="group.id">
                <span class="label">{{ getAutocompleteDisplay(group) }}</span>
                <span
                  class="btn btn-outline-danger icon icon-cross"
                  @click="groupRemoved(group)"
                  v-if="!(user.admin && group.name == 'administrators')"
                ></span>
              </li>
            </ul>
            <autocomplete
              ref="autocomplete"
              :source="groups"
              input-class="form-control"
              placeholder="Add to group..."
              :results-display="getAutocompleteDisplay"
              @selected="groupSelected"
            ></autocomplete>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary">
              <span class="label" v-if="user.id">Edit User</span>
              <span class="label" v-else>Add User</span>
            </button>
          </div>

          <span class="overlay align-self-center d-flex" v-if="loading">
            <div class="overlay-background bg-white opacity-50"></div>
            <div class="spinner-border text-primary m-auto" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
          <span class="overlay align-self-center d-flex" v-if="loading">
            <div class="overlay-background bg-white opacity-50"></div>
            <div class="spinner-border text-primary m-auto" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import Autoomplete from "vuejs-auto-complete";
import eventBus from "../../../eventBus";
import api from "../../../api";
export default {
  components: {
    "admin-menu": AdminMenu,
    autocomplete: Autoomplete,
  },
  created() {
    this.loadUser();
    this.loadGroups();
  },
  data() {
    return {
      errors: [],
      groups: [],
      loading: false,
      user: {
        username: "",
        password: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        groups: [],
      },
      confirm_password: "",
    };
  },
  methods: {
    getFullName(user) {
      var name = "";
      if (this.user.first_name)
        name =
          name.length == 0
            ? this.user.first_name
            : name + " " + this.user.first_name;
      if (this.user.middle_name)
        name =
          name.length == 0
            ? this.user.middle_name
            : name + " " + this.user.middle_name;
      if (this.user.last_name)
        name =
          name.length == 0
            ? this.user.last_name
            : name + " " + this.user.last_name;
      return name;
    },
    insert() {
      this.errors = [];
      var errors = [];
      if (!this.user.username) errors.push("Username is required");
      if (!this.user.password) errors.push("Password is required");
      if (this.user.password && this.user.password.length < 6)
        errors.push("Password should have a minimum length of 6 characters");
      if (this.user.password && this.user.password != this.confirm_password)
        errors.push("Password and confirm password do not match");
      if (this.user.email && !this.isEmail(this.user.email))
        errors.push("Invalid E-mail address specified");
      if (errors.length) {
        this.errors = errors;
        return;
      } else {
        this.loading = true;
        this.$http
          .put(api + "/rest/users", this.user)
          .then((res) => {
            this.loading = false;
            if (this.$router.length > 1) this.router.go(-1);
            else this.$router.push("..");
          })
          .catch((res) => {
            this.loading = false;
            if (res.status === 401) {
              eventBus.$emit("logout");
              this.$router.push(
                "/login?redirect_uri=" + encodeURIComponent(this.$route.path)
              );
            } else if (res.status === 409) {
              if (res && res.body && res.body.error && res.body.error.message) {
                errors.push(res.body.error.message);
              }
            } else {
              errors.push(
                "Unexpected server error:" +
                  (res && res.body && res.body.error && res.body.error.message)
              );
            }
            if (errors.length) this.errors = errors;
          });
      }
    },
    update() {
      this.errors = [];
      var errors = [];
      if (!this.user.username) errors.push("Username is required");
      if (
        this.user.password &&
        this.user.password.length > 0 &&
        this.user.password.length < 6
      )
        errors.push("Password should have a minimum length of 6 characters");
      if (
        this.user.password &&
        (this.user.password.length > 0 || this.confirm_password.length > 0) &&
        this.user.password != this.confirm_password
      )
        errors.push("Password and confirm password do not match");
      if (this.user.email && !this.isEmail(this.user.email))
        errors.push("Invalid E-mail address specified");
      if (errors.length) {
        this.errors = errors;
        return;
      } else {
        this.loading = true;
        this.$http
          .post(api + "/rest/users/" + this.user.id, this.user)
          .then((res) => {
            this.loading = false;
            this.$router.push("..");
          })
          .catch((res) => {
            if (res.status === 401) {
              eventBus.$emit("logout");
              this.$router.push(
                "/login?redirect_uri=" + encodeURIComponent(this.$route.path)
              );
            } else if (res.status === 409) {
              this.loading = false;
              if (res && res.body && res.body.error && res.body.error.message) {
                errors.push(res.body.error.message);
              }
            } else {
              this.loading = false;
              errors.push(
                "Unexpected server error:" +
                  (res && res.body && res.body.error && res.body.error.message)
              );
            }
            if (errors.length) this.errors = errors;
          });
      }
    },
    insertOrUpdate() {
      if (this.user.id) {
        this.update();
      } else {
        this.insert();
      }
    },
    isEmail(email) {
      return (
        email &&
        email.match(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        )
      );
    },
    getAutocompleteDisplay(group) {
      return group.name;
    },
    loadUser() {
      if (this.$route.params.id) {
        this.$http
          .get(api + "/rest/users/" + this.$route.params.id)
          .then((res) => {
            this.user = res.body;
          })
          .catch((res) => {
            if (res.status === 401) {
              eventBus.$emit("logout");
            } else if (res.body.error && res.body.error.message) {
              this.errors.push(res.body.error.message);
            }
          });
      }
    },
    loadGroups() {
      this.$http
        .get(api + "/rest/groups")
        .then((res) => {
          this.groups = res.body;
        })
        .catch((res) => {
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body.error && res.body.error.message) {
            this.errors.push(res.body.error.message);
          }
        });
    },
    groupSelected(evt) {
      if (
        this.user.groups.filter((g) => g.id == evt.selectedObject.id).length ==
        0
      ) {
        this.user.groups.push(evt.selectedObject);
      }
      this.$refs.autocomplete.clear();
    },
    groupRemoved(group) {
      this.user.groups = this.user.groups.filter((g) => g.id != group.id);
    },
  },
};
</script>
