var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.scenario && _vm.scenario.content && _vm.scenario.content.units)?_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col col-12"},[_c('label',{staticClass:"custom-control custom-checkbox justify-content-start"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.scenario.content.units.spaceLayer),expression:"scenario.content.units.spaceLayer"}],staticClass:"custom-control-input",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.scenario.content.units.spaceLayer)?_vm._i(_vm.scenario.content.units.spaceLayer,null)>-1:(_vm.scenario.content.units.spaceLayer)},on:{"change":function($event){var $$a=_vm.scenario.content.units.spaceLayer,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.scenario.content.units, "spaceLayer", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.scenario.content.units, "spaceLayer", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.scenario.content.units, "spaceLayer", $$c)}}}}),_vm._v(" "),_c('span',{staticClass:"custom-control-label"},[_vm._v("Space Layer")])])]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col col-sm-12 col-md-6 col-lg-3",staticStyle:{"padding-left":"12px"}},[_c('model-list-select',{ref:"layerNoFlyZones",staticClass:"form-control form-control-sm",attrs:{"list":_vm.getLayers('vector', 'POLYGON').filter((l) => l.access != 'direct'),"required":"","option-value":"id","option-text":"path","placeholder":"None"},on:{"input":function($event){return _vm.$forceUpdate()}},model:{value:(_vm.scenario.content.units.layerNoFlyZones),callback:function ($$v) {_vm.$set(_vm.scenario.content.units, "layerNoFlyZones", $$v)},expression:"scenario.content.units.layerNoFlyZones"}})],1),_vm._v(" "),(
      _vm.scenario &&
      _vm.scenario.content &&
      _vm.scenario.content.units &&
      _vm.scenario.content.units.icons
    )?_c('units-icons-type',{ref:"type",attrs:{"units":_vm.scenario.content.units}}):_vm._e(),_vm._v(" "),(
      _vm.scenario &&
      _vm.scenario.content &&
      _vm.scenario.content.units &&
      _vm.scenario.content.units.popup
    )?_c('units-popup-type',{attrs:{"popup":_vm.scenario.content.units.popup,"markupPopupUnit":_vm.markupPopupUnit,"markupPopupUnitWithTab":_vm.markupPopupUnitWithTab,"markupPopupUnitReference":_vm.markupPopupUnitReference,"units":_vm.scenario.content.units}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col col-12 col-lg-6"}),_vm._v(" "),_vm._l((_vm.scenario.content.units.sources),function(source,i){return _c('units-source',{key:i,staticClass:"col col-12 mb-1",attrs:{"title":_vm.scenario.content.vignettes[i],"scenario":_vm.scenario,"datastores":_vm.datastores,"locations":false},on:{"input":function($event){return _vm.$refs.type.updateReadOnly()}},model:{value:(source.records),callback:function ($$v) {_vm.$set(source, "records", $$v)},expression:"source.records"}})}),_vm._v(" "),_c('loading',{attrs:{"loading":_vm.loadingTables}})],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col col-sm-12 col-md-6 col-lg-3"},[_c('label',{staticClass:"justify-content-start",attrs:{"for":"layerNoFlyZones"}},[_vm._v("No Fly Zones:")])])
}]

export { render, staticRenderFns }