<template>
  <div class="container">
    <h1>Loginng out...</h1>
    <div class="alert alert-danger" v-if="message">{{ message }}</div>
    <div class="spinner-border text-primary m-auto" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>
<script>
import eventBus from "./../eventBus";
import api from "./../api";
export default {
  created() {
    this.logout();
  },
  data() {
    return { loading: false, message: null };
  },
  methods: {
    logout() {
      if (this.loading) return;
      console.log("logging out...");
      this.loading = true;
      this.$http
        .post(api + "/auth/tokens/logout", {})
        .then((res) => {
          eventBus.$emit("logout");
          this.loading = false;
          this.message = null;
        })
        .catch((err) => {
          console.error("Error Logging in...", err);
          this.message = "Invalid username and/or password";
          this.loading = false;
        });
    },
  },
};
</script>
