import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const defaultServerInfo = {
  version: "0.0.0",
  features: {},
};

const store = new Vuex.Store({
  state: {
    serverInfo: defaultServerInfo,
    instances:{
      filter:''
    },
    clockService : null
  },
  actions: {
    async loadServerInfo() {
      try {
        const res = await fetch(
          window.JMSS.config.xserverApiUrl + "/rest/info"
        );
        const info = await res.json();
        this.state.serverInfo = info;
      } catch (err) {
        console.error("Error Loading Server Info...");
        this.state.serverInfo = defaultServerInfo;
      }
    },
    async initClockService(){}
  },
});

export default store;
