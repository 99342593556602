<template>
  <div>
    <help-popup :class="{'isAbsolute': true,}" :name="'Basemaps'"></help-popup>
    <div class="w-100 p-2 new-basemap">
      <div class="mb-2">
        <button class="btn btn-outline-primary" @click.prevent="newBasemap">
          <span class="icon icon-plus2"></span>
          <span>Add Basemap</span>
        </button>
      </div>
      <div class="row align-items-center mb-4" v-for="(basemap, x) in scenario.content.basemaps" :key="x">
        <span class="col-2 justify-content-left">Name:</span>
        <input type="text" class="form-control col-4 align-content-center mb-1" v-model="basemap.name" />
        <span class="col-6">
          <span class="btn btn-outline-danger" @click.prevent="deleteBasemap(basemap)"
            v-show="scenario.content.basemaps.length > 1">
            <span class="icon icon-cross"></span>
            <span>Delete Basemap</span>
          </span>
        </span>
        <span class="col-2 justify-content-left">Thumbnail URL:</span>
        <input type="text" placeholder="/basemaps/osm.png" class="form-control mb-1 col-4" v-model="basemap.thumb" />
        <span class="col-6"></span>
        <span class="col-12">
          Basemap Layers:
          <span class="btn btn-sm btn-outline-primary" @click.prevent="addLayer(basemap)">
            <span class="icon icon-plus2"></span>
            <span>Add layer</span>
          </span>
        </span>
        <div class="row py-1 w-100 align-items-center row-basemap-layer" v-for="(layer, i) in basemap.layers" :key="i">
          <span class="col-1 text-right">#{{ i + 1 }}</span>
          <span class="col-10">
            <div class="row align-items-center">
              <span class="col-2 justify-content-left">Type:</span>
              <span class="col-10">
                <select v-model="layer.type" class="form-control mb-1">
                  <option value="xyz">XYZ</option>
                  <option value="wms">WMS</option>
                  <option value="wmts">WMTS</option>
                </select>
              </span>
              <span class="col-2 justify-content-left">URL:</span>
              <span class="col-10">
                <input type="text" :placeholder="placeholder(layer.type)" class="form-control mb-1 w-100"
                  v-model="layer.url" />
              </span>
            </div>
          </span>
          <span class="col-1">
            <span v-show="basemap.layers.length > 1" class="btn btn-sm btn-outline-danger icon-cross"
              @click.prevent="deleteLayer(basemap, layer)"></span>
          </span>
        </div>
      </div>
      <confirm-dialog ref="confirmDelete" :body="'Are you sure do you want to delete layer?'"
        confirmBtnClasses="btn-danger" @confirm="confirmDeleteLayer" @cancel="$refs.confirmDelete.close()">
      </confirm-dialog>
      <confirm-dialog ref="confirmDeleteBasemap" :body="
        'Are you sure do you want to delete basemap ' +
        this.deleteingBasemap.name +
        '?'
      " confirmBtnClasses="btn-danger" @confirm="confirmDeleteBasemap" @cancel="$refs.confirmDeleteBasemap.close()">
      </confirm-dialog>
    </div>
  </div>
</template>
<script>
import Autocomplete from "vuejs-auto-complete";
import eventBus from "../../../eventBus";
import HelpPopup from "../../../HelpPopup.vue";
var groups = {};
export default {
  components: {
    autocomplete: Autocomplete,
    "help-popup": HelpPopup
  },
  filters: {
    getGroupName(id) {
      if (!groups[id]) return "(group id " + id + ")";
      return groups[id].name;
    },
    yesNo(b) {
      return b ? "Yes" : "No";
    },
  },
  props: ["scenario"],
  created() {
    if (
      this.scenario.content.basemaps &&
      !this.scenario.content.basemaps.length
    ) {
      this.newBasemap(); // default basemap OSM
    }
  },
  methods: {
    placeholder(type) {
      if (type === "xyz")
        return "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png";
      if (type === "wms") return "/wms/world";
      if (type === "wmts") return "/wmts/world";
    },
    newBasemap() {
      this.scenario.content.basemaps.push({
        name: "OSM",
        thumb: "/basemaps/osm.png",
        layers: [
          {
            type: "xyz",
            url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          },
        ],
      });
      this.$forceUpdate();
    },
    addLayer(basemap) {
      basemap.layers.push({ type: "xyz", url: "" });
      this.$forceUpdate();
    },
    deleteLayer(basemap, layer) {
      this.deleteingBasemap = basemap;
      this.deleteingLayer = layer;
      this.$refs.confirmDelete.open();
    },
    deleteBasemap(basemap) {
      this.deleteingBasemap = basemap;
      this.$refs.confirmDeleteBasemap.open();
    },
    confirmDeleteLayer() {
      if (!this.deleteingBasemap) return;
      const index = this.deleteingBasemap.layers.indexOf(this.deleteingLayer);
      if (index >= 0) {
        this.deleteingBasemap.layers.splice(index, 1);
      }
      this.$refs.confirmDelete.close();
      this.$forceUpdate();
    },
    confirmDeleteBasemap() {
      if (!this.deleteingBasemap) return;
      const index = this.scenario.content.basemaps.indexOf(
        this.deleteingBasemap
      );
      if (index >= 0) {
        this.scenario.content.basemaps.splice(index, 1);
      }
      this.$refs.confirmDeleteBasemap.close();
      this.$forceUpdate();
    },
  },
  computed: {},
  data() {
    return { deleteingBasemap: {}, deleteingLayer: null };
  },
};
</script>
