<template>
  <div>
    <help-popup :class="{'isAbsolute': true,}"  :name="'Routing'"></help-popup>
    <div class=" routing-edit row align-items-center">
      <div class="col col-12">
        <label class="custom-control custom-checkbox justify-content-start">
          <input type="checkbox" class="custom-control-input" v-model="scenario.content.routing.enabled" />
          <span class="custom-control-label">Enabled</span>
        </label>
      </div>
      <label class="col-2 col-md-2 justify-content-start">Datastore:</label>
      <div class="col-10 col-md-4 px-0">
        <select name="datastore" class="form-control form-control-sm mb-1 w-100"
          v-model="scenario.content.routing.datastore" @change="datastoreChange()">
          <option :value="''" disabled selected>Select Datastore...</option>
          <option v-for="datastore in datastores" :key="datastore.id" :value="datastore.id">
            {{ datastore.name }}
          </option>
        </select>
      </div>
      <label class="col-2 col-md-2 justify-content-start">Table:</label>
      <div class="col-10 col-md-4 px-0">
        <select name="table" class="form-control form-control-sm mb-1 w-100" v-model="scenario.content.routing.table"
          :title="scenario.content.routing.table" @change="tableChange()">
          <option :value="''" disabled selected>Select Table...</option>
          <option v-for="table in tables[scenario.content.routing.datastore]" :key="table.schema + '.' + table.name"
            :value="table.schema + '.' + table.name">
            {{ table.schema + "." + table.name }}
          </option>
        </select>
      </div>
      <label class="col-2 col-md-2 justify-content-start">Time Field:</label>
      <input type="text" class="form-control form-control-sm col-10 col-md-4"
        v-model="scenario.content.routing.timeField" />
      <label class="col-2 col-md-2 justify-content-start">Oneway Field:</label>
      <input type="text" class="form-control form-control-sm col-10 col-md-4"
        v-model="scenario.content.routing.onewayField" />
    </div>
  </div>
</template>
<script>
import api from "../../../api";
import HelpPopup from "../../../HelpPopup.vue";
export default {
  name: "routing-section",
  props: ["scenario", "datastores"],
  components: {
    "help-popup": HelpPopup
  },
  created() {
    if (!this.scenario.content.routing) {
      this.scenario.content.routing = {
        enabled: false,
        datastore: null,
        table: "",
        timeField: "time",
        onewayField: "oneway",
      };
    }
    this.loadMissingTables();
  },
  watch: {
    scenario(newVal, oldVal) {
      if (!this.scenario || !this.scenario.content) return;
      if (!this.scenario.content.routing) {
        this.scenario.content.routing = {
          enabled: false,
          datastore: null,
          table: "",
          timeField: "time",
          onewayField: "oneway",
        };
      }
      this.loadMissingTables();
    },
  },
  methods: {
    loadMissingTables() {
      if (
        typeof this.tables[this.scenario.content.routing.datastore] ===
        "undefined"
      )
        this.datastoreChange();
    },
    datastoreChange() {
      if (!this.tables[this.scenario.content.routing.datastore]) {
        this.tables[this.scenario.content.routing.datastore] = [];
        this.loadTables();
      }
    },
    tableChange() { },
    loadTables() {
      if (!this.scenario.content.routing.datastore) return;
      this.tables[this.scenario.content.routing.datastore] = [];
      this.loadingTables = true;
      this.$http
        .get(
          api +
          "/rest/datastores/" +
          this.scenario.content.routing.datastore +
          "/tables"
        )
        .then((res) => {
          this.loadingTables = false;
          this.tables[this.scenario.content.routing.datastore] = res.body;
          this.$forceUpdate();
        })
        .catch((res) => {
          this.loadingTables = false;
          console.error(
            "Error getting tables for datastore with id " + this.layer.datastore
          );
        });
    },
  },
  data() {
    return { tables: {}, tablesLoading: false };
  },
};
</script>
