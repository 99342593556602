<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1 class="d-inline-block align-middle">Groups</h1>
        <help-popup :name="'Groups'"></help-popup>
        <div class="alert alert-danger" v-if="errors.length">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>
        <div class="mb-2 mt-2">
          <router-link to="groups/new" class="btn btn-primary">
            <span class="icon icon-plus2"></span>
            <span class="label">New</span>
          </router-link>
        </div>
        <table class="table w-100 table-groups">
          <thead>
            <tr>
              <th>Group Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="group in groups" v-bind:key="group.id">
              <th>{{ group.name }}</th>
              <td>
                <span class="btn btn-outline-primary" @click="$router.push('groups/' + group.id)">
                  <span class="icon icon-pencil4"></span>
                </span>
                <span class="btn btn-outline-danger" v-if="!group.system" @click="deleteGroup(group)">
                  <span class="icon icon-bin2"></span>
                </span>
              </td>
            </tr>
            <span class="overlay align-self-center d-flex" v-if="loading">
              <div class="overlay-background bg-white opacity-50"></div>
              <div class="spinner-border text-primary m-auto" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </span>
          </tbody>
        </table>
      </div>
      <div id="deleteConfirm" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              Are you sure that you want to delete the group "{{
              deletingGroup && deletingGroup.name
              }}"
            </div>
            <div class="modal-footer">
              <button class="btn btn-danger" @click="confirmDelete()">
                Delete
              </button>
              <button class="btn btn-secondary" @click="cancelDelete()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import eventBus from "../../../eventBus";
import api from "../../../api";
import HelpPopup from "../../../HelpPopup.vue"
export default {
  created() {
    this.loadGroups();
  },
  components: {
    "admin-menu": AdminMenu,
    "help-popup": HelpPopup
  },
  data() {
    return { errors: [], groups: [], loading: false, deletingGroup: null };
  },
  methods: {
    loadGroups() {
      this.loading = true;
      this.$http
        .get(api + "/rest/groups")
        .then((res) => {
          this.loading = false;
          this.groups = res.body;
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else {
            this.errors.push("Error " + res.status);
          }
        });
    },
    deleteGroup(group) {
      this.deletingGroup = group;
      $("#deleteConfirm").modal("show");
    },
    confirmDelete() {
      this.loading = true;
      this.$http
        .delete(api + "/rest/groups/" + this.deletingGroup.id)
        .then((res) => {
          this.loading = false;
          this.loadGroups();
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        });
      $("#deleteConfirm").modal("hide");
    },
    cancelDelete() {
      this.deletingGroup = null;
      $("#deleteConfirm").modal("hide");
    },
  },
};
</script>
