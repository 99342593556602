<template>
  <div class="modal fade" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row" v-if="step === 1">
            <ul class="nav nav-tabs mb-1 col-12">
              <li class="nav-item">
                <a
                  href
                  class="nav-link"
                  :class="{ active: currentTab === 'xlsx' }"
                  @click.prevent.stop="currentTab = 'xlsx'"
                  >Excel(.xlsx)</a
                >
              </li>
            </ul>
            <template v-if="currentTab === 'xlsx'">
              <label for="xlsxField" class="col col-12 col-md-2"> File: </label>
              <div
                class="
                  my
                  custom-file
                  form-control form-control-sm
                  col-12 col-md-10
                  mb-1
                "
              >
                <input
                  type="file"
                  name="xlsx"
                  id="xlsxField"
                  ref="xlsxField"
                  @change="xlsxFileChange"
                  class="custom-file-input"
                  placeholder="Select a File..."
                />
                <label for="xlsxField" class="custom-file-label">
                  {{ xlsxFile ? xlsxFile.name : "Choose a file..." }}
                </label>
              </div>
              <label for="sheetField" class="col col-12 col-md-2">
                Sheet:
              </label>
              <model-list-select
                class="form-control form-control-sm col-12 col-md-10"
                :list="xlsxSheets"
                v-model="xlsxSheet"
                option-value="id"
                option-text="name"
                placeholder="sheet..."
              />
            </template>
          </div>
          <div v-if="step === 2">
            <div>
              <span>{{ records.length }}</span>
              <span>Users loaded</span>
            </div>
            <h3>Groups Mapping:</h3>
            <div v-for="g in recordsGroups" :key="g.id" class="row">
              <span class="col-3">{{ g.name }}:</span>
              <model-list-select
                class="form-control form-control-sm col-9"
                :list="groups"
                v-model="groupMapping[g.id]"
                option-value="id"
                option-text="name"
                placeholder="Create"
              />
            </div>
            <h3>Options:</h3>
            <label
              class="
                custom-control custom-checkbox
                justify-content-start
                align-items-center align-middle
              "
            >
              <input
                type="checkbox"
                class="custom-control-input"
                v-model="forceUpdatePwd"
              />
              <label class="custom-control-label"></label>
              <span>Update Password if User already exists </span>
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-sm btn-secondary"
            v-if="step === 1"
            @click="close"
          >
            Cancel
          </button>
          <button
            class="btn btn-sm btn-secondary"
            v-if="step > 1"
            @click="back"
          >
            Back
          </button>
          <button
            class="btn btn-sm btn-primary"
            :disabled="!validStep1"
            @click="loadXlsxData()"
            v-if="step === 1"
          >
            Next
          </button>

          <button
            class="btn btn-sm btn-primary"
            :disabled="!isGroupMappingValid"
            @click="importRecords()"
            v-if="step === 2"
          >
            Import
          </button>
        </div>
      </div>
    </div>
    <!-- <loading :loading="loading" /> -->
    <vue-snotify></vue-snotify>
  </div>
</template>


<script>
import { ModelListSelect } from "vue-search-select";
import eventBus from "../../../eventBus";
import api from "../../../api";
import * as XLSX from "xlsx";

export default {
  components: {
    "model-list-select": ModelListSelect,
  },
  data() {
    return {
      currentTab: "xlsx",
      step: 1,
      error: false,
      xlsxFile: null,
      xlsxSheet: "",
      xlsxSheets: [],
      records: [],
      recordsGroups: [],
      groups: [],
      groupMapping: {},
      forceUpdatePwd: false,
    };
  },
  computed: {
    isGroupMappingValid() {
      if (Object.keys(this.groupMapping).length === 0) return false;
      let valid = true;
      return valid;
    },
    validStep1() {
      if (this.currentTab === "xlsx") {
        return this.xlsxSheet ? true : false;
      }
    },
  },
  watch: {
    records() {
      const groupsMap = {};
      for (let r of this.records) {
        for (let g of r.groups) {
          if (!groupsMap.hasOwnProperty(g)) {
            groupsMap[g] = { id: g, name: g };
          }
        }
      }
      this.recordsGroups = Object.keys(groupsMap).map((key) => groupsMap[key]);
      const mapping = {};
      const groupSides = [];

      for (let group of this.recordsGroups) {
        const dbGroup = this.groups.filter(
          (g) => g.name.trim().toLowerCase() == group.name.trim().toLowerCase()
        )[0];
        if (dbGroup) {
          mapping[group.id] = dbGroup.id;
        } else {
          mapping[group.id] = undefined;
        }
      }
      this.groupMapping = mapping;
      this.groupSides = groupSides;
    },
  },
  mounted() {
    jQuery(this.$el).on("shown.bs.modal", () => {
      this.$emit("shown");
    });
    jQuery(this.$el).on("hidden.bs.modal", () => {
      this.opened = false;
      this.$emit("close");
    });
  },
  destroyed() {
    this.$snotify.clear();
  },
  methods: {
    async getGroups() {
      this.loading = true;
      return this.$http
        .get(api + "/rest/groups")
        .then((res) => {
          this.loading = false;
          return res.body;
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else {
            this.errors.push("Error " + res.status);
          }
          return [];
        });
    },
    xlsxFileChange(event) {
      console.log("xlsx file changed");
      var reader = new FileReader();
      reader.onload = (e) => {
        this.xlsxFileUpload(e.target.result);
      };
      reader.readAsBinaryString(event.target.files[0]);
      this.xlsxFile = event.target.files[0];
    },
    xlsxFileUpload(data) {
      this.workbook = XLSX.read(data, {
        type: "binary",
      });
      console.log("xlsx file uploaded ");
      console.log("Workbook:", this.workbook);
      this.xlsxSheet = "";
      const sheets = [];
      for (let s of this.workbook.SheetNames) {
        sheets.push({ id: s, name: s });
      }
      this.xlsxSheets = sheets;
    },
    async loadXlsxData() {
      const records = [];
      const sheet = this.workbook.Sheets[this.xlsxSheet];
      const excelRows = XLSX.utils.sheet_to_row_object_array(sheet);
      const fieldsUserMapping = {
        username: "",
        password: "",
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        group: "",
      };
      for (let row of excelRows) {
        for (let key in row) {
          if (key.match(/username/gi)) {
            fieldsUserMapping.username = key;
          }
          if (key.match(/password/gi)) {
            fieldsUserMapping.password = key;
          }
          if (key.match(/firstname/gi)) {
            fieldsUserMapping.firstname = key;
          }
          if (key.match(/middlename/gi)) {
            fieldsUserMapping.middlename = key;
          }
          if (key.match(/lastname/gi)) {
            fieldsUserMapping.lastname = key;
          }
          if (key.match(/email/gi)) {
            fieldsUserMapping.email = key;
          }
          if (key.match(/group/gi)) {
            fieldsUserMapping.group = key;
          }
        }
        break;
      }
      for (let row of excelRows) {
        const rec = {
          username: row[fieldsUserMapping.username],
          password: row[fieldsUserMapping.password],
          firstname: row[fieldsUserMapping.firstname],
          middlename: row[fieldsUserMapping.middlename],
          lastname: row[fieldsUserMapping.lastname],
          email: row[fieldsUserMapping.email],
          groups: row[fieldsUserMapping.group],
        };
        if (rec.groups) {
          rec.groups = rec.groups
            .split(/[,;]+/gi)
            .map((g) => g.trim())
            .filter((g) => g.length > 0);
        } else {
          rec.groups = [];
        }
        records.push(rec);
      }
      this.groups = await this.getGroups();
      this.records = records;
      this.step = 2;
    },
    importRecords() {
      this.errors = [];
      const body = {
        users: this.records,
        mapping: this.groupMapping,
        updatePwd: this.forceUpdatePwd,
      };
      this.$http
        .post(api + "/rest/users/import", body)
        .then((res) => {
          this.$snotify.success("Import completed Successfully!", "Saved!", {
            timeout: 2000,
          });
          this.$emit("success");
        })
        .catch((res) => {
          if (res.status === 500) {
            this.$snotify.error("Error Import Users!", "Error");
          }
          this.$emit("fail");
        });
    },
    open() {
      jQuery(this.$el).modal("show");
      this.opened = true;
      $(".modal-backdrop").before($(this.$el));
      this.$emit("open");
      this.step = 1;
      this.currentTab = "xlsx";
      this.xlsxFile = null;
    },
    close() {
      jQuery(this.$el).modal("hide");
      this.opened = false;
      this.$snotify.clear();
    },
    back() {
      if (this.step > 1) this.step -= 1;
    },
  },
};
</script>


 