<template>
  <div class="container-main container-main--fullSize">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1 v-if="tile.id">Edit 3D Tile</h1>
        <h1 v-else>Build new 3D Tile</h1>

        <form action class="form" @submit.prevent="insertOrUpdate">
          <div class="alert alert-danger" v-if="errors.length">
            <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
          </div>
          <template v-if="tile.id">
            <div class="form-group">
              <label for="name">Name</label>
              <input
                id="name"
                name="name"
                class="form-control"
                type="text"
                v-model="tile.name"
              />
            </div>
          </template>
          <template v-else>
            <div class="row form-group">
              <label class="col-2 col-md-2" for="name">Name</label>
              <div class="col-10 col-md-4">
                <input
                  id="name"
                  name="name"
                  class="form-control mb-1"
                  type="text"
                  v-model="builder['name']"
                />
              </div>
              <label class="col-2 col-md-2" for="tileSize">Tile Size</label>
              <div class="col-10 col-md-4 mb-1">
                <input
                  id="tileSize"
                  name="tileSize"
                  class="form-control"
                  type="number"
                  min="0"
                  max="5000"
                  step="any"
                  v-model.number="builder['tileSize']"
                />
                <small>
                  Tiles size in your SR units (e.g. 1000 for UTM, 0.0025 for
                  WGS84)
                </small>
              </div>
              <label class="col col-2 col-md-2" for="datastore"
                >Datastore</label
              >

              <div class="col-10 col-md-4">
                <select
                  name="datastore"
                  class="form-control mb-1"
                  v-model="builder.datastore"
                  @change="datastoreChanged()"
                >
                  <option :value="''" disabled selected>
                    Select Datastore...
                  </option>
                  <option
                    v-for="datastore in datastores"
                    :key="datastore.id"
                    :value="datastore.id"
                  >
                    {{ datastore.name }}
                  </option>
                </select>
              </div>
              <label class="col col-2 col-md-2">Table:</label>
              <div class="col-10 col-md-4 mb-1">
                <select
                  name="table"
                  class="form-control mb-1"
                  v-model="builder.table"
                  :title="builder.table"
                  @change="tableChanged()"
                >
                  <option :value="''" disabled selected>Select Table...</option>
                  <option
                    v-for="table in tables"
                    :key="table.schema + '.' + table.name"
                    :value="table.schema + '.' + table.name"
                  >
                    {{ table.schema + "." + table.name }}
                  </option>
                </select>
              </div>
              <label class="col col-2 col-md-2">Z Field</label>
              <div class="col col-10 col-md-4 mb-1">
                <vue-typeahead-bootstrap
                  :data="fields"
                  v-model="builder.zField"
                  class=""
                  input-class="form-control"
                  :minMatchingChars="0"
                  :showOnFocus="true"
                ></vue-typeahead-bootstrap>
                <small>Ground elevation abose sea level in meters</small>
              </div>
              <label class="col col-2 col-md-2">Height Field</label>
              <div class="col col-10 col-md-4 mb-1">
                <vue-typeahead-bootstrap
                  :data="fields"
                  v-model="builder.heightField"
                  class=""
                  input-class="form-control"
                  :minMatchingChars="0"
                  :showOnFocus="true"
                ></vue-typeahead-bootstrap>
                <small>Building height in meters</small>
              </div>
              <label class="col col-2 col-md-2">X Field</label>
              <div class="col col-10 col-md-4 mb-1">
                <vue-typeahead-bootstrap
                  :data="fields"
                  v-model="builder.xField"
                  class=""
                  input-class="form-control"
                  :minMatchingChars="0"
                  :showOnFocus="true"
                ></vue-typeahead-bootstrap>
                <small
                  >Building X field (it will be used to indentify the tile of
                  the building)</small
                >
              </div>
              <label class="col col-2 col-md-2">Y Field</label>
              <div class="col col-10 col-md-4 mb-1">
                <vue-typeahead-bootstrap
                  :data="fields"
                  v-model="builder.yField"
                  class=""
                  input-class="form-control"
                  :minMatchingChars="0"
                  :showOnFocus="true"
                ></vue-typeahead-bootstrap>
                <small
                  >Building Y field (it will be used to indentify the tile of
                  the building)</small
                >
              </div>
              <label class="col col-2 col-md-2">Texture Package</label>
              <div class="col col-10 col-md-4 mb-1">
                <vue-typeahead-bootstrap
                  :data="[]"
                  v-model="builder.texturePackage"
                  class=""
                  input-class="form-control"
                  input-placeholder="default"
                  :minMatchingChars="0"
                  :showOnFocus="true"
                ></vue-typeahead-bootstrap>
                <small>Package of textures to use</small>
              </div>
              <div class="col col-12 mb-1">
                <div class="form-check">
                  <input
                    name="resume"
                    type="checkbox"
                    min="0"
                    class="form-check-input"
                    v-model="builder.collada"
                  />
                  <label class="form-check-label" for="resume"
                    >Generate Collada</label
                  >
                  <small> Generates zip files with collada files inside </small>
                </div>
              </div>
            </div>
          </template>
          <div class="form-group">
            <button type="submit" class="btn btn-primary">
              <span class="label" v-if="tile.id">Edit 3D Tile</span>
              <span class="label" v-else>Start building 3D Tile</span>
            </button>
          </div>
        </form>
        <loading :loading="loading || loadingDatastores || loadingTables"
          >Loading...</loading
        >
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import LoadingVue from "../../../components/loading/Loading.vue";
import eventBus from "../../../eventBus";
import api from "../../../api";
export default {
  components: {
    "admin-menu": AdminMenu,
    Loading: LoadingVue,
  },
  created() {
    this.initialLoad();
  },
  methods: {
    async initialLoad() {
      await this.loadDatastores();
      await this.loadTileDetail();
      if (this.builder.datastore) await this.loadTables();
      if (this.builder.datastore && this.builder.table) await this.loadFields();
    },
    async loadDatastores() {
      try {
        const res = await this.$http.get(api + "/rest/datastores");
        this.loadingDatastores = false;
        this.datastores = res.body;
      } catch (res) {
        this.loadingDatastores = false;
        console.error("Error getting datastores");
      }
    },
    async loadTables() {
      if (!this.builder.datastore) return;
      try {
        this.tables = [];
        this.loadingTables = true;
        const res = await this.$http.get(
          api + "/rest/datastores/" + this.builder.datastore + "/tables"
        );
        this.loadingTables = false;
        this.tables = res.body;
        this.$forceUpdate();
      } catch (res) {
        this.loadingTables = false;
        console.error(
          "Error getting tables for datastore with id " + this.builder.datastore
        );
      }
    },
    async loadFields() {
      console.log("getting fields...");
      this.fields = [];
      try {
        const res = await this.$http.get(
          api +
            "/rest/datastores/" +
            encodeURIComponent(this.builder.datastore) +
            "/tables/" +
            encodeURIComponent(this.builder.table) +
            "/fields"
        );
        this.fields = res.body;
      } catch (res) {
        console.error("Error getting fields", res);
        this.fields = [];
      }
    },
    async loadTileDetail() {
      if (this.$route.params.name) {
        this.loading = true;
        try {
          const res = await this.$http.get(
            api + "/rest/3dtiles/" + this.$route.params.name
          );
          this.loading = false;
          this.tile = res.body;
        } catch (res) {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        }
      }
    },
    datastoreChanged() {
      this.builder.table = "";
      if (this.builder.datastore) {
        this.loadTables();
      }
    },
    tableChanged() {
      this.fields = [];
      if (this.builder.datastore && this.builder.table) {
        this.loadFields();
      }
    },
    async insertOrUpdate() {
      if (this.tile.id) {
        await this.update();
      } else {
        await this.insert();
      }
    },
    async insert() {
      this.errors = [];
      var errors = [];
      const params = {};
      Object.keys(this.builder).forEach((k) => {
        if (this.builder[k]) params[k] = this.builder[k];
      });
      if (!params.datastore) errors.push("Datastore is required");
      if (!params.table) errors.push("Table is required");
      if (!params.zField) errors.push("Z Field is required");
      if (!params.heightField) errors.push("Height Field is required");
      if (errors.length) {
        this.errors = errors;
        return;
      } else {
        this.loading = true;
        try {
          const res = await this.$http.put(
            api + "/rest/proc/3dtile",
            this.builder
          );
          this.loading = false;
          console.log("##### /administration/process?from=3dtile");
          this.$router.push("/administration/process?from=3dtile");
        } catch (res) {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
            this.$router.push(
              "/login?redirect_uri=" + encodeURIComponent(this.$route.path)
            );
          } else if (res.status === 409) {
            if (res && res.body && res.body.error && res.body.error.message) {
              errors.push(res.body.error.message);
            }
          } else {
            errors.push(
              "Unexpected server error:" +
                (res && res.body && res.body.error && res.body.error.message)
            );
          }
          if (errors.length) this.errors = errors;
        }
      }
    },
    async update() {
      this.loading = true;
      const errors = [];
      try {
        const res = await this.$http.post(
          api + "/rest/3dtiles/" + encodeURIComponent(this.tile.id),
          { name: this.tile.name }
        );
        this.loading = false;
        console.log("##### /administration/process?from=3dtile");
        this.$router.push("/administration/3dtiles");
      } catch (res) {
        this.loading = false;
        if (res.status === 401) {
          eventBus.$emit("logout");
          this.$router.push(
            "/login?redirect_uri=" + encodeURIComponent(this.$route.path)
          );
        } else if (res.status === 409) {
          if (res && res.body && res.body.error && res.body.error.message) {
            errors.push(res.body.error.message);
          }
        } else {
          errors.push(
            "Error:" +
              (res && res.body && res.body.error && res.body.error.message)
          );
        }
        if (errors.length) this.errors = errors;
      }
    },
  },
  data() {
    return {
      tile: {},
      datastores: [],
      tables: [],
      fields: [],
      errors: [],
      loading: false,
      loadingDatastores: false,
      loadingTables: false,
      builder: {
        name: "",
        tileSize: 1000,
        datastore: null,
        table: "",
        xField: "x",
        yField: "y",
        zField: "z",
        heightField: "h",
        texturePackage: "",
        collada: true,
      },
    };
  },
};
</script>