<template>
  <div class="container">
    <h1>Administration Area</h1>
    <div class="row">
      <admin-menu></admin-menu>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./administration/Menu.vue";
export default {
  components: {
    "admin-menu": AdminMenu
  },
  created() {
    this.$router.push("/administration/users");
  },
  data() {
    return { user: null };
  }
};
</script>
