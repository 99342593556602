<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1>Terrain Details</h1>
        <form>
          <div class="alert alert-danger" v-if="errors.length">
            <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
          </div>

          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Id:</div>
            <div class="flex-grow-1 p-2">{{ terrain.id }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Name:</div>
            <div class="flex-grow-1 p-2">{{ terrain.name }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Description:</div>
            <div class="flex-grow-1 p-2">{{ terrain.description }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Zoom number:</div>
            <div class="flex-grow-1 p-2">{{ terrain.z }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-2 p-2 font-weight-bold">Zoom level:</div>
            <div class="flex-grow-1 p-2">
              {{ terrain.zoom ? terrain.zoom.join(", ") : "" }}
            </div>
          </div>
        </form>

        <span class="overlay align-self-center d-flex" v-if="loading">
          <div class="overlay-background bg-white opacity-50"></div>
          <div class="spinner-border text-primary m-auto" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import eventBus from "../../../eventBus";
import api from "../../../api";
export default {
  components: {
    "admin-menu": AdminMenu,
  },
  created() {
    this.loadTerrainDetails();
  },
  data() {
    return {
      terrain: [],
      errors: [],
    };
  },
  methods: {
    loadTerrainDetails() {
      if (this.$route.params.name) {
        this.loading = true;
        this.$http
          .get(api + "/rest/terrains/" + this.$route.params.name)
          .then((res) => {
            this.loading = false;
            this.terrain = res.body;
          })
          .catch((res) => {
            this.loading = false;
            if (res.status === 401) {
              eventBus.$emit("logout");
            } else if (res.body && res.body.error) {
              this.errors.push(res.body.error.message);
            } else {
              this.errors.push("Error " + res.status);
            }
          });
      }
    },
  },
};
</script>
