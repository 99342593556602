
<template>
  <div :class="{absolute: isAbsolute, isBlock }" class="input-group-append d-inline-block m-1">
    <button @click.stop type="button" class="input-group-text info info-popup ml-auto" data-placement="right"
      data-toggle="popover" data-custom-class="popover-xl" data-html="true" :title="name" :data-content="content">
      ?
    </button>
  </div>
</template>
<script>
import HelpContent from "../../xserver-common/components/HelpContent";
import eventBus from "./eventBus";
export default {
  name: "help-popup",
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  components: {

  },
  created() {
    eventBus.$on("close-dropdown-menus", () => {
      this.closePopoovers();
    });
    this.content = HelpContent.getHelpContentAdminByName(this.name);
  },
  mounted() {
    this.popover();
  },
  destroyed() {
    this.closePopoovers();
    this.$forceUpdate()
  },
  watch: {

  },
  methods: {
    popover() {
      $(".info.info-popup").popover({
        template: `<div class="popover popover-info popover-xl" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`,
      });
    },
    closePopoovers() {
      $(".popover ").popover("hide");
    },
  },
  data() {
    return { content: "", isAbsolute: false, isBlock: false };
  },
};
</script>
<style scoped>
.info.info-popup {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-append.absolute {
  position: absolute;
  top: 8px;
  right: 0;
  z-index: 2
}
.input-group-append.isBlock {
  display: block !important
}
</style>