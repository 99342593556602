<template>
  <div class="accordion w-100">
    <div
      :class="{
        'is-active': page.expanded,
      }"
      class="accordion-item w-100"
      v-for="page in pages"
      v-bind:key="page.title"
    >
      <button
        class="accordion-item-header btn d-flex w-100 text-left p-1"
        @click.prevent="togglePage(page)"
      >
        <span class="flex-grow-1">{{ page.title }}</span>
        <span
          :class="{
            oi: true,
            'icon icon-arrow-up2': page.expanded,
            'icon icon-arrow-down2': !page.expanded,
          }"
        ></span>
      </button>
      <keep-alive>
        <component
          v-bind:is="page.component"
          v-show="page.expanded"
          v-bind="page.data"
        ></component>
      </keep-alive>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pages: {
      required: true,
    },
    exclusive: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    togglePage(page) {
      page.expanded = !page.expanded;
      if (this.exclusive) {
        this.pages.forEach((p) => {
          if (p != page) p.expanded = false;
        });
      }
    },
  },
  data() {
    return {};
  },
};
</script>
