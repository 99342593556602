var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accordion w-100"},_vm._l((_vm.pages),function(page){return _c('div',{key:page.title,staticClass:"accordion-item w-100",class:{
      'is-active': page.expanded,
    }},[_c('button',{staticClass:"accordion-item-header btn d-flex w-100 text-left p-1",on:{"click":function($event){$event.preventDefault();return _vm.togglePage(page)}}},[_c('span',{staticClass:"flex-grow-1"},[_vm._v(_vm._s(page.title))]),_vm._v(" "),_c('span',{class:{
          oi: true,
          'icon icon-arrow-up2': page.expanded,
          'icon icon-arrow-down2': !page.expanded,
        }})]),_vm._v(" "),_c('keep-alive',[_c(page.component,_vm._b({directives:[{name:"show",rawName:"v-show",value:(page.expanded),expression:"page.expanded"}],tag:"component"},'component',page.data,false))],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }