<template>
  <div class="w-100 p-2">
    <help-popup :class="{'isAbsolute': true,}" :name="'Sides'"></help-popup>
    <div class="mb-2">
      <button class="btn btn-outline-primary" @click.prevent="newSide">
        <span class="icon icon-plus2"></span>
        <span>Add</span>
      </button>
    </div>
    <table class="table table-striped new-scenario-sides">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Group</th>
          <th>Affiliation</th>
          <th>Color</th>
          <th>Controller</th>
          <th v-if="false">Analyst</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!scenario.content.sides.length">
          <td colspan="5">No Side Defined</td>
        </tr>
        <tr v-for="(side, i) in scenario.content.sides" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ side.name }}</td>
          <td>{{ side.group | getGroupName }}</td>
          <td>{{ side.affiliation | getAffiliationName }}</td>
          <td>
            <!-- Se c'è il colore mostro l'anteprima con l'esadecimale -->
            <div v-if="side.color && side.color !== ''">
              <span
                class="preview-color-picker"
                v-bind:style="{ background: side.color }"
              ></span>
              <span>{{ side.color }}</span>
            </div>
            <div v-else>
              <span
                class="preview-color-picker"
                v-bind:style="{
                  background: getColorByAffiliation(side.affiliation),
                }"
              ></span>
            </div>
          </td>
          <td>{{ side.controller | yesNo }}</td>
          <td v-if="false">{{ side.analyst | yesNo }}</td>
          <td>
            <button
              class="btn btn-outline-primary icon icon-pencil4"
              @click.prevent="editSide(i)"
            ></button>
            <button
              class="btn btn-outline-danger icon icon-bin"
              @click.prevent="deleteSide(i)"
            ></button>
            <button
              :class="{
                btn: true,
                'btn-outline-secondary': true,
                icon: true,
                'icon-arrow-down2': true,
                disabled: disableMoveSideDown(i),
              }" @click.prevent="moveSideDown(i)"></button>
              <button :class="{
                btn: true,
                'btn-outline-secondary': true,
                icon: true,
                'icon-arrow-up2': true,
                disabled: disableMoveSideUp(i),
              }" @click.prevent="moveSideUp(i)"></button>
            </td>
          </tr>
        </tbody>
      </table>
      <div id="sideDialog" class="modal fade" data-keyboard="false">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <h3>Side</h3>
              <div class="form-group mb-2">
                <span class="label">Name:</span>
                <input type="text" class="form-control form-control-sm w-100" :class="{
                  'is-invalid': sideDialogInvalidName,
                }" v-model="sideDialog.name" @keydown.enter.prevent="confirmSideDialog()" />
              </div>
              <div class="form-group mb-2">
                <span class="label">Group:</span>
                <model-list-select class="form-control from-control-sm"
                  :class="{ 'is-invalid': sideDialogInvalidGroup }" :list="groups" v-model="sideDialog.group"
                  option-value="id" option-text="name" placeholder="Group..." />
              </div>
              <div class="form-group mb-2">
                <span class="label">Affiliation:</span>
                <model-list-select class="form-control from-control-sm" :list="affiliations"
                  v-model="sideDialog.affiliation" option-value="id" option-text="text" placeholder="affiliation..." />
              </div>
              <div class="form-group mb-2">
                <span class="label">Color:</span>
                <compact-picker v-model="sideDialog.color" @input="updateValue" />
                <div class="
                  input-group input-group-color-picker
                  col col-12 col-md-10
                ">
                  <span class="preview-color-picker" v-bind:style="{ background: sideDialog.color }"
                    v-if="sideDialog.color !== ''"></span>
                  <input class="form-control from-control-sm" v-model="sideDialog.color" placeholder="Color" />
                  <div class="input-group-append">
                    <button tabindex="0" class="btn btn-sm oi oi-pencil btn-outline-primary"
                      @click.stop.prevent="editColor()" />
                  </div>
                </div>
                <span v-if="isOpenColorPicker" class="color-picker">
                  <chrome-picker class="color-picker" :value="sideDialog.color" @input="updateValue"></chrome-picker>
                </span>
              </div>
              <div class="form-group mb-2">
                <span class="label">Controller:</span>
                <span style="display: inline-flex; vertical-align: middle">
                  <label class="
                    custom-control custom-checkbox
                    justify-content-start
                    d-inline-block
                    ml-4
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    v-model="sideDialog.controller"
                  />
                  
                  <span class="custom-control-label"></span>
                </label>
              </span>
              </div>
              <div v-if="false" class="form-group mb-2">
                <span class="label">Analyst:</span>
                <span style="display: inline-flex; vertical-align: middle">
                  <label
                    class="
                      custom-control custom-checkbox
                      justify-content-start
                      d-inline-block
                      ml-4
                    "
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      v-model="sideDialog.analyst"
                    />
                    
                    <span class="custom-control-label"></span>
                  </label>
                </span>
              </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-success" @click.prevent="confirmSideDialog">
              Ok
            </button>
            <button
              class="btn btn-secondary"
              @click.prevent="cancelSideDialog()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div id="sideDeleteDialog" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              Are you sure do you want to delete side "{{ sideDialog.name }}"
            </div>
            <div class="modal-footer">
              <button class="btn btn-danger" @click.prevent="confirmDeleteSide">
                Confirm
              </button>
              <button class="btn btn-secondary" @click.prevent="cancelDeleteSide">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Autocomplete from "vuejs-auto-complete";
import eventBus from "../../../eventBus";
import api from "../../../api";
import { getAffiliationByName } from "../../../../../xserver-common/utils";
import { ModelListSelect } from "vue-search-select";
import { Chrome } from "vue-color";
import { Compact } from "vue-color";
import stylesManager from "../../../../../xserver-client3d/src/util/stylesManager";
import HelpPopup from "../../../HelpPopup.vue";
var groupsMap = {};
export default {
  components: {
    autocomplete: Autocomplete,
    "model-list-select": ModelListSelect,
    "chrome-picker": Chrome,
    "compact-picker": Compact,
    "help-popup": HelpPopup
  },
  filters: {
    getGroupName(id) {
      if (!groupsMap[id]) return "(group id " + id + ")";
      return groupsMap[id].name;
    },
    getAffiliationName(id) {
      switch (id) {
        case "F":
          return "FRIEND";
        case "H":
          return "HOSTILE";
        case "N":
          return "NEUTRAL";
        case "U":
          return "UNKNOWN";
        default:
          return "UNDEFINED";
      }
    },
    yesNo(b) {
      return b ? "Yes" : "No";
    },
  },
  props: ["scenario"],
  created() {
    this.$http
      .get(api + "/rest/groups")
      .then((res) => {
        res.body.forEach((group) => {
          groupsMap[group.id] = group;
        });
        this.groups = res.body;
        this.$forceUpdate();
      })
      .catch((res) => {
        if (res.status === 401) {
          eventBus.$emit("logout");
        } else if (res.body && res.body.error) {
          console.error("Error Getting groups", res.body.error);
        } else {
          console.error("Error Getting groups, status:", res.status);
        }
      });
    this.scenario.content.sides.forEach((side) => {
      if (!side.affiliation) side.affiliation = getAffiliationByName(side.name);
    });
  },
  mounted() {
    $("#sideDialog").on("shown.bs.modal", (evt) => {
      $("#sideDialog").find("input").first().focus();
    });
    $("#sideDialog").on("keydown", (evt) => {
      if (evt.keyCode == 13) {
        evt.preventDefault();
        this.confirmSideDialog();
      }
    });
    document.addEventListener(
      "click",
      function (event) {
        // If the click inside the element, do nothing
        if (event.target.closest(".color-picker")) return;

        // If the clicks outside the element, hide it!
        this.isOpenColorPicker = false;
      }.bind(this)
    );
  },
  watch: {},
  computed: {},
  methods: {
    getColorByAffiliation: stylesManager.getColorByAffiliation,
    editColor() {
      if (this.isOpenColorPicker === true) {
      } else {
        this.isOpenColorPicker = true;
      }
    },
    updateValue(value) {
      console.log(value);
      this.sideDialog.color = value.hex;
    },
    disableMoveSideUp(index) {
      return index <= 0;
    },
    disableMoveSideDown(index) {
      return index >= this.scenario.content.sides.length - 1;
    },
    moveSideUp(index) {
      if (index == 0) return;
      var side = this.scenario.content.sides[index];
      this.scenario.content.sides.splice(index, 1);
      this.scenario.content.sides.splice(index - 1, 0, side);
    },
    moveSideDown(index) {
      if (index == this.scenario.content.sides.length - 1) return;
      var side = this.scenario.content.sides[index];
      this.scenario.content.sides.splice(index, 1);
      this.scenario.content.sides.splice(index + 1, 0, side);
    },
    deleteSide(index) {
      const side = this.scenario.content.sides[index];
      if (!side) return;
      console.log("sides:", side);
      this.sideDialog = {
        id: index,
        name: side.name,
        group: side.group,
        affiliation: side.affiliation,
        color: side.color,
      };
      $("#sideDeleteDialog").modal("show");
    },
    confirmDeleteSide() {
      this.scenario.content.sides.splice(this.sideDialog.id, 1);
      $("#sideDeleteDialog").modal("hide");
    },
    cancelDeleteSide() {
      $("#sideDeleteDialog").modal("hide");
    },
    editSide(index) {
      const side = this.scenario.content.sides[index];
      if (!side) return;
      if (typeof side.controller === "undefined") {
        side.controller = false;
      }
      if (typeof side.analyst === "undefined") {
        side.analyst = false;
      }
      this.sideDialog = {
        id: index,
        name: side.name,
        group: side.group,
        affiliation: side.affiliation,
        controller: side.controller,
        analyst: side.analyst,
        color: side.color || "",
      };
      //Se esiste il side ed è valorizzato coloro l'anteprima
      if (side && side.color !== "") {
        //$(".preview-color-picker").css("background-color", side.color);
      }
      //this.$refs.autocomplete.value = side.group;
      //this.$refs.autocomplete.selectedId = side.group;
      //this.$refs.autocomplete.display = this.getGroupName(side.group);
      //this.$refs.autocomplete.selectedDisplay = this.$refs.autocomplete.display;
      $("#sideDialog").modal("show");
    },
    newSide() {
      this.sideDialog = {
        id: -1,
        name: "",
        group: null,
        affiliation: "F",
        controller: false,
        analyst:false,
        color: "",
      };
      this.$forceUpdate();
      $("#sideDialog").modal("show");
    },
    confirmSideDialog() {
      const invalidName = this.sideDialogInvalidName;
      const invalidGroup = this.sideDialogInvalidGroup;
      console.log(
        "Confirm Side Dialog:",
        {
          id: this.sideDialog.id,
          name: this.sideDialog.name,
          affiliation: this.sideDialog.affiliation,
          group: this.sideDialog.group,
          color: this.sideDialog.color,
        },
        "invalid name:",
        invalidName,
        "invalid group:",
        invalidGroup
      );
      this.$forceUpdate();
      if (invalidName || invalidGroup) return;
      if (this.sideDialog.id == -1) {
        this.scenario.content.sides.push({
          name: this.sideDialog.name,
          group: this.sideDialog.group,
          affiliation: this.sideDialog.affiliation,
          controller: this.sideDialog.controller,
          analyst: this.sideDialog.analyst,
          color: this.sideDialog.color,
        });
        $("#sideDialog").modal("hide");
      } else {
        this.scenario.content.sides[this.sideDialog.id] = {
          name: this.sideDialog.name,
          group: this.sideDialog.group,
          affiliation: this.sideDialog.affiliation,
          controller: this.sideDialog.controller,
          analyst: this.sideDialog.analyst,
          color: this.sideDialog.color,
        };
        $("#sideDialog").modal("hide");
      }
    },
    sideDialogGroupEnterKey() {
      console.log("sideDialogGroupEnterKey", arguments);
    },
    cancelSideDialog() {
      $("#sideDialog").modal("hide");
    },
    groupSelected(evt) {
      this.sideDialog.group = evt.value;
    },
    getGroupName(group) {
      if (group == null) return "";
      return group.name || groups[group].name;
    },
  },
  computed: {
    sideDialogInvalidName() {
      var valid = true;
      if (!this.sideDialog.name) valid = false;
      this.scenario.content.sides.forEach((s, i) => {
        if (
          i != this.sideDialog.id &&
          s.name.toLowerCase() == this.sideDialog.name.toLowerCase().trim()
        )
          valid = false;
      });
      return !valid;
    },
    sideDialogInvalidGroup() {
      return this.sideDialog.group ? false : true;
    },
  },
  data() {
    return {
      sideDialog: {
        id: -1,
        name: "",
        group: 4,
        affiliation: "F",
        controller: false,
        analyst:false,
        color: "",
      },
      affiliations: [
        { id: "F", text: "FRIEND" },
        { id: "N", text: "NEUTRAL" },
        { id: "H", text: "HOSTILE" },
        { id: "U", text: "UNKNOWN" },
      ],
      isOpenColorPicker: false,
      groups: [],
    };
  },
};
</script>
<style>
.input-group.input-group-color-picker {
  max-width: 100%;
  padding: 0;
  display: inline-flex;
}

.preview-color-picker {
  display: inline-flex;
  width: 40px;
  height: calc(1.5em + 0.75rem + 2px);
  vertical-align: middle;
}

.input-group.input-group-color-picker+.color-picker {
  float: right;
}

.table .preview-color-picker {
  width: 25px;
  height: 25px;
}

.vc-compact {
  margin: 10px 0px;
  border-radius: 5px;
}
</style>