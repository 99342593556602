<template>
  <div>
    <help-popup :class="{'isAbsolute': true,}" :name="'Units'"></help-popup>
    <div v-if="scenario">
      <div class="units-edit d-flex" v-if="scenario.content.units">
        <span class="h2 flex-grow-1">
          <span v-if="scenario.content.units.type === 'luna'">Luna Wargame</span>
          <span v-if="
            scenario.content.units.type === 'up' || !scenario.content.units.type
          ">
            UP Wargame
          </span>
        </span>
        <button class="btn btn-outline-danger my-1" v-if="scenario.content.units"
          @click.prevent="$refs.confirmRemoveUnits.open()">
          Remove Units Section
        </button>
      </div>
      <units-section-up :scenario="scenario" :datastores="datastores" :markupPopupUnit="markupPopupUnit"
        :markupPopupUnitWithTab="markupPopupUnitWithTab" 
        :markupPopupUnitReference="markupPopupUnitReference" v-if=" 
          scenario.content.units &&
          (!scenario.content.units.type || scenario.content.units.type === 'up')
        " /> <!-- IP00 modificato GARC DSOL -->
      <units-section-luna :scenario="scenario" :datastores="datastores" :markupPopupUnit="markupPopupUnit"
        :markupPopupUnitWithTab="markupPopupUnitWithTab" 
        :markupPopupUnitReference="markupPopupUnitReference" v-if="
          scenario.content.units &&
          scenario.content.units.type === 'luna' &&
          $store.state.serverInfo.features.luna
        " /><!-- IP00 modificato GARC DSOL -->
      <units-section-custom :scenario="scenario" :datastores="datastores" :markupPopupUnit="markupPopupUnit"
        :markupPopupUnitWithTab="markupPopupUnitWithTab"
        :markupPopupUnitReference="markupPopupUnitReference"
        v-if="scenario.content.units && scenario.content.units.type === 'custom'" /><!-- IP00 modificato GARC DSOL -->
      <div v-if="
        scenario.content.units &&
        scenario.content.units.type === 'luna' &&
        !$store.state.serverInfo.features.luna
      ">
        Luna Wargame not available
      </div>
      <div>
        <button class="btn btn-outline-primary my-1" :class="{ active: upWargame }" @click.prevent="setType('up')"
          v-if="!scenario.content.units">
          UP Wargame
        </button>
        <button class="btn btn-outline-primary my-1" :class="{ active: lunaWargame }"
          v-if="!scenario.content.units && $store.state.serverInfo.features.luna" @click.prevent="setType('luna')">
          Luna Wargame
        </button>
        <button class="btn btn-outline-primary my-1" :class="{ active: customWargame }" v-if="!scenario.content.units"
          @click.prevent="setType('custom')">
          Custom Wargame
        </button>
      </div>
      <confirm-dialog ref="confirmRemoveUnits"
        :body="'Are you sure do you want to delete units section? All your units will be lost!'"
        confirmBtnClasses="btn-danger" @confirm="confirmRemoveUnits" @cancel="$refs.confirmRemoveUnits.close()">
      </confirm-dialog>
      <loading :loading="loadingTables" />
    </div>
  </div>
</template>
<script>
import eventBus from "../../../eventBus";
import UnitsSourceVue from "./UnitsSource.vue";
import api from "../../../api";
import UnitsSectionUPVue from "./UnitsSectionUP.vue";
import UnitsSectionLunaVue from "./UnitsSectionLuna.vue";
import UnitsSectionCustomVue from "./UnitsSectionCustom.vue";
import HelpPopup from "../../../HelpPopup.vue";
export default {
  name: "units-section",
  props: ["scenario", "datastores"],
  components: {
    "units-section-up": UnitsSectionUPVue,
    "units-section-luna": UnitsSectionLunaVue,
    "units-section-custom": UnitsSectionCustomVue,
    "help-popup": HelpPopup
  },
  created() {
    eventBus.$on("scenario-vignettes-changed", this.initialize.bind(this));
    this.initialize();
  },
  watch: {
    scenario() {
      // when scenario data load ensure units default value and load datastores tables
      this.initialize();
    },
  },
  computed: {
    upWargame() {
      return (
        this.scenario &&
        this.scenario.content.units &&
        (!this.scenario.content.units.type ||
          this.scenario.content.units.type === "up")
      );
    },
    lunaWargame() {
      return (
        this.scenario &&
        this.scenario.content.units &&
        this.scenario.content.units.type === "luna"
      );
    },
    customWargame() {
      return (
        this.scenario &&
        this.scenario.content.units &&
        this.scenario.content.units.type === "custom"
      );
    },
  },
  methods: {
    initialize() {
      if (!this.scenario.content.units) return; // nothing to do
      if (!this.scenario.content.units.iconsType) {
        this.scenario.content.units.iconsType = "app6b";
      }
      if (!this.scenario.content.units.popup) {
        this.scenario.content.units.popup = {
          type: "templated",
          template: this.markupPopupUnit,
          templateType: "classic",
        };
        this.$forceUpdate();
      }
      if (!this.scenario.content.units.icons) {
        this.scenario.content.units.icons = {
          type: "app6b",
        };
        this.$forceUpdate();
      }
      // adjust sources number according to vignettes
      if (Array.isArray(this.scenario.content.vignettes)) {
        // add sources if missing
        while (
          this.scenario.content.units.sources.length <
          this.scenario.content.vignettes.length
        ) {
          this.scenario.content.units.sources.push({
            type: "raw",
            records: [],
          });
        }
        // remove sources if there are to many
        while (
          this.scenario.content.units.sources >
          this.scenario.content.vignettes.length
        ) {
          this.scenario.content.units.sources.pop();
        }
        // source cleanup, only type raw with records are allowed
        for (let source of this.scenario.content.units.sources) {
          if (typeof source.records === "undefined") source.records = [];
          source.type = "raw";
          for (let key in source) {
            if (key !== "type" && key !== "records") delete source[key];
          }
        }
      }
      this.$forceUpdate();
    },
    setType(type) {
      if (!type) {
        if (this.scenario.content.units) delete this.scenario.content.units;
      } else {
        if (!this.scenario.content.units) {
          this.scenario.content.units = {
            enabled: true,
            turns: true,
            limitMovements: false,
            sources: [],
            type: type,
            visibility: "lastTurn",
            startTurn0: false,
            popup: {
              type: "templated",
              template: this.markupPopupUnit,
              templateType: "classic",
            },
            iconsType: "app6b",
          };
          if (type === "luna") {
            this.scenario.content.units.layerNoFlyZones = "";
            this.scenario.content.units.layerBlueSpots = "";
            this.scenario.content.units.layerRedSpots = "";
            this.scenario.content.units.limitMovements = true;
            this.scenario.content.units.layerTargets = "";
            this.scenario.content.units.visibility = "field";
            this.scenario.content.units.startTurn0 = true;
          }
        }
        this.initialize();
      }
      this.$forceUpdate();
    },
    addUnit(source) {
      if (!source.records) return;
      source.records.push({
        unit_id: ++this.idSeq,
        name: "",
        owner: this.scenario.content.sides[0].name,
        parent_id: null,
        parent_name: "",
        symbol_id: "",
        radius: 0,
        strength: 100,
        capabilities: "",
        long: null,
        lat: null,
      });
    },
    confirmRemoveUnits() {
      this.setType();
      this.$refs.confirmRemoveUnits.close();
    },
  },
  data() {
    return {
      tables: {},
      loadingTables: false,
      idSeq: 0,
      markupPopupUnit: `<table class='table table-sm table-striped orbat-properties felx-grow-1'>
              <div class="symbol_preview">{{{unit.symbol_preview}}}</div>
          
          <tbody>
            <tr>
                <th>Unit:</th>
                <td>{{unit.name}}</td>
            </tr>
            <tr>
                <th>Category:</th>
                <td>{{unit.category}}</td>
            </tr>
            <tr>
                <th>Symbol:</th>
                <td>{{unit.symbol}}</td>
            </tr>
            <tr>
                <th>Strength:</th>
                <td>
                   {{#getStrengthWithClass}}{{/getStrengthWithClass}}
                </td>
            </tr>
            <tr>
                <th>Capabilities:</th>
                <td>
                    <pre>{{unit.capabilities}}</pre>
                </td>
            </tr>
            <tr>
                <th>Long:</th>
                <td>{{#toPrecision}}{{unit.lon}},6{{/toPrecision}}</td>
            </tr>
            <tr>
                <th>Lat:</th>
                <td>{{#toPrecision}}{{unit.lat}},6{{/toPrecision}}</td>
            </tr>
            <tr>
              <th>Elev:</th>
              <td>{{unit.elev}}</td>
            </tr>
        </tbody>
    </table>`,
      markupPopupUnitWithTab: `<ul class="nav nav-tabs nav-justified">
   <li class="nav-item"><a class="nav-link active h-100" href="#general" data-toggle="tab">General</a></li>
   <li class="nav-item"><a class="nav-link h-100" href="#geo" data-toggle="tab">Geo Info</a></li>
   <li class="nav-item"><a class="nav-link h-100" href="#extra" data-toggle="tab">Extra</a></li>
</ul>

<div class="tab-content">
   <!--Content tab 1-->
   <div class="tab-pane active" id="general">
      <table class='table table-sm table-striped orbat-properties felx-grow-1'>
         <div class="symbol_preview m-2">{{{unit.symbol_preview}}}</div>
         <tbody>
            <tr>
               <th>Unit:</th>
               <td>{{unit.name}}</td>
            </tr>
            <tr>
               <th>Category:</th>
               <td>{{unit.category}}</td>
            </tr>
            <tr>
               <th>Symbol:</th>
               <td>{{unit.symbol}}</td>
            </tr>
            <tr>
               <th>Strength:</th>
               <td>
                  {{#getStrengthWithClass}}{{/getStrengthWithClass}}
               </td>
            </tr>
            <tr>
               <th>Capabilities:</th>
               <td>
                  <pre>{{unit.capabilities}}</pre>
               </td>
            </tr>
         </tbody>
      </table>
   </div>
    <!--Content tab 2-->
   <div class="tab-pane" id="geo">
      <table class='table table-sm table-striped orbat-properties felx-grow-1'>
         <div class="symbol_preview m-2">{{{unit.symbol_preview}}}</div>

         <tbody>
            <tr>
               <th>Long:</th>
               <td>{{#toPrecision}}{{unit.lon}},6{{/toPrecision}}</td>
            </tr>
            <tr>
               <th>Lat:</th>
               <td>{{#toPrecision}}{{unit.lat}},6{{/toPrecision}}</td>
            </tr>
            <tr>
               <th>Elev:</th>
               <td>{{unit.ele}}</td>
            </tr>
         </tbody>
      </table>
   </div>
   <!--Content tab 3-->
   <div class="tab-pane" id="extra">
      <table class='table table-sm table-striped orbat-properties felx-grow-1'>
         <div class="symbol_preview m-2">{{{unit.symbol_preview}}}</div>
      </table>
   </div>
</div>`,
// IP00 inizio modifiche GARC e DSOL - Inserimonto codice html 
markupPopupUnitReference:  `<table class='table table-sm table-striped orbat-properties felx-grow-1'>
              <div class="symbol_preview">{{{unit.symbol_preview}}}</div>

    <style>
	button {
		display: inline-block;
		padding: 0px 10px;
		font-size: 16px;
		background-color: #4CAF50;
		color: #fff;
		border: none;
		border-radius: 20px;
		cursor: pointer;
		margin-right: 0px;
                width: 120px; 
		height: 40px; 
		}
    </style>

          <tbody>
            <tr>
               <th class="text-center" colspan="100%">PMESII-DIME Cards:</th>
            </tr>     
            <tr>
               <td class="text-center"> <button onclick="window.open('https://jamboard.google.com/d/1bwbC3zbXNE4VXoSmxsQfgZfQihO_8bm22egJdxG4NY8/viewer?f=1');">Diplomatic</button> </td>
            </tr>
            <tr>
               <td class="text-center"> <button onclick="window.open('https://jamboard.google.com/d/1bwbC3zbXNE4VXoSmxsQfgZfQihO_8bm22egJdxG4NY8/viewer?f=3',);">Military </button> </td>
            </tr>
            <tr>
               <td class="text-center" > <button onclick="window.open('https://jamboard.google.com/d/1bwbC3zbXNE4VXoSmxsQfgZfQihO_8bm22egJdxG4NY8/viewer?f=4',);">Economic </button> </td>
            </tr>
            <tr>
               <td class="text-center" > <button onclick="window.open('https://jamboard.google.com/d/1bwbC3zbXNE4VXoSmxsQfgZfQihO_8bm22egJdxG4NY8/viewer?f=3',);"> Social  </button> </td>
            </tr>
            <tr>
               <td class="text-center" > <button onclick="window.open('https://jamboard.google.com/d/1bwbC3zbXNE4VXoSmxsQfgZfQihO_8bm22egJdxG4NY8/viewer?f=3',);">Infrastructure</button> </td>
            </tr>
            <tr>
               <td class="text-center" > <button onclick="window.open('https://jamboard.google.com/d/1bwbC3zbXNE4VXoSmxsQfgZfQihO_8bm22egJdxG4NY8/viewer?f=2',);"> Informational  </button> </td>
            </tr>
        </tbody>
</table> `,

// IP00 Fine modifiche GARC e DSOL

    };
  },
};
</script>
<style scoped>
.input-group-append.d-inline-block.m-1.isAbsolute{
  top: 0px;
}
</style>