<template>
  <div class="mapJournalRawJson">
    <div @click.stop.prevent="show = !show" class="btn btn-sm px-0">
      <span
        class="icon"
        :class="{ 'icon-arrow-down2': show, 'icon-arrow-right2': !show }"
      />
      <b class="ml-1">Raw JSON</b>
    </div>
    <div class="row" v-if="show">
      <div class="col col-12">
        <button
          class="btn btn-sm btn-outline-secondary icon icon-reload m-1"
          @click.prevent="load"
        />
        <button
          class="btn btn-sm btn-outline-success icon icon-checkmark m-1"
          @click.prevent="apply"
        />
      </div>
      <div class="col col-12">
        <v-jsoneditor v-model="json" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "map-journal-raw-json",
  props: ["scenario"],
  created() {
    this.load();
  },
  methods: {
    load() {
      if (this.scenario.mapJournal) {
        this.json = JSON.parse(JSON.stringify(this.scenario.mapJournal));
      } else {
        this.json = null;
      }
    },
    apply() {
      Object.keys(this.json).forEach((key) => {
        this.scenario.mapJournal[key] = this.json[key];
      });
      this.load();
    },
  },
  data() {
    return {
      show: false,
      json: null,
    };
  },
};
</script>