<template>
  <div class="col col-12 mt-1">
    <div>
      <label class="col-4 col-md-3 justify-content-end p-0">Icons type</label>
      <select
        v-if="!readOnly"
        class="
          col col-sm-12 col-md-6 col-lg-3
          form-control form-control-sm
          mb-1
          d-inline
          icons-type
        "
        v-model="units.iconsType"
      >
        <option value="app6b">APP-6B</option>
        <option value="app6d">APP-6D</option>
      </select>
      <span v-else v-html="units.iconsType"></span>
    </div>
  </div>
</template>
<script>
import eventBus from "../../../eventBus";

export default {
  name: "units-icon-type",
  props: ["units"],
  components: {},
  created() {
    this.updateReadOnly();
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    updateReadOnly() {
      const length = this.units.sources.length;
      let readOnly = false;
      //cycle the vignettes to see if there are any units
      for (let i = 0; i < length; i++) {
        if (this.units.sources[i].records.length) {
          readOnly = true;
          break;
        }
      }
      this.readOnly = readOnly;
    },
  },
  data() {
    return {
      readOnly: false,
    };
  },
};
</script>
