import "vue-search-select/dist/VueSearchSelect.css";
import "regenerator-runtime/runtime.js";
import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import VueResource from "vue-resource";
import VueSnofity from "vue-snotify";
import appRouter from "./appRouter";
import store from "./store";
import credentialsManager from "./CredentialsManager";
import AdminMenu from "./routes/administration/Menu.vue";
import StyleChooser from "./routes/administration/scenarios/StyleChooser.vue";
import StyleEditor from "./../../xserver-client3d/src/components/StyleEditor.vue";
import Loading from "./components/loading/Loading.vue";
import ConfirmDialog from "./components/confirm-dialog/ConfirmDialog.vue";
import Autocomplete from "vuejs-auto-complete";
import VJsonEditor from "v-jsoneditor";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";

// Global registration
Vue.component("vue-typeahead-bootstrap", VueTypeaheadBootstrap);
Vue.component("AdminMenu", AdminMenu);
Vue.component("loading", Loading);
Vue.component("autocomplete", Autocomplete);
Vue.component("confirm-dialog", ConfirmDialog);
Vue.component("v-jsoneditor", VJsonEditor);
Vue.component("style-chooser", StyleChooser);
Vue.component("style-editor", StyleEditor);
Vue.use(VueSnofity, {
  toast: {
    timeout: 0,
    closeOnClick: false,
    buttons: [{ text: "Close", bold: true, closeOnClick: true }],
  },
});
Vue.use(VueRouter);
Vue.use(VueResource);

console.log('## app')

credentialsManager.init();

store
  .dispatch("loadServerInfo")
  .then(() => {
    console.log("Server Info:", store.state.serverInfo);
    new Vue({
      el: "#app",
      render: h => h(App),
      router: appRouter,
      store,
    });
  })
  .catch(err => console.error(err));
