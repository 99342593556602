var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mapJournalVignetteEdit"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12 col-md-4 col-lg-4"},[_c('ul',{staticClass:"sections-list list-group"},[_vm._l((_vm.value),function(item,j){return _c('li',{key:j,staticClass:"list-group-item btn btn-sm text-left pt-0 pb-0",class:{
            active: item === _vm.editingItem,
            'cms-item-deleted': item.status === 'deleted',
          },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onItemClick(item)}}},[_vm._l((item.depth),function(n,index){return _c('span',{key:index,class:'ml-3'})}),_vm._v(" "),(item.type == 'file')?_c('span',{staticClass:"icon icon-file-spreadsheet"}):_vm._e(),_vm._v(" "),(item.type == 'file')?_c('span',{staticClass:"cms-item-title"},[_vm._v("\n            "+_vm._s(item.props.title || "(new files)"))]):_vm._e(),_vm._v(" "),(item.type == 'dir')?_c('span',{staticClass:"icon icon-documents"}):_vm._e(),_vm._v(" "),(item.type == 'dir')?_c('span',{staticClass:"cms-item-title"},[_vm._v("\n            "+_vm._s(item.props.title || "(new directory)"))]):_vm._e()],2)}),_vm._v(" "),(!_vm.value || !_vm.value.length)?_c('li',{staticClass:"list-group-item bg-light"},[_vm._v("\n          No Files here\n        ")]):_vm._e()],2)]),_vm._v(" "),(_vm.editingItem)?_c('cms-item-property',{staticClass:"col-12 col-md-8 col-lg-8",attrs:{"scenario":_vm.scenario,"dirTree":_vm.dirTree,"availableVignettes":_vm.scenario.content.vignettes,"availableSides":_vm.scenario.content.sides
          .filter((side) => !side.controller)
          .map((side) => side.name),"refs":"cmsItemProperty"},on:{"updateFilesTreeEdit":_vm.updateFilesTreeEdit},model:{value:(_vm.editingItem),callback:function ($$v) {_vm.editingItem=$$v},expression:"editingItem"}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn btn-sm px-0"},[_c('span',{staticClass:"icon icon-home"}),_vm._v(" "),_c('b',{staticClass:"ml-1"},[_vm._v("/")])])
}]

export { render, staticRenderFns }