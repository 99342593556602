import "regenerator-runtime/runtime.js";
import "./assets/css/app.scss";
import store from "./store";
fetch("app/config.json")
  .then(response => response.json())
  .then(config => {
    window.JMSS = {
      config: config,
    };
    return store.dispatch("loadServerInfo");
  })
  .then(() => {
    const app = require("./app");
  });
