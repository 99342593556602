<template>
  <div>
    <help-popup :class="{'isAbsolute': true,}"  :name="'Raw JSON'"></help-popup>
    <div>
      <div class="rawSection-edit buttons-container mb-1">
        <button class="btn btn-sm btn-outline-secondary icon icon-reload m-1" @click.prevent="load" />
        <button class="btn btn-sm btn-outline-success icon icon-checkmark m-1" @click.prevent="apply" />
      </div>
      <v-jsoneditor v-model="json" />
    </div>
  </div>
</template>
<script>
import HelpPopup from "../../../HelpPopup.vue";
export default {
  name: "raw-section",
  props: ["scenario"],
  components: {
    "help-popup": HelpPopup
  },
  created() {
    this.load();
  },
  watch: {
    scenario(newValue, oldValue) {
      this.load();
    },
  },
  methods: {
    load() {
      this.json = JSON.parse(JSON.stringify(this.scenario));
    },
    apply() {
      Object.keys(this.json).forEach((key) => {
        this.scenario[key] = this.json[key];
      });
      this.load();
    },
  },
  data() {
    return { json: null };
  },
};
</script>
<style scoped>
.input-group-append.d-inline-block.m-1.isAbsolute{
  top: 0px;
}
</style>