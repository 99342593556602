<template>
  <div class="event-source-form">
    <form @submit.prevent="">
      <div class="form-group row">
        <label for="event-name" class="col col-12 col-md-2">Name</label>
        <div class="input-group col col-12 col-md-10">
          <input
            id="event-name"
            placeholder="event name..."
            type="text"
            class="form-control form-control-sm"
            v-model.lazy="value.name"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="event-description" class="col col-12 col-md-2"
          >Description</label
        >
        <div class="input-group col col-12 col-md-10">
          <app-editor
            id="event-description"
            v-model="value.description"
            class="w-100"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="event-owner" class="col col-12 col-md-2">Side/Owner</label>
        <div class="input-group col col-12 col-md-10">
          <model-list-select
            id="event-owner"
            ref="owners"
            class="form-control form-control-sm"
            :list="owners"
            v-model="owner"
            option-value="id"
            option-text="text"
            placeholder="NONE"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="event-symbol" class="col col-12 col-md-2">Symbol ID</label>
        <div class="input-group col col-12 col-md-10">
          <input
            id="event-symbol"
            type="text"
            class="form-control form-control-sm"
            v-model="value.symbol_id"
          />
          <div class="input-group-append">
            <button
              class="btn btn-sm oi oi-pencil btn-outline-primary"
              @click.stop.prevent="$emit('editSymbolClick')"
            />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="event-loc-x" class="col col-12 col-md-2">Location</label>
        <div class="input-group col col-12 col-md-10">
          <div class="row flex-grow-1">
            <div class="col-md-4">
              <input
                id="event-loc-x"
                type="number"
                class="form-control form-control-sm coord-field mb-1"
                step="any"
                v-model.number="value.long"
                placeholder="Longitude/X"
              />
            </div>
            <div class="col-md-4">
              <input
                id="event-loc-y"
                type="number"
                class="form-control form-control-sm coord-field mb-1"
                step="any"
                v-model.number="value.lat"
                placeholder="Latitude/Y"
              />
            </div>
            <div class="col-md-4">
              <input
                id="event-loc-z"
                type="number"
                class="form-control form-control-sm coord-field mb-1"
                step="any"
                v-model.number="value.elev"
                placeholder="Elevation/Z"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="form-group row">
        <label for="event-name" class="col col-12 col-md-2"
          >Special Headquarters</label
        >
        <div class="input-group col col-12 col-md-10">
          <input
            id="event-special-headquarters"
            placeholder="Special Headquarters..."
            type="text"
            class="form-control form-control-sm"
            v-model="value.special_headquarters"
          />
        </div>
      </div> -->
      <div class="form-group row">
        <label for="event-name" class="col col-12 col-md-2">Created at:</label>
        <div class="input-group col col-12 col-md-10">
          <date-picker
            v-model="value.created_at"
            class="col-10 p-0"
            @datetime="onDateChange"
            :editingRecord="editingRecord"
            :value="value.created_at"
            :format="'YYYY/MM/DD, HH:mm'"
          ></date-picker>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <button
            class="btn btn-sm btn-outline-danger icon icon-cross"
            @click.stop.prevent="deleteRecord()"
          >
            Delete this event
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { ModelListSelect } from "vue-search-select";
import AppEditorVue from "../../../../../../xserver-common/components/AppEditor.vue";
import DatePickerVue from "../../../../../../xserver-common/components/DatePicker.vue";
export default {
  name: "events-source-form",
  props: {
    value: {
      required: true,
    },
    parent: {
      required: false,
    },
    owners: {
      required: true,
    },
    locations: {
      type: Boolean,
      default: true,
    },
    editingRecord: {
      required: true,
    },
  }, //["value", "owners", "parent", "edit"],
  components: {
    "model-list-select": ModelListSelect,
    "app-editor": AppEditorVue,
    "date-picker": DatePickerVue,
  },
  mounted() {},
  watch: {
    owner: function (newVal, oldVal) {
      this.value.owner = newVal;
      this.$emit("ownerChange", { newVal, oldVal, record: this.value });
    },
    value() {
      this.update();
    },
  },
  created() {
    this.update();
  },
  methods: {
    onDateChange(value) {
      this.value.created_at = value;
    },
    update() {
      this.owner = this.value.owner;
      this.symbol_id = this.value.symbol_id;
    },
    deleteRecord() {
      this.$emit("delete", this.value);
    },
  },
  data() {
    return {
      owner: "",
    };
  },
};
</script>
