<template>
  <div class="container-main container-main--fullSize">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1 class="d-inline-block align-middle" v-if="terrain.id">Edit Terrain</h1>
        <h1 class="d-inline-block align-middle" v-else>Build new Terrain</h1>
        <help-popup :name="'Terrains Building'"></help-popup>
        <form action class="form" @submit.prevent="insertOrUpdate">
          <div class="alert alert-danger" v-if="errors.length">
            <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
          </div>

          <div v-if="terrain.id">
            <div class="form-group">
              <label for="username">Id</label>
              <div class="flex-grow-1 p-2">{{ terrain.id }}</div>
            </div>
            <div class="form-group">
              <label for="username">Name</label>
              <input name="name" class="form-control" type="text" v-model="terrain.name" />
            </div>
            <div class="form-group">
              <label for="first_name">Description</label>
              <input name="description" class="form-control" type="text" v-model="terrain.description" />
            </div>
          </div>

          <div v-if="!terrain.id">
            <div class="row form-group">
              <label class="col-2 col-md-2" for="output-dir">Name</label>
              <div class="col-10 col-md-4 px-0">
                <input name="output-dir" class="form-control" type="text" v-model="builder['output-dir']" />
              </div>
              <label class="col-2 col-md-2" for="elevation">Elevation file</label>
              <div class="col-10 col-md-4 px-0">
                <select name="elevation" class="form-control" v-model="builder['elevation']" placeholder="select...">
                  <option value>select...</option>
                  <option v-for="elevation in elevations" :key="elevation" :value="elevation">
                    {{ elevation }}
                  </option>
                </select>
                <small> The file with elevation data </small>
              </div>
            </div>

            <div class="row form-group">
              <label class="col-2 col-md-2" for="output-format">Format</label>
              <div class="col-10 col-md-4 px-0">
                <select name="output-format" class="form-control" v-model="builder['output-format']"
                  placeholder="Format type...">
                  <option value>Format type...</option>
                  <option value="Terrain">Terrain</option>
                  <option value="Mesh">Mesh</option>
                </select>
                <small>
                  The output format for the tiles. This is either `Terrain` (the
                  default), `Mesh` (Chunked LOD mesh)
                </small>
              </div>
              <label class="col-2 col-md-2" for="profile">Profile</label>
              <div class="col-10 col-md-4 px-0">
                <select name="profile" class="form-control" v-model="builder.profile" placeholder="Profile type...">
                  <option value>Profile type...</option>
                  <option value="geodetic">Geodetic</option>
                  <option value="mercator">Mercator</option>
                </select>
                <small>
                  The TMS profile for the tiles. This is either `geodetic` (the
                  default) or `mercator`
                </small>
              </div>
            </div>

            <div class="row form-group">
              <label class="col-2 col-md-2" for="thread-count">Thread Count</label>
              <div class="col-10 col-md-4 px-0">
                <input name="thread-count" type="number" min="1" step="1" class="form-control"
                  v-model.number="builder['thread-count']" />
                <small>
                  The number of threads to use for tile generation. On multicore
                  machines this defaults to the number of CPUs
                </small>
              </div>
              <label class="col-2 col-md-2" for="tile-size">Tile Size</label>
              <div class="col-10 col-md-4 px-0">
                <input name="tile-size" type="number" min="0" class="form-control"
                  v-model.number="builder['thread-count']" />
                <small>
                  The size of the tiles in pixels. This defaults to 65 for
                  terrain tiles and 256 for other GDAL formats
                </small>
              </div>
            </div>

            <div class="row form-group">
              <label class="col-2 col-md-2" for="start-zoom">Start Zoom</label>
              <div class="col-10 col-md-4 px-0">
                <input name="start-zoom" type="number" min="1" step="1" class="form-control"
                  v-model.number="builder['start-zoom']" />
                <small>
                  The zoom level to start at. This should be greater than the
                  end zoom level. Default to 12
                </small>
              </div>
              <label class="col-2 col-md-2" for="end-zoom">End Zoom</label>
              <div class="col-10 col-md-4 px-0">
                <input name="end-zoom" type="number" min="0" class="form-control"
                  v-model.number="builder['end-zoom']" />
                <small>
                  The zoom level to end at. This should be less than the start
                  zoom level and >= 0
                </small>
              </div>
            </div>

            <div class="row form-group">
              <label class="col-2 col-md-2" for="resampling-method">Resampling Method</label>
              <div class="col-10 col-md-4 px-0">
                <select name="resampling-method" class="form-control" v-model="builder['resampling-method']"
                  placeholder="Resampling Method...">
                  <option value>Resampling Method...</option>
                  <option value="nearest">nearest</option>
                  <option value="bilinear">bilinear</option>
                  <option value="cubic">cubic</option>
                  <option value="cubicspline">cubicspline</option>
                  <option value="lanczos">lanczos</option>
                  <option value="average">average</option>
                  <option value="mode">mode</option>
                  <option value="max">max</option>
                  <option value="min">min</option>
                  <option value="med">med</option>
                  <option value="q1">q1</option>
                  <option value="q3">q3</option>
                </select>
                <small>
                  Specify the raster resampling algorithm. One of: nearest;
                  bilinear; cubic; cubicspline; lanczos; average; mode; max;
                  min; med; q1; q3. Defaults to average.
                </small>
              </div>
              <label class="col-2 col-md-2" for="error-threshold">
                Error Threshold
              </label>
              <div class="col-10 col-md-4 px-0">
                <input name="error-threshold" type="number" min="0" step="0.001" class="form-control"
                  v-model.number="builder['error-threshold']" />
                <small>
                  Specify the error threshold in pixel units for transformation
                  approximation. Larger values should mean faster transforms.
                  Defaults to 0.125
                </small>
              </div>
            </div>

            <div class="row form-group">
              <label class="col-2 col-md-2" for="warp-memory">Warp Memory</label>
              <div class="col-10 col-md-4 px-0">
                <input name="warp-memory" type="number" min="0" class="form-control"
                  v-model.number="builder['warp-memory']" />
                <small>
                  The memory limit in bytes used for warp operations. Higher
                  settings should be faster. Defaults to a conservative GDAL
                  internal setting.
                </small>
              </div>
              <label class="col-2 col-md-2" for="mesh-qfactor">Mesh Qfactor
              </label>
              <div class="col-10 col-md-4 px-0">
                <input type="number" name="mesh-qfactor" min="0" step="0.001" class="form-control"
                  v-model="builder['mesh-qfactor']" />
                <small>
                  specify the factor to multiply the estimated geometric error
                  to convert heightmaps to irregular meshes. Larger values
                  should mean minor quality. Defaults to 1.0
                </small>
              </div>
            </div>

            <div class="row form-group">
              <label class="col-2 col-md-2">Other Options</label>
              <div class="col-10 col-md-4 px-0">
                <div class="form-check">
                  <input name="resume" type="checkbox" min="0" class="form-check-input"
                    v-model.number="builder['resume']" />
                  <label class="form-check-label" for="resume">Resume</label>
                  <small> Do not overwrite existing files </small>
                </div>
                <div class="form-check">
                  <input name="cesium-friendly" type="checkbox" min="0" class="form-check-input"
                    v-model.number="builder['cesium-friendly']" />
                  <label class="form-check-label" for="cesium-friendly">Cesium-friendly</label>
                  <small>
                    Force the creation of missing root tiles to be
                    CesiumJS-friendly
                  </small>
                </div>
                <div class="form-check">
                  <input name="vertex-normals" type="checkbox" min="0" class="form-check-input"
                    v-model.number="builder['vertex-normals']" />
                  <label class="form-check-label" for="vertex-normals">Vertex normals</label>
                  <small>
                    Write 'Oct-Encoded Per-Vertex Normals' for Terrain Lighting,
                    only for `Mesh` format
                  </small>
                </div>
                <div class="form-check">
                  <input name="quiet" type="checkbox" min="0" class="form-check-input"
                    v-model.number="builder['quiet']" />
                  <label class="form-check-label" for="quiet">quiet</label>
                  <small> Only output errors </small>
                </div>
                <div class="form-check">
                  <input name="verbose" type="checkbox" min="0" class="form-check-input"
                    v-model.number="builder['verbose']" />
                  <label class="form-check-label" for="verbose">verbose</label>
                  <small> Be more noisy </small>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="btn btn-primary">
              <span class="label" v-if="terrain.id">Edit Terrain</span>
              <span class="label" v-else>Start building terrain</span>
            </button>
          </div>

          <span class="overlay align-self-center d-flex" v-if="loading">
            <div class="overlay-background bg-white opacity-50"></div>
            <div class="spinner-border text-primary m-auto" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import eventBus from "../../../eventBus";
import api from "../../../api";
import HelpPopup from "../../../HelpPopup.vue";
export default {
  components: {
    "admin-menu": AdminMenu,
    "help-popup": HelpPopup
  },
  created() {
    console.log("created");
    this.loadTerrainDetails();
    this.loadTerrainElevations();
  },
  data() {
    return {
      terrain: {},
      errors: [],
      loading: false,
      elevations: [],
      builder: {
        "output-dir": null,
        elevation: null,
        "output-format": "Mesh",
        profile: null,
        "thread-count": null,
        "tile-size": null,
        "start-zoom": 12,
        "end-zoom": 0,
        "resampling-method": "average",
        "error-threshold": 0.125,
        "warp-memory": null,
        resume: false,
        "mesh-qfactor": 1.0,
        layer: false,
        "cesium-friendly": true,
        "vertex-normals": false,
        quiet: false,
        verbose: false,
      },
    };
  },
  methods: {
    loadTerrainDetails() {
      if (this.$route.params.name) {
        this.loading = true;
        this.$http
          .get(api + "/rest/terrains/" + this.$route.params.name)
          .then((res) => {
            this.loading = false;
            this.terrain = res.body;
          })
          .catch((res) => {
            this.loading = false;
            if (res.status === 401) {
              eventBus.$emit("logout");
            } else if (res.body && res.body.error) {
              this.errors.push(res.body.error.message);
            } else {
              this.errors.push("Error " + res.status);
            }
          });
      }
    },
    loadTerrainElevations() {
      if (this.$route.params.name === undefined) {
        this.loading = true;
        this.$http
          .get(api + "/rest/terrains/elevations")
          .then((res) => {
            this.loading = false;
            this.elevations = res.body;
          })
          .catch((res) => {
            this.loading = false;
            if (res.status === 401) {
              eventBus.$emit("logout");
            } else if (res.body && res.body.error) {
              this.errors.push(res.body.error.message);
            } else {
              this.errors.push("Error " + res.status);
            }
          });
      }
    },
    insert() {
      this.errors = [];
      var errors = [];
      //remove null property
      Object.keys(this.builder).forEach(
        (k) => this.builder[k] == null && delete this.builder[k]
      );

      if (!this.builder["output-dir"]) errors.push("Name is required");
      if (!this.builder.elevation) errors.push("Elevation is required");
      if (errors.length) {
        this.errors = errors;
        return;
      } else {
        this.loading = true;
        this.$http
          .put(api + "/rest/proc/terrain", this.builder)
          .then((res) => {
            this.loading = false;
            console.log("##### /administration/process?from=terrain");
            this.$router.push("/administration/process?from=terrain");
          })
          .catch((res) => {
            this.loading = false;
            if (res.status === 401) {
              eventBus.$emit("logout");
              this.$router.push(
                "/login?redirect_uri=" + encodeURIComponent(this.$route.path)
              );
            } else if (res.status === 409) {
              if (res && res.body && res.body.error && res.body.error.message) {
                errors.push(res.body.error.message);
              }
            } else {
              errors.push(
                "Unexpected server error:" +
                (res && res.body && res.body.error && res.body.error.message)
              );
            }
            if (errors.length) this.errors = errors;
          });
      }
    },
    update() {
      this.errors = [];
      var errors = [];
      if (!this.terrain.name) errors.push("Username is required");

      if (errors.length) {
        this.errors = errors;
        return;
      } else {
        this.loading = true;
        delete this.terrain.z;
        delete this.terrain.zoom;
        this.$http
          .post(api + "/rest/terrains/" + this.terrain.id, this.terrain)
          .then((res) => {
            this.loading = false;
            this.$router.push("..");
          })
          .catch((res) => {
            if (res.status === 401) {
              eventBus.$emit("logout");
              this.$router.push(
                "/login?redirect_uri=" + encodeURIComponent(this.$route.path)
              );
            } else if (res.status === 409) {
              this.loading = false;
              if (res && res.body && res.body.error && res.body.error.message) {
                errors.push(res.body.error.message);
              }
            } else {
              this.loading = false;
              errors.push(
                "Unexpected server error:" +
                (res && res.body && res.body.error && res.body.error.message)
              );
            }
            if (errors.length) this.errors = errors;
          });
      }
    },
    insertOrUpdate() {
      if (this.terrain.id) {
        this.update();
      } else {
        this.insert();
      }
    },
  },
};
</script>
