<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div
        class="flex-grow-1 p-3 d-flex flex-column form-scenario"
        @submit.prevent="submit"
      >
        <h1 v-if="$route.params.id">Edit Scenario</h1>
        <h1 v-else>New Scenario</h1>
        <div>
          <errors-list :errors="errors" />
        </div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: editor === 'scenario' }"
              href="#"
              @click.stop.prevent="setEditor('scenario')"
              >Scenario Definition</a
            >
          </li>
          <li
            v-if="$store.state.serverInfo.features.mapJournal"
            class="nav-item"
          >
            <a
              class="nav-link"
              :class="{ active: editor === 'mapJournal' }"
              href="#"
              @click.stop.prevent="setEditor('mapJournal')"
              >Map Journal</a
            >
          </li>
          <li v-if="$store.state.serverInfo.features.cms" class="nav-item">
            <a
              class="nav-link"
              :class="{ active: editor === 'cms' }"
              href="#"
              @click.stop.prevent="setEditor('cms')"
              >CMS</a
            >
          </li>
        </ul>
        <accordion v-show="editor === 'scenario'" v-bind:pages="pages" />
        <map-journal-edit
          v-if="scenario && editor === 'mapJournal'"
          :scenario="scenario"
          @addMapJournal="$forceUpdate()"
          @removeMapJournal="$forceUpdate()"
        />
        <cms-edit
          v-if="scenario && editor === 'cms'"
          :scenario="scenario"
          @addCms="$forceUpdate()"
          @removeCms="$forceUpdate()"
        />
        <div class="form-group p-2 align-right">
          <button
            type="submit"
            class="btn btn-primary"
            @click.stop.prevent="submit"
          >
            <span v-if="$route.params.id">Apply</span>
            <span v-else>Add Scenario</span>
          </button>
          <button
            class="btn btn-primary ml-2"
            v-if="$route.params.id"
            @click.prevent.stop="submitNewScenario()"
          >
            <span v-if="$route.params.id">Save as New</span>
          </button>
          <button
            class="btn btn-secondary ml-2"
            @click.prevent.stop="$router.push('/administration/scenarios')"
          >
            Cancel
          </button>
        </div>
      </div>
      <loading :loading="loading || loadingDatastores" />
      <vue-snotify></vue-snotify>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import Accordion from "./../../../components/accordion/Accordion.vue";
import BaseInfoSection from "./BaseInfoSection.vue";
import VignettesSection from "./VignettesSection.vue";
import SidesSection from "./SidesSection.vue";
import DicesSection from "./DicesSection.vue";
import LayersSection from "./LayersSection.vue";
import LoadingVue from "../../../components/loading/Loading.vue";
import ErrorListVue from "../../../components/ErrorList.vue";
import BasemapsSectionVue from "./BasemapsSection.vue";
import StatisticsSection from "./StatisticsSection.vue";
import RoutingSection from "./RoutingSection.vue";
import LineOfSightSection from "./LineOfSightSection.vue";
import ClockServiceSectionVue from "./ClockServiceSection.vue";
//import AfterActionSectionVue from "./AfterActionSection.vue";
import AfterActionSectionVue from "./SimpleAARSection.vue";
import RawSectionVue from "./RawSection.vue";
import UnitsSection from "./UnitsSection.vue";
import MapJournalEditVue from "./map-journal/MapJournalEdit.vue";
import CmsEditVue from "./cms/CmsEdit.vue";
import EventsSection from "./events/EventsSection.vue";
import { getAffiliationByName } from "../../../../../xserver-common/utils";
import api from "../../../api";
export default {
  components: {
    "admin-menu": AdminMenu,
    accordion: Accordion,
    loading: LoadingVue,
    "errors-list": ErrorListVue,
    "map-journal-edit": MapJournalEditVue,
    "cms-edit": CmsEditVue,
  },
  created() {
    this.scenario = {
      name: "",
      content: {
        basemaps: [],
        vignettes: [],
        sides: [],
        layers: [],
        coordinates: { x: null, y: null },
        zoom: 2,
        minZoom: 2,
        maxZoom: 17,
        hla: {
          enabled: false,
          id: null,
        },
        issues: []
      },
    };
    this.pages = [
      {
        title: "Base Info",
        component: BaseInfoSection,
        expanded: true,
        data: { scenario: this.scenario },
      },
      {
        title: "Vignettes",
        component: VignettesSection,
        expanded: false,
        data: { scenario: this.scenario },
      },
      {
        title: "Sides",
        component: SidesSection,
        expanded: false,
        data: { scenario: this.scenario },
      },
      {
        title: "Dices",
        component: DicesSection,
        expanded: false,
        data: { scenario: this.scenario },
      },
      {
        title: "Layers",
        component: LayersSection,
        expanded: false,
        requireDatastore: true,
        data: { scenario: this.scenario, datastores: this.datastores },
      },
      {
        title: "Basemaps",
        component: BasemapsSectionVue,
        expanded: false,
        data: { scenario: this.scenario },
      },
      {
        title: "Statistics",
        component: StatisticsSection,
        expanded: false,
        requireDatastore: true,
        data: { scenario: this.scenario },
      },
      {
        title: "Routing",
        component: RoutingSection,
        expanded: false,
        requireDatastore: true,
        data: { scenario: this.scenario },
      },
      {
        title: "Line of Sight / Viewshed",
        component: LineOfSightSection,
        expanded: false,
        data: { scenario: this.scenario },
      },
      {
        title: "Units",
        component: UnitsSection,
        expanded: false,
        requireDatastore: true,
        data: { scenario: this.scenario, datastores: this.datastores },
      },
      {
        title: "Events",
        component: EventsSection,
        expanded: false,
        data: { scenario: this.scenario, datastores: this.datastores },
      },
      // {
      //   title: "Clock Service",
      //   component: ClockServiceSectionVue,
      //   expanded: false,
      //   data: { scenario: this.scenario },
      // },
      // {
      //   title: "After Action Review",
      //   component: AfterActionSectionVue,
      //   expanded: false,
      //   data: { scenario: this.scenario },
      // },
      {
        title: "Raw JSON",
        component: RawSectionVue,
        expanded: false,
        data: { scenario: this.scenario },
      },
    ];
    this.loadingDatastores = true;
    this.$http
      .get(api + "/rest/datastores")
      .then((res) => {
        this.loadingDatastores = false;
        this.datastores = res.body;
        for (let i = 0; i < this.pages.length - 1; i++) {
          if (
            this.pages[i].requireDatastore !== undefined &&
            this.pages[i].requireDatastore === true
          ) {
            this.pages[i].data.datastores = this.datastores;
          }
        }
      })
      .catch((res) => {
        this.loadingDatastores = false;
        console.error("Error getting datastores");
      });
    this.load();
  },
  destroyed() {
    this.$snotify.clear();
  },
  methods: {
    setEditor(editor) {
      this.editor = editor || "scenario";
    },
    load() {
      if (this.$route.params.id) {
        this.loading = true;
        this.$http
          .get(api + "/rest/scenarios/" + this.$route.params.id)
          .then((res) => {
            this.loading = false;
            this.scenario = res.body;
            if (!this.scenario.hasOwnProperty("mapJournal"))
              this.scenario.mapJournal = undefined;
            if (!this.scenario.hasOwnProperty("cms"))
              this.scenario.cms = undefined;
            if (!this.scenario.content.coordinates)
              this.scenario.content.coordinates = {
                x: null,
                y: null,
              };
            if (!this.scenario.content.zoom) this.scenario.content.zoom = null;
            if (!this.scenario.content.minZoom)
              this.scenario.content.minZoom = null;
            if (!this.scenario.content.maxZoom)
              this.scenario.content.maxZoom = null;
            if (!this.scenario.content.basemaps) {
              this.scenario.content.basemaps = [
                {
                  name: "Open Street Map",
                  thumb: "/basemaps/osm.png",
                  layers: [
                    {
                      type: "xyz",
                      url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    },
                  ],
                },
              ];
            }
            if (!this.scenario.content.units) {
              this.scenario.content.units = {
                enabled: false,
                turns: false,
                sources: [],
              };
            }
            this.scenario.content.sides.forEach((side) => {
              // normalize sides if affiliation is missing.
              if (!side.affiliation) {
                side.affiliation = getAffiliationByName(side.name);
              }
            });
            this.pages.forEach((p) => (p.data.scenario = this.scenario));
          })
          .catch((res) => {
            console.error("Error getting scenario info!");
            console.error(res);
            this.loading = false;
          });
      }
    },
    submit() {
      console.log("Submitting scenario:", this.scenario);
      if (!this.$route.params.id) this.submitNewScenario();
      else this.submitUpdateScenario();
    },
    submitNewScenario() {
      this.errors = [];
      this.loading = true;
      this.$snotify.clear();

      var scenarioFormData = new FormData();
      this.prepareScenarioFormData(scenarioFormData);

      this.$http
        .put(api + "/rest/scenarios/", scenarioFormData)
        .then((res) => {
          this.loading = false;
          this.$router.push("/administration/scenarios/" + res.body.id);
          this.scenario["cmsFiles"] = []; //clear file uploaded
          //update cms tab
          if (res.body.cms && res.body.cms !== null) {
            this.scenario.cms = res.body.cms;
          }
          this.$snotify.success("Save completed Successfully!", "Saved!", {
            timeout: 2000,
          });
        })
        .catch((res) => {
          this.loading = false;
          if (res.body && res.body.error)
            this.errors = [res.body.error.message];
          else if (
            res.body &&
            res.body.errors &&
            Array.isArray(res.body.errors)
          )
            this.errors = res.body.errors.map((e) => e.message);
          else this.errors = ["Unexpected error saving the secenario"];
          this.$snotify.error("Error Saving Scenario!", "Error");
          console.error("Error Saving Scenario...", res.body);
        });
    },
    submitUpdateScenario() {
      this.errors = [];
      this.loading = true;
      var scenarioFormData = new FormData();
      this.$snotify.clear();

      this.prepareScenarioFormData(scenarioFormData);

      this.$http
        .post(
          api + "/rest/scenarios/" + this.$route.params.id,
          scenarioFormData
        )
        .then((res) => {
          this.loading = false;
          this.scenario["cmsFiles"] = []; //clear file uploaded
          //update cms tab
          if (res.body.cms && res.body.cms !== null) {
            this.scenario.cms = res.body.cms;
          }

          this.$snotify.success("Save completed Successfully!", "Saved!", {
            timeout: 2000,
          });
        })
        .catch((res) => {
          this.loading = false;
          if (res.body && res.body.error)
            this.errors = [res.body.error.message];
          else if (
            res.body &&
            res.body.errors &&
            Array.isArray(res.body.errors)
          )
            this.errors = res.body.errors.map((e) => e.message);
          else this.errors = ["Unexpected error saving the secenario"];
          this.$snotify.error("Error Saving Scenario!", "Error");
          console.error("Error Saving Scenario...", res.body);
        });
    },
    prepareScenarioFormData(scenarioFormData) {
      const scenario = this.scenario;
      for (let [key, value] of Object.entries(this.scenario)) {
        if (key === "cmsFiles") {
          const uploadsIdx = [];
          for (var i = 0; i < value.length; i++) {
            scenarioFormData.append("uploadsFile", value[i].file);
            uploadsIdx.push(value[i].id);
          }
          scenarioFormData.append("uploadsIdx", JSON.stringify(uploadsIdx));
        } else {
          if (key === "cms" && value === undefined) continue;
          if (key === "name" && value === undefined) {
            scenarioFormData.append(key, value);
            continue;
          }
          scenarioFormData.append(key, JSON.stringify(value));
        }
      }
    },
  },
  data() {
    return {
      loading: false,
      loadingDatastores: false,
      scenario: null,
      pages: [],
      datastores: [],
      errors: [],
      editor: "scenario",
    };
  },
};
</script>
