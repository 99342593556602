<template>
  <div class="units-source">
    <div @click.stop.prevent="show = !show" class="btn btn-sm px-0">
      <span
        class="icon"
        :class="{ 'icon-arrow-down2': show, 'icon-arrow-right2': !show }"
      ></span>
      <b class="ml-1">{{ title }}</b>
      <span>({{ value ? value.length : 0 }} units)</span>
    </div>
    <div class="row pl-4" v-if="show">
      <div class="col col-12 mb-2">
        <button
          class="btn btn-sm btn-outline-primary icon icon-plus2"
          @click.prevent.stop="addUnit()"
        >
          Add unit
        </button>
        <button
          class="btn btn-sm btn-outline-secondary icon icon-plus2"
          @click.prevent.stop="$refs.importDS.open()"
        >
          Import
        </button>
        <button
          class="btn btn-sm btn-outline-secondary icon icon-arrow-down2"
          @click.prevent.stop="reorder()"
        >
          Reorder
        </button>
        <button
          class="btn btn-sm btn-outline-danger icon icon-bin2"
          @click.prevent.stop="$refs.confirmDeleteLocations.open()"
        >
          Delete Locations
        </button>
        <button
          class="btn btn-sm btn-outline-danger icon icon-bin2"
          @click.prevent.stop="$refs.confirmDeleteAll.open()"
        >
          Delete All
        </button>
      </div>
      <div class="col col-12 col-md-3 col-lg-2">
        <ul class="units-list list-group">
          <li
            v-for="(record, j) in value"
            :key="j"
            class="list-group-item btn btn-sm"
            :class="{
              active: record === editingRecord,
            }"
            @click.stop.prevent="onRecordClick(record)"
          >
            {{ record.name || "(no name)" }}
          </li>
          <li class="list-group-item bg-light" v-if="!value || !value.length">
            No units present
          </li>
        </ul>
      </div>
      <units-source-form
        v-model="editingRecord"
        v-if="editingRecord && value && value.length"
        :type="
          scenario &&
          scenario.content &&
          scenario.content.units &&
          scenario.content.units.type
        "
        :owners="sides"
        :parent="byId[editingRecord.parent_id]"
        @parentChange="onParentChange(editingRecord, $event)"
        @ownerChange="onOwnerChange(editingRecord, $event)"
        @delete="deleteRecordAt(value.indexOf(editingRecord))"
        @editSymbolClick="onEditSymbolClick(editingRecord, $event)"
        class="col-12 col-md-9 col-lg-10"
      />
    </div>

    <confirm-dialog
      ref="confirmDelete"
      :body="
        'Are you sure do you want to delete unit ' +
        getName(deletingIndex) +
        '?'
      "
      confirmBtnClasses="btn-danger"
      @confirm="confirmDelete"
      @cancel="$refs.confirmDelete.close()"
    ></confirm-dialog>
    <confirm-dialog
      ref="confirmDeleteAll"
      :body="'Are you sure do you want to delete all units for this wignette?'"
      confirmBtnClasses="btn-danger"
      @confirm="confirmDeleteAll"
      @cancel="$refs.confirmDeleteAll.close()"
    ></confirm-dialog>
    <confirm-dialog
      ref="confirmDeleteLocations"
      :body="'Are you sure do you want to delete the coordinates from all the units?'"
      confirmBtnClasses="btn-danger"
      @confirm="confirmDeleteLocations"
      @cancel="$refs.confirmDeleteLocations.close()"
    ></confirm-dialog>
    <symbol-edit-dialog
      v-if="editingRecord && editingRecord.symbol_id"
      v-model="editingRecord.symbol_id"
      ref="symbolDialog"
      :units="scenario.content.units"
      :sides="scenario.content.sides"
      :editingRecord="editingRecord"
    />
    <units-source-import-ds
      ref="importDS"
      :scenario="scenario"
      :datastores="datastores"
      @import="importRecords"
    />
  </div>
</template>
<script>
import UnitsSourceRecordVue from "./UnitsSourceRecord.vue";
import UnitsSourceImportDSVue from "./UnitsSourceImportDS.vue";
import SymbolEditDialogVue from "../../../../../xserver-common/components/SymbolEditDialog.vue";
import UnitsSourceFormVue from "./UnitsSourceForm.vue";
export default {
  name: "units-source",
  props: {
    value: {
      required: true,
    },
    scenario: {
      required: true,
    },
    datastores: {
      required: true,
    },
    title: {
      required: false,
    },
    locations: {
      type: Boolean,
      default: true,
    },
  }, //["value", "scenario", "datastores", "title"],
  components: {
    "units-source-record": UnitsSourceRecordVue,
    "units-source-form": UnitsSourceFormVue,
    "units-source-import-ds": UnitsSourceImportDSVue,
    "symbol-edit-dialog": SymbolEditDialogVue,
  },
  mounted() {
    this.update();
  },
  watch: {
    value() {
      this.update();
    },
  },
  methods: {
    reorder(ref, result) {
      if (!ref) {
        const res = this.reorder(this.tree, []);
        //this.value = res;
        while (this.value.length) this.value.pop();
        for (const unit of res) this.value.push(unit);
        this.$emit("input", res);
        this.$forceUpdate();
        return res;
      }
      const subs = ref.subordinates.sort((a, b) => {
        if (!a.unit || !b.unit) return 0;
        const ownerCompare = a.unit.owner.localeCompare(b.unit.owner);
        const nameCompare = a.unit.name.localeCompare(b.unit.name);
        return ownerCompare || nameCompare;
      });
      for (let sub of subs) {
        if (sub.unit) result.push(sub.unit);
        this.reorder(sub, result);
      }
      return result;
    },
    getName(index) {
      if (index === null || index < 0 || index >= this.value.length - 1)
        return "";
      else return this.value[index].name;
    },
    importRecords(records) {
      const idMapping = {};
      for (let r of records) {
        const newId = ++this.lastId;
        if (idMapping[r.unit_id]) {
          console.warn("Duplicate unit_id", r);
        }
        idMapping[r.unit_id] = newId;
      }
      for (let r of records) {
        r.unit_id = idMapping[r.unit_id];
        r.parent_id = r.parent_id ? idMapping[r.parent_id] : null;
      }
      for (const r of records) this.value.push(r);
      this.$emit("input", this.value);
      this.update();
      this.$forceUpdate();
    },
    deleteRecordAt(index) {
      this.deletingIndex = index;
      this.$refs.confirmDelete.open();
    },
    confirmDelete() {
      const record = this.value[this.deletingIndex];
      const unit = this.byId[record.unit_id];
      if (unit && unit.subordinates) {
        for (let sub of unit.subordinates) {
          if (!sub.unit) continue;
          sub.unit.parent_id = null;
        }
      }
      this.value.splice(this.deletingIndex, 1);
      this.$refs.confirmDelete.close();
      this.$emit("input", [...this.value]);
      this.$forceUpdate();
    },
    confirmDeleteAll() {
      while (this.value.length) this.value.pop();
      this.$emit("input", this.value);
      this.$forceUpdate();
      this.$refs.confirmDeleteAll.close();
    },
    confirmDeleteLocations() {
      this.value.forEach((r) => {
        r.long = null;
        r.lat = null;
        r.elev = null;
      });
      this.$emit("input", [...this.value]);
      this.$refs.confirmDeleteLocations.close();
    },
    // IP01 INIZIO GARC DSOL
     /* confirmDeleteReferenceLink() {
      if (!this.deleteingReferenceLink) return;
      const index = this.value.referenceLink.indexOf(
        this.deleteingReferenceLink
      );
      if (index >= 0) {
        this.value.referenceLink.splice(index, 1);
      }
      this.$refs.confirmDeleteReferenceLink.close();
      this.$forceUpdate();
    },*/
        // IP01 FINE GARC DSOL

    onRecordClick(record) {
      this.editingRecord = record;
    },
    onEditSymbolClick(record, event) {
      this.editingRecord = record;
      this.$refs.symbolDialog.open();
    },
    onOwnerChange(record, event) {
      if (event.oldVal !== event.newVal) {
        const unit = this.byId[record.unit_id];

        if (this.scenario.content.units.iconsType == "app6b") {
          record.symbol_id =
            record.symbol_id[0] +
            this.sides.filter((s) => s.id === record.owner)[0].affiliation +
            record.symbol_id.substr(2);
        } else {
          record.symbol_id =
            record.symbol_id.substring(0, 3) +
            this.getApp6dAffiliation(
              this.sides.filter((s) => s.id === record.owner)[0].affiliation
            ) +
            record.symbol_id.substr(4);
        }

        const oldParent =
          this.byId[record.parent_id] || this.byId[event.oldVal];
        const newParent = this.byId[event.newVal] || this.byId[record.owner];
        this.changeParent(unit, oldParent, newParent);
        record.parent_id = null;
      }
      this.$forceUpdate();
    },
    onParentChange(record, event) {
      if (record.parent_id === 0) record.parent_id = null;
      const unit = this.byId[record.unit_id];
      const oldParent = this.byId[event.oldVal] || this.byId[record.owner];
      const newParent = this.byId[event.newVal] || this.byId[record.owner];
      this.changeParent(unit, oldParent, newParent);
      this.$forceUpdate();
    },
    changeParent(unit, oldParent, newParent) {
      if (unit && oldParent && newParent && oldParent !== newParent) {
        const index = oldParent.subordinates.indexOf(unit);
        if (index >= 0) oldParent.subordinates.splice(index, 1);
        if (newParent.subordinates.indexOf(unit) === -1)
          newParent.subordinates.push(unit);
      }
    },
    getParents(unit, result, ref) {
      if (!result)
        return this.getParents(
          unit,
          [{ id: 0, text: "None" }],
          this.byId[unit.owner]
        );
      if (ref.unit && unit.unit_id === ref.unit.unit_id) return result;
      if (ref.unit && unit.unit_id !== ref.unit.unit_id) {
        result.push({ id: ref.unit.unit_id, text: ref.unit.name });
      }
      if (ref && Array.isArray(ref.subordinates)) {
        for (let sub of ref.subordinates) {
          this.getParents(unit, result, sub);
        }
      }
      return result;
    },
    getApp6dAffiliation(affiliation) {
      switch (affiliation) {
        case "F":
          return 3;
        case "H":
          return 6;
        case "N":
          return 4;
        case "U":
          return 1;
        default:
          return null;
      }
    },
    addUnit() {
      if (!this.value) return;

      const unit = {
        unit_id: ++this.lastId,
        name: "",
        owner: this.sides[0].id,
        parent_id: null,
        parent_name: "",
        symbol_id: "",
        radius: 0,
        strength: 100,
        capabilities: "",
        visible: false,
        long: null,
        lat: null,
        elev: null,
        direction: undefined,
        special_headquarters: "",
        referencelink: false, // IP01 aggiunto GARC DSOL
        referencedlinks: {title: "", links: []},     // IP01 Aggiunto GARC DSOL 
      };
      if (this.scenario.content.units.iconsType == "app6b") {
        unit.symbol_id = "S" + this.sides[0].affiliation + "G---------";
      } else {
        unit.symbol_id =
          "100" +
          this.getApp6dAffiliation(this.sides[0].affiliation) +
          "1000000000000000";
      }
      this.byId[unit.owner].subordinates.push(unit);
      this.value.push(unit);
      this.update();
      this.editingRecord = unit;
      this.$forceUpdate();
      this.$emit("input", this.value);
    },
    update() {
      const tree = { subordinates: [] };
      const byId = {};
      let lastId = 0;
      const sides = [];
      for (let side of this.scenario.content.sides) {
        const id = side.name.toLowerCase().replace(/[\s]+/g, "_");
        byId[id] = { type: "group", name: side.name, subordinates: [] };
        tree.subordinates.push(byId[id]);
        sides.push({ id: id, text: side.name, affiliation: side.affiliation });
      }
      if (Array.isArray(this.value)) {
        for (let unit of this.value) {
          // IP01 inizio GARC DSOL
          if (!unit.referencedlinks) {
            unit.referencedlinks = {title: "", links: []};
          }
          // IP01 fine GARC DSOL
          if (!unit.unit_id) unit.unit_id = ++lastId;
          if (typeof unit.visible === "undefined") unit.visible = false;
          byId[unit.unit_id] = { type: "unit", unit: unit, subordinates: [] };
          if (unit.unit_id > lastId) lastId = unit.unit_id;
        }
      }
      for (let unit_id in byId) {
        const unitElem = byId[unit_id];
        if (unitElem.type === "group") continue;
        if (!unitElem.unit) continue;
        const parent =
          byId[unitElem.unit.parent_id] || byId[unitElem.unit.owner];
        parent.subordinates.push(unitElem);
      }
      this.tree = tree;
      this.byId = byId;
      this.lastId = lastId;
      this.sides = sides;
      if (this.value.length && this.value.indexOf(this.editingRecord) === -1)
        this.editingRecord = this.value[0];
    },
  },
  data() {
    return {
      tree: {
        subordinates: [],
      },
      byId: {},
      show: false,
      lastId: 0,
      sides: [],
      deletingIndex: null,
      editingRecord: null,
    };
  },
};
</script>
