<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1 class="d-inline-block align-middle">Users</h1>
        <help-popup :name="'Users'"></help-popup>
        <div class="alert alert-danger" v-if="errors.length">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>
        <div class="mb-2 mt-2">
          <router-link to="users/new" class="btn btn-primary">
            <span class="icon icon-plus2"></span>
            <span class="label">New</span>
          </router-link>
          <button class="btn btn-primary" @click="showModal()" v-if="$store.state.serverInfo.features.importUsers">
            <span class="icon icon-plus2"></span>
            Import
          </button>
        </div>
        <table class="table w-100 table-users">
          <thead>
            <tr>
              <th>Username</th>
              <th>Name</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" v-bind:key="user.id">
              <th>{{ user.username }}</th>
              <td>{{ getFullName(user) }}</td>
              <td>{{ user.email }}</td>
              <td>
                <span class="btn btn-outline-primary" @click="editUser(user)">
                  <span class="icon icon-pencil4"></span>
                </span>
                <span class="btn btn-outline-danger" v-if="!user.admin" @click="deleteUser(user)">
                  <span class="icon icon-bin2"></span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div id="deleteConfirm" class="modal fade">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                Are you sure that you want to delete the user "{{
                deletingUser && deletingUser.username
                }}"
              </div>
              <div class="modal-footer">
                <button class="btn btn-danger" @click="confirmDelete()">
                  Delete
                </button>
                <button class="btn btn-secondary" @click="cancelDelete()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <import-modal @success="importSuccess" ref="modal"></import-modal>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import eventBus from "../../../eventBus";
import api from "../../../api";
import UserImportModal from "./UserImportModal.vue";
import HelpPopup from "../../../HelpPopup.vue"

export default {
  components: {
    "admin-menu": AdminMenu,
    "import-modal": UserImportModal,
    "help-popup": HelpPopup
  },
  created() {
    this.loadUsers();
  },
  data() {
    return { users: [], deletingUser: null, errors: [], name: "users" };
  },
  methods: {
    importSuccess() {
      console.log("import Success...");
      let element = this.$refs.modal;
      element.close();
      this.loadUsers();
    },
    showModal() {
      let element = this.$refs.modal;
      element.open();
    },
    loadUsers() {
      this.$http
        .get(api + "/rest/users")
        .then((res) => {
          this.users = res.body;
        })
        .catch((res) => {
          if (res.status === 401) {
            eventBus.$emit("logout");
          }
        });
    },
    getFullName(user) {
      var name = "";
      if (user.first_name)
        name =
          name.length == 0 ? user.first_name : name + " " + user.first_name;
      if (user.middle_name)
        name =
          name.length == 0 ? user.middle_name : name + " " + user.middle_name;
      if (user.last_name)
        name = name.length == 0 ? user.last_name : name + " " + user.last_name;
      return name;
    },
    deleteUser(user) {
      this.deletingUser = user;
      $("#deleteConfirm").modal();
    },
    cancelDelete() {
      this.deletingUser = null;
      $("#deleteConfirm").modal("hide");
    },
    confirmDelete() {
      this.$http
        .delete(api + "/rest/users/" + this.deletingUser.id)
        .then((res) => {
          $("#deleteConfirm").modal("hide");
          this.deletingUser = null;
          this.loadUsers();
        })
        .catch((res) => {
          if (res.status === 401) {
            eventBus.$emit("logout");
            return;
          } else {
            $("#deleteConfirm").modal("hide");
            if (res && res.body && res.body.error && res.body.error.message)
              this.errors = [res.body.error.message];
            else this.errors = ["Unexpected server error"];
          }
        });
    },
    editUser(user) {
      this.$router.push("users/" + user.id);
    },
  },
};
</script>
