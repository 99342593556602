<template>
  <div class="col col-12 mt-1">
    <div>
      <label class="col-4 col-md-3 justify-content-end p-0">Popup Template</label>
      <select class="
          col col-sm-12 col-md-6 col-lg-3
          form-control form-control-sm
          mb-1
          d-inline
          popup-type-select
        " @input="onTypeChange" v-model="popup.type">
        <option value="default">Classic</option>
        <option value="templated">Templated</option>
      </select>
    </div>
    <div v-if="popup.type == 'templated'">
      <label class="col-4 col-md-3 justify-content-end p-0">Template type</label>
      <button :class="{ active: isClassicTemplate }" class="btn btn-outline-primary" @click="getClassicTemplate">
        Classic template
      </button>
      <button class="btn btn-outline-primary" :class="{ active: isTabbedTemplate }" @click="getTabbedTemplate">
        Tabbed template
      </button>
    </div>
    <div class="editor mt-2" v-if="popup.type == 'templated'">
      <div class="
          col-xs-12 col-sm-6 col-md-7 col-lg-7
          d-inline-block
          align-top
          pl-0
        ">
        <span>Editor HTML</span>
        <div class="input-group-append d-inline-block m-1">
          <button @click.stop type="button" class="input-group-text info info-popup" data-placement="right"
            data-toggle="popover" data-custom-class="popover-xl" data-html="true" :title="popupHelpTitle"
            :data-content="popupHelpContent">
            ?
          </button>
        </div>
        <app-code v-model="popup.template" class="w-100" @input="onTemplateChange"></app-code>
      </div>
      <div class="
          col-xs-12 col-sm-6 col-md-5 col-lg-5
          d-inline-block
          align-top
          pr-0
          popup-preview
        ">
        <span>Preview popup</span>
        <div class="pt-2" v-html="renderedTemplate()"></div>
      </div>
    </div>
  </div>
</template>
<script>
import eventBus from "../../../../eventBus";
import api from "../../../../api";
import AppEditorVue from "../../../../../../xserver-client3d/node_modules/xserver-common/components/AppEditor.vue";
import CodeEditorVue from "../../../../../../xserver-client3d/node_modules/xserver-common/components/CodeEditor.vue";
import Mustache from "mustache";
import MS from "milsymbol";
import MustacheFunctions from "xserver-common/dist/components/MustacheFunctions";
import { ModelListSelect } from "vue-search-select";
export default {
  name: "events-popup-type",
  props: [
    "scenario",
    "popup",
    "markupPopupEvent",
    "events",
    "markupPopupEventWithTab",
  ],
  components: {
    "app-editor": AppEditorVue,
    "app-code": CodeEditorVue,
    "model-list-select": ModelListSelect,
  },
  created() {
    eventBus.$on("close-dropdown-menus", () => {
      this.closePopoovers();
    });
    if (this.popup.templateType) {
      if (this.popup.templateType == "classic") {
        this.isTabbedTemplate = false;
        this.isClassicTemplate = true;
      } else {
        this.isTabbedTemplate = true;
        this.isClassicTemplate = false;
      }
    }
  },

  watch: {},
  computed: {},
  mounted() {
    this.popover();
  },
  methods: {
    getClassicTemplate() {
      this.popup.template = this.markupPopupEvent;
      this.isTabbedTemplate = false;
      this.isClassicTemplate = true;
      this.popup.templateType = "classic";
    },
    getTabbedTemplate() {
      this.popup.template = this.markupPopupEventWithTab;
      this.isClassicTemplate = false;
      this.isTabbedTemplate = true;
      this.popup.templateType = "tabbed";
    },
    closePopoovers() {
      $(".info.info-popup").popover("hide");
    },
    popover() {
      $(".info.info-popup").popover({
        template: `<div class="popover popover-info popover-xl" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`,
      });
    },
    onTemplateChange(value) {
      this.$forceUpdate();
    },
    onTypeChange(value) {
      if ((this.popup.type = "default")) {
        this.popup.template = this.markupPopupEvent;
      } else {
        this.popup.template = this.markupPopupEvent;
      }
      this.$forceUpdate();
    },
    renderedTemplate() {
      return Mustache.render(this.popup.template, {
        event: this.mockupEvent,
        ...MustacheFunctions,
      });
    },

    updateSymbolPreview(symbol_id) {
      const symbol = new MS.Symbol(symbol_id, {
        size: 60,
      });

      return symbol.asSVG();
    },
  },
  data() {
    return {
      mockupEvent: {
        name: "Killing",
        description: "placeholder for event information",
        symbol: "10034000001101080000",
        lon: 13.5,
        lat: 10.5,
        elev: 2,
        created_at:
          new Date().getDate() +
          "-" +
          new Date().getMonth() +
          "-" +
          new Date().getFullYear(),
        symbol_preview: this.updateSymbolPreview("10034000001101080000"),
        extra: {
          munitionsType: "0.0.0.0.0.0.0",
        },
      },
      popupHelpTitle: ` <h3>Editor html</h3>`,
      popupHelpContent: `<p>In the editor you can interpolate data dynamically using double braces e.g. <strong>{{event.name}}</strong><br>
                          In addition you can call functions such as toUppercase e.g <strong>{{#toUpperCase}}</strong>text or {{event.name}} <strong>{{/toUpperCase}}</strong><br>
                          User can see the changes in real-time section “preview popup” near the editor HTML.</p>`,
      showCustomEditor: false,
      isClassicTemplate: false,
      isTabbedTemplate: false,
    };
  },
};
</script>
<style>
.code_area {
  min-height: 133px;
}

.mark>div {
  color: orange !important;
  font-weight: bold;
}

.mark {
  background-color: #282c34 !important;
}

.editor>div>span {
  font-size: 1rem;
  font-weight: bold;
}

.editor .code-editor {
  padding-top: 10px;
}

.popup-preview {
  font-size: 1rem;
}

.editor {
  font-size: 0;
}

.info-popup {
  padding: 0px 8px;
}

.popover-info .table thead tr th {
  width: 50%;
}
</style>
