var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('help-popup',{class:{'isAbsolute': true,},attrs:{"name":'Vignettes'}}),_vm._v(" "),_c('div',{staticClass:"w-100 p-2"},[_c('div',{staticClass:"mb-2"},[_c('button',{staticClass:"btn btn-outline-primary",on:{"click":function($event){$event.preventDefault();return _vm.newVignette.apply(null, arguments)}}},[_c('span',{staticClass:"icon icon-plus2"}),_vm._v(" "),_c('span',[_vm._v("Add")])])]),_vm._v(" "),_c('table',{staticClass:"table table-striped"},[_vm._m(0),_vm._v(" "),_c('tbody',[(!_vm.scenario.content.vignettes.length)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("No Vignette Defined")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.scenario.content.vignettes),function(vignette,i){return _c('tr',{key:vignette,class:{'table-warning': _vm.vSimulation && _vm.vSimulation.name === vignette}},[_c('td',[_vm._v(_vm._s(i + 1))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(vignette))]),_vm._v(" "),_c('td',[_c('div',{staticClass:"btn btn-outline-primary icon icon-pencil4",on:{"click":function($event){$event.preventDefault();return _vm.editVignette(i)}}}),_vm._v(" "),_c('div',{staticClass:"btn btn-outline-danger icon icon-bin2",on:{"click":function($event){$event.preventDefault();return _vm.deleteVignette(i)}}}),_vm._v(" "),_c('div',{staticClass:"btn btn-outline-secondary icon icon-arrow-down2",class:{
                disabled: _vm.cannotGoDown(i),
              },on:{"click":function($event){$event.preventDefault();return _vm.moveVignetteDown(i)}}}),_vm._v(" "),_c('div',{staticClass:"btn btn-outline-secondary icon icon-arrow-up2",class:{
                disabled: _vm.cannotGoUp(i),
              },on:{"click":function($event){$event.preventDefault();return _vm.moveVignetteUp(i)}}})])])})],2)]),_vm._v(" "),(_vm.scenario.content.vignettes.length > 0)?_c('div',{staticClass:"form-group row mx-0",attrs:{"title":"Choose a vignette for simulation"}},[_vm._m(1),_vm._v(" "),_c('model-list-select',{ref:"listVignettes",staticClass:"form-control form-control-sm col-3",attrs:{"id":"vSimulation","list":_vm.listVignettes,"option-value":"id","option-text":"name","placeholder":"select a vignette for Simulation..."},on:{"input":_vm.vignetteSimulationSet},model:{value:(_vm.vSimulation),callback:function ($$v) {_vm.vSimulation=$$v},expression:"vSimulation"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal fade",attrs:{"id":"vignetteDialog"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_c('h3',[_vm._v("Vignette")]),_vm._v(" "),_c('span',[_vm._v("Vignette:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vignetteDialog.name),expression:"vignetteDialog.name"}],class:{
                'form-control': true,
                'is-invalid': _vm.vignetteDialogInvalidName,
              },attrs:{"type":"text"},domProps:{"value":(_vm.vignetteDialog.name)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.confirmVignetteDialog()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.vignetteDialog, "name", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-success",on:{"click":function($event){$event.preventDefault();return _vm.confirmVignetteDialog.apply(null, arguments)}}},[_vm._v("\n                Ok\n              ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){$event.preventDefault();return _vm.cancelVignetteDialog()}}},[_vm._v("\n                Cancel\n              ")])])])])]),_vm._v(" "),_c('div',{staticClass:"modal fade",attrs:{"id":"vignetteDeleteDialog"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_vm._v("\n              Are you sure do you want to delete vignette \""+_vm._s(_vm.vignetteDialog.name)+"\"\n            ")]),_vm._v(" "),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteVignette.apply(null, arguments)}}},[_vm._v("\n                Confirm\n              ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){$event.preventDefault();return _vm.cancelDeleteVignette.apply(null, arguments)}}},[_vm._v("\n                Cancel\n              ")])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_vm._v(" "),_c('th',[_vm._v("Name")]),_vm._v(" "),_c('th',[_vm._v("Actions")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"control-label col-3 col-form-label p-0 py-2",attrs:{"for":"vSimulation"}},[_c('strong',[_vm._v("Vignette for Simulation:")])])
}]

export { render, staticRenderFns }