<template>
  <div class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body" v-html="body"></div>
        <div class="modal-footer">
          <button
            :class="'btn ' + confirmBtnClasses"
            @click.prevent.stop="confirm()"
          >
            {{ confirmBtnText }}
          </button>
          <button
            :class="'btn ' + cancelBtnClasses"
            @click.prevent.stop="cancel()"
          >
            {{ cancelBtnText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    body: {
      type: String,
      default: "Are you sure?"
    },
    confirmBtnClasses: {
      type: String,
      default: "btn-primary"
    },
    cancelBtnClasses: {
      type: String,
      default: "btn-secondary"
    },
    confirmBtnText: {
      type: String,
      default: "Confirm"
    },
    cancelBtnText: {
      type: String,
      default: "Cancel"
    }
  },
  mounted() {
    jQuery(this.$el).on("shown.bs.modal", () => {
      this.$emit("shown");
    });
    jQuery(this.$el).on("hidden.bs.modal", () => {
      this.$emit("close");
    });
  },
  methods: {
    open() {
      jQuery(this.$el).modal("show");
      this.$emit("open");
    },
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
    close() {
      jQuery(this.$el).modal("hide");
    }
  },
  data() {
    return {};
  }
};
</script>
