<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <vue-snotify></vue-snotify>
        <h1 class="d-inline-block align-middle">Instances</h1>
        <help-popup :name="'Instances'"></help-popup>
        <div class="mb-2 mt-2 d-flex align-items-center">
          <router-link to="instances/new" class="btn btn-primary text-nowrap">
            <span class="icon icon-plus2"></span>
            <span class="label">New</span>
          </router-link>
          <span class="flex-grow-1" />
          <span class="d-flex align-items-center">
            <label class="label label-sm p-2 m-0" for="filter"><strong>Filter</strong></label>
            <input 
              id="filter"
              name="filter" 
              type="text" 
              v-model="$store.state.instances.filter" 
              placeholder="Filter instances..."
              class="form-control"
            />
          </span>
          <!-- <input id="search" name="search" type="search" :prop="istanceProp"/> -->
        </div>
        <table class="table table-striped w-100 table-instances">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Status</th>
              <th>Scenario</th>
              <th v-if="$store.state.serverInfo.features.app3d">3D</th>
              <th>Group</th>
              <th v-if="$store.state.serverInfo.features.hla">HLA</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="filteredInstances.length === 0">
              <td colspan="100%">No Instance Available</td>
            </tr>
            <tr v-else v-for="instance in filteredInstances" :key="instance.id">
              <td>
                <span v-if="instance.active" class="icon icon-checkmark text-success"></span>
                <span v-else class="icon icon-cross text-danger"></span>
              </td>
              <th>{{ instance.name }}</th>
              <td>
                <div>{{ instance.status }}</div>
                <div class="progress" v-if="showProgress(instance)">
                  <div :style="{
                    width:
                      Math.round(
                        (instance.currentStep / instance.totalSteps) * 100
                      ) + '%',
                  }" class="progress-bar" role="progressbar" :aria-valuenow="instance.currentStep" aria-valuemin="0"
                    :aria-valuemax="instance.totalSteps">
                    {{
                    Math.round(
                    (instance.currentStep / instance.totalSteps) * 100
                    ) + "%"
                    }}
                  </div>
                </div>
              </td>
              <td>{{ scenarioName(instance.scenarioId) }}</td>
              <td v-if="$store.state.serverInfo.features.app3d">
                <span v-if="instance.has3D" class="icon icon-checkmark text-success"></span>
                <span v-else class="icon icon-cross text-danger"></span>
              </td>
              <td>{{ groupName(instance.groupId) }}</td>
              <td v-if="$store.state.serverInfo.features.hla">
                {{ retriveHlaSettings(instance) }}
              </td>
              <td>
                <span class="btn btn-outline-primary" @click="$router.push('instances/' + instance.id)">
                  <span class="icon icon-pencil4"></span>
                </span>
                <span v-if="instance.active" class="btn btn-outline-secondary" @click="restartInstance(instance)">
                  <span class="icon icon-reload"></span>
                </span>
                <span v-if="instance.active" @click="stopInstance(instance)" class="btn btn-outline-danger">
                  <span class="icon icon-pause2"></span>
                </span>
                <span v-else @click="startInstance(instance)" class="btn btn-outline-primary">
                  <span class="icon icon-play4"></span>
                </span>
                <span class="btn btn-outline-danger" @click="deleteInstance(instance)">
                  <span class="icon icon-cross"></span>
                </span>
                <span v-if="$store.state.serverInfo.features.monitoring" class="btn btn-outline-primary"
                  @click="$router.push('instances/stats/' + instance.id)">
                  <span class="oi oi-monitor"></span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <confirm-dialog ref="confirmDelete" :body="
        'Are you sure do you want to delete instance ' +
        (currentInstance && currentInstance.name) +
        '?'
      " confirmBtnClasses="btn-danger" @confirm="confirmDelete" @cancel="$refs.confirmDelete.close()">
      </confirm-dialog>
      <confirm-dialog ref="confirmRestart" :body="
        'Are you sure do you want to restart instance ' +
        (currentInstance && currentInstance.name) +
        '?'
      " confirmBtnClasses="btn-danger" @confirm="confirmRestart" @cancel="$refs.confirmRestart.close()">
      </confirm-dialog>
      <loading :loading="
        loadingInstances ||
        loadingScenarios ||
        loadingGroups ||
        loadingRestart ||
        loadingDelete ||
        loadingStop ||
        loadingStart
      "></loading>
    </div>
  </div>
</template>
<script>
import eventBus from "../../../eventBus";
import api from "../../../api";
import HelpPopup from "../../../HelpPopup.vue";
export default {
  data() {
    return {
      instances: [],
      currentInstance: {},
      scenarios: {},
      groups: {},
      errors: [],
      loadingInstances: false,
      loadingScenarios: false,
      loadingGroups: false,
      loadingDelete: false,
      loadingRestart: false,
      loadingStop: false,
      loadingStart: false,
    };
  },
  components: {
    "help-popup": HelpPopup
  },
  created() {
    this.loadInstances();
    this.loadScenarios();
    this.loadGroups();
    var $component = this;
    eventBus.$on("instance-update", (instance) => {
      console.log("INSTANCES INCOMING EVENT: instance-update", instance);
      const i = $component.instances.filter((i) => i.id == instance.id)[0];
      if (i) Object.assign(i, instance);
    });
  },
  computed:{
    filteredInstances(){
      return this.instances.filter(instance => this.isRecordVisible(instance));
    }
  },
  methods: {
    isRecordVisible(instance){
      const filter = this.$store.state.instances.filter;
      return instance.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    },
    startInstance(instance) {
      this.currentInstance = instance;
      this.loadingStart = true;
      this.$http
        .post(api + "/rest/instances/" + this.currentInstance.id + "/start")
        .then((res) => {
          this.loadingStart = false;
          this.loadInstances();
        })
        .catch((res) => {
          this.loadingStart = false;
          var toast = this.$snotify.error(
            "Error stopping the instance",
            "Error"
          );
          this.errors.push(toast);
        });
    },
    stopInstance(instance) {
      this.currentInstance = instance;
      this.loadingStop = true;
      this.$http
        .post(api + "/rest/instances/" + this.currentInstance.id + "/stop")
        .then((res) => {
          this.loadingStop = false;
          this.loadInstances();
        })
        .catch((res) => {
          this.loadingStop = false;
          var toast = this.$snotify.error(
            "Error stopping the instance",
            "Error"
          );
          this.errors.push(toast);
        });
    },
    restartInstance(instance) {
      this.currentInstance = instance;
      this.$refs.confirmRestart.open();
    },
    deleteInstance(instance) {
      this.currentInstance = instance;
      this.$refs.confirmDelete.open();
    },
    showProgress(instance) {
      return instance.status === "initializing" && instance.totalSteps > 0;
    },
    confirmRestart() {
      this.loadingRestart = true;
      this.$refs.confirmRestart.close();
      this.$http
        .post(api + "/rest/instances/" + this.currentInstance.id + "/restart")
        .then((res) => {
          this.loadingRestart = false;
          this.loadInstances();
        })
        .catch((res) => {
          this.loadingRestart = false;
          var toast = this.$snotify.error("Error restarting instance", "Error");
          this.errors.push(toast);
        });
    },
    confirmDelete() {
      this.loadingDelete = true;
      this.clearErrors();
      this.$http
        .delete(api + "/rest/instances/" + this.currentInstance.id)
        .then((res) => {
          this.loadingDelete = false;
          this.loadInstances();
          this.$refs.confirmDelete.close();
        })
        .catch((res) => {
          this.loadingDelete = false;
          var toast = this.$snotify.error("Error getting instances", "Error");
          this.errors.push(toast);
          this.$refs.confirmDelete.close();
        });
    },
    loadInstances() {
      this.loadingInstances = true;
      this.instances = [];
      this.clearErrors();
      this.$http
        .get(api + "/rest/instances")
        .then((res) => {
          this.instances = res.body;
          this.loadingInstances = false;
        })
        .catch((res) => {
          console.log("Error getting instances");
          var toast = this.$snotify.error("Error getting instances", "Error");
          this.errors.push(toast);
          this.loadingInstances = false;
        });
    },
    loadScenarios() {
      this.loadingScenarios = true;
      this.scenarios = {};
      this.$http
        .get(api + "/rest/scenarios")
        .then((res) => {
          res.body.forEach((s) => (this.scenarios[s.id] = s));
          this.loadingScenarios = false;
        })
        .catch((res) => {
          console.log("Error getting scenarios");
          var toast = this.$snotify.error("Error getting scenarios", "Error");
          this.errors.push(toast);
          this.loadingScenarios = false;
        });
    },
    loadGroups() {
      this.loadingGroups = true;
      this.groups = {};
      this.$http
        .get(api + "/rest/groups")
        .then((res) => {
          res.body.forEach((item) => (this.groups[item.id] = item));
          this.loadingGroups = false;
        })
        .catch((res) => {
          console.error("Error getting groups");
          var toast = this.$snotify.error("Error getting groups", "Error");
          this.errors.push(toast);
          this.loadingGroups = false;
        });
    },
    scenarioName(id) {
      return (
        (this.scenarios[id] && this.scenarios[id].name) ||
        "(Scenario " + id + ")"
      );
    },
    groupName(id) {
      return (this.groups[id] && this.groups[id].name) || "(Group " + id + ")";
    },
    clearErrors() {
      this.errors.forEach((e) => this.$snotify.remove(e.id));
    },
    retriveHlaSettings(instance) {
      if (!instance.hlaEnabled) {
        return "Disabled";
      }
      return "Enabled";
    },

  },
  filters: {},
  destroyed() {
    this.errors.forEach((e) => this.$snotify.remove(e.id));
  }
};
</script>
