<template>
  <div id="app">
    <app-header v-if="isNotLoginPage"></app-header>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "./Header.vue";
import ws from "./websocket/connectionManager";
export default {
  components: {
    "app-header": Header
  },
  computed: {
    isNotLoginPage() {
      return this.$route.path.indexOf("/login") != 0;
    },
  },
  name: "app",
  data() {
    return {};
  }
};
</script>