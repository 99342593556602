<template>
  <div class="alert alert-danger" v-if="errors.length">
    <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
  </div>
</template>
<script>
export default {
  props: {
    errors: {
      required: true,
      type: Array
    }
  }
};
</script>
