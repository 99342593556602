<template>
  <div class="container-home">
    <h1>
      Welcome <span>{{ user && user.username }}</span>
    </h1>
    <template v-for="(scenario, id) in scenarios">
      <div class="scenario-group" :key="id">
        <div class="instance-list">
          <div
            class="instance mb-2"
            v-for="(instance, i) in scenario.instances"
            :key="i"
          >
            <span>
              <span class="scenario-name">
                {{ scenario.name }}
              </span>
              <span class="instance-name" v-if="scenario.instances.length > 1">
                ({{ instance.name }})
              </span>
            </span>
            <div class="scenario__actionWrap">
              <div class="scenario__action">
                <a
                  :href="'client/#/app/' + instance.id + '?app2D'"
                  class="btn btn-outline-secondary"
                  @click.prevent.stop="open2D(instance.id)"
                  >2D
                </a>
                <a
                  v-if="
                    scenario.instances[0].has3D &&
                    $store.state.serverInfo.features.app3d
                  "
                  :href="'client/#/app/' + instance.id + '?app3D'"
                  class="btn btn-outline-secondary"
                  @click.prevent.stop="open3D(instance.id)"
                  >3D</a
                >
                <a
                  v-if="
                    scenario.instances[0].has3D &&
                    $store.state.serverInfo.features.app3d
                  "
                  :href="'client/#/app/' + instance.id"
                  class="btn btn-outline-secondary"
                  @click.prevent.stop="open2D3D(instance.id)"
                  >2D | 3D</a
                >
                <a
                  v-if="
                    scenario.instances[0].hasMapJournal &&
                    $store.state.serverInfo.features.mapJournal
                  "
                  :href="'client/#/app/' + instance.id"
                  class="btn btn-outline-secondary"
                  @click.prevent.stop="openJournal(instance.id)"
                  >Journal</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <loading :loading="loading"></loading>
    <vue-snotify></vue-snotify>
  </div>
</template>
<script>
import api from "../api";
export default {
  created() {
    this.loadScenarios();

    this.$http
      .get(api + "/rest/users/self")
      .then((res) => {
        this.user = res.body;
      })
      .catch((res) => {
        if (res.status === 401 || res.status === 403) {
          this.$router.push(
            "/login?redirect_uri=" + encodeURIComponent(this.$route.path)
          );
        }
      });
  },
  methods: {
    open2D(id) {
      window.location = "client/#/app/" + id + "?app2D";
    },
    open3D(id) {
      window.location = "client/#/app/" + id + "?app3D";
    },
    open2D3D(id) {
      window.location = "client/#/app/" + id;
    },
    openJournal(id) {
      window.location = "client/#/app/" + id + "?journal";
    },
    loadScenarios() {
      this.loading = true;
      this.scenarios = {};
      this.$http
        .get(api + "/rest/instances/self")
        .then((res) => {
          res.body.forEach((instance) => {
            if (!this.scenarios[instance.scenario.id]) {
              this.scenarios[instance.scenario.id] = {
                id: instance.scenario.id,
                name: instance.scenario.name,
                instances: [],
              };
            }
            this.scenarios[instance.scenario.id].instances.push(instance);
          });
          this.loading = false;
        })
        .catch((res) => {
          console.error("Unable to get scenarios from server");
          this.$snotify.error("Unable to get scenarios from server", "Error");
          this.loading = false;
        });
    },
  },
  data() {
    return { scenarios: [], loading: false, user: null };
  },
};
</script>
