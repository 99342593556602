<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3" v-if="datastore">
        <h1 v-if="datastore.id">Edit Datastore</h1>
        <h1 v-else>New Datastore</h1>
        <div class="alert alert-danger" v-if="errors.length">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>
        <form action @submit.prevent="insertOrUpdate()">
          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" name="name" autocomplete="off" class="form-control" placeholder="Datastore name..."
              v-model="datastore.name" />
          </div>
          <div class="form-group">
            <label for="type" title="The database types currently supported are PostgreSQL.">Type</label>
            <select name="type" class="form-control" v-model="datastore.content.type" placeholder="Database type...">
              <option value>Database type...</option>
              <option value="postgres">PostgreSQL</option>
            </select>
          </div>
          <div class="form-group">
            <label for="address">Address</label>
            <input type="text" name="address" autocomplete="off" class="form-control" placeholder="Database address..."
              v-model="datastore.content.address" />
          </div>
          <div class="form-group">
            <label for="port">Port</label>
            <input type="number" name="port" autocomplete="off" class="form-control" placeholder="Database port..."
              v-model="datastore.content.port" />
          </div>
          <div class="form-group">
            <label for="database">Database Name</label>
            <input type="text" name="database" autocomplete="off" class="form-control" placeholder="Database Name..."
              v-model="datastore.content.database" />
          </div>
          <div class="form-group">
            <label for="username">Username</label>
            <input type="text" name="username" autocomplete="off" class="form-control"
              placeholder="Database Username..." v-model="datastore.content.username" />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" name="password" autocomplete="off" class="form-control"
              placeholder="Database Password..." v-model="datastore.content.password" />
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary" v-if="datastore.id">
              Edit Datastore
            </button>
            <button type="submit" class="btn btn-primary" v-else>
              Add Datastore
            </button>
          </div>
          <span class="overlay align-self-center d-flex" v-if="loading">
            <div class="overlay-background bg-white opacity-50"></div>
            <div class="spinner-border text-primary m-auto" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import Autocomplete from "vuejs-auto-complete";
import eventBus from "../../../eventBus";
import HelpPopup from "../../../HelpPopup.vue";
import api from "../../../api";
export default {
  created() {
    this.loadDatastore();
  },
  components: {
    "admin-menu": AdminMenu,
    "help-popup": HelpPopup,
    autocomplete: Autocomplete,
  },
  data() {
    return {
      errors: [],
      datastore: {
        name: "",
        content: {
          type: "",
          address: "",
          port: 5432,
          database: "",
          username: "",
          password: "",
        },
      },
      loading: false,
    };
  },
  methods: {
    loadDatastore() {
      if (this.$route.params.id) {
        this.loading = true;
        this.$http
          .get(api + "/rest/datastores/" + this.$route.params.id)
          .then((res) => {
            this.loading = false;
            this.datastore = res.body;
          })
          .catch((res) => {
            this.loading = false;
            if (res.status === 401) {
              eventBus.$emit("logout");
            } else if (res.body && res.body.error) {
              this.errors.push(res.body.error.message);
            } else {
              this.errors.push("Error " + res.status);
            }
          });
      }
    },
    insertOrUpdate() {
      this.errors = [];
      if (this.datastore.id) this.update();
      else this.insert();
    },
    update() {
      this.loading = true;
      this.$http
        .post(api + "/rest/datastores/" + this.datastore.id, this.datastore)
        .then((res) => {
          this.loading = false;
          this.$router.push("../datastores");
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        });
    },
    insert() {
      this.loading = true;
      this.$http
        .put(api + "/rest/datastores", this.datastore)
        .then((res) => {
          this.loading = false;
          this.$router.push("../datastores");
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        });
    },
  },
};
</script>
