import Vue from "vue";
import eventBus from "./eventBus";
class CredentialsManager {
  constructor() {
    this.credentials = null;
    this.cookie_name = "xserver_credentials";
    try {
      var c = this.getCookie(this.cookie_name);
      if (c && c.length > 0) {
        this.credentials = JSON.parse(decodeURIComponent(c));
        eventBus.$emit("login", this.credentials);
      }
    } catch (err) {
      console.error("Error restoring credentials from cookie");
    }
    console.log("Initializing credential manager");
    eventBus.$on("login", token => {
      this.credentials = token;
      this.setCookie(
        this.cookie_name,
        encodeURIComponent(JSON.stringify(token)),
        token.expires_on
      );
      console.log("received new access token event", this.credentials);
    });
    eventBus.$on("logout", () => {
      this.setCookie(this.cookie_name, "", new Date(1900, 0, 1).toISOString());
      this.credentials = null;
    });
  }
  init() {
    Vue.http.interceptors.push((req, next) => {
      if (this.credentials) {
        req.headers.set(
          "Authorization",
          "Bearer " + this.credentials.access_token
        );
      }
    });
  }
  setCookie(cname, cvalue, expires) {
    var expires = "expires=" + expires;
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
export default new CredentialsManager();
