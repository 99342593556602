<template>
  <div class="container-main container-main--detailsProcess">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1>Process Details</h1>

        <div class="alert alert-danger" v-if="errors.length">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>

        <div class="d-flex flex-row">
          <div class="col-2 p-2 font-weight-bold">Name:</div>
          <div class="flex-grow-1 p-2">{{ process.name }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="col-2 p-2 font-weight-bold">Type:</div>
          <div class="flex-grow-1 p-2">{{ process.processType }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="col-2 p-2 font-weight-bold">Args:</div>
          <div class="flex-grow-1 p-2">{{ process.args }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="col-2 p-2 font-weight-bold">Started at:</div>
          <div class="flex-grow-1 p-2">{{ getDate(process.startedAt) }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="col-2 p-2 font-weight-bold">Ended at:</div>
          <div class="flex-grow-1 p-2">{{ getDate(process.endedAt) }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="col-2 p-2 font-weight-bold">Status:</div>
          <div class="flex-grow-1 p-2">{{ process.status }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="col-2 p-2 font-weight-bold">Exit code:</div>
          <div class="flex-grow-1 p-2">{{ process.exitCode }}</div>
        </div>
        <div class="d-flex flex-row">
          <div class="col-2 p-2 font-weight-bold">Error Log:</div>
          <div class="flex-grow-1 p-2">
            <!-- <span>Size: {{ getLogSize(logs.error) }}</span>
            <span>Date: {{ getLogDate(logs.error) }}</span> -->
            <div
              ref="err"
              class="text-monospace overflow-auto small"
              style="height: 150px"
            >
              <div style="background-color: #fbefef" v-html="logs.error"></div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="col-2 p-2 font-weight-bold">Output Log:</div>
          <div class="flex-grow-1 p-2">
            <!-- <span>Size: {{ getLogSize(logs.output) }}</span>
            <span>Date: {{ getLogDate(logs.output) }}</span> -->
            <div
              ref="out"
              class="text-monospace overflow-auto small"
              style="height: 150px"
            >
              <div style="background-color: #f1fbef" v-html="logs.output"></div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="col-2 p-2 font-weight-bold">Input:</div>
          <div class="flex-grow-1 p-2">
            <input
              type="text"
              class="form-control"
              v-model="inputCommand"
              @keydown.enter="sendInput"
            />
          </div>
        </div>

        <span class="overlay align-self-center d-flex" v-if="loading">
          <div class="overlay-background bg-white opacity-50"></div>
          <div class="spinner-border text-primary m-auto" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "./../Menu.vue";
import eventBus from "../../../eventBus";
import api from "../../../api";
import Convert from "ansi-to-html";
import connectionManager from "../../../websocket/connectionManager";
export default {
  components: {
    "admin-menu": AdminMenu,
  },
  created() {
    this.loadProcessDetails();
    eventBus.$on("ws-authenticated", this.subscribe.bind(this));
    eventBus.$on("process_out", this.onOutReceive.bind(this));
    eventBus.$on("process_err", this.onErrReceive.bind(this));
    this.logsConverter = new Convert({
      fg: "#000",
      bg: "#FFF",
      newline: true,
      escapeXML: false,
      stream: false,
    });
    // this.loadProcessLog("output");
    // this.loadProcessLog("error");
  },
  data() {
    return {
      process: [],
      inputCommand: "",
      loading: false,
      errors: [],
      logs: { error: "", output: "" },
    };
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    async sendInput() {
      console.log("Sending input:" + this.inputCommand);
      const cmd = ">>> " + this.inputCommand + "\n";
      const row = this.logsConverter ? this.logsConverter.toHtml(cmd) : cmd;
      this.logs.output += row;
      this.scrollBottom("out");
      if (!this.inputCommand) return;
      try {
        const res = await this.$http.post(
          api + "/rest/proc/" + this.$route.params.name + "/input",
          {
            input: this.inputCommand,
          }
        );
        if (res.ok) {
          this.inputCommand = "";
        } else {
          this.errors.push("Unable to execute command:" + this.inputCommand);
        }
      } catch (res) {
        if (res.status === 401) {
          eventBus.$emit("logout");
        } else if (res.body && res.body.error) {
          this.errors.push(res.body.error.message);
        } else {
          this.errors.push("Error " + res.status);
        }
      }
      this.loading = false;
    },
    async loadProcessDetails() {
      if (this.$route.params.name) {
        try {
          this.loading = true;
          const res = await this.$http.get(
            api + "/rest/proc/" + this.$route.params.name
          );
          this.process = res.body;
          await this.loadProcessLog("output");
          await this.loadProcessLog("error");
          // scroll logs to bottom;
          this.scrollBottom("out");
          this.scrollBottom("err");
          if (connectionManager.authenticated) {
            this.subscribe();
          }
        } catch (res) {
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        }
        this.loading = false;
      }
    },
    subscribe() {
      if (this.process && this.process.id) {
        connectionManager.send({
          event: "subscribe",
          data: "process_" + this.process.name,
        });
      }
    },
    unsubscribe() {
      if (connectionManager.authenticated && this.process && this.process.id) {
        connectionManager.send({
          event: "unsubscribe",
          data: "process_" + this.process.name,
        });
      }
    },
    scrollBottom(el) {
      this.$nextTick(() => {
        this.$refs[el].scrollTop = this.$refs[el].scrollHeight;
      });
    },
    onOutReceive(event) {
      const row = this.logsConverter
        ? this.logsConverter.toHtml(event.out)
        : event.out;
      this.logs.output += row;

      //Autoscroll after update
      this.scrollBottom("out");
    },
    onErrReceive(event) {
      const row = this.logsConverter
        ? this.logsConverter.toHtml(event.err)
        : event.err;
      this.logs.error += row;

      //Autoscroll after update
      this.scrollBottom("err");
    },
    async loadProcessLog(type) {
      if (this.$route.params.name) {
        const res = await this.$http.get(
          api + "/rest/proc/" + type + "-log/" + this.$route.params.name
        );
        const data = res.body;
        const row = this.logsConverter
          ? this.logsConverter.toHtml(data.log)
          : data.log;
        this.logs[type] += row;
      }
    },
    getLogDate(data) {
      if (data === undefined || data === null || !data.lastEdit) return "";
      return this.getDate(data.lastEdit);
    },
    getLogSize(data) {
      if (data === undefined || data === null || data.size <= 0) return "0 B";
      let i = Math.floor(Math.log(data.size) / Math.log(1024));
      return (
        (data.size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "KB", "MB", "GB", "TB"][i]
      );
    },
    getLog(data) {
      if (data === undefined || data === null) return "";
      if (data.size === 0) return "<Empty log>";
      if (data.log)
        return this.logsConverter
          ? this.logsConverter.toHtml(data.log)
          : data.log;
    },
    getDate(data) {
      if (data === undefined || data === null) return "";
      const d = new Date(data);
      return (
        "" +
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes() +
        ":" +
        d.getSeconds()
      );
    },
  },
};
</script>
