<template>
  <div class="events-source">
    <div @click.stop.prevent="show = !show" class="btn btn-sm px-0">
      <span
        class="icon"
        :class="{ 'icon-arrow-down2': show, 'icon-arrow-right2': !show }"
      ></span>
      <b class="ml-1">{{ title }}</b>
      <span>({{ value ? value.length : 0 }} events)</span>
    </div>
    <div class="row pl-4" v-if="show">
      <div class="col col-12 mb-2">
        <button
          class="btn btn-sm btn-outline-primary icon icon-plus2"
          @click.prevent.stop="addEvent()"
        >
          <span class="pl-2" style="font-family: initial">Add event</span>
        </button>
        <button
          class="btn btn-sm btn-outline-danger icon icon-bin2"
          @click.prevent.stop="$refs.confirmDeleteAll.open()"
        >
          <span class="pl-2" style="font-family: initial">Delete all</span>
        </button>
      </div>
      <div class="col col-12 col-md-3 col-lg-2">
        <ul class="units-list list-group">
          <li
            v-for="(record, j) in value"
            :key="j"
            class="list-group-item btn btn-sm"
            :class="{
              active: record === editingRecord,
            }"
            @click.stop.prevent="onRecordClick(record)"
          >
            {{ record.name || "(no name)" }}
          </li>
          <li class="list-group-item bg-light" v-if="!value || !value.length">
            No events present
          </li>
        </ul>
      </div>
      <events-source-form
        v-model="editingRecord"
        v-if="editingRecord && value && value.length"
        :owners="sides"
        :editingRecord="editingRecord"
        @parentChange="onParentChange(editingRecord, $event)"
        @ownerChange="onOwnerChange(editingRecord, $event)"
        @delete="deleteRecordAt(value.indexOf(editingRecord))"
        @editSymbolClick="onEditSymbolClick(editingRecord, $event)"
        class="col-12 col-md-9 col-lg-10"
      />
    </div>
    <confirm-dialog
      ref="confirmDelete"
      :body="
        'Are you sure do you want to delete event ' +
        getName(deletingIndex) +
        '?'
      "
      confirmBtnClasses="btn-danger"
      @confirm="confirmDelete"
      @cancel="$refs.confirmDelete.close()"
    ></confirm-dialog>
    <confirm-dialog
      ref="confirmDeleteAll"
      :body="'Are you sure do you want to delete all events for this vignette?'"
      confirmBtnClasses="btn-danger"
      @confirm="confirmDeleteAll"
      @cancel="$refs.confirmDeleteAll.close()"
    ></confirm-dialog>
    <event-edit-symbol-dialog
      v-if="editingRecord && editingRecord.symbol_id"
      v-model="editingRecord.symbol_id"
      ref="symbolDialog"
      :events="scenario.content.events"
      :sides="scenario.content.sides"
      :editingRecord="editingRecord"
    />
  </div>
</template>
<script>
import EventsSourceFormVue from "./EventsSourceForm.vue";
import EventEditSymbolDialogVue from "./EventEditSymbolDialog.vue";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "events-source",
  components: {
    "events-source-form": EventsSourceFormVue,
    "event-edit-symbol-dialog": EventEditSymbolDialogVue,
  },
  props: {
    value: {
      required: true,
    },
    scenario: {
      required: true,
    },
    datastores: {
      required: true,
    },
    title: {
      required: false,
    },
    locations: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.update();
  },
  watch: {
    value() {
      this.update();
    },
  },
  methods: {
    confirmDeleteAll() {
      while (this.value.length) this.value.pop();
      this.$emit("input", this.value);
      this.$forceUpdate();
      this.$refs.confirmDeleteAll.close();
    },
    getName(index) {
      if (index === null || index < 0 || index >= this.value.length - 1)
        return "";
      else return this.value[index].name;
    },
    deleteRecordAt(index) {
      this.deletingIndex = index;
      this.$refs.confirmDelete.open();
    },
    confirmDelete() {
      const record = this.value[this.deletingIndex];
      this.value.splice(this.deletingIndex, 1);
      this.$refs.confirmDelete.close();
      this.$emit("input", [...this.value]);
      this.$forceUpdate();
    },
    onEditSymbolClick(record, event) {
      this.editingRecord = record;
      this.$refs.symbolDialog.open();
    },
    onRecordClick(record) {
      console.log("REcord: ", record);
      this.editingRecord = record;
    },
    onOwnerChange(record, event) {
      if (event.oldVal !== event.newVal) {
        record.symbol_id =
          record.symbol_id.substring(0, 3) +
          this.getApp6dAffiliation(
            this.sides.filter((s) => s.id === record.owner)[0].affiliation
          ) +
          record.symbol_id.substr(4);
      }
      this.$forceUpdate();
    },
    addEvent() {
      if (!this.value) return;

      const event = {
        id: uuidv4(),
        name: "",
        owner: this.sides[0].id,
        description: "",
        symbol_id:
          "100" +
          this.getApp6dAffiliation(this.sides[0].affiliation) +
          "1000000000000000",
        long: null,
        lat: null,
        elev: null,
        special_headquarters: "",
        created_at: new Date(),
      };

      this.value.push(event);
      this.update();
      this.editingRecord = event;
      this.$forceUpdate();
      this.$emit("input", this.value);
    },

    update() {
      const byId = {};
      const sides = [];
      for (let side of this.scenario.content.sides) {
        const id = side.name.toLowerCase().replace(/[\s]+/g, "_");
        byId[id] = { type: "group", name: side.name };
        sides.push({ id: id, text: side.name, affiliation: side.affiliation });
      }
      this.byId = byId;
      this.sides = sides;
      if (this.value.length && this.value.indexOf(this.editingRecord) === -1)
        this.editingRecord = this.value[0];
    },

    getApp6dAffiliation(affiliation) {
      switch (affiliation) {
        case "F":
          return 3;
        case "H":
          return 6;
        case "N":
          return 4;
        case "U":
          return 1;
        default:
          return null;
      }
    },
  },
  data() {
    return {
      lastId: 0,
      sides: [],
      deletingIndex: null,
      editingRecord: null,
      show: false,
    };
  },
};
</script>
