<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3" v-if="hla">
        <h1 class="d-inline-block align-middle" v-if="hla.id">Edit HLA informations</h1>
        <h1 class="d-inline-block align-middle" v-else>New HLA Connector</h1>
        <help-popup :name="'HLA Information'"></help-popup>
        <div class="alert alert-danger" v-if="errors.length">
          <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
        </div>
        <form action @submit.prevent="insertOrUpdate()">
          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" name="name" autocomplete="off" class="form-control" placeholder="Hla connector name..."
              v-model="hla.name" />
          </div>
          <div class="form-group">
            <label for="name">Site ID</label>
            <input type="number" name="name" autocomplete="off" class="form-control" placeholder="Site ID..." min="1"
              v-model.number="hla.content.siteId" />
          </div>
          <div class="form-group">
            <label for="name">App ID</label>
            <input type="number" name="name" autocomplete="off" class="form-control" placeholder="Application ID..."
              min="1" v-model.number="hla.content.appId" />
          </div>
          <div class="form-group">
            <label for="type">RTI Host</label>
            <input type="text" name="name" autocomplete="off" class="form-control" placeholder="RTI Host..."
              v-model="hla.content.rti" />
          </div>
          <div class="form-group">
            <label for="type">RTI Port</label>
            <input type="number" min="1" name="name" autocomplete="off" class="form-control" placeholder="Port..."
              v-model.number="hla.content.port" />
          </div>
          <div class="form-group">
            <label for="address">Preset</label>
            <select name="type" class="form-control" v-model="hla.content.preset" placeholder="Select Preset...">
              <option value>Select Preset...</option>
              <option v-for="hlaPreSet in hlaPreSets" :value="hlaPreSet.value" :key="hlaPreSet.value">
                {{ hlaPreSet.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="username">Federation name</label>
            <input type="text" name="username" autocomplete="off" class="form-control" placeholder="Federation name..."
              v-model="hla.content.federationName" />
          </div>
          <div class="form-group mt-4">
            <label for="side">Icons</label>
            <div v-if="hla.content.icons.length === 0" class="col p-0 mb-2">
              <span>No icons set</span>
            </div>
            <div class="row mb-2" v-for="(icon, i) in hla.content.icons" :key="i">
              <div class="col-md col-sm-12">
                <input v-model="icon.key" type="text" class="form-control mb-1" placeholder="DIS Entity Type"
                  aria-label="iconKey" />
              </div>
              <div class="col-md col-sm-12">
                <input v-model="icon.value" type="text" class="form-control mb-1" placeholder="APP6 Symbol"
                  aria-label="iconValue" v-uppercase />
              </div>
              <div class="col-md col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="custom-control custom-checkbox">
                      <input 
                        v-model="icon.forAggregates" 
                        type="checkbox" 
                        class="custom-control-input" 
                        :id="'forAggregates-'+i"
                        :name="'forAggregates-'+i"/>
                      <label class="custom-control-label" :for="'forAggregates-'+i">Aggregates</label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="custom-control custom-checkbox">
                      <input 
                        v-model="icon.forDisaggregates" 
                        type="checkbox" 
                        class="custom-control-input" 
                        :id="'forDisaggregates-'+i"
                        :name="'forDisaggregates-'+i"/>
                      <label class="custom-control-label" :for="'forDisaggregates-'+i">Disaggregates</label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="custom-control custom-checkbox">
                      <input 
                        v-model="icon.forHumans" 
                        type="checkbox" 
                        class="custom-control-input" 
                        placeholder="Value"
                        :id="'forHumans-'+i"
                        :name="'forHumans-'+i"/>
                      <label class="custom-control-label" :for="'forHumans-'+i">Humans</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <button class="btn btn-danger text-nowrap px-1" data-repeater-delete="" type="button"
                  @click="removeIcon(i)">
                  <i class="bx bx-x"></i>
                  Delete
                </button>
              </div>
            </div>
            <button @click="addIcon" class="btn btn-primary">Add</button>
          </div>
          <div class="form-group mt-4">
            <label for="side">Damages</label>
            <div v-if="hla.content.damages.length === 0" class="col p-0 mb-2">
              <span>No damage set</span>
            </div>
            <div class="row mb-2" v-for="(damage, i) in hla.content.damages" :key="i">
              <div class="col-md col-sm-12">
                <input v-model="damage.key" type="text" class="form-control" placeholder="Damage 0-4" aria-label="damageKey" />
              </div>
              <div class="col-md col-sm-12">
                <input v-model.number="damage.value" type="text" class="form-control mb-1" placeholder="Strength 0-100"
                  aria-label="damageValue" />
              </div>
              <div class="col-auto">
                <button class="btn btn-danger text-nowrap px-1" data-repeater-delete="" type="button"
                  @click="removeDamage(i)">
                  <i class="bx bx-x"></i>
                  Delete
                </button>
              </div>
            </div>
            <button @click="addDamage" class="btn btn-primary">Add</button>
          </div>
          <div class="form-group mt-4">
            <label for="side">Sides</label>
            <div v-if="hla.content.sides.length === 0" class="col p-0 mb-2" s>
              <span>No side set</span>
            </div>
            <div class="row mb-2" v-for="(side, i) in hla.content.sides" :key="i">
              <div class="col-md-12 col-lg">
                <input v-model="side.key" type="text" class="form-control mb-1" placeholder="Faction ID"
                  aria-label="sideKey" />
              </div>
              <div class="col-md-12 col-lg">
                <input v-model="side.value" type="text" class="form-control mb-1" placeholder="Side"
                  aria-label="sideValue" />
              </div>
              <div class="col-md-12 col-lg">
                <select name="type" class="form-control" v-model="side.affiliation" placeholder="Select affiliation...">
                  <option value>Select affiliation...</option>
                  <option v-for="affiliation in affiliations" :value="affiliation.value" :key="affiliation.value">
                    {{ affiliation.name }}
                  </option>
                </select>
              </div>
              <div class="col-auto">
                <button class="btn btn-danger text-nowrap px-1" data-repeater-delete="" type="button"
                  @click="removeSide(i)">
                  <i class="bx bx-x"></i>
                  Delete
                </button>
              </div>
            </div>
            <button @click="addSide" class="btn btn-primary">Add</button>
          </div>
          <div class="form-group mt-4">
            <label for="side">Munitions</label>
            <div v-if="hla.content.munitions.length === 0" class="col p-0 mb-2">
              <span>No munitions set</span>
            </div>
            <div
              class="row mb-2"
              v-for="(munition, i) in hla.content.munitions"
              :key="i"
            >
              <div class="col-md col-sm-12">
                <input
                  v-model="munition.key"
                  type="text"
                  class="form-control"
                  placeholder="DIS Entity Type"
                  aria-label="munitionKey"
                />
              </div>
              <div class="col-md col-sm-12">
                <input
                  v-model.number="munition.value"
                  type="text"
                  class="form-control mb-1"
                  placeholder="Label"
                  aria-label="munitionValue"
                />
              </div>
              <div class="col-auto">
                <button
                  class="btn btn-danger text-nowrap px-1"
                  data-repeater-delete=""
                  type="button"
                  @click="removeMunition(i)"
                >
                  <i class="bx bx-x"></i>
                  Delete
                </button>
              </div>
            </div>
            <button @click="addMunition" class="btn btn-primary">Add</button>
          </div>
          <div class="form-group mt-4">
            <label class="custom-control custom-checkbox justify-content-start">
              <input type="checkbox" class="custom-control-input" @change="resetValue()"
                v-model="hla.content.showOnlyLeaf" />
              <span class="custom-control-label">Show only leaf units</span>
            </label>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary" v-if="hla.id">
              Save Hla Connector
            </button>
            <button type="submit" class="btn btn-primary" v-else>
              Add Hla Connector
            </button>
          </div>
          <span class="overlay align-self-center d-flex" v-if="loading">
            <div class="overlay-background bg-white opacity-50"></div>
            <div class="spinner-border text-primary m-auto" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </span>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import AdminMenu from "../Menu.vue";
import Autocomplete from "vuejs-auto-complete";
import eventBus from "../../../eventBus";
import HelpPopup from "../../../HelpPopup.vue";
export default {
  created() {
    this.loadHla();
  },
  components: {
    "admin-menu": AdminMenu,
    autocomplete: Autocomplete,
    "help-popup": HelpPopup
  },
  data() {
    return {
      errors: [],
      hla: {
        name: "",
        content: {
          siteId: 1,
          appId: 1,
          rti: "",
          preset: "",
          federationName: "",
          port: 8989,
          sides: [],
          icons: [],
          damages: [],
          munitions: [],
          showOnlyLeaf: false,
        },
      },
      hlaPreSets: [
        {
          name: "RPR",
          value: "RPR",
        },
        {
          name: "JTLS",
          value: "JTLS",
        },
        {
          name: "NETN",
          value: "NETN",
        },
      ],
      affiliations: [
        {
          name: "Friend",
          value: "F",
        },
        {
          name: "Enemy",
          value: "H",
        },
        {
          name: "Neutral",
          value: "N",
        },
      ],
      loading: false,
    };
  },
  directives: {
    uppercase: {
      update: function (el) {
        el.value = el.value.toUpperCase();
      },
    },
    lowercase: {
      update: function (el) {
        el.value = el.value.toLowerCase();
      },
    },
  },
  methods: {
    addSide(e) {
      e.preventDefault();
      this.hla.content.sides.push({
        key: "",
        value: "",
        affiliation: "",
      });
    },
    removeSide(i) {
      this.hla.content.sides.splice(i, 1);
    },
    addIcon(e) {
      e.preventDefault();
      this.hla.content.icons.push({
        key: "",
        value: "",
        forAggregates: true,
        forDisaggregates: true,
        forHumans: true,
      });
    },
    removeIcon(i) {
      this.hla.content.icons.splice(i, 1);
    },
    addDamage(e) {
      e.preventDefault();
      this.hla.content.damages.push({
        key: "",
        value: "",
      });
    },
    removeDamage(i) {
      this.hla.content.damages.splice(i, 1);
    },
    addMunition(e) {
      e.preventDefault();
      this.hla.content.munitions.push({
        key: "",
        value: ""
      })
    },
    removeMunition(i) {
      this.hla.content.munitions.splice(i, 1);
    },
    loadHla() {
      if (this.$route.params.id) {
        this.loading = true;
        this.$http
          .get("/rest/hla/" + this.$route.params.id)
          .then((res) => {
            this.loading = false;
            const hla = res.body;
            if (!hla.content.sides) {
              hla.content.sides = [];
            }
            if (!hla.content.icons) {
              hla.content.icons = [];
            }
            if (!hla.content.damages) {
              hla.content.damages = [];
            }
            if (!hla.content.munitions) {
              hla.content.munitions = [];
            }
            for(const icon of hla.content.icons){
              if(typeof icon.forAggregates === "undefined") icon.forAggregates = true;
              if(typeof icon.forDisaggregates === "undefined") icon.forDisaggregates = true;
              if(typeof icon.forHumans === "undefined") icon.forHumans = true;
            }
            this.hla = hla;
          })
          .catch((res) => {
            this.loading = false;
            if (res.status === 401) {
              eventBus.$emit("logout");
            } else if (res.body && res.body.error) {
              this.errors.push(res.body.error.message);
            } else {
              this.errors.push("Error " + res.status);
            }
          });
      }
    },
    insertOrUpdate() {
      this.errors = [];
      if (this.hla.id) this.update();
      else this.insert();
    },
    update() {
      this.loading = true;
      this.$http
        .post("/rest/hla/" + this.hla.id, this.hla)
        .then((res) => {
          this.loading = false;
          this.$router.push("../hla");
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        });
    },
    insert() {
      this.loading = true;
      this.$http
        .put("/rest/hla", this.hla)
        .then((res) => {
          this.loading = false;
          this.$router.push("../hla");
        })
        .catch((res) => {
          this.loading = false;
          if (res.status === 401) {
            eventBus.$emit("logout");
          } else if (res.body && res.body.error) {
            this.errors.push(res.body.error.message);
          } else {
            this.errors.push("Error " + res.status);
          }
        });
    },
  },
};
</script>
