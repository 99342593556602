<template>
  <div class="container-main">
    <div class="d-flex">
      <admin-menu></admin-menu>
      <div class="flex-grow-1 p-3">
        <h1 class="d-inline-block align-middle">Cache Status</h1>
        <help-popup :name="'Cache'"></help-popup>
        <div class="alert alert-danger" v-if="error">{{ error }}</div>
        <div class="map-container">
          <div id="cache-map" class="map cache-map"></div>
        </div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="{ active: tab === 'request' }" href="#"
              @click.stop.prevent="setTab('request')">Cache Form</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: tab === 'package' }" href="#"
              @click.stop.prevent="setTab('package')">Cache Package
              {{
              this.cachePackage.requests.length > 0
              ? "(" + this.cachePackage.requests.length + ")"
              : ""
              }}</a>
          </li>
        </ul>
        <div v-if="tab === 'request'">
          <div class="row p-3">
            <button class="btn btn-primary" @click="useMapExtent">
              Map Extent
            </button>
            <button class="btn btn-primary ml-1" @click="useWorldExtent">
              World Extent
            </button>
            <br />
          </div>
          <form @submit.prevent="addCacheRequest" class="form-row">
            <div class="form-group col-12">
              <label for="map">Map</label>
              <input id="map" type="text" v-model="addReq.map" name="map" class="form-control mb-2" />
            </div>
            <div class="form-group col-md-6 col-lg-4">
              <label for="minX">Min X</label>
              <input type="number" step="any" v-model.number="addReq.minX" name="minX" class="form-control"
                @input="updateMapGeometry" />
            </div>
            <div class="form-group col-md-6 col-lg-4">
              <label for="minY">Min Y</label>
              <input type="number" step="any" v-model.number="addReq.minY" name="minY" class="form-control"
                @input="updateMapGeometry" />
            </div>
            <div class="form-group d-none d-lg-block col-lg-4">
              <label for="minZ">From Z</label>
              <input type="number" v-model.number="addReq.minZ" name="minZ" class="form-control" />
            </div>
            <div class="form-group col-md-6 col-lg-4">
              <label for="maxX">Max X</label>
              <input type="number" step="any" v-model.number="addReq.maxX" name="maxX" class="form-control"
                @input="updateMapGeometry" />
            </div>
            <div class="form-group col-md-6 col-lg-4">
              <label for="maxY">Max Y</label>
              <input type="number" step="any" v-model.number="addReq.maxY" name="maxY" class="form-control"
                @input="updateMapGeometry" />
            </div>
            <div class="form-group d-block col-md-6 d-lg-none">
              <label for="minZ">From Z</label>
              <input type="number" v-model.number="addReq.minZ" name="minZ" min="0" max="17" class="form-control" />
            </div>
            <div class="form-group col-md-6 col-lg-4">
              <label for="minZ">To Z</label>
              <input type="number" v-model.number="addReq.maxZ" name="maxZ" min="0" max="17" class="form-control" />
            </div>
            <div class="row align-items-center col-12 p-3">
              <button type="button" class="btn btn-sm btn-primary mb-2" @click.stop.prevent="addCachePackage()">
                {{
                editIndex === -1
                ? "Add to package"
                : "Update request to package"
                }}
              </button>
              <button v-if="editIndex !== -1" type="button" class="btn btn-sm btn-warning ml-2 mb-2"
                @click.stop.prevent="backCachePackage()">
                Cancel Update
              </button>
              <span class="ml-2 mb-2">
                <span v-if="estimation.levels[addReq.maxZ]" class="alert p-1 mb-2" :class="
                  getLevelClass(estimation.levels[addReq.maxZ], 'alert')
                " @mouseenter="estimation.expanded = true" @mouseleave="estimation.expanded = false">Estimated total
                  {{ estimation.total === 1 ? "tile" : "tiles" }}:
                  <b>{{ estimation.total }}</b></span>
                <ul class="list-group cacheMenu" v-if="estimation.expanded">
                  <li class="list-group-item d-flex align-items-center p-1" v-for="(level, z) in estimation.levels"
                    :key="z">
                    <span class="mr-2">L. {{ z }} </span>
                    <span class="badge badge-pill mr-1" :class="getLevelClass(level, 'badge')">{{ level.total }}
                      {{ level.total === 1 ? "tile" : "tiles" }}</span>
                  </li>
                </ul>
              </span>
              <div class="col"></div>
              <button class="btn btn-sm btn-primary" type="submit">
                Add Cache Request
              </button>
            </div>
          </form>
          <div class="d-flex header-general alert alert-info mt-3">
            <span class="mr-1">Cache running tasks:</span>
            <span class="mr-1">{{ status.runningTasks }}</span>
            <span class="ml-2 mr-1">Cache requests:</span>
            <span class="mr-1">{{ status.requests.length }}</span>
            <span class="ml-2 mr-1">Refresh in {{ this.timerCounter }}s</span>
            <button class="btn btn-primary" @click="loadStatus">Refresh</button>
          </div>
          <div v-for="(req, i) in status.requests" :key="i" class="d-flex flex-column">
            <div class="d-flex rounded-0 p-3" :style="{ backgroundColor: '#d6d8db' }">
              <span class="mr-1 map">{{ req.map }}</span>
              <span class="mr-1 extent">{{ req.minX }},{{ req.minY }}:{{ req.maxX }},{{
              req.maxY
              }}</span>
              <button class="btn btn-sm btn-danger" @click="deleteRequest(i)">
                Delete
              </button>
            </div>
            <table class="table table-sm table-striped">
              <tbody>
                <tr v-for="(level, z) in req.levels" :key="z">
                  <td>{{ z }}</td>
                  <td colspan="2" class="w-100 p-0" style="vertical-align: middle">
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" :aria-valuenow="level.completed" aria-valuemin="0"
                        :aria-valuemax="level.total" :style="{
                          width:
                            ((level.completed / level.total) * 100).toFixed(2) +
                            '%',
                        }">
                        {{ level.completed }}/{{ level.total }}
                      </div>
                    </div>
                  </td>
                  <td class="text-nowrap">{{ level.errors }} errors</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="tab === 'package'">
          <div class="row p-3">
            <button class="btn btn-primary" @click.stop.prevent="$refs.file.click()">
              Load package
            </button>
            <input v-show="false" ref="file" type="file" @change="loadCachePackage($event)" accept=".json" />
            <button v-if="this.cachePackage.requests.length" type="button" class="btn btn-primary ml-2"
              @click="downloadCachePackage()">
              Download package
            </button>

            <div class="col"></div>
            <button v-if="this.cachePackage.requests.length" class="btn btn-primary ml-2"
              @click.stop.prevent="generateCachePackage()">
              Generate Cache
            </button>
            <button class="btn btn-primary" @click="useWorldExtent">
              World Extent
            </button>
            <br />
          </div>
          <table class="table table-sm table-striped mt-1" v-if="cachePackage && cachePackage.requests.length">
            <thead>
              <tr>
                <th colspan="5" class="text-center">
                  {{ file === "" ? "New cache package" : file }}
                </th>
                <th>
                  <span class="btn btn-outline-danger" @click="cleanCachePackage()">
                    <span>Clean Package</span>
                  </span>
                </th>
              </tr>
              <tr>
                <th scope="col"># pack</th>
                <th scope="col">Min (x:y)</th>
                <th scope="col">Max (x:y)</th>
                <th scope="col">Level</th>
                <th scope="col">Tiles (est.)</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(request, r) in cachePackage.requests" :key="r">
                <th scope="row">{{ r + 1 }}</th>
                <td>
                  ( {{ request.minX.toFixed(2) }} :
                  {{ request.minY.toFixed(2) }} )
                </td>
                <td>
                  ( {{ request.maxX.toFixed(2) }} :
                  {{ request.maxY.toFixed(2) }} )
                </td>
                <td>from {{ request.minZ }} to {{ request.maxZ }}</td>
                <td>
                  <span class="ml-2">
                    <span v-if="request.estimation.levels[request.maxZ]" class="alert p-1" :class="
                      getLevelClass(
                        request.estimation.levels[request.maxZ],
                        'alert'
                      )
                    " @mouseenter="request.hoverIndex = 'r'" @mouseleave="request.hoverIndex = 0">
                      {{ request.estimation.total }}</span>
                    <ul class="list-group cacheMenu" v-if="request.hoverIndex">
                      <li class="list-group-item d-flex align-items-center p-1"
                        v-for="(level, z) in request.estimation.levels" :key="z">
                        <span class="mr-2">L. {{ z }} </span>
                        <span class="badge badge-pill mr-1" :class="getLevelClass(level, 'badge')">{{ level.total }}
                          {{ level.total === 1 ? "tile" : "tiles" }}</span>
                      </li>
                    </ul>
                  </span>
                </td>
                <td>
                  <span class="btn btn-outline-primary" @click="updatePackage(r)">
                    <span class="icon icon-pencil4"></span>
                  </span>
                  <span class="btn btn-outline-secondary" :class="{ disabled: r >= cachePackage.requests.length - 1 }"
                    @click="moveDown(r)">
                    <span class="icon icon-arrow-down2"></span>
                  </span>
                  <span class="btn btn-outline-secondary" :class="{ disabled: r === 0 }" @click="moveUp(r)">
                    <span class="icon icon-arrow-up2"></span>
                  </span>
                  <span class="btn btn-outline-danger" @click="removeFromCachePackage(r)">
                    <span class="icon icon-bin2"></span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span class="overlay align-self-center d-flex" v-if="loading">
          <div class="overlay-background bg-white opacity-50"></div>
          <div class="spinner-border text-primary m-auto" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </span>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import olcss from "ol/ol.css";
import api from "../../../api";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { XYZ, Vector as VectorSource } from "ol/source";
import HelpPopup from "../../../HelpPopup.vue";
//for drawing
import Draw, { createBox } from "ol/interaction/Draw";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import { ScaleLine, defaults as defaultControls } from "ol/control";
import { Fill, Stroke, Style, Text } from "ol/style";
import { throttle } from "throttle-debounce";

export default {
  created() {
    this.updateMapGeometry = throttle(300, this.updateMapGeometry);
    //this.updateMapGeometry = debounce(300, this.updateMapGeometry);
    this.estimateCache = throttle(300, this.estimateCache);
    this.addCachePackage = throttle(300, this.addCachePackage);
  },
  components: {
    "help-popup": HelpPopup
  },
  mounted() {
    this.initMap();
    this.initInteraction();
    this.useWorldExtent();
    this.estimateCache();
    this.loadStatus();
    this.start();
  },
  destroyed() {
    this.map.dispose();
    this.stop();
    this.$snotify.clear();
  },
  watch: {
    "addReq.minX"() {
      this.estimateCache();
    },
    "addReq.minY"() {
      this.estimateCache();
    },
    "addReq.maxX"() {
      this.estimateCache();
    },
    "addReq.maxY"() {
      this.estimateCache();
    },
    "addReq.minZ"() {
      this.estimateCache();
    },
    "addReq.maxZ"() {
      this.estimateCache();
    },
  },
  methods: {
    start() {
      if (this.interval) this.stop();
      this.interval = setInterval(this.timerFunc.bind(this), 1000);
      this.timerFunc();
    },
    stop() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    timerFunc() {
      if (this.timerCounter === 0) {
        this.loadStatus();
      } else {
        this.timerCounter -= 1;
      }
    },
    setTab(tab) {
      this.tab = tab || "request";
    },
    deleteRequest(index) {
      this.$http
        .delete(api + "/cache/" + index)
        .then((res) => {
          this.error = "";
          this.loadStatus();
        })
        .catch((err) => {
          this.error = err.message || "Unexpected Server Error";
        });
    },
    async generateCachePackage() {
      if (this.cachePackage && this.cachePackage.requests.length > 0) {
        for (const req of this.cachePackage.requests) {
          try {
            this.loading = true;
            const res = await this.$http.post(api + "/cache", req);
            if (res.ok) {
              console.log("Added Request Successfull!");
              this.error = "";
              this.loadStatus();
            }
          } catch (err) {
            this.error =
              (err.body.error && err.body.error.message) ||
              "Unexpected Server Error";
            this.loading = false;
          }
        }
        this.loading = false;
        this.setTab("request");
      }
    },
    addCacheRequest() {
      let error = this.checkAddReq();
      if (error) {
        this.error = error;
        return;
      }
      this.$http
        .post(api + "/cache", this.addReq)
        .then((res) => {
          console.log("Added Request Successful!");
          this.error = "";
          this.loadStatus();
        })
        .catch((err) => {
          this.error =
            (err.body.error && err.body.error.message) ||
            "Unexpected Server Error";
        });
    },
    estimateCache() {
      let error = this.checkAddReq();
      if (error) {
        this.error = error;
        return;
      }
      this.$http
        .post(api + "/cache/estimate", this.addReq)
        .then((res) => {
          console.log("Estimated Request Successful!");
          this.error = "";
          this.estimation = res.body;
        })
        .catch((err) => {
          this.error =
            (err.body.error && err.body.error.message) ||
            "Unexpected Server Error";
        });
    },
    removeFromCachePackage(i) {
      this.cachePackage.requests.splice(i, 1);
      this.updatePackageLayer();
      if (!this.cachePackage.requests.length) this.file = "";
    },
    cleanCachePackage() {
      this.cachePackage.requests = [];
      this.file = "";
      this.updatePackageLayer();
    },
    addCachePackage() {
      let error = this.checkAddReq();
      if (error) {
        this.error = error;
        return;
      }
      let pack = {
        ...this.addReq,
        estimation: this.estimation,
        hoverIndex: this.hoverIndex,
      };
      if (this.editIndex === -1) {
        this.cachePackage.requests.push(pack);
      } else {
        this.cachePackage.requests.splice(this.editIndex, 1, pack);
        this.editIndex = -1;
        this.setTab("package");
      }
      this.updatePackageLayer();
      this.file = "";
    },
    downloadCachePackage() {
      if (!this.cachePackage || !this.cachePackage.requests.length) {
        this.error = "Cache Package is empty.";
        return;
      }
      const file = new Blob([JSON.stringify(this.cachePackage, null, 2)], {
        type: "application/json",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = "cache_package";
      link.click();
      URL.revokeObjectURL(link.href);
    },
    loadCachePackage(evt) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.parseCachePackage(e.target.result);
      };
      reader.readAsText(evt.target.files[0]);
      this.file = evt.target.files[0].name;
      evt.target.value = "";
    },
    parseCachePackage(data) {
      let content = JSON.parse(data);
      if (
        content &&
        content.type === "CachePackage" &&
        content.version &&
        Array.isArray(content.requests)
      ) {
        this.cachePackage = content;
        this.updatePackageLayer();
      } else {
        console.error("File loaded is not a valid cache package...");
        this.$snotify.error(
          "File loaded is not a valid cache package...",
          "Error"
        );
      }
    },
    loadStatus() {
      this.timerCounter = this.timerCounterDefault;
      this.$http
        .get(api + "/cache")
        .then((res) => {
          this.status = res.body;
          this.errorStatus = "";
        })
        .catch((err) => {
          console.error("Error getting cache status:", err);
          this.errorStatus =
            err.message || "Unexpected Server Error updating status";
        });
    },
    updatePackageLayer() {
      this.package_source.clear();
      if (this.cachePackage && this.cachePackage.requests.length > 0) {
        this.cachePackage.requests.forEach((req) => {
          let { minX: x, minY: y, maxX: X, maxY: Y } = req;
          const geometry = new Polygon([
            [
              [x, y],
              [x, Y],
              [X, Y],
              [X, y],
              [x, y],
            ],
          ]);
          const feature = new Feature({
            geometry,
            name: "Z: " + req.minZ + "-" + req.maxZ,
          });
          feature.setStyle(
            new Style({
              stroke: new Stroke({
                color: "red",
                width: 3,
              }),
              fill: new Fill({
                color: [255, 0, 0, 0.3],
              }),
              text: new Text({
                font: "bold 14px Verdana",
                fill: new Fill({
                  color: "red",
                }),
                stroke: new Stroke({
                  color: "#fff",
                  width: 2,
                }),
                offsetY: 10,
                text: feature.get("name"),
                placement: "line",
                baseline: "bottom",
                outlineWidth: 1,
              }),
            })
          );
          this.package_source.addFeature(feature);
        });
        //this.map.getLayers().insertAt(1, this.package_layer);
      } else {
        return;
      }
    },
    updatePackage(i) {
      this.editIndex = i;
      this.addReq = this.cachePackage.requests[i];
      this.setTab("request");
      this.updateMapGeometry();
    },
    backCachePackage() {
      this.editIndex = -1;
      this.setTab("package");
    },
    moveDown(i) {
      if (i >= this.cachePackage.requests.length) return;
      let reqMoved = this.cachePackage.requests.splice(i, 1)[0];
      this.cachePackage.requests.splice(i + 1, 0, reqMoved);
    },
    moveUp(i) {
      if (i === 0) return;
      let reqMoved = this.cachePackage.requests.splice(i, 1)[0];
      this.cachePackage.requests.splice(i - 1, 0, reqMoved);
    },
    initMap() {
      const vec_source = new VectorSource({ wrapX: false });
      this.vector = new VectorLayer({
        source: vec_source,
        style: new Style({
          stroke: new Stroke({
            color: "yellow",
            width: 3,
          }),
          fill: new Fill({
            color: [255, 211, 0, 0.1],
          }),
        }),
      });
      this.package_source = new VectorSource({ wrapX: false });
      this.package_layer = new VectorLayer({
        source: this.package_source,
        style: new Style({
          stroke: new Stroke({
            color: "red",
            width: 4,
          }),
          fill: new Fill({
            color: [255, 0, 0, 0.3],
          }),
        }),
      });
      this.map = new Map({
        controls: defaultControls().extend([
          new ScaleLine({
            units: "degrees",
          }),
        ]),
        view: new View({
          projection: "EPSG:4326",
          center: [0, 0],
          zoom: 1,
        }),
        layers: [
          new TileLayer({
            source: new XYZ({
              url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
              maxZoom: 17,
            }),
          }),
          this.package_layer,
          this.vector,
        ],
        target: "cache-map",
      });
    },
    initInteraction() {
      const vec_source = this.vector.getSource();
      this.draw = new Draw({
        source: vec_source,
        type: "Circle",
        geometryFunction: createBox(),
      });

      this.map.addInteraction(this.draw);
      this.draw.on("drawend", this.onDrawend.bind(this));
    },
    onDrawend(evt) {
      const vec_source = this.vector.getSource();
      const ex = evt.feature.getGeometry().getExtent();
      this.addReq.minX = ex[0];
      this.addReq.minY = ex[1];
      this.addReq.maxX = ex[2];
      this.addReq.maxY = ex[3];
      if (vec_source.getFeatures().length > 0) vec_source.clear();
    },
    updateMapGeometry() {
      const vec_source = this.vector.getSource();
      const features = vec_source.getFeatures();
      const { minX: x, minY: y, maxX: X, maxY: Y } = this.addReq;
      const geometry = new Polygon([
        [
          [x, y],
          [x, Y],
          [X, Y],
          [X, y],
          [x, y],
        ],
      ]);
      if (features.length === 0) {
        const feature = new Feature({ geometry });
        vec_source.addFeature(feature);
      } else {
        const feature = features[0];
        feature.setGeometry(geometry);
      }
    },
    useMapExtent() {
      const exMap = this.map.previousExtent_; //da controllare
      this.addReq.minX = exMap[0];
      this.addReq.minY = exMap[1];
      this.addReq.maxX = exMap[2];
      this.addReq.maxY = exMap[3];
      this.updateMapGeometry();
    },
    useWorldExtent() {
      //const ex1 = [-20026376.39, -20048966.1, 20026376.39, 20048966.1]; //EPSG:3857
      const ex1 = [-180.0, -90.0, 180.0, 90.0];
      this.addReq.minX = ex1[0];
      this.addReq.minY = ex1[1];
      this.addReq.maxX = ex1[2];
      this.addReq.maxY = ex1[3];
      this.updateMapGeometry();
      const geom = this.vector.getSource().getFeatures()[0].getGeometry();
      this.map.getView().fit(geom, { size: this.map.getSize() });
    },
    checkAddReq() {
      let error = "";
      if (!this.addReq.map) {
        error = "Map Required";
      } else if (this.addReq.minX > this.addReq.maxX) {
        error = "Min X is greater than Max X";
      } else if (this.addReq.minY > this.addReq.maxY) {
        error = "Min Y is greater than Max Y";
      } else if (this.addReq.minZ > this.addReq.maxZ) {
        error = "Min Z is greater than Max Z";
      } else if (this.addReq.maxZ > 17 || this.addReq.minZ > 17) {
        error = "MaxZ or MinZ must lower than 18";
      }
      return error;
    },
    getLevelClass(level, prefix) {
      prefix = prefix || "";
      let levelClass = "-secondary";
      const { limits } = this;
      if (level.total > limits.warning && level.total <= limits.danger) {
        levelClass = "-warning";
      } else if (level.total > limits.danger) levelClass = "-danger";

      return prefix + levelClass;
    },
  },
  data() {
    return {
      addReq: {
        map: "world",
        minX: 0,
        minY: 0,
        maxX: 0,
        maxY: 0,
        minZ: 0,
        maxZ: 5,
        proj: "EPSG:4326",
      },
      error: "",
      errorStatus: "",
      status: {
        requests: [],
        runningTasks: 0,
        running: false,
      },
      estimation: {
        expanded: false,
        total: 0,
        levels: {},
      },
      timerCounterDefault: 20,
      timerCounter: 20,
      limits: {
        warning: 10000,
        danger: 30000,
      },
      cachePackage: {
        requests: [],
        type: "CachePackage",
        version: "1.0",
      },
      tab: "request",
      file: "",
      editIndex: -1,
      hoverIndex: 0,
      loading: false,
    };
  },
};
</script>

<style>

</style>
