export const padWithZeros = (vNumber, width) => {
  var numAsString = vNumber.toString();
  while (numAsString.length < width) {
    numAsString = "0" + numAsString;
  }
  return numAsString;
};

export const addZero = vNumber => {
  return padWithZeros(vNumber, 2);
};

export const printDateTime = date => {
  if (date === undefined || date === null) return "";
  const d = new Date(date);
  return (
    "" +
    d.getFullYear() +
    "-" +
    addZero(d.getMonth() + 1) +
    "-" +
    addZero(d.getDate()) +
    " " +
    addZero(d.getHours()) +
    ":" +
    addZero(d.getMinutes()) +
    ":" +
    addZero(d.getSeconds())
  );
};

export const printDateDiff = (dateStart, dateEnd) => {
  if (dateStart === undefined || dateStart === null) return "";
  if (dateEnd === undefined || dateEnd === null) return "";

  const ds = new Date(dateStart);
  const de = new Date(dateEnd);
  //console.log(printDateTime(ds) + " - " + printDateTime(de))
  let diffSeconds = Math.floor((de - ds) / 1000);
  const hours = Math.floor(diffSeconds / 3600);
  diffSeconds %= 3600;
  const minutes = Math.floor(diffSeconds / 60);
  const seconds = diffSeconds % 60;
  return "" + addZero(hours) + ":" + addZero(minutes) + ":" + addZero(seconds);
};
const MASACodes = [
  {
    id: 10000,
    name: "Forest",
    file: "Forest",
    type: "Polygon",
  },
  {
    id: 10001,
    name: "Plantation",
    file: "Plantation",
    type: "Polygon",
  },
  {
    id: 10002,
    name: "Swamp",
    file: "Swamp",
    type: "Polygon",
  },
  {
    id: 10003,
    name: "Urban",
    file: "Urban_Point",
    type: "Point",
  },
  {
    id: 10003,
    name: "Urban",
    file: "Urban_Area",
    type: "Polygon",
  },
  {
    id: 10004,
    name: "WaterArea",
    file: "Water",
    type: "Polygon",
  },
  {
    id: 9999,
    name: "**Sea(only for sea/land shape export)",
    file: "Water",
    type: "Polygon",
  },
  {
    id: 10005,
    name: "Sand",
    file: "Sand",
    type: "Polygon",
  },
  {
    id: 10006,
    name: "Ice",
    file: "Ice",
    type: "Polygon",
  },
  {
    id: 10007,
    name: "Mountain",
    file: "Mountain",
    type: "Polygon",
  },
  {
    id: 20001,
    name: "Motorway",
    file: "Road",
    type: "Line",
  },
  {
    id: 20002,
    name: "LargeRoad",
    file: "Road",
    type: "Line",
  },
  {
    id: 20003,
    name: "MediumRoad",
    file: "Road",
    type: "Line",
  },
  {
    id: 20004,
    name: "SmallRoad",
    file: "Road",
    type: "Line",
  },
  {
    id: 20005,
    name: "Bridge",
    file: "Bridge",
    type: "Line",
  },
  {
    id: 20006,
    name: "RailRoad",
    file: "RailRoad",
    type: "Line",
  },
  {
    id: 20007,
    name: "Tunnel",
    file: "Tunnel",
    type: "Line",
  },
  {
    id: 21000,
    name: "Cliff",
    file: "Cliff",
    type: "Line",
  },
  {
    id: 21001,
    name: "LargeRiver",
    file: "River",
    type: "Line",
  },
  {
    id: 21002,
    name: "MediumRiver",
    file: "River",
    type: "Line",
  },
  {
    id: 21003,
    name: "SmallRiver",
    file: "River",
    type: "Line",
  },
];

export default {
  padWithZeros,
  addZero,
  printDateTime,
  printDateDiff,
  MASACodes,
};
