<template>
  <div class="w-100 p-2" v-if="scenario && scenario.content">
    <div class="d-flex" v-if="scenario.content.clockService">
      <span class="h2 flex-grow-1"> </span>
      <button class="btn btn-outline-danger my-1" v-if="scenario.content.clockService"
        @click.prevent="confirmRemoveClockService()">
        Remove Clock Service
      </button>
    </div>
    <div v-else class="d-flex">
      <button class="btn btn-outline-primary my-1" @click.prevent="createClockService()">
        Add Clock Service
      </button>
    </div>
    <div v-if="scenario.content.clockService">
      <table v-if="scenario.content.clockService" class="table table-striped new-scenario-sides ">
        <thead style=" text-align: center;  ">
          <tr>
            <th>Real Time</th>
            <th>Hidden</th>
            <th class="col-1">Speed</th>
            <th v-if="!scenario.content.clockService.realTime">Game Time(only if real time is false)</th>
          </tr>
        </thead>
        <tbody style=" text-align: center;  ">
          <tr>
            <td>
              <label class="custom-control custom-checkbox d-block">
                <input type="checkbox" class="custom-control-input" v-model="scenario.content.clockService.realTime"/>
                <span class="custom-control-label"></span>
              </label>
            </td>
            <td>
              <label class="custom-control custom-checkbox d-block">
                <input type="checkbox" class="custom-control-input" v-model="scenario.content.clockService.hidden"/>
                <span class="custom-control-label"></span>
              </label>
            </td>
            <td class="col-1">
              <input type="number" min="0" class="form-control form-control-sm "
                v-model.number="scenario.content.clockService.speed" v-model="scenario.content.clockService.speed"/>
            </td>
            <td v-if="!scenario.content.clockService.realTime">
              <date-picker  class="p-0" v-model="scenario.content.clockService.time" :format="'YYYY-MM-DD HH:mm:ss'"
              ></date-picker>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <confirm-dialog ref="confirmRemoveClockService"
      :body="'Are you sure do you want to delete dices section? All your dices settings will be lost!'"
      confirmBtnClasses="btn-danger" @confirm="confirmRemoveClockService"
      @cancel="$refs.confirmRemoveClockService.close()"></confirm-dialog>
  </div>
</template>
<script>

import DatePickerVue from "../../../../../xserver-common/components/DatePicker.vue";

export default {
  name: "clockservice-section",
  components: { "date-picker": DatePickerVue },
  props: ["scenario", "context", "value", "side", "editingRecord"],

  data() {
    return {
      realTime: true,
      hidden: true,
      speed:1,
      paused:false,
      time: Date.now()
    }

  },

  methods: {
    createClockService() {
      const clockWidget = {
        realTime: this.realTime,
        hidden: this.hidden,
        speed: this.speed,
        paused: this.paused,
        time: this.time
      };

      this.scenario.content.clockService = clockWidget;
      this.$forceUpdate();
    },
    confirmRemoveClockService() {
      if (this.scenario.content.hasOwnProperty("clockService")) {
        delete this.scenario.content.clockService;
      }
      this.$refs.confirmRemoveClockService.close();
      this.$forceUpdate();
    },
  }
}

</script>